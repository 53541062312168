import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import {
    Row, Col, Tab, Tabs, Pagination,
} from "react-bootstrap";
import "./CollectorProfile.css";
import CollectorTablesDetails from "./CollectorTablesDetails";
import LoadingScreen from '../../components/common/LoadingScreen/LoadingScreen';
import { getFromAgel } from "../../utils/api";
import TransferMoneyImageModal from "./TransferMoneyImageModal";
import AuthorizationRequiredScreen from "../AuthorizationRequiredScreen/AuthorizationRequiredScreen";

//permission
import { userPermissionsAndActionsContext } from "../../App";
import { userValidPermissionAndAction } from "../../utils/permissionsAndActions";

export default function CollectorProfile(props) {
    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext);
    let viewCashInCashOutPermission = userValidPermissionAndAction(validPermissionsAndActions, "collector", "viewCollectorOperations");

    const params = useParams();
    const collectorId = params.id;

    // cash in/out states
    const [cashIn, setCashIN] = useState([]);
    const [cashOut, setCashOut] = useState([]);
    const [cashInFetchDataFlag, setCashInFetchDataFlag] = useState(true); // by default awl mara enter screen cash in loaded
    const [cashOutFetchDataFlag, setCashOutFetchDataFlag] = useState(false);
    const [transferedMoneyImageModalFlag, setTransferedMoneyImageModalFlag] = useState(false); // for setting image modal
    const [transferedMoneyImage, setTransferedMoneyImage] = useState(''); // for setting image
    // pagination
    const [totalCountOfCashInOrCashOut, setTotalCountOfCashInOrCashOut] = useState("");
    const [currentPaginationItem, setCurrentPaginationItem] = useState(0);
    const [items, setItems] = useState([0, 1, 2, 3]);
    const [nextBtnPaginationItemsFlag, setNextBtnPaginationItemsFlag] = useState(false);
    // state checker on corrupted images
    const [billCorruptedImg, setBillCorruptedImg] = useState(false);
    // loading indicator for requests send
    const [requestLoader, setRequestLoader] = useState(false);

    // fetch cash in data
    useEffect(() => {
        const fetchCashInData = async () => {
            if (cashInFetchDataFlag) {
                try {
                    setRequestLoader(true)
                    // cash in
                    const cashInResponse = await getFromAgel(`dashboard/collector/${collectorId}/operations`, {
                        params: {
                            page: currentPaginationItem,
                            type: "cashIn",
                        },
                    });
                    setCashIN(cashInResponse.data.data);
                    setTotalCountOfCashInOrCashOut(cashInResponse.data.count);
                } catch (e) { }
                setRequestLoader(false);
                ;
            }
        };
        fetchCashInData();
    }, [currentPaginationItem, cashInFetchDataFlag]);

    // fetch cash out  data
    useEffect(() => {
        const fetchCashOutData = async () => {
            if (cashOutFetchDataFlag) {
                try {
                    setRequestLoader(true)
                    // cash out
                    const cashOutResponse = await getFromAgel(`dashboard/collector/${collectorId}/operations`, {
                        params: {
                            page: currentPaginationItem,
                            type: "cashOut",
                        },
                    });
                    setCashOut(cashOutResponse.data.data);
                    setTotalCountOfCashInOrCashOut(cashOutResponse.data.count);
                } catch (e) { }
                setRequestLoader(false);
            }
        };
        fetchCashOutData();
    }, [currentPaginationItem, cashOutFetchDataFlag]);

    // toggle tabs
    const toggleTabsFlag = (e) => {
        if (e === "cashIn") {
            setCashInFetchDataFlag(true);
            setCashOutFetchDataFlag(false);
            setCurrentPaginationItem(0);
            setItems([0, 1, 2, 3]);
            setNextBtnPaginationItemsFlag(false);
        } else if (e === "cashOut") {
            setCashInFetchDataFlag(false);
            setCashOutFetchDataFlag(true);
            setCurrentPaginationItem(0);
            setItems([0, 1, 2, 3]);
            setNextBtnPaginationItemsFlag(false);
        }
    };

    const paginationItemsCount = Math.ceil(parseInt(totalCountOfCashInOrCashOut) / 10);
    const incrementPaginationItem = () => {
        if (items[3] >= (paginationItemsCount - 4)) {
            setNextBtnPaginationItemsFlag(false);
            const tempItems = [paginationItemsCount - 4, paginationItemsCount - 3, paginationItemsCount - 2, paginationItemsCount - 1];
            setItems([...tempItems]);
        } else {
            setNextBtnPaginationItemsFlag(true);
            const tempItems = [currentPaginationItem + 1, currentPaginationItem + 2, currentPaginationItem + 3, currentPaginationItem + 4];
            setItems([...tempItems]);
        }
        setCurrentPaginationItem(currentPaginationItem + 1);
    };

    const fetchLastPaginationPage = () => {
        if (paginationItemsCount < 4) {
            const tempItems = [];
            for (let i = 0; i < paginationItemsCount; i + 1) {
                tempItems.push(i); // append in array
            }
            setItems([...tempItems]);
        } else {
            const tempItems = [paginationItemsCount - 4, paginationItemsCount - 3, paginationItemsCount - 2, paginationItemsCount - 1];
            setItems([...tempItems]);
        }
        setCurrentPaginationItem(paginationItemsCount - 1);
        setNextBtnPaginationItemsFlag(false); // so that > disappear on click on >>
    };

    const decrementPaginationItem = () => {
        setItems((prev) => prev.map((i) => i - 1));
        setCurrentPaginationItem(currentPaginationItem - 1);
        setNextBtnPaginationItemsFlag(true);
    };

    const fetchFirstPaginationPage = () => {
        const tempItems = [0, 1, 2, 3];
        setItems([...tempItems]);
        setCurrentPaginationItem(0);
        setNextBtnPaginationItemsFlag(true);
    };

    if (viewCashInCashOutPermission) {
        return (
            <div className="collector-page-layout">
                {/* header */}
                <ul className="d-grid justify-content-start align-items-center mb-5">
                    <li className="mb-3">
                        <span className="collector-wallet">
                            wallet = {cashIn && cashIn[0] && cashIn[0].Collector && cashIn[0].Collector.wallet || 0}
                        </span>
                    </li>
                    <li className="mb-3">
                        <span className="collector-wallet">
                            Total cash collected = {cashIn && cashIn[0] && cashIn[0].Collector && cashIn[0].Collector.totalCashInWallet || 0}
                        </span>
                    </li>
                </ul>

                <Tabs defaultActiveKey="cashIn" className="mb-5" justify onSelect={toggleTabsFlag}>
                    <Tab eventKey="cashIn" title="Cash in">
                        {requestLoader && cashIn.length === 0 ?
                            <LoadingScreen />
                            : (!requestLoader && cashIn.length != 0) ?
                                cashIn.map((i) => (
                                    <CollectorTablesDetails
                                        collectorDetailsData={i}
                                        setTransferedMoneyImageModalFlag={setTransferedMoneyImageModalFlag}
                                        setTransferedMoneyImage={setTransferedMoneyImage}
                                        billCorruptedImg={billCorruptedImg}
                                        type="cashIn"
                                    />
                                )) : null}
                    </Tab>
                    <Tab eventKey="cashOut" title="Cash out">
                        {requestLoader && cashOut.length === 0 ?
                            <LoadingScreen />
                            : (!requestLoader && cashOut.length != 0) ?
                                cashOut.map((i) => (
                                    <CollectorTablesDetails
                                        collectorDetailsData={i}
                                        type="cashOut"
                                    />
                                )) : null}
                    </Tab>
                </Tabs>

                {/* pagination won't appear till the cash in or out data appear */}
                {((cashIn?.length !== 0 && cashInFetchDataFlag) || (cashOut?.length !== 0 && cashOutFetchDataFlag)) ? (
                    <Row>
                        <Col xs="12" className="d-flex justify-content-center align-items-center mt-4">
                            <Pagination className="retailer-table-pagination" style={{ width: "50%", flexFlow: "wrap" }}>
                                {/* first page */}
                                {currentPaginationItem !== 0
                                    && <Pagination.First onClick={() => { fetchFirstPaginationPage(); }} />}

                                {/* prev page */}
                                {currentPaginationItem > 0 && items[0] !== 0 ? (
                                    <Pagination.Prev onClick={() => { decrementPaginationItem(); }} />
                                ) : null}

                                {/* all pages */}
                                {
                                    items.map((i) => (
                                        <Pagination.Item active={currentPaginationItem === i} key={i} onClick={() => { setCurrentPaginationItem(i); }}>
                                            {i + 1}
                                        </Pagination.Item>
                                    ))
                                }

                                {/* next page */}
                                {nextBtnPaginationItemsFlag && items[3] <= paginationItemsCount
                                    && <Pagination.Next onClick={() => { incrementPaginationItem(); }} />}

                                {/* last page */}
                                {currentPaginationItem + 1 < paginationItemsCount
                                    && (<Pagination.Last onClick={() => { fetchLastPaginationPage(); }} />)}

                            </Pagination>
                        </Col>
                    </Row>
                )
                    : null}

                {/* transfered money image model */}
                {
                    transferedMoneyImageModalFlag
                        ? (
                            <TransferMoneyImageModal
                                setTransferedMoneyImageModalFlag={setTransferedMoneyImageModalFlag}
                                transferedMoneyImage={transferedMoneyImage}
                                setBillCorruptedImg={setBillCorruptedImg}
                            />
                        )
                        : null
                }
            </div>
        );
    }
    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }

}

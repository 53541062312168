import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Container, Row, Col, Pagination,
} from 'react-bootstrap';
import Select from 'react-select';
import { getFromAgel, postToAgel, putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

export default function SMSModal(props) {
  const {
    setShowSMS,
    setSuccessModal,
    setFailedModalWithErrorMsg,
    setErrorMsgInFailedModal,
  } = props;

  const [paymentDate, setPaymentDate] = useState([]);
  const [selectedPaymentDate, setSelectedPaymentDate] = useState("");
  const [validationNumber, setValidationNumber] = useState("");
  const [smsData, setSmsData] = useState([]);
  const [retailerName, setRetailerName] = useState("");
  const [retailerPhone, setRetailerPhone] = useState("");
  const [smsContent, setSmsContent] = useState("");

  // state checker on button
  const [sendSmsBtn, setSendSmsBtn] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        // payment Date
        const paymentDate = await getFromAgel(`dashboard/installmentsDates`);
        setPaymentDate(paymentDate.data.dates);
      } catch (err) {
        // console.log({ err });
      }
    }
    fetchData();
  }, []);

  const fetchSmsData = async (selectedDate) => {
    // dates sms info
    try {
      const smsDataResponse = await getFromAgel(`dashboard/installments/`, { params: { date: selectedDate } });
      console.log("smsDataResponse=", smsDataResponse.data);
      setSmsData(smsDataResponse.data);

      setRetailerName("");
      setRetailerPhone("");

      // console.log("date=", selectedDate)
    } catch (err) {
      // console.log({ err })
      // console.log("date=", selectedDate)
    }
  };

  const sendSMS = async (e) => {
    e.preventDefault();
    setSendSmsBtn(true);

    const temp = [{ phoneNumber: retailerPhone, sms: smsContent }, { phoneNumber: validationNumber, sms: smsContent }];

    const data = {
      phoneToSms: temp,
    };
    try {
      await postToAgel("dashboard/installments/send-sms", data);
      setSuccessModal(true);
    } catch (e) {
      setErrorMsgInFailedModal(e?.response?.data?.message);
      setFailedModalWithErrorMsg(true);
    }
    setShowSMS(false);
    setSendSmsBtn(false);
  };

  const items = [];

  // generate unique retailerid of smsData array
  const uniqueSmsData = [...new Set(smsData.map((i) => i.RetailerId))];
  // console.log({ uniqueSmsData })

  for (let i = 1; i <= uniqueSmsData.length; i++) {
    items.push(
      <Pagination.Item
        key={i}
        onClick={() => {
          const nameTemp = smsData[i - 1] && smsData[i - 1].Retailer && smsData[i - 1].Retailer.name;
          const phoneTemp = smsData[i - 1] && smsData[i - 1].Retailer && smsData[i - 1].Retailer.phoneNumber;

          setRetailerName(nameTemp);
          setRetailerPhone(phoneTemp);
          console.log({ nameTemp, phoneTemp });

          let totalInstallmentPrice = 0;
          let counternumbers = 0;

          const repeatedRetailerId = smsData.filter((RId) => RId.RetailerId === smsData[i - 1].RetailerId).map((retailerInstallement) => {
            totalInstallmentPrice = Math.round((parseInt(retailerInstallement.totalPrice)) + (parseInt(totalInstallmentPrice)));
            counternumbers = Math.round((parseInt(counternumbers)) + 1);

            return (
              `\n` + `\n` + `${counternumbers}- متمثلة في عملية شراء بتاريخ ${retailerInstallement.paymentDate} وقسطة ${retailerInstallement.totalPrice} جنيه مصري وكود الدفع الخاص بة لدي شركائنا ${retailerInstallement.serviceRefNumber}`
            );

            // <ol key={retailerInstallement.id}>
            //     {
            //         <li key={retailerInstallement.RetailerId} className="msg-content">

            //             <span style={{ paddingLeft: "0.3rem" }}>
            //                 متمثلة في عملية شراء بتاريخ {retailerInstallement.paymentDate}
            //             </span>
            //             <span style={{ paddingLeft: "0.3rem" }}>
            //                 وقسطة {retailerInstallement.totalPrice}
            //             </span>
            //             <span style={{ paddingLeft: "0.3rem" }}>
            //                 وكود الدفع الخاص بة لدي شركتنا {retailerInstallement.serviceRefNumber}
            //             </span>

            //         </li>
            //     }
            // </ol>
          });

          const tempMsg = `شريك آجل العزيز،\nبرجاء العلم انك لديك اقساط  مستحقة الدفع  بتاريخ : ${selectedPaymentDate}\n${repeatedRetailerId} \n \nبرجاء الالتزام بتسديد المبلغ : ${totalInstallmentPrice} جنيه مصري في الميعاد المتفق عليه لتجنب وقوع اي غرامات تأخير سداد.\n في حالة قيامك بالسداد بالفعل برجاء تجاهل الرسالة \nمع جزيل الشكر .`;

          setSmsContent(tempMsg);
        }}
      >
        {i}
      </Pagination.Item>,
    );
  }

  return (
    <div>
      <Modal show onHide={() => setShowSMS(false)} className="dashboard-modal mt-5" centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <Container className="mt-3">
            <Form onSubmit={sendSMS}>

              <Row className="mb-3">
                <Col xs="12">
                  <Form.Group style={{ marginTop: "-3em" }}>
                    <Form.Label className="form-label-text">Dates</Form.Label>
                    <Select
                        className="search-select mt-2"
                        defaultValue={{ label: "choose a payment Date...", value: "payment Date" }}
                        onChange={(e) => { console.log("value=", e.value); fetchSmsData(e.value); setSelectedPaymentDate(e.value); }}
                        options={paymentDate.map((date) => ({ value: date && date.paymentDate, label: date && date.paymentDate }))}
                      />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs="6">
                  <Form.Group>
                    <Form.Label className="form-label-text">Retailer Name </Form.Label>
                    <Form.Control className="sms-input-field" type="text" defaultValue={retailerName && retailerName} disabled readOnly />
                  </Form.Group>
                </Col>

                <Col xs="6">
                  <Form.Group>
                    <Form.Label className="form-label-text">Retailer Phone </Form.Label>
                    <Form.Control className="sms-input-field" type="text" defaultValue={retailerPhone && retailerPhone} disabled readOnly />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label-text">Message</Form.Label>
                    <p dir="rtl" className="sms-msg-body">
                        {smsContent}
                      </p>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs="12">
                  <Form.Group>
                    <Form.Label className="form-label-text">Validation number </Form.Label>
                    <Form.Control className="sms-input-field" type="text" value={validationNumber} onChange={(e) => { setValidationNumber(e.target.value); }} required />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs="12" className="d-flex justify-content-center align-items-center">
                  <Pagination style={{ maxWidth: "100%", flexFlow: "wrap" }}>
                    {items && items}
                  </Pagination>
                </Col>
              </Row>

              <Modal.Footer className="d-flex justify-content-end align-items-center" style={{ width: "100%", paddingRight: "0" }}>
                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={!!sendSmsBtn}>
                  {sendSmsBtn
                    ? (<LoadingBtnSpinner />)
                    : (<span>Done</span>)}
                </button>
                <button className="roleCloseBtn" onClick={() => setShowSMS(false)}>Close</button>
              </Modal.Footer>

            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}

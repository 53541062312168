import React, { useEffect, useState, useContext } from 'react'
import { getFromAgel } from '../../utils/api';
import './PaymentReport.css'
import PaymentReportFilters from '../../components/PaymentReport/PaymentReport'
import SuccessModal from '../../components/common/SuccessModal';
import Failed_Modal from '../../components/common/FailedModal';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen';

import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';



export default function PaymentReport() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewReportPermission = userValidPermissionAndAction(validPermissionsAndActions, "Report", "viewReport")
    let viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity")
    let viewIndustriesPermission = userValidPermissionAndAction(validPermissionsAndActions, "industry", "viewIndustry")

    const [industries, setIndustries] = useState([])
    const [cities, setCities] = useState([])
    const [reportType, setReportType] = useState([])

    const [cities_status, set_cities_status] = useState(true)
    const [industries_status, set_industries_status] = useState(true)
    const [date_status, set_date_status] = useState(true)

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")


    async function fetchData() {
        try {

            // report types
            const reportTypes = await getFromAgel("dashboard/reportTypes")
            // console.log("reportTypes", reportTypes)
            setReportType(reportTypes.data.reports)

            //get cities //view cities permission
            if (viewCitiesPermission) {
                const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
                setCities(cityResponse.data.cities)
            }

            //industries //view industries permission
            if (viewIndustriesPermission) {
                const industryResponse = await getFromAgel(`${process.env.React_APP_GET_INDUSTRIES}`);
                setIndustries(industryResponse.data.industries)
            }

        }
        catch (err) {
            // console.log({ err });
        }
    }

    useEffect(() => {
        fetchData()
    }, [])



    if (viewReportPermission) {
        return (
            <div className="layout-top">
                <PaymentReportFilters
                    industries={industries}
                    cities={cities}
                    reportType={reportType}

                    //filters flag status
                    cities_status={cities_status}
                    industries_status={industries_status}
                    date_status={date_status}
                    set_cities_status={set_cities_status}
                    set_industries_status={set_industries_status}
                    set_date_status={set_date_status}

                    setSuccessModal={setSuccessModal}
                    setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                    setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                />


                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={fetchData}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }

            </div>
        )
    }

    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import NotFoundText from '../common/notFoundText/notFoundText';
import ActivationProcessTablesChunks from './ActivationProcessTablesChunks';


const RetailerActivationTable = (props) => {

    //props
    const {
        setRegisterModal,
        setFirstVisitModal,
        setFirstTeamModal,
        setSecondTeamModal,
        setContractPreparationModal,
        setSecondVisitModal,
        setTeamTwoSecondVisitModal,
        setTeamThreeSecondVisitModal,
        setFinalModal,
        setCurrent,

        pendingLoadingSkeleton,
        firstVisitLoadingSkeleton,
        firstTeamLoadingSkeleton,
        secondTeamLoadingSkeleton,
        contractPhaseLoadingSkeleton,
        secondVisitLoadingSkeleton,
        secondVisitFirstTeamLoadingSkeleton,
        secondVisitSecondTeamLoadingSkeleton,
        finalLoadingSkeleton,

        pendingList,
        firstVisitList,
        firstTeamList,
        secondTeamList,
        contractPhaseList,
        secondVisitList,
        secondVisitFirstTeamList,
        secondVisitSecondTeamTeamList,
        finalList,

        fetchPendingApData,
        fetchfirstVisitApData,
        fetchFirstTeamApData,
        fetchSecondTeamApData,
        fetchContractPhaseApData,
        fetchSecondVisitApData,
        fetchSecondVisitFirstTeamApData,
        fetchSecondVisitSecondTeamApData,
        fetchFinalApData,

        selectedCity,
        selectedIndustry,
        filterByNameOrPhoneNumber,

        pendingCounter,
        firstVisitCounter,
        firstTeamCounter,
        secondTeamCounter,
        contractPhaseCounter,
        secondVisitCounter,
        secondVisitFirstTeamCounter,
        secondVisitSecondTeamCounter,
        finalCounter,

        setPendingCounter,
        setFirstVisitCounter,
        setFirstTeamCounter,
        setSecondTeamCounter,
        setContractPhaseCounter,
        setSecondVisitCounter,
        setSecondVisitFirstTeamCounter,
        setSecondVisitSecondTeamCounter,
        setFinalCounter,

        pendingWasLastList,
        firstVisitWasLastList,
        firstTeamWasLastList,
        secondTeamWasLastList,
        contractPhaseWasLastList,
        secondVisitWasLastList,
        secondVisitFirstTeamWasLastList,
        secondVisitSecondTeamWasLastList,
        finalWasLastList,
    } = props;

    //ref tracking tables scroll
    const pendingRef = useRef();
    const firstVisitRef = useRef();
    const firstTeamRef = useRef();
    const secondTeamRef = useRef();
    const contractPhaseRef = useRef();
    const secondVisitRef = useRef();
    const secondVisitFirstTeamRef = useRef();
    const secondVisitSecondTeamRef = useRef();
    const finalRef = useRef();

    //handle scroll event in each table
    const onScrollTableVisits = (phaseRef, fetchPhaseVisits, setPhaseCounter, phaseCounter, phaseWasLastList) => {
        if (phaseRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = phaseRef.current;
            if (scrollTop + clientHeight < scrollHeight) {
                if (!phaseWasLastList) {
                    setPhaseCounter(phaseCounter + 1)
                    fetchPhaseVisits(phaseCounter + 1)
                }
            }

        }
    }

    return (
        <div>
            {/* NotFoundText filter result condition */}
            {pendingList.length === 0 && firstVisitList.length === 0 && firstTeamList.length === 0 && secondTeamList.length === 0 && contractPhaseList.length === 0 && secondVisitList.length === 0 && secondVisitFirstTeamList.length === 0 && secondVisitSecondTeamTeamList.length === 0 && finalList.length === 0 &&
                (selectedCity !== "" || selectedIndustry !== "" || filterByNameOrPhoneNumber !== "") &&
                !pendingLoadingSkeleton && !firstVisitLoadingSkeleton && !firstTeamLoadingSkeleton && !secondTeamLoadingSkeleton && !contractPhaseLoadingSkeleton && !secondVisitLoadingSkeleton && !secondVisitFirstTeamLoadingSkeleton && !secondVisitSecondTeamLoadingSkeleton && !finalLoadingSkeleton ?
                <div className='tables-layout'>
                    <NotFoundText />
                </div>
                :
                <div className='Ap-table-layout d-flex'>

                    {/* pending */}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(pendingRef, fetchPendingApData, setPendingCounter, pendingCounter, pendingWasLastList) }}
                        ref={pendingRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={pendingList}
                            setCurrent={setCurrent}
                            setModal={setRegisterModal}
                            tableTitle={"Team Lead"}
                            tableStatus={"pending"}
                            btnName={"Registered"}
                            btnClass={"pending-btn"}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            contractPhaseLoadingSkeleton={contractPhaseLoadingSkeleton}
                            secondVisitLoadingSkeleton={secondVisitLoadingSkeleton}
                            secondVisitFirstTeamLoadingSkeleton={secondVisitFirstTeamLoadingSkeleton}
                            secondVisitSecondTeamLoadingSkeleton={secondVisitSecondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            contractPhaseCounter={contractPhaseCounter}
                            secondVisitCounter={secondVisitCounter}
                            secondVisitFirstTeamCounter={secondVisitFirstTeamCounter}
                            secondVisitSecondTeamCounter={secondVisitSecondTeamCounter}
                            finalCounter={finalCounter}

                        />
                    </Container>

                    {/* firstVisit */}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(firstVisitRef, fetchfirstVisitApData, setFirstVisitCounter, firstVisitCounter, firstVisitWasLastList) }}
                        ref={firstVisitRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={firstVisitList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            contractPhaseLoadingSkeleton={contractPhaseLoadingSkeleton}
                            secondVisitLoadingSkeleton={secondVisitLoadingSkeleton}
                            secondVisitFirstTeamLoadingSkeleton={secondVisitFirstTeamLoadingSkeleton}
                            secondVisitSecondTeamLoadingSkeleton={secondVisitSecondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setFirstVisitModal}
                            tableTitle={"1st Visit"}
                            tableStatus={"firstVisit"}
                            btnName={"Informer"}
                            btnClass={"firstVisit-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            contractPhaseCounter={contractPhaseCounter}
                            secondVisitCounter={secondVisitCounter}
                            secondVisitFirstTeamCounter={secondVisitFirstTeamCounter}
                            secondVisitSecondTeamCounter={secondVisitSecondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>

                    {/* firstTeam */}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(firstTeamRef, fetchFirstTeamApData, setFirstTeamCounter, firstTeamCounter, firstTeamWasLastList) }}
                        ref={firstTeamRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={firstTeamList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            contractPhaseLoadingSkeleton={contractPhaseLoadingSkeleton}
                            secondVisitLoadingSkeleton={secondVisitLoadingSkeleton}
                            secondVisitFirstTeamLoadingSkeleton={secondVisitFirstTeamLoadingSkeleton}
                            secondVisitSecondTeamLoadingSkeleton={secondVisitSecondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setFirstTeamModal}
                            tableTitle={"Team 1"}
                            tableStatus={"firstTeam"}
                            btnName={"Informer review"}
                            btnClass={"firstTeam-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            contractPhaseCounter={contractPhaseCounter}
                            secondVisitCounter={secondVisitCounter}
                            secondVisitFirstTeamCounter={secondVisitFirstTeamCounter}
                            secondVisitSecondTeamCounter={secondVisitSecondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>

                    {/* secondTeam */}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(secondTeamRef, fetchSecondTeamApData, setSecondTeamCounter, secondTeamCounter, secondTeamWasLastList) }}
                        ref={secondTeamRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={secondTeamList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            contractPhaseLoadingSkeleton={contractPhaseLoadingSkeleton}
                            secondVisitLoadingSkeleton={secondVisitLoadingSkeleton}
                            secondVisitFirstTeamLoadingSkeleton={secondVisitFirstTeamLoadingSkeleton}
                            secondVisitSecondTeamLoadingSkeleton={secondVisitSecondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setSecondTeamModal}
                            tableTitle={"Team 2"}
                            tableStatus={"secondTeam"}
                            btnName={"1st validation"}
                            btnClass={"secondTeam-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            contractPhaseCounter={contractPhaseCounter}
                            secondVisitCounter={secondVisitCounter}
                            secondVisitFirstTeamCounter={secondVisitFirstTeamCounter}
                            secondVisitSecondTeamCounter={secondVisitSecondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>

                    {/*contractPreparartion*/}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(contractPhaseRef, fetchContractPhaseApData, setContractPhaseCounter, contractPhaseCounter, contractPhaseWasLastList) }}
                        ref={contractPhaseRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={contractPhaseList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            contractPhaseLoadingSkeleton={contractPhaseLoadingSkeleton}
                            secondVisitLoadingSkeleton={secondVisitLoadingSkeleton}
                            secondVisitFirstTeamLoadingSkeleton={secondVisitFirstTeamLoadingSkeleton}
                            secondVisitSecondTeamLoadingSkeleton={secondVisitSecondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setContractPreparationModal}
                            tableTitle={"Contract"}
                            tableStatus={"contractPreparation"}
                            btnName={"Contract"}
                            btnClass={"contract-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            contractPhaseCounter={contractPhaseCounter}
                            secondVisitCounter={secondVisitCounter}
                            secondVisitFirstTeamCounter={secondVisitFirstTeamCounter}
                            secondVisitSecondTeamCounter={secondVisitSecondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>

                    {/*secondVisit*/}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(secondVisitRef, fetchSecondVisitApData, setSecondVisitCounter, secondVisitCounter, secondVisitWasLastList) }}
                        ref={secondVisitRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={secondVisitList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            contractPhaseLoadingSkeleton={contractPhaseLoadingSkeleton}
                            secondVisitLoadingSkeleton={secondVisitLoadingSkeleton}
                            secondVisitFirstTeamLoadingSkeleton={secondVisitFirstTeamLoadingSkeleton}
                            secondVisitSecondTeamLoadingSkeleton={secondVisitSecondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setSecondVisitModal}
                            tableTitle={"2nd Visit"}
                            tableStatus={"secondVisit"}
                            btnName={"Signature"}
                            btnClass={"secondVisit-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            contractPhaseCounter={contractPhaseCounter}
                            secondVisitCounter={secondVisitCounter}
                            secondVisitFirstTeamCounter={secondVisitFirstTeamCounter}
                            secondVisitSecondTeamCounter={secondVisitSecondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>

                    {/*secondVisitFirstTeam*/}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(secondVisitFirstTeamRef, fetchSecondVisitFirstTeamApData, setSecondVisitFirstTeamCounter, secondVisitFirstTeamCounter, secondVisitFirstTeamWasLastList) }}
                        ref={secondVisitFirstTeamRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={secondVisitFirstTeamList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            contractPhaseLoadingSkeleton={contractPhaseLoadingSkeleton}
                            secondVisitLoadingSkeleton={secondVisitLoadingSkeleton}
                            secondVisitFirstTeamLoadingSkeleton={secondVisitFirstTeamLoadingSkeleton}
                            secondVisitSecondTeamLoadingSkeleton={secondVisitSecondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setTeamTwoSecondVisitModal}
                            tableTitle={"Team 2"}
                            tableStatus={"secondVisitFirstTeam"}
                            btnName={"2nd Review"}
                            btnClass={"secondVisitFirstTeam-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            contractPhaseCounter={contractPhaseCounter}
                            secondVisitCounter={secondVisitCounter}
                            secondVisitFirstTeamCounter={secondVisitFirstTeamCounter}
                            secondVisitSecondTeamCounter={secondVisitSecondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>

                    {/*secondVisitSecondTeam*/}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(secondVisitSecondTeamRef, fetchSecondVisitSecondTeamApData, setSecondVisitSecondTeamCounter, secondVisitSecondTeamCounter, secondVisitSecondTeamWasLastList) }}
                        ref={secondVisitSecondTeamRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={secondVisitSecondTeamTeamList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            contractPhaseLoadingSkeleton={contractPhaseLoadingSkeleton}
                            secondVisitLoadingSkeleton={secondVisitLoadingSkeleton}
                            secondVisitFirstTeamLoadingSkeleton={secondVisitFirstTeamLoadingSkeleton}
                            secondVisitSecondTeamLoadingSkeleton={secondVisitSecondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setTeamThreeSecondVisitModal}
                            tableTitle={"Team 3"}
                            tableStatus={"secondVisitSecondTeam"}
                            btnName={"Approval"}
                            btnClass={"secondVisitSecondTeam-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            contractPhaseCounter={contractPhaseCounter}
                            secondVisitCounter={secondVisitCounter}
                            secondVisitFirstTeamCounter={secondVisitFirstTeamCounter}
                            secondVisitSecondTeamCounter={secondVisitSecondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>

                    {/*final*/}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(finalRef, fetchFinalApData, setFinalCounter, finalCounter, finalWasLastList) }}
                        ref={finalRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={finalList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            contractPhaseLoadingSkeleton={contractPhaseLoadingSkeleton}
                            secondVisitLoadingSkeleton={secondVisitLoadingSkeleton}
                            secondVisitFirstTeamLoadingSkeleton={secondVisitFirstTeamLoadingSkeleton}
                            secondVisitSecondTeamLoadingSkeleton={secondVisitSecondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setFinalModal}
                            tableTitle={"Manager"}
                            tableStatus={"final"}
                            btnName={"Activation"}
                            btnClass={"final-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            contractPhaseCounter={contractPhaseCounter}
                            secondVisitCounter={secondVisitCounter}
                            secondVisitFirstTeamCounter={secondVisitFirstTeamCounter}
                            secondVisitSecondTeamCounter={secondVisitSecondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>
                </div>
            }
        </div>
    )
}
export default RetailerActivationTable
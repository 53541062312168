import React from 'react'
import { FaCircle } from 'react-icons/fa'
import { MdOutlineError } from 'react-icons/md'

import DashboardChart from '../charts/DashboardCharts'

export default function HomePieChart(props) {
    const {
        statisticsData,
    } = props


    let temp = Object.keys(statisticsData).filter((i) => i === "wholesaler" || i === "retailer")


    return (
        <div>
            {/* charts */}
            <div className="charts-container-layout">
                <div className="charts-container">
                    {temp && temp.map((i) =>
                        <div>
                            <p className="chart-title">{i}</p>
                            {
                                parseInt(statisticsData[i].active) === 0 && parseInt(statisticsData[i].inactive) === 0 ?
                                    (
                                        <span className="charts-info-container pb-5">
                                            <MdOutlineError className="error-chart-response-icon" />
                                            <p className='error-chart-response-text'>Data not found try another range filter</p>
                                        </span>

                                    )
                                    :
                                    (
                                        <div>
                                            <DashboardChart val1={parseInt(statisticsData[i].active)} val2={parseInt(statisticsData[i].inactive)} />
                                            <div className="charts-info-container pb-5">
                                                <span className="charts-info">
                                                    <FaCircle color={"#10d99d"} />
                                                    <span>
                                                        Active
                                                    </span>
                                                </span>
                                                <br />
                                                <span className="charts-info">
                                                    <FaCircle color={"#c1c7cd"} />
                                                    <span>
                                                        Inactive
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    )}
                </div>
            </div>
        </div>

    )
}
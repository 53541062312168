import React, { useState } from 'react';
import { Col, Row, Modal, Form } from 'react-bootstrap'
import { putToAgel } from '../../utils/api';

//icons
import { IoCall } from 'react-icons/io5'

//components
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';



export default function SalesNumbersModal(props) {
    const {
        set_show_sales_numbers,
        id, //da el ws id
        retrievedSalesContactNumber,
        updateData,
        setSuccessModal,
        setErrorMsgInFailedModal,
        setFailedModalWithErrorMsg,
    } = props

    const [firstSalesNumber, setFirstSalesNumber] = useState(retrievedSalesContactNumber?.contactNumber1)
    const [secondSalesNumber, setSecondSalesNumber] = useState(retrievedSalesContactNumber?.contactNumber2)
    const [thirdSalesNumber, setThirdSalesNumber] = useState(retrievedSalesContactNumber?.contactNumber3)
    const [fourthSalesNumber, setFourthSalesNumber] = useState(retrievedSalesContactNumber?.contactNumber4)

    //done btn state checker
    const [doneBtn, setDoneBtn] = useState(false)


    const submitSalesNumbers = async (e) => {

        e.preventDefault()
        setDoneBtn(true)

        var data = {
            "contactNumber1": firstSalesNumber,
            "contactNumber2": secondSalesNumber,
            "contactNumber3": thirdSalesNumber,
            "contactNumber4": fourthSalesNumber
        }
        try {
            await putToAgel(`dashboard/wholesaler/addSalesContactNumber/${id}`, data)
            updateData()
            setSuccessModal(true)
        }
        catch (e) {
            setFailedModalWithErrorMsg(true)
            setErrorMsgInFailedModal(e?.response?.data?.message)
        }
        set_show_sales_numbers(false)
    }


    return (
        <div>
            <Modal show={true} onHide={() => set_show_sales_numbers(false)} className="dashboard-modal mt-5" centered >
                <Modal.Header closeButton className="cancel-msg-modal-header">
                    <span className='d-flex align-items-center justify-content-center'>
                        <IoCall className='ws-profile-salesnumber-call-icon' />
                        <span style={{ marginLeft: "0.3rem" }}>
                            sales numbers
                        </span>
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitSalesNumbers}>


                        {/* first sales number */}
                        <Form.Group className="mt-4">
                            <Row className='d-flex align-items-center'>
                                <Col xs="5">
                                    <Form.Label className="form-text">
                                        first Number
                                    </Form.Label>
                                </Col>
                                <Col xs="7">
                                    <Form.Control type="text" className="salesNumbersInputs" defaultValue={firstSalesNumber} onChange={(e) => { setFirstSalesNumber(e.target.value) }} />
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* second sales number */}
                        <Form.Group className="mt-4">
                            <Row className='d-flex align-items-center'>
                                <Col xs="5">
                                    <Form.Label className="form-text">
                                        second Number
                                    </Form.Label>
                                </Col>
                                <Col xs="7">
                                    <Form.Control type="text" className="salesNumbersInputs" defaultValue={secondSalesNumber} onChange={(e) => { setSecondSalesNumber(e.target.value) }} />
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* third sales number */}
                        <Form.Group className="mt-4">
                            <Row className='d-flex align-items-center'>
                                <Col xs="5">
                                    <Form.Label className="form-text">
                                        third Number
                                    </Form.Label>
                                </Col>
                                <Col xs="7">
                                    <Form.Control type="text" className="salesNumbersInputs" defaultValue={thirdSalesNumber} onChange={(e) => { setThirdSalesNumber(e.target.value) }} />
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* fourth sales number */}
                        <Form.Group className="mt-4 mb-4">
                            <Row className='d-flex align-items-center'>
                                <Col xs="5">
                                    <Form.Label className="form-text">
                                        fourth Number
                                    </Form.Label>
                                </Col>
                                <Col xs="7">
                                    <Form.Control type="text" className="salesNumbersInputs" defaultValue={fourthSalesNumber} onChange={(e) => { setFourthSalesNumber(e.target.value) }} />
                                </Col>
                            </Row>
                        </Form.Group>


                        <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                            <button className="role-add-btn-with-loader-spinner" type="submit" disabled={doneBtn ? true : false}>
                                {doneBtn ?
                                    (<LoadingBtnSpinner />)
                                    : (<span>Done</span>)}
                            </button>

                            <button className="roleCloseBtn" onClick={() => set_show_sales_numbers(false)}>Close</button>
                        </Modal.Footer>

                    </Form>
                </Modal.Body>
            </Modal>



        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap'
import './acquisition.css'
import { MdAddCircle } from 'react-icons/md'
import Select from 'react-select';

//api
import { getFromAgel } from '../../utils/api';

//components 
import Failed_Modal from '../../components/common/FailedModal'
import SuccessModal from '../../components/common/SuccessModal'
import CreateLead from '../../components/Acquisition/commonModalsAcquisitionBetweenWsAndRetailer/CreateLead';
import EditLead from '../../components/Acquisition/commonModalsAcquisitionBetweenWsAndRetailer/EditLead';
import MoveLeadComment from '../../components/Acquisition/commonModalsAcquisitionBetweenWsAndRetailer/MoveLeadComment';
import AcquisitionTable from '../../components/Acquisition/commonModalsAcquisitionBetweenWsAndRetailer/AcquisitionTable';

export default function WholesalerAcquisition() {

    //acquisition table data
    const [acquisitionData, setAcquisitionData] = useState([
        {
            "id": 2,
            "userType": "superAdmin",
            "name": "nanoo",
            "phoneNumber": "01023489010",
            "secondNumber": "01234567890",
            "storeName": "nanoo",
            "isActive": false,
            "status": "unassigned",
            "createdAt": "2022-12-14T09:36:04.623Z",
            "updatedAt": "2022-12-14T17:55:56.406Z",
            "StaffId": null,
            "CityId": 23,
            "IndustryId": 2,
            "currentAccountManagerId": 2,
            "previousAccountManagerId": 1,
            "SourceId": 1
        },
        {
            "id": 1,
            "userType": "superAdmin",
            "name": "nadiiaaaa",
            "phoneNumber": "01117393339",
            "secondNumber": "01555887710",
            "storeName": "nanoooooooooz",
            "isActive": false,
            "status": "assigned",
            "createdAt": "2022-10-14T09:36:04.623Z",
            "updatedAt": "2022-12-18T16:06:51.506Z",
            "StaffId": null,
            "CityId": 23,
            "IndustryId": 7,
            "currentAccountManagerId": 1,
            "previousAccountManagerId": 1,
            "SourceId": 1
        },
        {
            "id": 3,
            "userType": "superAdmin",
            "name": "hala",
            "phoneNumber": "01117393330",
            "secondNumber": "01528964780",
            "storeName": "nony",
            "isActive": true,
            "status": "firstCall",
            "createdAt": "2023-12-14T09:36:04.623Z",
            "updatedAt": "2022-12-14T09:36:04.623Z",
            "StaffId": null,
            "CityId": 43,
            "IndustryId": 1,
            "currentAccountManagerId": 1,
            "previousAccountManagerId": 1,
            "SourceId": 1
        },
        {
            "id": 4,
            "userType": "superAdmin",
            "name": "farah",
            "phoneNumber": "01117393330",
            "secondNumber": "01528964780",
            "storeName": "nony",
            "isActive": true,
            "status": "secondCall",
            "createdAt": "2023-12-14T09:36:04.623Z",
            "updatedAt": "2022-12-14T09:36:04.623Z",
            "StaffId": null,
            "CityId": 43,
            "IndustryId": 1,
            "currentAccountManagerId": 1,
            "previousAccountManagerId": 1,
            "SourceId": 1
        },
        {
            "id": 5,
            "userType": "superAdmin",
            "name": "gunish",
            "phoneNumber": "01117393330",
            "secondNumber": "01528964780",
            "storeName": "nony",
            "isActive": true,
            "status": "won",
            "createdAt": "2023-12-14T09:36:04.623Z",
            "updatedAt": "2022-12-14T09:36:04.623Z",
            "StaffId": null,
            "CityId": 43,
            "IndustryId": 1,
            "currentAccountManagerId": 1,
            "previousAccountManagerId": 1,
            "SourceId": 1
        },
        {
            "id": 5,
            "userType": "superAdmin",
            "name": "sandra",
            "phoneNumber": "01117393330",
            "secondNumber": "01528964780",
            "storeName": "nony",
            "isActive": true,
            "status": "failed",
            "createdAt": "2023-12-14T09:36:04.623Z",
            "updatedAt": "2022-12-14T09:36:04.623Z",
            "StaffId": null,
            "CityId": 43,
            "IndustryId": 1,
            "currentAccountManagerId": 1,
            "previousAccountManagerId": 1,
            "SourceId": 1
        }



    ])
    const [currentLead, setCurrentLead] = useState()//{ha set fe el id  bta3 kol current lead }


    //filters selectors
    const [cities, setCities] = useState([])
    const [industries, setIndustries] = useState([])
    const [agents, setAgents] = useState([])

    const [textInputSearch, setTextInputSearch] = useState("")
    const [filterOnCity, setFilterOnCity] = useState('');
    const [filterOnIndustry, setFilterOnIndustry] = useState('');
    const [filterOnAgent, setFilterOnAgent] = useState('');
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const fromDay = new Date(fromDate).toJSON()
    const toDay = new Date(toDate).toJSON()

    //modals
    //action modals
    const [createLeadModal, setCreateLeadModal] = useState(false)
    const [editLeadModal, setEditLeadModal] = useState(false)
    const [moveLeadCommentModal, setMoveLeadCommentModal] = useState(false) //hyb2a false

    //server response modal
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")

    // card movement states
    const [fromPhase, setFromPhase] = useState("")
    const [toPhase, seToPhase] = useState("")


    const fetchfiltersSelectorsData = async () => {
        try {
            //get cities
            const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
            console.log("cityResponse=", cityResponse.data.cities)
            setCities(cityResponse.data.cities)

            //get industries
            const industryResponse = await getFromAgel(`${process.env.React_APP_GET_INDUSTRIES}`);
            console.log("industryResponse=", industryResponse.data.industries)
            setIndustries(industryResponse.data.industries)

            // get agents
            const agentsResponse = await getFromAgel(`${process.env.React_APP_GET_AGENT}`)
            console.log('agentsResponse', agentsResponse.data.agents)
            setAgents(agentsResponse.data.agents)
        }
        catch (e) {
            //
        }
    }
    useEffect(() => {
        fetchfiltersSelectorsData()
    }, [])


    //fetch acquisition data table
    const fetchAcquisitionData = async () => {
        try {

            //get acquisition data table
            const acquisitionDataResponse = await getFromAgel("", {
                params: {
                    ...(textInputSearch ? { "filterByPhoneOrName": textInputSearch } : {}),
                    ...(filterOnCity ? { "CityId": filterOnCity } : {}),
                    ...(filterOnIndustry ? { "IndustryId": filterOnIndustry } : {}),
                    ...(filterOnAgent ? { "agent": filterOnAgent } : {}),
                    ...(fromDay ? { "fromDay": fromDay } : {}),
                    ...(toDay ? { "agent": toDay } : {}),

                }
            });

            console.log("acquisitionDataResponse=", acquisitionDataResponse.data)
            // setAcquisitionData(acquisitionDataResponse.data)
        }
        catch (err) {
            console.log('error=', err)
        }
    }

    useEffect(() => {
        fetchAcquisitionData()
    }, [])




    //search btn fn
    const search = () => {
        //bynade 3la nfs el fn ele btgeb el acquisition table bs byb3tlha el params ele et8ayrt
        fetchAcquisitionData()
    }


    return (
        <div>
            {/* row1 */}
            <Row className="layout-top">
                <Col xs="3" className=' text-start'>
                    {/* input */}
                    <div className='input-wrapper'>
                        <input type="text" placeholder="cutomer name / number " value={textInputSearch} onChange={(e) => setTextInputSearch(e.target.value)} />
                    </div>
                </Col>
                <Col xs="4" className='text-start'>
                    {/* search */}
                    <button className='role-search-btn' onClick={() => search()}>Search</button>
                </Col>
                <Col xs="5" className='text-end'>
                    {/* create new lead btn */}
                    <div className='create-lead-btn-positioning'>
                        <button className='add-role-btn' onClick={() => { setCreateLeadModal(true) }}>
                            <span className='d-flex align-items-center justify-content-center'>
                                <MdAddCircle size={20} className="add-role-btn-icon" />
                                <p>create lead</p>
                            </span>
                        </button>
                        {
                            createLeadModal ?
                                <CreateLead
                                    setCreateLeadModal={setCreateLeadModal}
                                    updateData={fetchAcquisitionData}
                                    setSuccessModal={setSuccessModal}
                                    setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                    setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                    userType={"wholesaler"}

                                />
                                : null
                        }
                    </div>
                </Col>

            </Row>

            {/* row2 */}
            <Row className="layout-row-2">
                <Col xs={2}>
                    <Select
                        className="search-select mt-2"
                        defaultValue={{ "label": "City", "value": 0 }}
                        onChange={(e) => { setFilterOnCity(e.value) }}
                        options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                    />
                </Col>
                <Col xs={2}>
                    <Select
                        className="search-select mt-2"
                        defaultValue={{ "label": "Industry", "value": 0 }}
                        onChange={(e) => { setFilterOnIndustry(e.value) }}
                        options={industries.map((industry) => ({ "value": industry.id, "label": industry.name }))}
                    />
                </Col>
                <Col xs={2}>
                    <Select
                        className="search-select mt-2"
                        defaultValue={{ "label": "Agent", "value": 0 }}
                        onChange={(e) => { setFilterOnAgent(e.value) }}
                        options={agents.map((agent) => ({ "value": agent.id, "label": agent.name }))}
                    />
                </Col>
            </Row>

            {/* row3 */}
            <Form onSubmit={search}>
                <Row className="layout-row-3">

                    <Col xs="3">
                        <Form.Group className='d-flex align-items-center'>
                            <Form.Label className="acquisition-date-flex-label">From</Form.Label>
                            <Form.Control type="date" className="acquisition-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} required />
                        </Form.Group>
                    </Col>
                    <Col xs="3">
                        <Form.Group className='d-flex align-items-center'>
                            <Form.Label className="acquisition-date-flex-label">To</Form.Label>
                            <Form.Control type="date" className="acquisition-date" value={toDate} onChange={(e) => setToDate(e.target.value)} required />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>


            {/* WS acquisition table  */}
            <AcquisitionTable
                acquisitionData={acquisitionData}
                setCurrentLead={setCurrentLead}
                currentLead={currentLead}
                setMoveLeadCommentModal={setMoveLeadCommentModal}
                setFromPhase={setFromPhase}
                seToPhase={seToPhase}
                setEditLeadModal={setEditLeadModal}
            />

            {/*  edit lead card */}

            {
                editLeadModal ?
                    <EditLead
                        setEditLeadModal={setEditLeadModal}
                        currentLead={currentLead}
                        updateData={fetchAcquisitionData}
                        setSuccessModal={setSuccessModal}
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        userType={"wholesaler"}
                    />
                    : null
            }

            {/* leave comment while drag lead card */}
            {
                moveLeadCommentModal ?
                    <MoveLeadComment
                        setMoveLeadCommentModal={setMoveLeadCommentModal}
                        currentLead={currentLead}
                        updateData={fetchAcquisitionData}
                        setSuccessModal={setSuccessModal}
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        userType={"wholesaler"}
                        fromPhase={fromPhase}
                        toPhase={toPhase}

                    />
                    : null
            }

            {/* success server response model */}
            {
                successModal ?
                    <SuccessModal
                        setSuccessModal={setSuccessModal}
                        updateData={fetchAcquisitionData}
                    />
                    : null
            }

            {failedModalWithErrorMsg ?
                (<Failed_Modal
                    setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                    errorMsg={errorMsgInFailedModal}
                />)
                : null
            }

        </div>
    )
}
import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'

export default function HomeCardDetails(props){
    const {
        title,
        number,
        bgColor,
        mt
    }=props
    return(
        <div>
        <Card className="posCard text-center" style={{backgroundColor:bgColor}}>
            <Card.Body>
                <Row>
                    <Col>
                        <Card.Title className='homeCard-title'>
                            <strong>{title&&title}</strong>
                        </Card.Title>
                    </Col>
                </Row>
                <Row style={{marginTop:mt}}>
                    <Col>
                        <h5 className="home-card-number">
                            {number&&number}
                        </h5>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </div>

    )
}
import React, { useState } from 'react'
import Select from 'react-select';

import { Modal, Form, Container, Row, Col } from "react-bootstrap"
import { postToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


const CreateStaffModal = (props) => {
    //props

    let {
        cities,
        roles,
        setCreateStaffModal,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal

    } = props;

    //create input Btn's values
    const [createName, setCreateName] = useState('');
    const [createPhone, setCreatePhone] = useState('');
    const [createEmail, setCreateEmail] = useState('');
    const [createPassword, setCreatePassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMsg, setPasswordMsg] = useState(false);
    const [passwordMsgLength, setPasswordMsgLength] = useState(false);
    const [createRole, setCreateRole] = useState('');
    const [createRoleFlag, setCreateRoleFlag] = useState('');
    const [createCity, setCreateCity] = useState('');
    const [createCityFlag, setCreateCityFlag] = useState('');

    //create btn state checker
    const [createBtn, setCreateBtn] = useState(false)



    //submit new staff in create btn
    const Submit = async (e) => {
        e.preventDefault();
        setCreateBtn(true);

        if (createPassword === confirmPassword && createPassword.length > 4 && createRole !== "" && createCity !== "") {
            var data = {
                'name': createName,
                'phoneNumber': createPhone,
                'password': createPassword,
                'email': createEmail,
                'RoleId': createRole,
                'CityId': createCity,
            }
            try {
                await postToAgel(`${process.env.React_APP_CREATE_NEW_STAFF}`, data)
                setSuccessModal(true)
            }
            catch (err) {
                console.log({err})
                setErrorMsgInFailedModal(err?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
            updateData()
            setCreateStaffModal(false)
        }
        else if (createRole === "") {
            setCreateRoleFlag(true)
            setCreateBtn(false);
        }
        else if (createCity === "") {
            setCreateCityFlag(true)
            setCreateBtn(false);

        }
        else if (createPassword.length < 4) {
            setPasswordMsgLength(true);
            setCreateBtn(false);

        }
        else if (createPassword !== confirmPassword === "") {
            setPasswordMsg(false);
            setCreateBtn(false);

        }
        else {
            setPasswordMsgLength(true);
            setPasswordMsg(true);
            setCreateCityFlag(true);
            setCreateRoleFlag(true)
            setCreateBtn(false);

        }
    }

    return (
        <div>
            <Modal show={true} onHide={() => setCreateStaffModal(false)} className="dashboard-modal mt-5" centered>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Create Staff</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={Submit}>

                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Full name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Name" value={createName} onChange={(e) => setCreateName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">Phone number</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Phone number" value={createPhone} onChange={(e) => setCreatePhone(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">Email</Form.Label>
                                <Form.Control type="email" className="role-input mt-2" placeholder="Enter Email" value={createEmail} onChange={(e) => setCreateEmail(e.target.value)} required />
                            </Form.Group>

                            <Row>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">Role</Form.Label>
                                        <Select
                                            className="search-select mt-2"
                                            defaultValue={{ "value": 0, "label": " select..." }}
                                            onChange={(e) => { setCreateRole(e.value) }}
                                            options={roles.map((role) => ({ "value": role.id, "label": role.name }))}
                                        />
                                    </Form.Group>
                                    {createRoleFlag && (<p className="select-error-msg">Please select a Role!</p>)}
                                </Col>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">City</Form.Label>
                                        <Select
                                            className="search-select mt-2"
                                            defaultValue={{ "value": 0, "label": " select..." }}
                                            onChange={(e) => { setCreateCity(e.value) }}
                                            options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                                        />
                                    </Form.Group>
                                    {createCityFlag && (<p className="select-error-msg">Please select a City!</p>)}
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">Password</Form.Label>
                                        <Form.Control type="Password" className="role-input mt-2" placeholder="Enter Password" value={createPassword} onChange={(e) => setCreatePassword(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">Confirm password</Form.Label>
                                        <Form.Control type="Password" className="role-input mt-2" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* incase password isnot the same */}
                            {passwordMsg && (<p className="password-confirm-msg">Password isn't match!</p>)}

                            {/* incase password length is too small */}
                            {passwordMsgLength && (<p className="password-confirm-msg">Password should be at least 5 digits!</p>)}


                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={createBtn ? true : false}>
                                    {createBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setCreateStaffModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>



        </div>
    )
}

export default CreateStaffModal

import React from 'react'
import { Table, Container } from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai'

const CitiesTable = (props) => {
    // props
    let cities = props.cities
    let selectedCountries = props.selectedCountries;
    let setCurrentCityModal = props.setCurrentCityModal;
    let setEditCities = props.setEditCities;


    return (
        <div className='tables-layout'>
            {/* Table */}
            <Container fluid>
                <Table responsive>
                    <thead className='text-center'>
                        <tr className='role-table-header'>
                            <th className="text-center" >ID</th>
                            <th className="text-center" >Arabic Name</th>
                            <th className="text-center" >English Name</th>
                            <th className="text-center" >Country</th>
                            <th className="text-center" >Status</th>
                            <th className="text-center" ></th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {cities.map((i) => //lazm no7t key lkol tr 34an byboz el dnya wel search
                            <tr key={i.id} className='role-table-text'>
                                <td className="text-center">{i.id}</td>
                                <td className="text-center">{i.ArName}</td>
                                <td className="text-center">{i.EnName}</td>

                                <td className="text-center">
                                    {selectedCountries.map((country) => {
                                        if (country.id === i.CountryId) {
                                            return (
                                                <p key={country.id}>{country.EnName}</p>
                                            )
                                        }
                                        return null;
                                    }
                                    )}
                                </td>
                                <td className='text-center' style={i.isActive ? { color: "#00BD85" } : null}>{i.isActive ? ("Active") : ("Inactive")}</td>
                                <td className='text-center'>
                                    <button
                                        className='role-edit-btn-cities'
                                        style={{ width: "50%" }}
                                        onClick={() => {
                                            setEditCities(true);
                                            setCurrentCityModal(i);
                                            // console.log(i)
                                        }}>
                                        Edit <AiOutlineEdit />
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default CitiesTable

import React, { useState } from 'react'

import { Modal, Container } from "react-bootstrap"
import { putToAgel } from '../../utils/api'
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn'


const CancelConfirmScreen = (props) => {

    const {
        current,
        setCancelConfirmModal,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        updateData,
    } = props

    //state checker on buttons 
    const [doneBtn, setDoneBtn] = useState(false)

    // cancel
    const cancel = async (e) => {

        e.preventDefault();
        setDoneBtn(true)

        var data = {
            "comment": "retentionCancel"
        }
        try {
            await putToAgel(`dashboard/wholesaler/activationProcess/${current.id}/retentionCancel`, data)
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setCancelConfirmModal(false)
        updateData()
        setDoneBtn(false)
    }


    return (
        <div>
            <Modal show={true} onHide={() => setCancelConfirmModal(false)} centered size="lg" className="dasboardModal">
                <Modal.Header closeButton></Modal.Header>

                <Modal.Body>
                    <Container className="mt-3 text-center">
                        <img src="/images/revert.svg" className="img-fluid revertImg" alt="revertImg" />
                        <p className="action-confirm-text mt-2"> Are you sure , You want to Cancel ?</p>
                    </Container>
                </Modal.Body>

                <Modal.Footer className='d-flex justify-content-end align-items-center mt-3' style={{ width: "100%", paddingRight: "0" }}>
                    <button className="role-add-btn-with-loader-spinner" type="submit" disabled={doneBtn ? true : false} onClick={cancel}>
                        {doneBtn ?
                            (<LoadingBtnSpinner />)
                            : (<span>Done</span>)}
                    </button>
                    <button className="roleCloseBtn" onClick={() => setCancelConfirmModal(false)}>Close</button>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default CancelConfirmScreen

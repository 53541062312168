import React, { useState } from 'react'

import { Modal, Form, Row, Col, Container, Spinner } from 'react-bootstrap';

//react icons
import { AiOutlineFileImage } from "react-icons/ai"
import { CgSoftwareDownload } from 'react-icons/cg'

import fileDownload from 'js-file-download'

import DynamicAF from '../RetailerApModals/DynamicAFAfterAgentVisit ';
import { getFileFromAgel, getFromAgel, putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

export default function ActivationForm(props) {

    const [AFDynamicData, setAFDynamicData] = useState([])
    const [AFStaticData, setAFStaticData] = useState([])
    const [AFRetailerData, setAFRetailerData] = useState([]);
    const [agentAFStaticData, setAgentAFStaticData] = useState([])
    const [estimatedCredit, setEstimatedCredit] = useState("")

    //state checker on buttons 
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)

    //edits
    const [userEdits, setUserEdits] = useState([])
    const [colEditsData, setColEditsData] = useState({})

    //contract states
    const [contractBeforeSignatureSpinner, setContractBeforeSignatureSpinner] = useState(false)
    const [contractAfterSignatureSpinner, setContractAfterSignatureSpinner] = useState(false)

    const {
        setShowAF,
        id,
        setAFRetailerStore,
        AFRetailerStore,
        APIdFromEachStore,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        setSuccessModal,
    } = props


    //contract before signature
    const downloadPdfBeforeSignature = async () => {
        try {
            /* get ws contract before signature */
            const contractResponse = await getFileFromAgel(`${process.env.React_APP_RETAILER_GET_CONTRACT}${APIdFromEachStore}`, { responseType: 'blob' })
            // console.log("contractResponse=", contractResponse)
            fileDownload(contractResponse.data, 'contract.pdf');
        }
        catch (e) {
            console.log({ e })
        }
        setContractBeforeSignatureSpinner(false)
    }

    //contract after signature
    const downloadPdfAfterSignature = async () => {
        try {
            /* get ws contract after signature */
            const contractResponse = await getFromAgel(`dashboard/retailer/activationProcess/${APIdFromEachStore}/downloadContract`)
            const contractData = contractResponse.data.url
            window.open(contractData);
        }
        catch (e) {
            console.log({ e })
        }
        setContractAfterSignatureSpinner(false)
    }


    // const saveEdits = async (e) => {

    //     e.preventDefault();
    //     setSaveEditsBtn(true)

    //     var data = {
    //         "Static": colEditsData,
    //         "Dynamic": userEdits
    //     }
    //     try {
    //         /* retailer save Af edits */
    //         await putToAgel(`${process.env.React_APP_RETAILER_AF_SAVE_EDITS}${APIdFromEachStore}`, data)
    //         setShowAF(false)
    //         setSuccessModal(true)
    //     }
    //     catch (err) {
    //         setShowAF(false)
    //         setFailedModalWithErrorMsg(true)
    //         setErrorMsgInFailedModal(err.response.data.message)
    //     }
    // }


    return (
        <div>
            <Modal show={true} onHide={() => setShowAF(false)} centered size="lg" className="AP-modal" style={{ marginTop: "5em" }}>
                {/* top */}
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body style={{ marginBottom: "5em" }}>
                    <Container style={{ marginTop: "-1.5rem" }}>
                        <Row>
                            <Col xs={12} className="modal-header-title">
                                <p>{AFStaticData && AFStaticData.name}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} className='d-flex  justify-content-between align-items-start'>
                                <p className="modal-title">Agent name <span className='text-muted' style={{ marginLeft: "0.3rem" }}>{agentAFStaticData && agentAFStaticData.name && agentAFStaticData.name}</span></p>
                                <p className="modal-title">request date <span className='text-muted' style={{ marginLeft: "0.3rem" }}>{(new Date(AFDynamicData && AFDynamicData.createdAt).toLocaleDateString())}</span></p>
                                <p className="modal-title">visit date <span className='text-muted' style={{ marginLeft: "0.3rem" }}>{(new Date(AFDynamicData && AFDynamicData.updatedAt).toLocaleDateString())}</span></p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} className='d-flex text-end justify-content-between align-items-start mt-2'>
                                <p className="modal-title">Estimated credit<span className='text-muted' style={{ marginLeft: "0.3rem" }}>{estimatedCredit && estimatedCredit}</span></p>
                            </Col>
                        </Row>

                    </Container>
                    <Container className="mt-3" dir="rtl">
                        <Form>

                            {/* dynamic  */}
                            <DynamicAF
                                AFDynamicData={AFDynamicData}
                                AFstaticData={AFStaticData}
                                setAFDynamicData={setAFDynamicData}
                                setAFStaticData={setAFStaticData}
                                AFRetailerData={AFRetailerData}
                                setAFRetailerData={setAFRetailerData}
                                setAgentAFStaticData={setAgentAFStaticData}
                                agentAFStaticData={agentAFStaticData}
                                setEstimatedCredit={setEstimatedCredit}
                                id={id}
                                editCase={true}
                                editableLatAndLong={true}
                                AFRetailerStore={AFRetailerStore}
                                setUserEdits={setUserEdits}
                                userEdits={userEdits}
                                setColEditsData={setColEditsData}
                            />

                            {/* contract */}
                            {/* before signature */}
                            <Container className='d-flex mt-5'>
                                <AiOutlineFileImage size="32" className='img-icon' />
                                <p className='form-text img-name'>Contract before signature</p>
                                {
                                    contractBeforeSignatureSpinner ?
                                        <Spinner animation="border" className="loader" />
                                        : <CgSoftwareDownload size="28" className="download-icon" onClick={async (e) => { setContractBeforeSignatureSpinner(true); await downloadPdfBeforeSignature() }} />
                                }
                            </Container>

                            {/* after signature */}
                            <Container className='d-flex mt-5'>
                                <AiOutlineFileImage size="32" className='img-icon' />
                                <p className='form-text img-name'>Contract after signature</p>
                                {
                                    contractAfterSignatureSpinner ?
                                        <Spinner animation="border" className="loader" />
                                        : <CgSoftwareDownload size="28" className="download-icon" onClick={async (e) => { setContractAfterSignatureSpinner(true); await downloadPdfAfterSignature() }} />
                                }
                            </Container>

                            {/* action btns */}
                            <Row>

                                <Col xs="6" className='d-flex justify-content-start align-items-center mt-5 ' >
                                    <button className="cancel-visit" type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowAF(false)
                                            setAFRetailerStore(false)
                                        }}
                                    >Close</button>
                                </Col>

                                {/* <Col xs="6" className='d-flex justify-content-end align-items-center mt-5 ' >
                                    <button className="save-edits" type="submit" onClick={saveEdits} disabled={saveEditsBtn ? true : false}>
                                        {saveEditsBtn ?
                                            (<LoadingBtnSpinner />)
                                            : (<span>Save Edits</span>)}
                                    </button>
                                </Col> */}

                            </Row>

                        </Form>
                    </Container>

                </Modal.Body>
            </Modal>
        </div>
    )
}
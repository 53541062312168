import React, { useState } from 'react'
import Select from 'react-select';
import { Form, Modal, Row, Col, Container } from "react-bootstrap"
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

const EditStaffModal = (props) => {
    //props
    let {
        currentStaffModal,
        setEditStaffModal,
        cities,
        roles,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props;


    //Edit input Btn's values
    const [editName, setEditName] = useState(currentStaffModal.name);
    const [editPhone, setEditPhone] = useState(currentStaffModal.phoneNumber);
    const [editEmail, setEditEmail] = useState(currentStaffModal.email);
    const [editRole, setEditRole] = useState(currentStaffModal.Role && currentStaffModal.Role.id);
    const [editCity, setEditCity] = useState(currentStaffModal.City && currentStaffModal.City.id);
    const [editActive, setEditActive] = useState("");

    // btn state checker
    const [editBtn, setEditBtn] = useState(false)

    const submitEdit = async (e) => {
        e.preventDefault();
        setEditBtn(true)

        var data = {
            'name': editName,
            'phoneNumber': editPhone,
            'email': editEmail,
            ...(editRole ? { RoleId: editRole } : {}),
            'CityId': editCity,
            'isActive': editActive === "" ? currentStaffModal.isActive : editActive,
        }
        try {
            await putToAgel(`dashboard/staff/${currentStaffModal.id}/edit`, data)
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }

        updateData();
        setEditStaffModal(false)
    }


    return (
        <div>
            <Modal show={true} onHide={() => setEditStaffModal(false)} className="dashboard-modal mt-5" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={submitEdit}>

                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Full name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Full Name" defaultValue={editName} onChange={(e) => setEditName(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">Phone number</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Phone number" defaultValue={editPhone} onChange={(e) => setEditPhone(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">Email</Form.Label>
                                <Form.Control type="email" className="role-input mt-2" placeholder="Enter Email" defaultValue={editEmail} onChange={(e) => setEditEmail(e.target.value)} />
                            </Form.Group>

                            <Row>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">Role</Form.Label>
                                        <Select
                                            className="search-select mt-2"
                                            defaultValue={{ "label": currentStaffModal.Role && currentStaffModal.Role.name, "value": editRole }}
                                            onChange={(e) => { setEditRole(e.value) }}
                                            options={roles.map((role) => ({ "value": role.id, "label": role.name }))}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">City</Form.Label>
                                        <Select
                                            className="search-select mt-2"
                                            // <Select className="form-select customize-select mt-2"
                                            defaultValue={{ "label": currentStaffModal.City && currentStaffModal.City.EnName, "value": editCity }}
                                            onChange={(e) => { setEditCity(e.value) }}
                                            options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                                        />
                                    </Form.Group>

                                </Col>
                            </Row>

                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" className="status form-text mt-3" label="isActive" defaultChecked={currentStaffModal.isActive} onChange={(e) => setEditActive(e.target.checked)} />
                            </Form.Group>


                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={editBtn ? true : false}>
                                    {editBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setEditStaffModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditStaffModal

import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { MdFileDownload } from 'react-icons/md'
import { getExcelFileFromAgel, getFileFromAgel } from '../../utils/api'
import fileDownload from 'js-file-download'
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


const PaymentReportFilters = (props) => {

    const {
        reportType,
        cities,
        industries,

        cities_status,
        industries_status,
        date_status,
        set_cities_status,
        set_industries_status,
        set_date_status,

        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
    } = props;

    const [selectedCity, setSelectedCity] = useState("")
    const [selectedIndustry, setSelectedIndustry] = useState("")
    const [selectedReportType, setSelectedReportType] = useState("")
    const [selectedStartDate, setSelectedStartDate] = useState(""); //start date
    const [selectedEndDate, setSelectedEndDate] = useState(""); //end date


    const [reportTypeCheckEmptyField, setReportTypeCheckEmptyField] = useState(false)

    //state checker on button
    const [exportReportBtn, setExportReportBtn] = useState(false)

    async function updateInputFields() {
        setSelectedCity("")
        setSelectedIndustry("")
        // setSelectedReportType("")
        setSelectedStartDate([])
        setSelectedEndDate([])
    }

    useEffect(() => { //kol ma select report gded byfde el input field el adema kolha
        updateInputFields()
    }, [selectedReportType])

    const extractReport = async (e) => {
        e.preventDefault();

        if (selectedReportType !== "") {

            // validation flags
            setReportTypeCheckEmptyField(false)

            // export btn flag
            setExportReportBtn(true);
            // console.log("query=", selectedReportType, selectedCity, selectedIndustry, selectedDate )

            try {
                const reportResponse = await getExcelFileFromAgel("dashboard/Reports", {
                    params: {
                        type: (selectedReportType.toString()),
                        ...(selectedCity ? { CityId: selectedCity } : {}),
                        ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                        ...(selectedStartDate ? { startDate: new Date(selectedStartDate).toJSON() } : {}),
                        ...(selectedEndDate ? { endDate: new Date(selectedEndDate).toJSON() } : {}),
                    }
                });
                fileDownload(reportResponse.data, 'report.xlsx');
                setExportReportBtn(false);
                setSuccessModal(true)
                // updateInputFields()
                // console.log("reportResponse=", reportResponse)
            }
            catch (err) {
                setExportReportBtn(false);
                setErrorMsgInFailedModal(err?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
        }

        else {
            toggleselectboxValidationMsg()
        }
    }


    function toggleselectboxValidationMsg() {
        if (selectedReportType === "") {
            setReportTypeCheckEmptyField(true)
        }
    }

    const selectSpecificReport = (e) => {

        setReportTypeCheckEmptyField(false)
        set_cities_status(true)
        set_date_status(true)
        set_industries_status(true)

        setSelectedReportType(e.value);
        let selectedReportFilters = e.filters

        //filters in reports
        //CityId
        if (selectedReportFilters?.includes("CityId")) {
            set_cities_status(false)
        }

        //startDate & endDate
        if (selectedReportFilters?.includes("startDate")) {
            set_date_status(false)
        }

        //IndustryId
        if (selectedReportFilters?.includes("IndustryId")) {
            set_industries_status(false)
        }
    }


    return (
        <div>
            <Form onSubmit={extractReport}>
                {/* row1 */}
                <Row>
                    <Col xs={12} className="d-flex align-items-center">
                        <Form.Label style={{ width: "8.5%", marginTop:"0.5rem" }} className="form-label-text">
                            Report
                        </Form.Label>

                        <div style={{ width: "80%" }} className="d-block">
                            <Select
                                className="form-extract-report-selectbox"
                                onChange={(e) => { selectSpecificReport(e) }}

                                options={reportType.map((type) => ({ "value": type.report, "label": type.report, "filters": type.filters }))}
                            />
                            {/* incase report type is empty */}
                            {reportTypeCheckEmptyField && (<p className="password-confirm-msg mt-2">please select a report type!</p>)}

                        </div>

                    </Col>
                </Row>


                {/* row2 */}
                <Row className='mt-5'>
                    <Col xs={6} className="d-flex align-items-center">
                        <Form.Label style={{ width: "17%", marginTop:"0.5rem" }} className=" form-label-text d-flex align-items-center ">
                            start Date
                        </Form.Label>
                        <div style={date_status ? { width: "60%", backgroundColor: "#f2f2f2", fontWeight: "800" } : { width: "60%" }} className="d-block">
                            <Form.Control type="date" className="form-extract-report-date" value={selectedStartDate} onChange={(e) => setSelectedStartDate(e.target.value)} required disabled={date_status} />
                        </div>
                    </Col>

                    <Col xs={6} className="d-flex align-items-center">
                        <Form.Label style={{ width: "17%", marginTop:"0.5rem" }} className=" form-label-text d-flex align-items-center ">
                            end Date
                        </Form.Label>
                        <div style={date_status ? { width: "60%", backgroundColor: "#f2f2f2", fontWeight: "800" } : { width: "60%" }} className="d-block">
                            <Form.Control type="date" className="form-extract-report-date" value={selectedEndDate} onChange={(e) => setSelectedEndDate(e.target.value)} required disabled={date_status} />
                        </div>
                    </Col>

                </Row>


                {/* row3 */}
                <Row className='mt-5'>
                    <Col xs={6} className="d-flex align-items-center">
                        <Form.Label style={{ width: "17%", marginTop:"0.5rem" }} className=" form-label-text d-flex align-items-center">
                            Industry
                        </Form.Label>
                        <div style={{ width: "60%" }} className="d-block">
                            <Select
                                className="form-extract-report-selectbox"
                                onChange={(e) => { setSelectedIndustry(e.value); }}
                                options={industries.map((industry) => ({ "value": industry.id, "label": industry.name }))}
                                isDisabled={industries_status}
                            />
                        </div>
                    </Col>


                    <Col xs={6} className="d-flex align-items-center">
                        <Form.Label style={{ width: "17%", marginTop:"0.5rem" }} className="form-label-text d-flex align-items-center">
                            City
                        </Form.Label>
                        <div style={{ width: "60%" }} className="d-block">
                            <Select
                                className="form-extract-report-selectbox"
                                onChange={(e) => { setSelectedCity(e.value); }}
                                options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                                isDisabled={cities_status}
                                required={true}
                            />
                        </div>
                    </Col>
                </Row>


                {/* row4 */}
                <Row className='mt-5'>
                    <Col xs={12} className="d-flex align-items-center justify-content-end">
                        <button type='submit' className='export-report-btn' disabled={exportReportBtn ? true : false}>
                            {exportReportBtn ?
                                (<LoadingBtnSpinner />)
                                : (
                                    <span>
                                        Export Report
                                        <MdFileDownload style={{ marginLeft: "0.7rem" }} />

                                    </span>
                                )
                            }
                        </button>
                    </Col>
                </Row>

            </Form>
        </div>
    )
}

export default PaymentReportFilters
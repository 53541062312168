import React, { useState } from 'react'
import { Form, Modal, Container } from "react-bootstrap"
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


const ResetPasswordAgentsModal = (props) => {
    //props
    let {

        setResetPasswordAgentsModal,
        currentAgentPasswordModal,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props;

    // btn state checker
    const [resetBtn, setResetBtn] = useState(false)

    //password input Btn's values
    const [password, setPassword] = useState("");
    const [passwordMsgLength, setPasswordMsgLength] = useState(false);

    //lesa el back m3mlha4
    const submitPassword = async (e) => {

        e.preventDefault();
        setResetBtn(true);

        if (password.length >= 8) {
            var data = {
                "password": password
            }
            try {
                await putToAgel(`${process.env.React_APP_RESET_AGENT_PASSWORD}${currentAgentPasswordModal.id}`, data)
                setSuccessModal(true)
            }
            catch (err) {
                setErrorMsgInFailedModal(err?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
            updateData();
            setResetPasswordAgentsModal(false)
        }
        else {
            setPasswordMsgLength(true);
            setResetBtn(false);
        }
    }

    return (
        <div>
            <Modal show={true} onHide={() => setResetPasswordAgentsModal(false)} centered>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Container className="mt-3">
                        <Form onSubmit={submitPassword}>

                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Reset Password</Form.Label>
                                <Form.Control type="Password" className="role-input mt-2" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            </Form.Group>

                            {/* incase password length is too small */}
                            {passwordMsgLength && (<p className="password-confirm-msg">Password should be at least 8 digits!</p>)}


                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={resetBtn ? true : false}>
                                    {resetBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setResetPasswordAgentsModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ResetPasswordAgentsModal

import React, { useState } from 'react'
import Select from 'react-select';
import { postToAgel } from '../../utils/api'

import { Modal, Form, Button, Container } from 'react-bootstrap'
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';



const CreateModal = (props) => {
    // props
    const {
        setCreateArea,
        cities,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
    } = props

    //create btn vars
    const [createSelectCity, setCreateSelectCity] = useState('')
    const [createArabicName, setCreateArabicName] = useState('')
    const [createEnglishName, setCreateEnglishName] = useState('')
    const [createActive, setCreateActive] = useState(false)

    //create btn state checker
    const [createBtn, setCreateBtn] = useState(false)

    //post create

    const submitCreate = async (e) => {

        e.preventDefault();
        setCreateBtn(true);

        var data = {
            'CityId': createSelectCity,
            'ArName': createArabicName,
            'EnName': createEnglishName,
            'isActive': createActive,
        }
        try {
            await postToAgel(`${process.env.React_APP_CREATE_NEW_AREA}`, data)
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        updateData();
        setCreateArea(false)
    }


    return (
        <div>
            <Modal show={true} onHide={() => setCreateArea(false)} centered className="mt-5">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={submitCreate}>

                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Arabic Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Arabic Name" value={createArabicName} onChange={(e) => setCreateArabicName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">English Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter English Name" value={createEnglishName} onChange={(e) => setCreateEnglishName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" className="status form-text mt-3" label="isActive" defaultChecked={createActive} onChange={(e) => setCreateActive(e.target.checked)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">City Name(English)</Form.Label>
                                <Select
                                    className="search-select mt-2"
                                    defaultValue={{ "label": "Cities", "value": "0" }}
                                    onChange={(e) => { setCreateSelectCity(e.value) }}
                                    options={cities.map((city) => ({ "value": city && city.id, "label": city && city.EnName }))}
                                />
                            </Form.Group>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={createBtn ? true : false}>
                                    {createBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setCreateArea(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CreateModal

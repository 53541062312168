import React, { useState } from 'react'
import { Modal, Container } from 'react-bootstrap'
import { BsPersonPlusFill, BsEyeFill } from 'react-icons/bs'
import { putToAgel } from '../../utils/api'



export default function UpdateProfileIdImg(props) {

    const {
        set_update_id_images_modal,
        imgSrc,
        setImgSrc,
        id,
        ws_front_id_image,
        ws_back_id_image,
        updateData,
        setSuccessModal,
        setFailedModal,
    } = props

    const [corruptedImg, setCorruptedImg] = useState(false)

    const uploadProfileImg = async (e) => {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);

        fileReader.onload = async (event) => {
            setImgSrc(event.target.result)
            var data = {
                "wholesaler": 
                ws_front_id_image?{
                    "nationalIdImage": event.target.result
                }:
                {
                    "nationalIdBackImage": event.target.result
                }

            }
            try {
                await putToAgel(`${process.env.React_APP_WS_SAVE_EDITS_ACCOUNT}${id}/`, data)
                set_update_id_images_modal(false)
                updateData()
                setSuccessModal(true)
            }
            catch (error) {
                console.log("error=", error)
                set_update_id_images_modal(false)
                setFailedModal(true)
            }
        }
    }

    return (
        <div>
            <Modal show={true} onHide={() => set_update_id_images_modal(false)} className="dashboard-modal mt-5" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>

                    {!corruptedImg && imgSrc !== null ?
                        (<Container className="d-flex justify-content-center align-items-center">
                            <img src={imgSrc} onError={() => setCorruptedImg(true)} className="img-fluid" style={{ width: "100%", height: "100%" }} />
                        </Container>
                        ) : null
                    }

                    <Container className="d-flex justify-content-center align-items-center mt-4">
                        <div className='text-center'>
                            <label>
                                <BsPersonPlusFill className="profile-img-setting-icon" style={{ color: '#00BD85', borderColor: "#00BD85" }} />
                                <input type="file" style={{ display: "none" }} 
                                onChange={uploadProfileImg} 
                                />
                            </label>
                            <p className='text-label'>Update Photo</p>
                        </div>
                        <div className='text-center'>
                            <BsEyeFill className="profile-img-setting-icon"
                                style={{ color: 'black', borderColor: "black" }} 
                                onClick={()=>{window.location.replace(imgSrc)}}
                            />
                            <p className='text-label'>view Photo</p>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

        </div>
    )
}
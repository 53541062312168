import React, { useState } from 'react';
import { Col, Row, Modal, Form } from 'react-bootstrap'
import { putToAgel } from '../../utils/api';

//icons
import { RiWhatsappFill } from 'react-icons/ri'
import { MdFacebook } from 'react-icons/md'
import { BsTelegram } from 'react-icons/bs'

//components
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';



export default function SocialLinksModal(props) {
    const {
        set_show_social_links,
        id, //da el ws id
        retrievedSocialMediaLinks,
        updateData,
        setSuccessModal,
        setErrorMsgInFailedModal,
        setFailedModalWithErrorMsg,

    } = props

    const [telegramNumber, setTelegramNumber] = useState(retrievedSocialMediaLinks.telegramNum)
    const [telegramGroup, setTelegramGroup] = useState(retrievedSocialMediaLinks.telegramLink)
    const [whatsappNumber, setWhatsappNumber] = useState(retrievedSocialMediaLinks.whatsNum)
    const [whatsappGroup, setWhatsappGroup] = useState(retrievedSocialMediaLinks.whatsGroup)
    const [instagram, setInstagram] = useState(retrievedSocialMediaLinks.instagram)
    const [facebook, setFacebook] = useState(retrievedSocialMediaLinks.facebook)

    //done btn state checker
    const [doneBtn, setDoneBtn] = useState(false)


    const submitSocialLinks = async (e) => {

        e.preventDefault()
        setDoneBtn(true)

        var data = {
            "telegramNum": telegramNumber,
            "telegramLink": telegramGroup,
            "whatsNum": whatsappNumber,
            "whatsGroup": whatsappGroup,
            "instagram": instagram,
            "facebook": facebook,
        }
        try {
            await putToAgel(`dashboard/wholesaler/addSocialMediaLinks/${id}`, data)
            updateData()
            setSuccessModal(true)
        }
        catch (e) {
            setFailedModalWithErrorMsg(true)
            setErrorMsgInFailedModal(e?.response?.data?.message)
        }
        setDoneBtn(false)
        set_show_social_links(false)
    }


    return (
        <div>
            <Modal show={true} onHide={() => set_show_social_links(false)} className="dashboard-modal mt-5" centered >
                <Modal.Header closeButton className="cancel-msg-modal-header">
                    social Links
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitSocialLinks}>

                        {/* telegram number*/}
                        <Form.Group className="mt-4">
                            <Row className='d-flex align-items-center'>
                                <Col xs="6">
                                    <Form.Label className="form-text">
                                        <span className='d-flex align-items-center justify-content-center'>
                                            <BsTelegram className='ws-profile-telegram-icon' />
                                            <span style={{ marginLeft: "0.3rem" }}> Telegram number</span>
                                        </span>
                                    </Form.Label>
                                </Col>
                                <Col xs="6">
                                    <Form.Control type="text" className="salesNumbersInputs" defaultValue={telegramNumber} onChange={(e) => { setTelegramNumber(e.target.value) }} />
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* telegram group*/}
                        <Form.Group className="mt-4">
                            <Row className='d-flex align-items-center'>
                                <Col xs="6">
                                    <Form.Label className="form-text">
                                        <span className='d-flex align-items-center justify-content-center'>
                                            <BsTelegram className='ws-profile-telegram-icon' />
                                            <span style={{ marginLeft: "0.3rem" }}> Telegram group</span>
                                        </span>
                                    </Form.Label>
                                </Col>
                                <Col xs="6">
                                    <Form.Control type="text" className="salesNumbersInputs" defaultValue={telegramGroup} onChange={(e) => { setTelegramGroup(e.target.value) }} />
                                </Col>
                            </Row>
                        </Form.Group>



                        {/* whatsapp number*/}
                        <Form.Group className="mt-4">
                            <Row className='d-flex align-items-center'>
                                <Col xs="6">
                                    <Form.Label className="form-text">
                                        <span className='d-flex align-items-center justify-content-center'>
                                            <RiWhatsappFill className='ws-profile-whatsapp-icon' />
                                            <span style={{ marginLeft: "0.3rem" }}> whatsapp number</span>
                                        </span>
                                    </Form.Label>
                                </Col>
                                <Col xs="6">
                                    <Form.Control type="text" className="salesNumbersInputs" defaultValue={whatsappNumber} onChange={(e) => { setWhatsappNumber(e.target.value) }} />
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* whatsapp group*/}
                        <Form.Group className="mt-4">
                            <Row className='d-flex align-items-center'>
                                <Col xs="6">
                                    <Form.Label className="form-text">
                                        <span className='d-flex align-items-center justify-content-center'>
                                            <RiWhatsappFill className='ws-profile-whatsapp-icon' />
                                            <span style={{ marginLeft: "0.3rem" }}> whatsapp group</span>
                                        </span>
                                    </Form.Label>
                                </Col>
                                <Col xs="6">
                                    <Form.Control type="text" className="salesNumbersInputs" defaultValue={whatsappGroup} onChange={(e) => { setWhatsappGroup(e.target.value) }} />
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* instagram*/}
                        <Form.Group className="mt-4">
                            <Row className='d-flex align-items-center'>
                                <Col xs="6">
                                    <Form.Label className="form-text">
                                        <span className='d-flex align-items-center justify-content-center'>
                                            <div className="ws-profile-instagram-icon"></div>
                                            <span style={{ marginLeft: "0.3rem" }}> instagram </span>
                                        </span>
                                    </Form.Label>
                                </Col>
                                <Col xs="6">
                                    <Form.Control type="text" className="salesNumbersInputs" defaultValue={instagram} onChange={(e) => { setInstagram(e.target.value) }} />
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* facebook*/}
                        <Form.Group className="mt-4 mb-4">
                            <Row className='d-flex align-items-center'>
                                <Col xs="6">
                                    <Form.Label className="form-text">
                                        <span className='d-flex align-items-center justify-content-center'>
                                            <MdFacebook className='ws-profile-facebook-icon' />
                                            <span style={{ marginLeft: "0.3rem" }}> Facebook </span>
                                        </span>
                                    </Form.Label>
                                </Col>
                                <Col xs="6">
                                    <Form.Control type="text" className="salesNumbersInputs" defaultValue={facebook} onChange={(e) => { setFacebook(e.target.value) }} />
                                </Col>
                            </Row>
                        </Form.Group>



                        <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                            <button className="role-add-btn-with-loader-spinner" type="submit" disabled={doneBtn ? true : false}>
                                {doneBtn ?
                                    (<LoadingBtnSpinner />)
                                    : (<span>Done</span>)}
                            </button>

                            <button className="roleCloseBtn" onClick={() => set_show_social_links(false)}>Close</button>
                        </Modal.Footer>

                    </Form>
                </Modal.Body>
            </Modal>



        </div>
    )
}
import React, { useState, useEffect } from 'react'

import { Modal, Form, Container } from "react-bootstrap"
import { getFromAgel, putToAgel, postToAgel } from '../../utils/api';
import Select from 'react-select';

import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


const ReschduleModal = (props) => {

    const {
        setReschduleModal,
        registerRescheduleFlag,
        firstVisitRescheduleFlag,
        secondVisitRescheduleFlag,
        contractRescheduleFlag,
        current,
        rescheduleCurrent,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        updateData
    } = props


    //edit btn vars
    const [editAgentName, setEditAgentName] = useState('');
    const [editVisitDate, setEditVisitDate] = useState('');
    const [agents, setAgents] = useState([]);
    const [selectAgentFlag, setSelectAgentFlag] = useState(false)
    const options = agents.map(agent => ({ "value": agent.id, "label": agent.name }))

    //state checker on buttons 
    const [doneBtn, setDoneBtn] = useState(false)


    useEffect(() => {
        const fetchAgents = async () => {
            try {
                // get agents
                const agentsResponse = await getFromAgel(`${process.env.React_APP_GET_AGENT}`)
                setAgents(agentsResponse.data.agents)
            }
            catch (err) {
                // console.log('error=', err)
            }
        }
        fetchAgents()
    }, [])


    //put edits
    const submitEdit = async (e) => {

        e.preventDefault();

        if (editAgentName) {
            if (registerRescheduleFlag) {
                var data = {
                    //assign 
                    "mode": "first",
                    'AgentId': editAgentName,
                    'visitDate': editVisitDate ? new Date(editVisitDate).toJSON() : new Date(rescheduleCurrent && rescheduleCurrent.WholesalerAF && rescheduleCurrent.WholesalerAF.visitDate).toJSON(),
                }
            }
            else if (firstVisitRescheduleFlag) {
                //resc
                var data = {
                    "mode": "first",
                    'AgentId': editAgentName,
                    'visitDate': editVisitDate ? new Date(editVisitDate).toJSON() : new Date(rescheduleCurrent && rescheduleCurrent.WholesalerAF && rescheduleCurrent.WholesalerAF.visitDate).toJSON(),
                }
            }
            else if (contractRescheduleFlag) {
                var data = {
                    "mode": "pos",
                    'AgentId': editAgentName,
                    'visitDate': editVisitDate ? new Date(editVisitDate).toJSON() : new Date(rescheduleCurrent && rescheduleCurrent.WholesalerAF && rescheduleCurrent.WholesalerAF.visitDate).toJSON(),
                }
            }
            else if (secondVisitRescheduleFlag) {
                var data = {
                    "mode": "pos",
                    'AgentId': editAgentName,
                    'visitDate': editVisitDate ? new Date(editVisitDate).toJSON() : new Date(rescheduleCurrent && rescheduleCurrent.WholesalerAF && rescheduleCurrent.WholesalerAF.visitDate).toJSON(),
                }
            }


            try {

                setSelectAgentFlag(false)
                setDoneBtn(true)

                if (registerRescheduleFlag) {
                    //assign
                    // console.log("registerRescheduleFlag")
                    await postToAgel(`/dashboard/wholesaler/activationProcess/${current.id}/visits/create`, data)
                }
                else if (firstVisitRescheduleFlag) {
                    //res
                    await putToAgel(`dashboard/wholesaler/activationProcess/${current.id}/visits/edit`, data)
                    // console.log("AP id=", current.id)
                    // console.log("AF id=", rescheduleCurrent && rescheduleCurrent.WholesalerAF && rescheduleCurrent.WholesalerAF.id)
                }
                else if (contractRescheduleFlag) {
                    // console.log("contractRescheduleFlag")
                    await postToAgel(`/dashboard/wholesaler/activationProcess/${current.id}/visits/create`, data)
                    // console.log("AP id=",current.id)
                    // console.log("trydata=", data)
                }
                else if (secondVisitRescheduleFlag) {
                    // console.log("secondVisitRescheduleFlag")
                    await putToAgel(`dashboard/wholesaler/activationProcess/${current.id}/visits/edit`, data)
                    // console.log("AP id=", current.id)
                    // console.log("AF id=", rescheduleCurrent && rescheduleCurrent.WholesalerAF && rescheduleCurrent.WholesalerAF.id)
                }
                updateData()//fe ay success hay update el data f el a5er
                setSuccessModal(true)
            }
            catch (err) {
                setErrorMsgInFailedModal(err?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
            setReschduleModal(false)//fe game3 el a7wal hay2fl el modal b3d ay request may5ls
        }

        else {
            setSelectAgentFlag(true)
        }
    }


    return (
        <div>
            <Modal show={true} className="reschedule-modal" onHide={() => setReschduleModal(false)} centered size="lg" style={{ marginTop: "5em" }}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={submitEdit}>
                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">Agent Name</Form.Label>
                                <Select className="search-select mt-2"
                                    // <Select className="form-select customize-select mt-2"
                                    onChange={(e) => { setEditAgentName(e.value) }}
                                    options={options}
                                />
                                {selectAgentFlag && (<p className="select-error-msg">Please select an agent!</p>)}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">Visit Date</Form.Label>
                                <Form.Control type="date" className="role-input mt-2" placeholder="Enter Visit Date" defaultValue={rescheduleCurrent && rescheduleCurrent.WholesalerAF && (new Date(rescheduleCurrent && rescheduleCurrent.WholesalerAF && rescheduleCurrent.WholesalerAF.visitDate).toLocaleDateString())} onChange={(e) => setEditVisitDate(e.target.value)} required />
                            </Form.Group>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={doneBtn ? true : false}>
                                    {doneBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setReschduleModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ReschduleModal

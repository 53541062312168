import React, { useState } from 'react'
import { Modal, Container } from 'react-bootstrap'
import { BsPersonPlusFill, BsEyeFill } from 'react-icons/bs'
import { putToAgel } from '../../utils/api'

//components
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn'


export default function UpdateIndustryImg(props) {

    const {
        currentIndustry,
        setUpdateIndutryImgModal,
        imgSrc,
        setImgSrc,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props

    const [corruptedImg, setCorruptedImg] = useState(false)
    // btn state checker
    const [saveImgBtn, setSaveImgBtn] = useState(false)

    const uploadProfileImg = async (e) => {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);

        fileReader.onload = async (event) => {
            setImgSrc(event.target.result)
            setSaveImgBtn(true) //open loader indicator till request complete

            var data = {
                "industryLogo": event.target.result
            }
            try {
                await putToAgel(`dashboard/Industries/editLogo/${currentIndustry.id}/`, data)
                setSuccessModal(true)
            }
            catch (err) {
                setErrorMsgInFailedModal(err?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
            setSaveImgBtn(false)
            updateData()
            setUpdateIndutryImgModal(false)
        }
    }

    return (
        <div>
            <Modal show={true} onHide={() => setUpdateIndutryImgModal(false)} className="dashboard-modal mt-5" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>

                    {!corruptedImg && imgSrc !== null ?
                        (<Container className="d-flex justify-content-center align-items-center">
                            <img src={imgSrc} onError={() => setCorruptedImg(true)} className="img-fluid" style={{ width: "70%", height: "70%" }} />
                        </Container>
                        ) : null
                    }

                    {/* loading during changing images */}
                    {saveImgBtn &&
                        <Container className='d-flex justify-content-center align-items-center mt-2'>
                            <LoadingBtnSpinner />
                        </Container>
                    }

                    <Container className="d-flex justify-content-center align-items-center mt-4">
                        <div className='text-center'>
                            <label>
                                <BsPersonPlusFill className="profile-img-setting-icon" style={{ color: '#00BD85', borderColor: "#00BD85" }} />
                                <input type="file" style={{ display: "none" }}
                                    onChange={uploadProfileImg}
                                />
                            </label>
                            <p className='text-label'>Update Photo</p>
                        </div>
                        <div className='text-center'>
                            <BsEyeFill className="profile-img-setting-icon"
                                style={{ color: 'black', borderColor: "black" }}
                                onClick={() => { window.location.replace(imgSrc) }}
                            />
                            <p className='text-label'>view Photo</p>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

        </div>
    )
}
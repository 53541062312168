import React, { useEffect, useState } from 'react'
import { Form, Modal, Row, Col, Container } from 'react-bootstrap';
import { IoIosCloseCircle } from 'react-icons/io'
import { MdAddCircle } from 'react-icons/md'
import { VscError } from "react-icons/vsc";
import { getFromAgel, postToAgel, deleteFromAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


const AddLabelToIndustry = (props) => {


    let {
        currentIndustry,
        setAddLabelModal,
        updateData,
    } = props

    const [addNewLabel, setAddNewLabel] = useState("")
    const [allLabels, setAllLabels] = useState([])
    const [labelvalidity, setLabelvalidity] = useState(false)
    const [labelExist, setLabelExist] = useState(false)

    //state checker on buttons 
    const [addLabelBtn, setAddLabelBtn] = useState(false)
    const [cancelLabelBtn, setCancelLabelBtn] = useState(false)

    //server response(error handling) in add label and delete label
    const[addLabelResponse,setAddLabelResponse]=useState("")
    const[deleteLabelResponse,setDeleteLabelResponse]=useState("")


    const fetchLabels = async () => {
        setAddLabelResponse("")
        setDeleteLabelResponse("")

        try {
            const response = await getFromAgel(`${process.env.React_APP_FETCH_LABEL_IN_INDUSTRY}${currentIndustry.id}`)
            setAllLabels(response.data.labels)
        }
        catch (error) {
            // console.log({ error })
        }
    }

    useEffect(() => {
        fetchLabels()
    }, [])


    //submit new label
    const submitNewLabel = async (e) => {
        e.preventDefault()
        setAddLabelResponse("")

        if (addNewLabel !== "") { // lw mafe4 label check
            setLabelvalidity(false)
            let isLabelExist = allLabels.map((i) => addNewLabel === i.name ? (true) : (false)) //lw label mtkrr check
            if (isLabelExist.includes(true)) {
                setLabelExist(true) //keda hwa tl3 mtkrr f3ln
            }
            else {
                setAddLabelBtn(true)

                var data = {
                    "name": addNewLabel
                }
                try {

                    await postToAgel(`${process.env.React_APP_ADD_NEW_LABEL_TO_INDUSTRY}${currentIndustry.id}`, data)
                    fetchLabels();
                    updateData();    
                }
                catch (err) {
                    setAddLabelResponse(err?.response?.data?.message)
                }
                setAddLabelBtn(false)
                setLabelExist(false)
                setAddNewLabel("")
                setLabelvalidity(false)
            }
        }
        else {
            setLabelvalidity(true)
            setLabelExist(false)
        }
    }


    const cancelLabel = async (id) => {
        setDeleteLabelResponse("")
        setCancelLabelBtn(true)
        try {
            await deleteFromAgel(`${process.env.React_APP_DELETE_LABEL_FROM_INDUSTRY}${id}`)
            let allLabelsTemp = allLabels && allLabels.filter((label) => label.id !== id)
            setAllLabels(allLabelsTemp)
        }
        catch (err) {
            setDeleteLabelResponse(err?.response?.data?.message)
        }

        fetchLabels();
        updateData();
        setCancelLabelBtn(false)
    }


    return (
        <div>
            <Modal show={true} onHide={() => setAddLabelModal(false)} className="dashboard-modal mt-5" centered>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Container className="mt-3">
                        <Form>
                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Label Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter label name" value={addNewLabel} onChange={(e) => setAddNewLabel(e.target.value)} />
                            </Form.Group>

                            <Container className="d-flex justify-content-end align-items-center">
                                <button className="add-label-btn" onClick={submitNewLabel} disabled={addLabelBtn ? true : false}>
                                    {addLabelBtn ?
                                        (
                                            <LoadingBtnSpinner className="add-label-btn" />
                                        )
                                        : (
                                            <span>
                                                <MdAddCircle size="17" /> Add label
                                            </span>
                                        )}
                                </button>
                            </Container>

                            {/* if label is empty string */}
                            {
                                labelvalidity && (
                                    <Container className="d-flex justify-content-start align-items-center">
                                        <p className="password-confirm-msg">Please add label!</p>
                                    </Container>
                                )
                            }

                            {/* if label is repeated before */}
                            {
                                labelExist && (
                                    <Container className="d-flex justify-content-start align-items-center">
                                        <p className="password-confirm-msg">This label is already exist!</p>
                                    </Container>
                                )
                            }

                            {/* adding label error handling  */}
                            {addLabelResponse!=="" && (
                                <span className="dashboard-error-msg mt-3">
                                    <VscError/>
                                    {addLabelResponse}
                                </span>
                            )}

                            <div className='mt-5 mb-4'>
                                {allLabels.length !== 0 ?
                                    <>
                                        <span className="form-text">All labels for {currentIndustry && currentIndustry.name} industry</span>

                                        <div className="labels-box mt-2">
                                            <Row className='d-flex justify-content-center align-items-center'>
                                                {allLabels && allLabels.map((label) =>
                                                    <Col xs="3" key={label.id} className='d-flex align-items-center justify-content-around mt-3'>
                                                        <span>
                                                            {label.name}
                                                        </span>
                                                        <IoIosCloseCircle size="17" className={cancelLabelBtn?"cancel-label-clicked":"cancel-label"} onClick={() => cancelLabel(label.id)} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    </>
                                    : null}
                            </div>

                            {/* adding label error handling  */}
                            {deleteLabelResponse!=="" && (
                                <span className="dashboard-error-msg mt-3">
                                    <VscError/>
                                    {deleteLabelResponse}
                                </span>
                            )}

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="roleCloseBtn" onClick={() => setAddLabelModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>

                    </Container>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default AddLabelToIndustry

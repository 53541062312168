import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { MdDelete } from 'react-icons/md'
export default function InvoiceItem(props) {
    let { 
        index,
        setItemsList,
        itemsList
    } = props
    

    let quantity=0
    let price=0
    let ItemtotPrice=0
    
    const handleChangeinvoice = (e) => {
        itemsList[index] = {...itemsList[index],[e.target.name]: e.target.value}
        quantity = itemsList[index].quantity
        price = itemsList[index].price
        ItemtotPrice = quantity* price
        itemsList[index] = {...itemsList[index],ItemtotPrice:parseInt(ItemtotPrice)}
        setItemsList([...itemsList]) //de el spread 34an yfrdo w y4ofo 3la eno array gded et8yr maykrno4 bl adem y4ofo nfso hwa hwa bnfs el memory location w my8yro4 lkn keda hy4ofo mo5tlf
    }

    const removeItem = (currentIndex) =>{
         const newList = itemsList.filter((item,index2) =>{
            if (currentIndex !== index2){
                console.log({index2,currentIndex})
                return item;
            }
         })

         setItemsList([...newList])
    }  

    // console.log({itemsList})

    return (
        <>
            <Row className='mt-3' key={""}>
                <Col xs="10">

                    <Row>
                        <Col xs="3">
                            <Form.Group>
                                <Form.Label className="form-text">Item</Form.Label>
                                <Form.Control type="text" className="amount-input" style={{ height: "100%", width: "100%" }} name="itemName" onChange={handleChangeinvoice} required />
                            </Form.Group>
                        </Col>
                        <Col xs="3">
                            <Form.Group>
                                <Form.Label className="form-text">Unit</Form.Label>
                                <Form.Control type="text" className="amount-input" style={{ height: "100%", width: "100%" }} name="quantity" onChange={handleChangeinvoice} required />
                            </Form.Group>
                        </Col>
                        <Col xs="3">
                            <Form.Group>
                                <Form.Label className="form-text">Price</Form.Label>
                                <Form.Control type="text" className="amount-input" style={{ height: "100%", width: "100%" }} name="price" onChange={handleChangeinvoice} required />
                            </Form.Group>
                        </Col>
                        <Col xs="3">
                            <Form.Group>
                                <Form.Label className="form-text">Total</Form.Label>
                                <Form.Control type="text" className="amount-input" style={{ height: "100%", width: "100%" }} value={itemsList[index]?.ItemtotPrice} readOnly disabled />
                            </Form.Group>
                        </Col>
                    </Row>

                </Col>


                {index !== 0 ?
                    (<Col xs="2" className='d-flex justify-content-end align-items-center'>
                        <MdDelete className='invoice-recycle-bin' onClick={() => removeItem(index)} />
                    </Col>) : null}


            </Row>
            <hr style={{margin:"0.8rem 0"}}/>
        </>

    )
}
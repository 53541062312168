import React, { useState, useEffect } from 'react'

import { Modal, Form, Container, Row, Col } from "react-bootstrap"
import { getFromAgel } from '../../utils/api';

const Reinstate = (props) => {
    //props

    let {
        setReinstateModal,
        setCancelConfirmModal,
        setReinstateConfirmModal,
        current,
    } = props;
    console.log("props=", props)

    const [teamLeadData, setTeamLeadData] = useState([]);
    // const [logs, setLogs] = useState([1]);

    //state checker on corrupted images 
    const [frontNationIdCorruptedImg, setFrontNationIdCorruptedImg] = useState(false)
    const [backNationIdCorruptedImg, setBackNationIdCorruptedImg] = useState(false)
    const [commercialRegistrationCorruptedImg1, setCommercialRegistrationCorruptedImg1] = useState(false)
    const [commercialRegistrationCorruptedImg2, setCommercialRegistrationCorruptedImg2] = useState(false)
    const [commercialRegistrationCorruptedImg3, setCommercialRegistrationCorruptedImg3] = useState(false)


    // fetch Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                /* get WS AF data */
                const teamLeadDataResponse = await getFromAgel(`${process.env.React_APP_GET_WS_AP}${current.id}`)
                setTeamLeadData(teamLeadDataResponse.data.wholesalerActivationProcess[0])
                console.log("register af=", teamLeadDataResponse.data.wholesalerActivationProcess[0])

                //logs
                // const logsResponse=await getFromAgel(`/${current.id}`)
                // console.log("id=",current.id)
                // console.log("logsResponse=",logsResponse)
                // // setLogs(logsResponse)

            }
            catch (e) {
                console.log("error=", e)
                console.log("id=", current.id)
            }
        }
        fetchData()
    }, [])


    return (
        <div>
            <Modal show={true} onHide={() => setReinstateModal(false)} centered size="lg" className="AP-modal" style={{ marginTop: "5em" }}>
                <Modal.Header closeButton>
                    <Row>
                        <Col xs={12} className="modal-header-title">
                            <p>{teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.name}</p>
                        </Col>
                        <Col xs={12} className='d-flex  justify-content-between align-items-start'>
                            <p className="modal-title">Agent name <span className='text-muted'>{teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.Wstore && teamLeadData.WholesalerAF.Wstore.AgentWholeAF && teamLeadData.WholesalerAF.Wstore.AgentWholeAF.name}</span></p>
                            <p className="modal-title">request date <span className='text-muted'>{(new Date(teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.createdAt).toLocaleDateString())}</span></p>
                            <p className="modal-title">visit date <span className='text-muted'>{(new Date(teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.visitDate).toLocaleDateString())}</span></p>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body style={{ marginBottom: "5em" }}>
                    <Container className="mt-3" dir="rtl">
                        <Form>
                            {/* row1 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">الأسم ثلاثى</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value="" readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row2 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">رقم السجل التجاري</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_id} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.commercial_registration_id} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value="" readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* row3 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">مكتب السجل</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_office} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.commercial_registration_office} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value="" readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row4 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">رقم البطاقة</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.nationalId} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.nationalId} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value="" readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row5 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">اسم المنشأة</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore.name && teamLeadData.Wstore.name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.storeName} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value="" readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row6 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">رقم هاتف </Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.phoneNumber} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row7 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">رقم هاتف اخر </Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.secondNumber} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.secondNumber} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row8 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">مدير الفرع</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.owner_name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.owner_name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value="" readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row9 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text"> اسم الفرع</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore.name && teamLeadData.Wstore.name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.storeName} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value="" readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row10 */}
                            <Row>
                                <Col xs="4" className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">عنوان المنشأة</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.address} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.address} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value="" readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* row11 */}
                            <Row>
                                <Col xs="4" className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">نشاط المنشأة</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.industry} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.industry} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value="" readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row12 */}
                            <Row>
                                <Col xs="4" className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">Lat Store</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.lat} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row13 */}
                            <Row>
                                <Col xs="4" className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">Long Store</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.long} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row14 */}
                            <Row>
                                <Col xs="4" className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">برجاء كتابة عنوان المنشأة تفصيلياً مع ذكر أقرب علامة مميزة</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.storeAddressByCust} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "3em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "3em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* row15*/}
                            {(!commercialRegistrationCorruptedImg1 && (teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_image)) ?
                                <Row>
                                    <Col xs="4">
                                        <Form.Group>
                                            <Form.Label className="AP-modal-text">السجل التجاري ١</Form.Label>
                                            <br />
                                            <a href={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_image}`} target="_blank">
                                                <img onError={() => setCommercialRegistrationCorruptedImg1(true)} src={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_image}`} className='img-fluid Ap-register-image' />
                                            </a>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                : null}

                            {/* row16*/}
                            {(!commercialRegistrationCorruptedImg2 && (teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one)) ?
                                <Row>
                                    <Col xs="4">
                                        <Form.Group>
                                            <Form.Label className="AP-modal-text">السجل التجاري ٢</Form.Label>
                                            <br />
                                            <a href={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one}`} target="_blank">
                                                <img onError={() => setCommercialRegistrationCorruptedImg2(true)} src={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one}`} className='img-fluid Ap-register-image' />
                                            </a>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                : null}

                            {/* row17*/}
                            {(!commercialRegistrationCorruptedImg3 && (teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two)) ?
                                <Row>
                                    <Col xs="4">
                                        <Form.Group>
                                            <Form.Label className="AP-modal-text">السجل التجاري ٣</Form.Label>
                                            <br />
                                            <a href={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two}`} target="_blank">
                                                <img onError={() => setCommercialRegistrationCorruptedImg3(true)} src={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two}`} className='img-fluid Ap-register-image' />
                                            </a>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                : null}


                            {/* row18*/}
                            {(!frontNationIdCorruptedImg && (teamLeadData && teamLeadData.Wholesaler && teamLeadData.Wholesaler.nationalIdImage)) ?
                                <Row>
                                    <Col xs="4">
                                        <Form.Group>
                                            <Form.Label className="AP-modal-text">الصورة الأمامية للبطاقة</Form.Label>
                                            <br />
                                            <a href={`${teamLeadData && teamLeadData.Wholesaler && teamLeadData.Wholesaler.nationalIdImage}`} target="blank">
                                                <img onError={() => setFrontNationIdCorruptedImg(true)} src={`${teamLeadData && teamLeadData.Wholesaler && teamLeadData.Wholesaler.nationalIdImage}`} className='img-fluid Ap-register-image' />
                                            </a>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly disabled />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly disabled />
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                : null}

                            {/* row19*/}
                            {(!backNationIdCorruptedImg && (teamLeadData && teamLeadData.Wholesaler && teamLeadData.Wholesaler.nationalIdBackImage)) ?
                                <Row>
                                    <Col xs="4">
                                        <Form.Group>
                                            <Form.Label className="AP-modal-text">الصورة الخلفية للبطاقة</Form.Label>
                                            <br />
                                            <a href={`${teamLeadData && teamLeadData.Wholesaler && teamLeadData.Wholesaler.nationalIdBackImage}`} target="blank">
                                                <img onError={() => setBackNationIdCorruptedImg(true)} src={`${teamLeadData && teamLeadData.Wholesaler && teamLeadData.Wholesaler.nationalIdBackImage}`} className='img-fluid Ap-register-image' />
                                            </a>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly disabled />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly disabled />
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                : null}

                            {/* action btns */}
                            <Row>
                                <Col xs="8" className='d-flex justify-content-start align-items-center mt-5 ' >
                                    <button className="cancel-retention-modal-btn" type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setReinstateModal(false);
                                            setCancelConfirmModal(true);
                                        }}
                                    >Cancel</button>
                                    <button className="reinstate-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setReinstateModal(false);
                                            setReinstateConfirmModal(true);
                                        }}
                                    >
                                        Reinstate
                                    </button>
                                </Col>
                            </Row>

                        </Form>
                    </Container>

                    {/* logs */}

                    {/* <Container>
                        <p className="logs-title mt-5">All Logs</p>
                        <div className='Ap-logs' style={{paddingLeft:"1.5em"}}>
                            {logs.map((i)=>
                            <span>
                                <div className='d-flex'>
                                    <p className='logs-data'>mohmaed ali</p>
                                    <p className='logs-data'>22/10/2022 </p>
                                    <p className='logs-data'>3:00pm</p>
                                </div>
                                <div className='info'>
                                    created form
                                </div>
                            </span>
                            )}
                        </div>
                    </Container> */}

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Reinstate

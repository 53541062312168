import React, { useState } from 'react'
import { Modal, Form, Container, Row, Col } from "react-bootstrap"
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


const DefineDamage = (props) => {

    const {
        setDefineDamagedModal,
        pos,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        updateData
    } = props

    //Done btn state checker
    const [defineDamageBtn, setDefineDamageBtn] = useState(false)

    const [damaged, setDamaged] = useState("");
    const [availableCheckFlag, setAvailableCheckFlag] = useState(false);

    const available = pos && pos.available



    //submit adjust
    const Submit = async (e) => {

        e.preventDefault();

        if (damaged <= available) {
            setAvailableCheckFlag(false);
            setDefineDamageBtn(true)
            var data = {
                'damaged': parseInt(damaged)
            }
            try {
                /* define damage */
                await putToAgel(`${process.env.React_APP_DEFINE_DAMAGE}`, data)
                setSuccessModal(true)
            }
            catch (err) {
                setErrorMsgInFailedModal(err?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
            updateData()
            setDefineDamagedModal(false)
        }
        else {
            setAvailableCheckFlag(true);
            setDefineDamageBtn(false)
        }
    }

    return (
        <div>
            <Modal show={true} onHide={() => setDefineDamagedModal(false)} centered size="lg" style={{ marginTop: "5em" }} className="posActionModal">
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <span className="dashboard-font">
                            <strong>
                                Define damaged
                            </strong>
                        </span>
                        {/* form */}
                        <Form onSubmit={Submit}>
                            <Row className='mt-2'>
                                <Col xs="12">
                                    <Form.Group>
                                        <Form.Control type="text" className="damage-input" value={damaged} onChange={(e) => setDamaged(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {availableCheckFlag === true &&
                                <p className='password-confirm-msg mt-4'>Damaged number should be less than or equal the available number</p>
                            }

                            <Modal.Footer className='d-flex justify-content-end align-items-center mt-4' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={defineDamageBtn ? true : false}>
                                    {defineDamageBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>
                                <button className="roleCloseBtn" onClick={() => setDefineDamagedModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DefineDamage

import React from 'react'
import { PieChart } from 'react-minimal-pie-chart';

export default function DashboardChart(props) {
    const {
        val1,
        val2
    } = props
    return (
        <div>
            <PieChart className='home-pie-chart'
                data={[
                    { title: 'Active', key: 0, value: val1?val1:null, color: '#10d99d' },
                    { title: 'Inactive', key: 1, value: val2?val2:null, color: '#e9ecef' },
                ]}
                animate={true}
                label={({ dataEntry }) => dataEntry.value}
                labelPosition={50} //b center el label f kol segment lw 7atet rakm fa hyb2a blnsba ll origin lw 50 fa da hy center it
            // labelStyle={{ fontSize: "0.5vw" }} // style el label bya5od css object 3ade
            // lineWidth={100} //somk kol radius segmentstartAngle el defult 100 34an ta5od el full width wtmla el dayra
            // lengthAngle={360} //el pie chart kolha hatlf l7d ad eh el default bta3ha 360 34an tlf lfa kamla
            // paddingAngle={10} //lw 3yza aseb bnhom fwasl

            />
            
        </div>
    )
}

import React from 'react'
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'


export default function PaymentsDetails(props) {
    const payments = props.wholesalerPayment
    const {
        wsID
    } = props
    let navigate = useNavigate()


    const seeInvoices = (id) => {
        navigate(`/wholesalers/list/${id}/${wsID}/details/invoices`)
    }
    return (
        <div className="mt-5">
            <Table>
                <thead className="thead-profile">
                    <tr>
                        <th className='text-center'>Date</th>
                        <th className='text-center'>Store Name</th>
                        <th className='text-center'>Retailer Name</th>
                        <th className='text-center'>Invoice Status</th>
                        <th className='text-center'>Paid/Notpaid</th>
                        <th className='text-center'>Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {payments && payments.map((i) =>


                    (
                        <tr key={i?.payment?.id} className='role-table-text clickable-row' onClick={() => { seeInvoices(i?.payment?.id) }}>
                            <td className='text-center'>{
                                new Date(i?.payment?.createdAt).toLocaleDateString()
                            }</td>
                            <td className='text-center'>{i?.payment?.Transaction?.Wstore?.name}</td>
                            <td className='text-center'>{i?.payment?.Transaction?.Retailer?.name}</td>
                            <td className='text-center'>{i?.payment?.Transaction?.status}</td>
                            <td className={i && i.payment&&i.payment.status && i.payment.status ? "profile-active text-center" : "profile-inActive text-center"}>{JSON.stringify(i?.payment?.status)}</td>
                            <td className="profile-active text-center">{i?.payment?.totalPrice}</td>
                        </tr>
                    )
                    )

                    }

                </tbody>
            </Table>
        </div>
    )
}
import { postToAgel } from "./api";

import Cookies from 'universal-cookie';

import { setAuthorizationToken } from '../utils/api'
export async function login(data) {
    try {
        const response = await postToAgel(`${process.env.REACT_APP_DASHBOARD_LOGIN}`, data)
        const token = response.data.token;
        // console.log("token=", token)
        const cookies = new Cookies();
        cookies.set('token', token, { sameSite: true, maxAge: 12 * 60 * 60, path: '/', domain: '' })
        setAuthorizationToken(token)
        return response //byrg3 el response bdl (true) 34an a3rf amsk el data fa amsk el permission 
    }
    catch (error) {
        // console.log(error.name, error.message);
        return error; //byrg3 elerror ele zahr asna2 el login 34an el user y4ofo f el failed modal box
    }


}

export function logout() {
    const cookies = new Cookies();
    cookies.remove('token', { path: '/', domain: '' });
    window.location.reload();
}
import React, { useRef, useState } from 'react';
import { Table, Container, Card, Row, Col } from 'react-bootstrap';
import APLoadingSkeletonUi from '../../common/loadingSkeletonUi/activationProcessLoadingSkeletonUi ';

const AcquisitionTable = (props) => {

    // const dragItem = useRef();
    // const dragOverItem = useRef();
    
    
    //props
    const {
        acquisitionData,
        setCurrentLead,
        currentLead,
        setMoveLeadCommentModal,
        setFromPhase,
        seToPhase,
        setEditLeadModal
    } = props

    const [unAssigned, setUnAssigned] = useState(acquisitionData.filter((i) => i.status === "unassigned"))
    const [assigned, setAssigned] = useState(acquisitionData.filter((i) => i.status === "assigned"))


    const acquisitionPhases = [
        {
            id: 1,
            title: "First Call",
            status: "firstCall",
            backgroundColor: "#FFCBA4"
        },
        {
            id: 2,
            title: "Second Call",
            status: "secondCall",
            backgroundColor: "#F4A460"
        },
        {
            id: 3,
            title: "Won",
            status: "won",
            backgroundColor: "#90EE90"
        },
        {
            id: 4,
            title: "Failed",
            status: "failed",
            backgroundColor: "#E77471"
        }
    ]

    // const dragStart = (e, position) => {
    //     dragItem.current = position;
    //     console.log(e.target.innerHTML);
    // }

    // const dragEnter = (e, position) => {
    //     dragOverItem.current = position;
    //     console.log(e.target.innerHTML);
    // }

    // const drop = (e) => {
    //     setAssigned([...assigned, unAssigned])
    //     // const copyListItems = [...list];
    //     // const dragItemContent = copyListItems[dragItem.current];
    //     // copyListItems.splice(dragItem.current, 1);
    //     // copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    //     // dragItem.current = null;
    //     // dragOverItem.current = null;
    //     // setList(copyListItems);
    // }

    return (

        <div className='Ap-table-layout acquisition-table d-flex' style={{ marginTop: "3rem", zIndex: "-3" }}>

            {/* new lead */}
            <Container fluid className='scroll-table'>
                <Table className='d-grid justify-content-center align-items-center'>
                    <thead className='d-flex justify-content-center align-items-center mb-2'>
                        <tr className='role-table-header'>
                            <th className="text-center" style={{ minWidth: "10em" }} >New lead</th>
                        </tr>
                    </thead>
                    {acquisitionData.length !== 0 ?
                        <tbody className='text-center Ap'>
                            <Row>
                                <Col xs="6"
                                    style={{ minHeight: "27rem" }}
                                    onDragEnd={(e) => {
                                        e.preventDefault();
                                        setMoveLeadCommentModal(false);
                                    }}
                                >

                                    <span className="role-table-header text-center acquisition-table-titles-border" style={{ minWidth: "10em" }} >unassigned</span>
                                    {/* unassigned */}
                                    {unAssigned && unAssigned.map((i) =>
                                        <tr key={i.id} className="d-grid justify-content-center align-items-center">
                                            <td>
                                                <div className=" d-flex justify-content-center align-items-center">
                                                    <Card
                                                        style={{ backgroundColor: "white" }}
                                                        className="d-flex justif-content-center align-items-center acquisition-card"
                                                        onClick={() => {
                                                            setEditLeadModal(true)
                                                            setCurrentLead(i)
                                                        }}>

                                                        <Card.Body style={{ minWidth: "9.5rem" }}>
                                                            {/* <Card.Title className="cardTitle">{"hello"}</Card.Title> */}
                                                            <Card.Text className='cardText'>
                                                                <span>{"gina"}</span>
                                                                <br />
                                                                <span>{"0129383774654534"}</span>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </Col>

                                {/* assigned */}
                                <Col xs="6"
                                    style={{ minHeight: "27rem" }}
                                    // onDragOver={(e) => { e.preventDefault(); console.log("drag over") }}
                                    onDragEnd={(e) => {
                                        e.preventDefault();
                                        console.log("drop")
                                        setMoveLeadCommentModal(true);
                                        seToPhase("assigned")
                                        // setAssigned([...assigned, currentLead])
                                        // let newUnAssigned = unAssigned.filter((i) => i.id !== currentLead.id)
                                        // setUnAssigned(newUnAssigned)// hy3ml kda f kol state han7otha lkol table fehom
                                    }}

                                >
                                    <span className="role-table-header text-center acquisition-table-titles-border" style={{ minWidth: "10em" }} >assigned</span>
                                    {assigned && assigned.map((i) =>
                                        <tr key={i.id} className="d-grid justify-content-center align-items-center"
                                        >

                                            <td>
                                                <div className=" d-flex justify-content-center align-items-center">
                                                    <Card
                                                        style={{ backgroundColor: "white" }}
                                                        className="d-flex justif-content-center align-items-center acquisition-card"
                                                        onClick={() => {
                                                            setEditLeadModal(true)
                                                            setCurrentLead(i)
                                                        }}
                                                        onDragStart={(e) => {
                                                            setCurrentLead(i);
                                                            setFromPhase("assigned")
                                                            console.log("assigned drag start")

                                                        }}
                                                        draggable
                                                    >

                                                        <Card.Body style={{ minWidth: "9.5rem" }}>
                                                            {/* <Card.Title className="cardTitle">{"hello"}</Card.Title> */}
                                                            <Card.Text className='cardText'>
                                                                <span>{"gina"}</span>
                                                                <br />
                                                                <span>{"0129383774654534"}</span>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </Col>

                            </Row>
                        </tbody>
                        :
                        <APLoadingSkeletonUi />
                    }
                </Table>
            </Container>


            {/* all phases */}
            {acquisitionPhases.map((currentAcquisitionPhase) =>
                <Container fluid className='scroll-table'
                    key={currentAcquisitionPhase.id}
                    // onDragOver={(e) => { e.preventDefault(); console.log("drag over=",e) }}
                    onDragEnd={(e) => {
                        e.preventDefault();
                        // console.log("drop target", e)
                        // console.log("drop tansfer=", e.dataTransfer)
                        setMoveLeadCommentModal(true);
                        seToPhase("")
                    }}


                >
                    <Table className='d-grid justify-content-center align-items-center'>
                        <thead className='d-flex justify-content-center align-items-center' style={{ marginBottom: "1.8rem" }}>
                            <tr className='role-table-header'>
                                <th className="text-center" style={{ minWidth: "10em" }} >{currentAcquisitionPhase.title}</th>
                            </tr>
                        </thead>
                        {acquisitionData.length !== 0 ?
                            <tbody className='text-center Ap'>
                                {acquisitionData.map((i) =>
                                    <tr key={i.id} className="d-grid justify-content-center align-items-center">
                                        {i.status === currentAcquisitionPhase.status ?
                                            <td>
                                                <div className=" d-flex justify-content-center align-items-center">
                                                    <Card
                                                        style={{ backgroundColor: currentAcquisitionPhase.backgroundColor }}
                                                        className="d-flex justif-content-center align-items-center acquisition-card"
                                                        onClick={() => {
                                                            setEditLeadModal(true)
                                                            setCurrentLead(i)
                                                        }}
                                                        onDragStart={(e) => {
                                                            console.log("drag start now")
                                                            setFromPhase(currentAcquisitionPhase.status)
                                                        }
                                                        }
                                                        draggable
                                                    >
                                                        <Card.Body style={{ minWidth: "9.5rem" }}>
                                                            <Card.Text className='cardText'>
                                                                <span>{i?.name}</span>
                                                                <br />
                                                                <span>{i?.phoneNumber}</span>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </td>
                                            : null}
                                    </tr>
                                )}
                            </tbody>
                            :
                            <APLoadingSkeletonUi />
                        }
                    </Table>
                </Container>

            )}
        </div>
    )
}

export default AcquisitionTable

import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

const PrivateRoute = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  // to capture previous location
  const location = useLocation();
  console.log(location.pathname);
  // console.log(token)
  // const auth = true; //han8yrha b3d keda wna5odha mn el back b cookies or local storage

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return token ? <Outlet /> : (
    <Navigate
      to={`/login${
        location.pathname == "/" ? "" : "?rediret-to=" + location.pathname
      }`}
      state={{ location }}
    />
  ); // mmkn ab3tlha hena state el permission w yt3ml setstate gwa b3den keda hya ba2et gwa el component da kol el permissions
};
export default PrivateRoute;

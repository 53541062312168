import React, { useState } from "react";
import {
  Modal, Container, Form, Row, Col,
} from "react-bootstrap";
import { postToAgel } from "../../utils/api";
import AllCustomersOption from "./AllCustomersOption";
import SelectedCustomers from "./SelectedCustomers";
import LoadingBtnSpinner from "../common/LoadingBtn/LoadingBtn";

export default function DahboardNotification(props) {
  const {
    cities,
    industries,
    userType,
    setShowNotification,
    setSuccessModal,
    setFailedModalWithErrorMsg,
    setErrorMsgInFailedModal,
  } = props;

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [selectedCityId, setSelectedCityId] = useState("");
  const [selectedIndustryId, setSelectedIndustryId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchByNumber, setSearchByNumber] = useState('');
  const [searchedResultNames, setSearchedResultNames] = useState([]);
  const [switchNotificationOptions, setSwitchNotificationOptions] = useState("All Customers");

  const switchNotification = (option) => {
    setSwitchNotificationOptions(option);
  };

  // send notification btn state checker
  const [sendNotificationBtn, setSendNotificationBtn] = useState(false);

  // send notification btn fn to all customers
  const handleSendNotificationToAll = async (e) => {
    e.preventDefault();
    setSendNotificationBtn(true);
    const requestBody = {
      userType,
      body,
      title,
      data: { action: "regular_action" },
    };
    const params = {
      ...(selectedStatus === "" || selectedStatus === "all" ? {} : { status: selectedStatus }),
      ...(selectedIndustryId ? { IndustryId: selectedIndustryId } : {}),
      ...(selectedCityId ? { CityId: selectedCityId } : {}),
    };
    try {
      await postToAgel(`dashboard/sendNotificationByFilters?${new URLSearchParams(params)}`, requestBody);
      setSuccessModal(true);
    } catch (err) {
      setErrorMsgInFailedModal(err?.response?.data?.message);
      setFailedModalWithErrorMsg(true);
    }
    setSendNotificationBtn(false);
    setShowNotification(false);
  };

  // send notification btn fn to all specific users
  const handleSendNotificationToSpecific = async (e) => {
    e.preventDefault();
    setSendNotificationBtn(true);
    const requestBody = {
      userType,
      body,
      title,
      data: { action: "regular_action" },
      selectedCustomerIDs: searchedResultNames.map((i) => i.id),
    };
    try {
      await postToAgel('dashboard/sendNotificationByIds', requestBody);
      setSuccessModal(true);
    } catch (err) {
      setErrorMsgInFailedModal(err?.response?.data?.message);
      setFailedModalWithErrorMsg(true);
    }
    setSendNotificationBtn(false);
    setShowNotification(false);
  };

  return (
    <div>
      <Modal show onHide={() => setShowNotification(false)} className="dashboard-modal mt-5" centered>
        <Modal.Header closeButton>
          <span className="notification-header">
            Send Notification
          </span>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form onSubmit={switchNotificationOptions === "All Customers" ? handleSendNotificationToAll : handleSendNotificationToSpecific}>

              {/* notification data [title/body] */}
              <Form.Group className="mb-1">
                <Form.Label className="form-text">title</Form.Label>
                <Form.Control type="text" className="notification-msg-title" placeholder="Notification Title" value={title} onChange={(e) => { setTitle(e.target.value); }} required />
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label className="form-text">Message</Form.Label>
                <Form.Control type="text" className="notification-msg-body" as="textarea" placeholder="Enter notification Message" value={body} onChange={(e) => { setBody(e.target.value); }} required />
              </Form.Group>

              {/* notification options */}
              <Row className="mt-3">
                <Col xs="6" onClick={() => switchNotification("All Customers")}>
                  <span className={switchNotificationOptions === "All Customers" ? "switch-notification-options-text-active" : "switch-notification-options-text"}>
                    All {userType && userType}
                  </span>
                </Col>
                <Col xs="6" onClick={() => switchNotification("Select Customers")}>
                  <span className={switchNotificationOptions === "Select Customers" ? "switch-notification-options-text-active" : "switch-notification-options-text"}>
                    Select {userType && userType}
                  </span>
                </Col>
              </Row>
              {switchNotificationOptions === "All Customers"
                ? (
                  <AllCustomersOption
                    cities={cities}
                    industries={industries}
                    setSelectedCityId={setSelectedCityId}
                    setSelectedIndustryId={setSelectedIndustryId}
                    setSelectedStatus={setSelectedStatus}
                    selectedStatus={selectedStatus}
                  />
                )
                : (
                  <SelectedCustomers
                    searchByNumber={searchByNumber}
                    setSearchByNumber={setSearchByNumber}
                    userType={userType}
                    searchedResultNames={searchedResultNames}
                    setSearchedResultNames={setSearchedResultNames}
                  />
                )}
              <Modal.Footer className="mt-4" style={{ padding: 0 }}>
                <button type="submit" className="notification-footer-btn" disabled={sendNotificationBtn === true}>
                  {sendNotificationBtn
                    ? (<LoadingBtnSpinner />)
                    : (<span>Send</span>)}
                </button>
              </Modal.Footer>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </div>

  );
}

import React, { useContext } from "react";
import LoadingBtnSpinner from "../common/LoadingBtn/LoadingBtn";
import { userValidPermissionAndAction } from "../../utils/permissionsAndActions";
import { userPermissionsAndActionsContext } from "../../App";

export default function APActionButtons(props) {
  const {
    permissionName,
    actionName,
    noPermission,
    btnClassName,
    btnType,
    btnName,
    btnActions,
    isLoaderFlagTrue,
    disableModalActions
  } = props;

  const validPermissionsAndActions = useContext(userPermissionsAndActionsContext);
  const viewAPActionButtonPermission = userValidPermissionAndAction(validPermissionsAndActions, permissionName, actionName);

  // if only have permission to see button
  if (viewAPActionButtonPermission || noPermission) {
    return (
      <button
        className={btnClassName}
        type={btnType}
        onClick={btnActions}
        disabled={isLoaderFlagTrue || disableModalActions}
      >
        {isLoaderFlagTrue
          ? (<LoadingBtnSpinner />)
          : (
            <span>
              {btnName}
            </span>
          )}
      </button>
    );
  }

  return (<></>);
}

import React, { useState, useEffect } from 'react';
import {
  Modal, Form, Container, Row, Col, Spinner,
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import fileDownload from 'js-file-download';

// import component
import { BiMessageDetail } from 'react-icons/bi';
import { AiOutlineFileImage } from "react-icons/ai";
import { CgSoftwareDownload, CgProfile } from 'react-icons/cg';
import ApproveButton from './ApproveButton';
import DynamicAF from './DynamicAFBeforeAgentVisit';
import APActionButtons from '../ActivationProcessCommonModals/APActionButtons';

// icons
import { putToAgel, getFileFromAgel } from '../../utils/api';

const Register = (props) => {
  const navigate = useNavigate();

  // props
  const {
    setReschduleModal,
    setRescheduleCurrent,
    setRegisterRescheduleFlag,
    setFirstVisitRescheduleFlag,
    setContractRescheduleFlag,
    setSecondVisitRescheduleFlag,
    setRegisterModal,
    current,
    setSuccessModal,
    setFailedModalWithErrorMsg,
    setErrorMsgInFailedModal,
    updateData,
    setCancelConfirmModal,
    setShowAFComment,
    setAFCancelComment,
  } = props;

  const [AFDynamicData, setAFDynamicData] = useState([]);
  const [AFStaticData, setAFStaticData] = useState([]);
  const [AFRetailerData, setAFRetailerData] = useState([]);
  const [AFCancelCommentInfo, setAFCancelCommentInfo] = useState([]);
  const [estimatedCredit, setEstimatedCredit] = useState("");
  // const [logs, setLogs] = useState([1]);
  const [downloading, setDownloading] = useState(false);
  const [userEdits, setUserEdits] = useState([]);
  const [colEditsData, setColEditsData] = useState({});

  // state checker on buttons
  const [saveEditsBtn, setSaveEditsBtn] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // logs
        // const logsResponse=await getFromAgel(`/${current.id}`)
        // console.log("id=",current.id)
        // console.log("logsResponse=",logsResponse)
        // setLogs(logsResponse)

      } catch (error) {
        // console.log("error=", error)
        // console.log("id=", current.id)
      }
    };
    fetchData();
  }, []);

  const saveEdits = async (e) => {
    e.preventDefault();
    setSaveEditsBtn(true);

    const data = {
      Static: colEditsData,
      Dynamic: userEdits,
    };
    try {
      /* retailer save Af edits */
      await putToAgel(`${process.env.React_APP_RETAILER_AF_SAVE_EDITS}${current.id}`, data);
      updateData();
      setSuccessModal(true);
    } catch (err) {
      setErrorMsgInFailedModal(err?.response?.data?.message);
      setFailedModalWithErrorMsg(true);
    }
    setRegisterModal(false);
  };

  const downloadPdf = async () => {
    /* get retailer contract before signature */
    try {
      const contractResponse = await getFileFromAgel(`${process.env.React_APP_RETAILER_GET_CONTRACT}${current.id}`, { responseType: 'blob' });
      fileDownload(contractResponse.data, 'contract.pdf');
    } catch (err) {
      setErrorMsgInFailedModal(err?.response?.data?.message);
      setFailedModalWithErrorMsg(true);
    }
    setDownloading(false);
  };

  const currentAFCancelComment = AFCancelCommentInfo;

  return (
    <div>
      <Modal show onHide={() => setRegisterModal(false)} centered size="lg" className="AP-modal" style={{ marginTop: "5em", marginBottom: "15em" }}>
        {/* top */}
        <Modal.Header closeButton />
        <Modal.Body style={{ marginBottom: "5em" }}>
          <Container style={{ marginTop: "-1.5rem" }}>

            <Row>
              <Col xs={12} className=" d-flex align-items-center">
                <p className="modal-header-title">{AFStaticData && AFStaticData.name}</p>
                {AFStaticData && AFStaticData.name
                  ? <CgProfile className="link-to-profile-icon-through-af" onClick={() => navigate(`/retailers/list/${AFDynamicData && AFDynamicData.RetailerId}/details`)} />
                  : null}
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="d-flex text-end justify-content-between align-items-start mt-2">
                <p className="modal-title">
                  Agent name
                  <span className="text-muted" style={{ marginLeft: "0.3rem" }}>No agents</span>
                </p>
                <p className="modal-title">
                  request date
                  <span className="text-muted" style={{ marginLeft: "0.3rem" }}>{(new Date(AFDynamicData && AFDynamicData.createdAt).toLocaleDateString())}</span>
                </p>
                <p className="modal-title">
                  visit date
                  <span className="text-muted" style={{ marginLeft: "0.3rem" }}>{(new Date(AFDynamicData && AFDynamicData.updatedAt).toLocaleDateString())}</span>
                </p>
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="d-flex text-end justify-content-between align-items-start mt-2">
                <p className="modal-title">
                  Estimated credit
                  <span className="text-muted" style={{ marginLeft: "0.3rem" }}>{estimatedCredit && estimatedCredit}</span>
                </p>
              </Col>
            </Row>

            {/* approve button */}
            <Row>
              <Col xs="2" className="d-flex mt-2" style={{ width: "100%", paddingLeft: 0 }}>
                <ApproveButton
                  model={setRegisterModal}
                  updateData={updateData}
                  setSuccessModal={setSuccessModal}
                  setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                  setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                  current={current}
                />
              </Col>
            </Row>

          </Container>

          <Container className="mt-3" dir="rtl">
            <Form>
              {/* dynamic  */}
              <DynamicAF
                setUserEdits={setUserEdits}
                userEdits={userEdits}
                AFDynamicData={AFDynamicData}
                AFstaticData={AFStaticData}
                AFRetailerData={AFRetailerData}
                setAFRetailerData={setAFRetailerData}
                setAFDynamicData={setAFDynamicData}
                setAFStaticData={setAFStaticData}
                setAFCancelCommentInfo={setAFCancelCommentInfo}
                setColEditsData={setColEditsData}
                setEstimatedCredit={setEstimatedCredit}
                id={current.id}
                editCase={false}
              />

              {/* contract */}
              <Container className="d-flex mt-5">
                <AiOutlineFileImage className="AF-reinstate-comment-icon" />
                <p className="contract-text mt-1">Contract</p>
                {
                  downloading
                    ? <Spinner animation="border" className="loader" />
                    : <CgSoftwareDownload className="AF-reinstate-comment-icon cursor-pointer" onClick={async (e) => { setDownloading(true); await downloadPdf(); }} />
                }
              </Container>

              {/* reinstate comment */}
              {currentAFCancelComment && currentAFCancelComment.length !== 0
                ? (
                  <Container className="mt-3">
                    <BiMessageDetail
                      className="AF-reinstate-comment-icon cursor-pointer"
                      onClick={() => {
                        setRegisterModal(false);
                        setShowAFComment(true);
                        setAFCancelComment(currentAFCancelComment);
                      }}
                    />
                    <span className="AF-reinstate-comment-text">
                      reinstate comment
                    </span>
                  </Container>
                )
                : null}

              {/* actions btn */}
              <Row>
                <Col xs="6" className="d-flex justify-content-start align-items-center mt-5 ">
                  <APActionButtons
                    permissionName="retailerActivationProcess"
                    actionName="cancel retailer activation process"
                    btnClassName="cancel-visit"
                    btnType="submit"
                    btnName="Cancel"
                    isLoaderFlagTrue={false}
                    btnActions={(e) => {
                      e.preventDefault();
                      setRegisterModal(false);
                      setCancelConfirmModal(true);
                    }}
                  />

                  <APActionButtons
                    permissionName="retailerActivationProcess"
                    actionName="assign agent retailer activation process"
                    btnClassName="action-visit"
                    btnType="submit"
                    btnName="Assign Agent"
                    isLoaderFlagTrue={false}
                    btnActions={(e) => {
                      e.preventDefault();
                      setRegisterRescheduleFlag(true);
                      setFirstVisitRescheduleFlag(false);
                      setSecondVisitRescheduleFlag(false);
                      setContractRescheduleFlag(false);
                      setReschduleModal(true);
                      setRescheduleCurrent(AFDynamicData);
                      setRegisterModal(false);
                    }}
                  />

                </Col>
                <Col xs="6" className="d-flex justify-content-end align-items-center mt-5 ">
                  <APActionButtons
                    permissionName=""
                    actionName=""
                    noPermission
                    btnClassName="save-edits"
                    btnType="submit"
                    btnName="Save Edits"
                    isLoaderFlagTrue={saveEditsBtn}
                    btnActions={saveEdits}
                  />
                </Col>

              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Register;

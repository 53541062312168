import React, { useEffect, useState, useContext } from 'react'
import { getFromAgel } from '../../utils/api'
import { Row, Col } from 'react-bootstrap'
import { MdAddCircle } from 'react-icons/md'
import './Compliance.css'

import Failed_Modal from '../../components/common/FailedModal'
import SuccessModal from '../../components/common/SuccessModal'
import RelationTable from '../../components/Compliance/RelationTable'
import CreateRelation from '../../components/Compliance/CreateRelation'
import EditRelationStatus from '../../components/Compliance/EditRelationStatus'
import RelationComment from '../../components/Compliance/RelationComment'
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen'

import { userValidPermissionAndAction } from '../../utils/permissionsAndActions'
import { userPermissionsAndActionsContext } from '../../App'



export default function Compliance() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewRelationsPermission = userValidPermissionAndAction(validPermissionsAndActions, "transactionRelation", "viewTRXRelation")

    const [relations, setRelations] = useState([])
    const [currentRelation, setCurrentRelation] = useState("")
    //modals
    //action modals
    const [createRelationModal, setCreateRelationModal] = useState(false)
    const [editRelationStatusModal, setEditRelationStatusModal] = useState(false)
    const [relationCommentModal, setRelationCommentModal] = useState(false)
    const [currentCommentTxt, setCurrentCommentTxt] = useState("")
    const [currentCommentMaker, setCurrentCommentMaker] = useState("")

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")

    //search
    const [relationSearch, setRelationSearch] = useState("") // search input state
    const [searchResult, setSearchResult] = useState("") //search btn

    //check on all filters thats not empty for setting loading checker
    const [allFiltersChecker, setAllFiltersChecker] = useState(false)



    async function getRelations() {
        try {
            const getRelations = await getFromAgel("/dashboard/transactionRelation/get")
            setRelations(getRelations.data)
        }
        catch (error) {
            //
        }
    }

    useEffect(() => {
        getRelations()
    }, [])

    //search btn fn
    const search = () => {
        setSearchResult(relationSearch)
        if (relationSearch !== "") {
            setAllFiltersChecker(true)
        }
        else {
            setAllFiltersChecker(false)
        }
    }

    //update role result
    let relationsSearch = searchResult !== "" ? relations.filter((i) => i?.retailernumber.toLowerCase().includes(searchResult.toLowerCase()) || i?.wholesalernumber.toLowerCase().includes(searchResult.toLowerCase()) || i?.retailerName.toLowerCase().includes(searchResult.toLowerCase()) || i?.wholesalerName.toLowerCase().includes(searchResult.toLowerCase())) : relations

    if (viewRelationsPermission) {
        return (
            <div>
                <Row className="layout-top">
                    <Col xs="3" className='text-start'>
                        {/* input */}
                        <div className='input-wrapper'>
                            <input type="text" placeholder="name/phone number" value={relationSearch} onChange={(e) => setRelationSearch(e.target.value)} />
                        </div>
                    </Col>
                    <Col xs="4" className='text-start'>
                        {/* search */}
                        <button className='role-search-btn' onClick={() => search()}>Search</button>
                    </Col>
                    <Col xs="5" className='text-end'>
                        {/* create new relation btn */}
                        <div>
                            <button className='add-role-btn' onClick={() => { setCreateRelationModal(true) }}>
                                <span className='d-flex align-items-center justify-content-center'>
                                    <MdAddCircle size={20} className="add-role-btn-icon" />
                                    <p>add relation</p>
                                </span>
                            </button>
                            {
                                createRelationModal ?
                                    <CreateRelation
                                        setCreateRelationModal={setCreateRelationModal}
                                        updateData={getRelations}
                                        setSuccessModal={setSuccessModal}
                                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                    />
                                    : null
                            }
                        </div>
                    </Col>
                </Row>

                {/* relations table */}
                <RelationTable
                    relations={relationsSearch}
                    setEditRelationStatusModal={setEditRelationStatusModal}
                    setCurrentRelation={setCurrentRelation}
                    setRelationCommentModal={setRelationCommentModal}
                    setCurrentCommentTxt={setCurrentCommentTxt}
                    setCurrentCommentMaker={setCurrentCommentMaker}
                    allFiltersChecker={allFiltersChecker}
                />

                {/* edit relation status  */}
                {editRelationStatusModal ?
                    <EditRelationStatus
                        setEditRelationStatusModal={setEditRelationStatusModal}
                        updateData={getRelations}
                        setSuccessModal={setSuccessModal}
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        currentRelation={currentRelation}
                    />
                    : null}

                {/* show relation comment  */}
                {relationCommentModal ?
                    <RelationComment
                        setRelationCommentModal={setRelationCommentModal}
                        currentCommentTxt={currentCommentTxt}
                        currentCommentMaker={currentCommentMaker}

                    />
                    : null}

                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={getRelations}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }


            </div>
        )
    }
    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }

}
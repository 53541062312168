import React from 'react'

import { Modal } from "react-bootstrap"
import{ BsCheckCircle } from 'react-icons/bs'

const SuccessModal = (props) => {

    const{
        setSuccessModal,
        successMsg
    }=props


    return (
        <div>
            <Modal show={true} onHide={() => setSuccessModal(false)} centered  className="server-msg-modal" size="sm" style={{marginTop:"5em"}}>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="text-center">
                    <BsCheckCircle size={65} color={"#00BD85"}/>
                    <p className='success-modal-text mt-5'>{successMsg?successMsg:"Done"}</p>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SuccessModal

import React, { useEffect, useState, useContext } from "react";
import Select from 'react-select';
import { Row, Col } from "react-bootstrap";
import './Agents.css';
import { MdAddCircle } from 'react-icons/md';

// components
import AgentTable from "../../components/Agents/AgentTable";
import EditAgentsModal from "../../components/Agents/EditAgentsModal";
import ResetPasswordAgentsModal from "../../components/Agents/ResetPasswordAgentsModal";
import CreateAgentsModal from "../../components/Agents/CreateAgentsModal";
import Failed_Modal from "../../components/common/FailedModal";
import AuthorizationRequiredScreen from "../AuthorizationRequiredScreen/AuthorizationRequiredScreen";

// api

import { getFromAgel } from "../../utils/api";

import { userPermissionsAndActionsContext } from "../../App";
import SuccessModal from "../../components/common/SuccessModal";
import { userValidPermissionAndAction } from "../../utils/permissionsAndActions";

export default function Agents() {
  const validPermissionsAndActions = useContext(userPermissionsAndActionsContext);
  const viewAgentPermission = userValidPermissionAndAction(validPermissionsAndActions, "agent", "viewAgent");
  const viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity");

  const [agents, setAgents] = useState([]);
  const [cities, setCities] = useState([]);

  /// Modal
  const [resetPasswordAgentsModal, setResetPasswordAgentsModal] = useState(false);
  const [currentAgentPasswordModal, setCurrentAgentPasswordModal] = useState({});
  const [createAgentModal, setCreateAgentModal] = useState(false);
  const [editAgentModal, setEditAgentModal] = useState(false);
  const [currentAgentModal, setCurrentAgentModal] = useState({});

  // search
  const [agentSearch, setAgentSearch] = useState(""); // search input state
  const [searchCity, setSearchCity] = useState("");
  const [searchResult, setSearchResult] = useState(""); // search btn

  // server modal response success/failed
  const [successModal, setSuccessModal] = useState(false);
  const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false);
  const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("");

  const fetchAgents = async () => {
    try {
      // get agents
      const agentsResponse = await getFromAgel(`${process.env.React_APP_GET_AGENT}`, {
        params: {
          ...(searchCity ? { CityId: searchCity } : {}),
        },
      });
      console.log('agentsResponse', agentsResponse.data.agents);
      setAgents(agentsResponse.data.agents);

      // get cities //view cities permission
      if (viewCitiesPermission) {
        const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
        setCities(cityResponse.data.cities);
      }
    } catch (err) {
      console.log('error=', err);
    }
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  const sortedAgents = agents && agents.sort((a, b) => (a.id) - (b.id));

  // search btn fn
  const search = () => {
    setSearchResult(agentSearch);
    if (searchCity !== "") {
      fetchAgents();
    }
  };
  const agentSearchResult = searchResult !== "" ? sortedAgents.filter((i) => i.name.toLowerCase().includes(searchResult.toLowerCase()) || i.phoneNumber.toLowerCase().includes(searchResult)) : sortedAgents;

  if (viewAgentPermission) {
    return (
      <div>

        {/* row1 */}
        <Row className="layout-top">

          {/* input */}
          <Col xs="3 text-start">
            <div className="input-wrapper">
              <input type="text" placeholder="Name / PhoneNumber" value={agentSearch} onChange={(e) => setAgentSearch(e.target.value)} />
            </div>
          </Col>

          {/* search */}
          <Col xs="4" className="text-start">
            <button type="button" className="role-search-btn" onClick={() => search()}>Search</button>
          </Col>

          {/* create agent btn */}
          <Col xs="5 text-end">
            <div>
              <button type="button" className="add-role-btn" onClick={() => { setCreateAgentModal(true); }}>
                <span className="d-flex align-items-center justify-content-center">
                  <MdAddCircle size={20} className="add-role-btn-icon" />
                  <p>Add Agent</p>
                </span>
              </button>
              {
                createAgentModal
                  ? (
                    <CreateAgentsModal
                      cities={cities}
                      setCreateAgentModal={setCreateAgentModal}
                      updateData={fetchAgents}
                      setSuccessModal={setSuccessModal}
                      setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                      setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                    />
                  )
                  : null
              }

            </div>
          </Col>
        </Row>

        {/* row2 */}
        <Row className="layout-row-2">
          <Col xs="2 text-start">
            {/* select city */}
            <Select
              className="search-select mt-2"
              defaultValue={{ label: "City", value: 0 }}
              onChange={(e) => { setSearchCity(e.value); }}
              options={cities.map((city) => ({ value: city.id, label: city.EnName }))}
            />
          </Col>
        </Row>

        {/* agents table and its header  */}
        <AgentTable
          agents={agentSearchResult}
          cities={cities}
          setEditAgentModal={setEditAgentModal}
          setCurrentAgentModal={setCurrentAgentModal}
          setResetPasswordAgentsModal={setResetPasswordAgentsModal}
          setCurrentAgentPasswordModal={setCurrentAgentPasswordModal}
        />
        {/* EDit model */}
        {
          editAgentModal
            ? (
              <EditAgentsModal
                setEditAgentModal={setEditAgentModal}
                setCurrentAgentModal={setCurrentAgentModal}
                currentAgentModal={currentAgentModal}
                cities={cities}
                updateData={fetchAgents}
                setSuccessModal={setSuccessModal}
                setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                setErrorMsgInFailedModal={setErrorMsgInFailedModal}
              />
            )
            : null
        }
        {/* Reset Password model */}
        {
          resetPasswordAgentsModal
            ? (
              <ResetPasswordAgentsModal
                setResetPasswordAgentsModal={setResetPasswordAgentsModal}
                currentAgentPasswordModal={currentAgentPasswordModal}
                updateData={fetchAgents}
                setSuccessModal={setSuccessModal}
                setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                setErrorMsgInFailedModal={setErrorMsgInFailedModal}
              />
            )
            : null
        }

        {/* success server response model */}
        {
          successModal
            ? (
              <SuccessModal
                setSuccessModal={setSuccessModal}
                updateData={fetchAgents}
              />
            )
            : null
        }

        {/* failed server response model */}
        {failedModalWithErrorMsg
          ? (
            <Failed_Modal
              setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
              errorMsg={errorMsgInFailedModal}
            />
          )
          : null}

      </div>
    );
  }
  // if not auth

  return (
    <AuthorizationRequiredScreen />
  );
}

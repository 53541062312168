
import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Select from 'react-select';
import './RetailerActivationProcess.css'

// components
import RetailerActivationTable from "../../components/RetailerActivationProcess/RetailerActivationTable";
import Register from "../../components/RetailerApModals/Register";
import SuccessModal from "../../components/RetailerApModals/SuccessModal";
import FirstVisit from "../../components/RetailerApModals/FirstVisit";
import ReschduleModal from "../../components/RetailerApModals/ReschduleModal";
import FirstTeam from "../../components/RetailerApModals/FirstTeam";
import RevertModal from "../../components/RetailerApModals/RevertModal";
import SecondTeam from "../../components/RetailerApModals/SecondTeam";
import ContractPreparartion from "../../components/RetailerApModals/ContractPreparartion";
import SecondVisit from "../../components/RetailerApModals/SecondVisit";
import TeamTwoSecondVisit from "../../components/RetailerApModals/TeamTwoSecondVisit";
import TeamThreeSecondVisit from "../../components/RetailerApModals/TeamThreeSecondVisit";
import Final from "../../components/RetailerApModals/Final";
import CancelConfirmScreen from "../../components/RetailerApModals/CancelConfirmScreen";
import Failed_Modal from "../../components/common/FailedModal";
import AFComment from "../../components/ActivationProcessCommonModals/AFComment";
import AuthorizationRequiredScreen from "../AuthorizationRequiredScreen/AuthorizationRequiredScreen";


//api
import { getFromAgel } from "../../utils/api";

import { userPermissionsAndActionsContext } from "../../App";
import { userValidPermissionAndAction } from "../../utils/permissionsAndActions";



export default function RetailerActivationProcess() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewRetailerApPermission = userValidPermissionAndAction(validPermissionsAndActions, "retailerActivationProcess", "viewRActivationProcessByStatus")
    let viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity")
    let viewIndustriesPermission = userValidPermissionAndAction(validPermissionsAndActions, "industry", "viewIndustry")


    //filters api
    const [cities, setCities] = useState([])
    const [industries, setIndustries] = useState([])

    //filters
    const [filterByNameOrPhoneNumber, setFilterByNameOrPhoneNumber] = useState("") // search input state
    const [selectedCity, setSelectedCity] = useState("")
    const [selectedIndustry, setSelectedIndustry] = useState("")


    ///Modals
    const [registerModal, setRegisterModal] = useState(false)
    const [firstVisitModal, setFirstVisitModal] = useState(false)
    const [current, setCurrent] = useState({})
    const [rescheduleCurrent, setRescheduleCurrent] = useState({})
    const [firstTeamModal, setFirstTeamModal] = useState(false)
    const [revertModal, setRevertModal] = useState(false)
    const [secondTeamModal, setSecondTeamModal] = useState(false)
    const [contractPreparationModal, setContractPreparationModal] = useState(false)
    const [secondVisitModal, setSecondVisitModal] = useState(false)
    const [teamTwoSecondVisitModal, setTeamTwoSecondVisitModal] = useState(false)
    const [reschduleModal, setReschduleModal] = useState(false)
    const [registerRescheduleFlag, setRegisterRescheduleFlag] = useState(false)
    const [firstVisitRescheduleFlag, setFirstVisitRescheduleFlag] = useState(false)
    const [contractRescheduleFlag, setContractRescheduleFlag] = useState(false)
    const [secondVisitRescheduleFlag, setSecondVisitRescheduleFlag] = useState(false)
    const [teamThreeSecondVisitModal, setTeamThreeSecondVisitModal] = useState(false)
    const [finalModal, setFinalModal] = useState(false)
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false)
    const [hold, setHold] = useState(false)
    const [showAFComment, setShowAFComment] = useState(false)
    const [AFCancelComment, setAFCancelComment] = useState("")

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")

    //tables pagination states
    //pending
    const [pendingCounter, setPendingCounter] = useState(0)
    const [pendingList, setPendingList] = useState([]); // storing list
    const [pendingWasLastList, setPendingWasLastList] = useState(false); // setting a flag to know the last list

    //first visit
    const [firstVisitCounter, setFirstVisitCounter] = useState(0)
    const [firstVisitList, setFirstVisitList] = useState([]); // storing list
    const [firstVisitWasLastList, setFirstVisitWasLastList] = useState(false); // setting a flag to know the last list

    //first team
    const [firstTeamCounter, setFirstTeamCounter] = useState(0)
    const [firstTeamList, setFirstTeamList] = useState([]); // storing list
    const [firstTeamWasLastList, setFirstTeamWasLastList] = useState(false); // setting a flag to know the last list

    //second team
    const [secondTeamCounter, setSecondTeamCounter] = useState(0)
    const [secondTeamList, setSecondTeamList] = useState([]); // storing list
    const [secondTeamWasLastList, setSecondTeamWasLastList] = useState(false); // setting a flag to know the last list

    //contract phase
    const [contractPhaseCounter, setContractPhaseCounter] = useState(0)
    const [contractPhaseList, setContractPhaseList] = useState([]); // storing list
    const [contractPhaseWasLastList, setContractPhaseWasLastList] = useState(false); // setting a flag to know the last list

    //second visit
    const [secondVisitCounter, setSecondVisitCounter] = useState(0)
    const [secondVisitList, setSecondVisitList] = useState([]); // storing list
    const [secondVisitWasLastList, setSecondVisitWasLastList] = useState(false); // setting a flag to know the last list

    //second visit first team
    const [secondVisitFirstTeamCounter, setSecondVisitFirstTeamCounter] = useState(0)
    const [secondVisitFirstTeamList, setSecondVisitFirstTeamList] = useState([]); // storing list
    const [secondVisitFirstTeamWasLastList, setSecondVisitFirstTeamWasLastList] = useState(false); // setting a flag to know the last list

    //second visit second team
    const [secondVisitSecondTeamCounter, setSecondVisitSecondTeamCounter] = useState(0)
    const [secondVisitSecondTeamTeamList, setSecondVisitSecondTeamList] = useState([]); // storing list
    const [secondVisitSecondTeamWasLastList, setSecondVisitSecondTeamWasLastList] = useState(false); // setting a flag to know the last list

    //final
    const [finalCounter, setFinalCounter] = useState(0)
    const [finalList, setFinalList] = useState([]); // storing list
    const [finalWasLastList, setFinalWasLastList] = useState(false); // setting a flag to know the last list

    //check on all filters thats not empty for loading checker
    const [pendingLoadingSkeleton, setPendingLoadingSkeleton] = useState(false)
    const [firstVisitLoadingSkeleton, setFirstVisitLoadingSkeleton] = useState(false)
    const [firstTeamLoadingSkeleton, setFirstTeamLoadingSkeleton] = useState(false)
    const [secondTeamLoadingSkeleton, setSecondTeamLoadingSkeleton] = useState(false)
    const [contractPhaseLoadingSkeleton, setContractPhaseLoadingSkeleton] = useState(false)
    const [secondVisitLoadingSkeleton, setSecondVisitLoadingSkeleton] = useState(false)
    const [secondVisitFirstTeamLoadingSkeleton, setSecondVisitFirstTeamLoadingSkeleton] = useState(false)
    const [secondVisitSecondTeamLoadingSkeleton, setSecondVisitSecondTeamLoadingSkeleton] = useState(false)
    const [finalLoadingSkeleton, setFinalLoadingSkeleton] = useState(false)



    //tables get retailer activation process data use effect functions

    //pending
    const fetchPendingApData = async (pageNumber) => {
        setPendingLoadingSkeleton(true)
        try {
            const pendingResponse = await getFromAgel(`dashboard/retailers/activationProcess/v2/`, {
                params: {
                    status: "pending",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setPendingLoadingSkeleton(false)
            setPendingList((prev) => [...prev, ...pendingResponse.data.pending.data]);
            setPendingWasLastList(pendingResponse.data.pending.data.length !== 0 ? false : true)

        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }

    useEffect(() => {
        fetchPendingApData(0);
    }, [])


    //first visit
    const fetchfirstVisitApData = async (pageNumber) => {
        setFirstVisitLoadingSkeleton(true)
        try {
            const firstVisitResponse = await getFromAgel(`dashboard/retailers/activationProcess/v2/`, {
                params: {
                    status: "firstVisit",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setFirstVisitLoadingSkeleton(false)
            setFirstVisitList((prev) => [...prev, ...firstVisitResponse.data.firstVisit.data]);
            setFirstVisitWasLastList(firstVisitResponse.data.firstVisit.data.length !== 0 ? false : true)
        }
        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }
    useEffect(() => {
        fetchfirstVisitApData(0);
    }, [])


    //first team
    const fetchFirstTeamApData = async (pageNumber) => {
        setFirstTeamLoadingSkeleton(true)
        try {
            const firstTeamResponse = await getFromAgel(`dashboard/retailers/activationProcess/v2/`, {
                params: {
                    status: "firstTeam",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setFirstTeamLoadingSkeleton(false)
            setFirstTeamList(prev => [...prev, ...firstTeamResponse.data.firstTeam.data]);
            setFirstTeamWasLastList(firstTeamResponse.data.firstTeam.data.length !== 0 ? false : true)
        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }
    useEffect(() => {
        fetchFirstTeamApData(0)
    }, [])


    //second team
    const fetchSecondTeamApData = async (pageNumber) => {
        setSecondTeamLoadingSkeleton(true)
        try {
            const secondTeamResponse = await getFromAgel(`dashboard/retailers/activationProcess/v2/`, {
                params: {
                    status: "secondTeam",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setSecondTeamLoadingSkeleton(false)
            setSecondTeamList(prev => [...prev, ...secondTeamResponse.data.secondTeam.data]);
            setSecondTeamWasLastList(secondTeamResponse.data.secondTeam.data.length !== 0 ? false : true)
        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }
    useEffect(() => {
        fetchSecondTeamApData(0);
    }, [])


    //contract phase
    const fetchContractPhaseApData = async (pageNumber) => {
        setContractPhaseLoadingSkeleton(true)
        try {
            const contractPhaseResponse = await getFromAgel(`dashboard/retailers/activationProcess/v2/`, {
                params: {
                    status: "contractPreparation",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setContractPhaseLoadingSkeleton(false)
            setContractPhaseList(prev => [...prev, ...contractPhaseResponse.data.contractPreparation.data]);
            setContractPhaseWasLastList(contractPhaseResponse.data.contractPreparation.data.length !== 0 ? false : true)
        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }
    useEffect(() => {
        fetchContractPhaseApData(0);
    }, [])


    //second visit
    const fetchSecondVisitApData = async (pageNumber) => {
        setSecondVisitLoadingSkeleton(true)
        try {
            const secondVisitResponse = await getFromAgel(`dashboard/retailers/activationProcess/v2/`, {
                params: {
                    status: "secondVisit",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setSecondVisitLoadingSkeleton(false)
            setSecondVisitList(prev => [...prev, ...secondVisitResponse.data.secondVisit.data]);
            setSecondVisitWasLastList(secondVisitResponse.data.secondVisit.data.length !== 0 ? false : true)
        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }
    useEffect(() => {
        fetchSecondVisitApData(0);
    }, [])


    //second visit first team
    const fetchSecondVisitFirstTeamApData = async (pageNumber) => {
        setSecondVisitFirstTeamLoadingSkeleton(true)
        try {
            const secondVisitFirstTeamResponse = await getFromAgel(`dashboard/retailers/activationProcess/v2/`, {
                params: {
                    status: "secondVisitFirstTeam",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setSecondVisitFirstTeamLoadingSkeleton(false)
            setSecondVisitFirstTeamList(prev => [...prev, ...secondVisitFirstTeamResponse.data.secondVisitFirstTeam.data]);
            setSecondVisitFirstTeamWasLastList(secondVisitFirstTeamResponse.data.secondVisitFirstTeam.data.length !== 0 ? false : true)
        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }
    useEffect(() => {
        fetchSecondVisitFirstTeamApData(0);
    }, [])


    //second visit second team
    const fetchSecondVisitSecondTeamApData = async (pageNumber) => {
        setSecondVisitSecondTeamLoadingSkeleton(true)
        try {
            const secondVisitSecondTeamResponse = await getFromAgel(`dashboard/retailers/activationProcess/v2/`, {
                params: {
                    status: "secondVisitSecondTeam",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setSecondVisitSecondTeamLoadingSkeleton(false)
            setSecondVisitSecondTeamList(prev => [...prev, ...secondVisitSecondTeamResponse.data.secondVisitSecondTeam.data]);
            setSecondVisitSecondTeamWasLastList(secondVisitSecondTeamResponse.data.secondVisitSecondTeam.data.length !== 0 ? false : true)
        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }
    useEffect(() => {
        fetchSecondVisitSecondTeamApData(0);
    }, [])


    //final
    const fetchFinalApData = async (pageNumber) => {
        setFinalLoadingSkeleton(true)
        try {
            const finalResponse = await getFromAgel(`dashboard/retailers/activationProcess/v2/`, {
                params: {
                    status: "final",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setFinalLoadingSkeleton(false)
            setFinalList(prev => [...prev, ...finalResponse.data.final.data]);
            setFinalWasLastList(finalResponse.data.final.data.length !== 0 ? false : true)
        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }

    useEffect(() => {
        fetchFinalApData(0);
    }, [])




    const fetchCitiesAndIndtries = async () => {
        try {
            //get cities //view cities permission
            if (viewCitiesPermission) {
                const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
                setCities(cityResponse.data.cities)
            }

            //industries //view industries permission
            if (viewIndustriesPermission) {
                const industryResponse = await getFromAgel(`${process.env.React_APP_GET_INDUSTRIES}`);
                setIndustries(industryResponse.data.industries)
            }
        }
        catch (err) {
            // console.log('error=', err)
        }
    }

    //component did mout ///// [] y3ne hy render only first time lma el component yd5ol el dom wyt3mlo render awl mara
    useEffect(() => {
        fetchCitiesAndIndtries()
    }, [])



    //set counters to zero to start new filter search
    const setCountersToZero = () => {
        setPendingCounter(0)
        setFirstVisitCounter(0)
        setFirstTeamCounter(0)
        setSecondTeamCounter(0)
        setContractPhaseCounter(0)
        setSecondVisitCounter(0)
        setSecondVisitFirstTeamCounter(0)
        setSecondVisitSecondTeamCounter(0)
        setFinalCounter(0)
    }

    //fetch all retailer ap tables request at one time
    const fetchAllApDataTables = () => {
        //  Promise.all([
        fetchPendingApData(0)
        fetchfirstVisitApData(0)
        fetchFirstTeamApData(0)
        fetchSecondTeamApData(0)
        fetchContractPhaseApData(0)
        fetchSecondVisitApData(0)
        fetchSecondVisitFirstTeamApData(0)
        fetchSecondVisitSecondTeamApData(0)
        fetchFinalApData(0)
        // ]);
    }

    const resetApListsAndFetchAllLists = () => {
        //set empty lists for reseting new data after filteration
        setPendingList([])
        setFirstVisitList([])
        setFirstTeamList([])
        setSecondTeamList([])
        setContractPhaseList([])
        setSecondVisitList([])
        setSecondVisitFirstTeamList([])
        setSecondVisitSecondTeamList([])
        setFinalList([])

        //make request to get new data after filterarion
        fetchAllApDataTables()
    }



    if (viewRetailerApPermission) {
        return (
            <div>
                <Row className="layout-top">
                    <Col xs="3 text-start">
                        {/* input */}
                        <div className='input-wrapper'>
                            <input type="text" placeholder="Name/phone" value={filterByNameOrPhoneNumber} onChange={(e) => { setFilterByNameOrPhoneNumber(e.target.value); setCountersToZero(); }} />
                        </div>
                    </Col>
                    <Col xs="4 text-start">
                        {/* search */}
                        <button className='role-search-btn' onClick={() => resetApListsAndFetchAllLists()}>Search</button>
                    </Col>
                </Row>

                <Row className="layout-row-2">

                    <Col xs={2} style={{ zIndex: "999" }}>

                        <Select
                            className="search-select mt-2"
                            defaultValue={{ "label": "City", "value": 0 }}
                            onChange={(e) => { setSelectedCity(e.value); setCountersToZero(); }}
                            options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                        />
                    </Col>
                    <Col xs={2} style={{ zIndex: "999" }}>
                        <Select
                            className="search-select mt-2"
                            defaultValue={{ "label": "Industry", "value": 0 }}
                            onChange={(e) => { setSelectedIndustry(e.value); setCountersToZero(); }}
                            options={industries.map((industry) => ({ "value": industry.id, "label": industry.name }))}
                        />
                    </Col>

                </Row>



                {/* retailer activation process table  */}
                <RetailerActivationTable
                    setRegisterModal={setRegisterModal}
                    setCurrent={setCurrent}
                    setFirstVisitModal={setFirstVisitModal}
                    setFirstTeamModal={setFirstTeamModal}
                    setSecondTeamModal={setSecondTeamModal}
                    setContractPreparationModal={setContractPreparationModal}
                    setSecondVisitModal={setSecondVisitModal}
                    setTeamTwoSecondVisitModal={setTeamTwoSecondVisitModal}
                    setTeamThreeSecondVisitModal={setTeamThreeSecondVisitModal}
                    setFinalModal={setFinalModal}

                    pendingLoadingSkeleton={pendingLoadingSkeleton}
                    firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                    firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                    secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                    contractPhaseLoadingSkeleton={contractPhaseLoadingSkeleton}
                    secondVisitLoadingSkeleton={secondVisitLoadingSkeleton}
                    secondVisitFirstTeamLoadingSkeleton={secondVisitFirstTeamLoadingSkeleton}
                    secondVisitSecondTeamLoadingSkeleton={secondVisitSecondTeamLoadingSkeleton}
                    finalLoadingSkeleton={finalLoadingSkeleton}

                    pendingList={pendingList}
                    fetchPendingApData={fetchPendingApData}
                    firstVisitList={firstVisitList}
                    fetchfirstVisitApData={fetchfirstVisitApData}
                    firstTeamList={firstTeamList}
                    fetchFirstTeamApData={fetchFirstTeamApData}
                    secondTeamList={secondTeamList}
                    fetchSecondTeamApData={fetchSecondTeamApData}
                    contractPhaseList={contractPhaseList}
                    fetchContractPhaseApData={fetchContractPhaseApData}
                    secondVisitList={secondVisitList}
                    fetchSecondVisitApData={fetchSecondVisitApData}
                    secondVisitFirstTeamList={secondVisitFirstTeamList}
                    fetchSecondVisitFirstTeamApData={fetchSecondVisitFirstTeamApData}
                    secondVisitSecondTeamTeamList={secondVisitSecondTeamTeamList}
                    fetchSecondVisitSecondTeamApData={fetchSecondVisitSecondTeamApData}
                    finalList={finalList}
                    fetchFinalApData={fetchFinalApData}

                    pendingCounter={pendingCounter}
                    firstVisitCounter={firstVisitCounter}
                    firstTeamCounter={firstTeamCounter}
                    secondTeamCounter={secondTeamCounter}
                    contractPhaseCounter={contractPhaseCounter}
                    secondVisitCounter={secondVisitCounter}
                    secondVisitFirstTeamCounter={secondVisitFirstTeamCounter}
                    secondVisitSecondTeamCounter={secondVisitSecondTeamCounter}
                    finalCounter={finalCounter}

                    setPendingCounter={setPendingCounter}
                    setFirstVisitCounter={setFirstVisitCounter}
                    setFirstTeamCounter={setFirstTeamCounter}
                    setSecondTeamCounter={setSecondTeamCounter}
                    setContractPhaseCounter={setContractPhaseCounter}
                    setSecondVisitCounter={setSecondVisitCounter}
                    setSecondVisitFirstTeamCounter={setSecondVisitFirstTeamCounter}
                    setSecondVisitSecondTeamCounter={setSecondVisitSecondTeamCounter}
                    setFinalCounter={setFinalCounter}

                    pendingWasLastList={pendingWasLastList}
                    firstVisitWasLastList={firstVisitWasLastList}
                    firstTeamWasLastList={firstTeamWasLastList}
                    secondTeamWasLastList={secondTeamWasLastList}
                    contractPhaseWasLastList={contractPhaseWasLastList}
                    secondVisitWasLastList={secondVisitWasLastList}
                    secondVisitFirstTeamWasLastList={secondVisitFirstTeamWasLastList}
                    secondVisitSecondTeamWasLastList={secondVisitSecondTeamWasLastList}
                    finalWasLastList={finalWasLastList}

                    selectedCity={selectedCity}
                    selectedIndustry={selectedIndustry}
                    filterByNameOrPhoneNumber={filterByNameOrPhoneNumber}
                />

                {/* Register model */}
                {
                    registerModal ?
                        <Register
                            setRegisterModal={setRegisterModal}
                            setRegisterRescheduleFlag={setRegisterRescheduleFlag}
                            setFirstVisitRescheduleFlag={setFirstVisitRescheduleFlag}
                            setContractRescheduleFlag={setContractRescheduleFlag}
                            setSecondVisitRescheduleFlag={setSecondVisitRescheduleFlag}
                            current={current}
                            setCurrent={setCurrent}
                            setReschduleModal={setReschduleModal}
                            setRescheduleCurrent={setRescheduleCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }

                {/* FirstVisit model */}
                {
                    firstVisitModal ?
                        <FirstVisit
                            setFirstVisitModal={setFirstVisitModal}
                            setReschduleModal={setReschduleModal}
                            setRescheduleCurrent={setRescheduleCurrent}
                            setRegisterRescheduleFlag={setRegisterRescheduleFlag}
                            setFirstVisitRescheduleFlag={setFirstVisitRescheduleFlag}
                            setContractRescheduleFlag={setContractRescheduleFlag}
                            setSecondVisitRescheduleFlag={setSecondVisitRescheduleFlag}
                            current={current}
                            setCurrent={setCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }

                {/* ReschduleModal model */}
                {
                    reschduleModal ?
                        <ReschduleModal
                            current={current}
                            rescheduleCurrent={rescheduleCurrent}
                            registerRescheduleFlag={registerRescheduleFlag}
                            firstVisitRescheduleFlag={firstVisitRescheduleFlag}
                            secondVisitRescheduleFlag={secondVisitRescheduleFlag}
                            contractRescheduleFlag={contractRescheduleFlag}
                            setSecondVisitRescheduleFlag={setSecondVisitRescheduleFlag}
                            setReschduleModal={setReschduleModal}
                            setRescheduleCurrent={setRescheduleCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}

                        />
                        : null
                }

                {/* firstTeam model */}
                {
                    firstTeamModal ?
                        <FirstTeam
                            setFirstTeamModal={setFirstTeamModal}
                            current={current}
                            setCurrent={setCurrent}
                            setRevertModal={setRevertModal}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }

                {/* revert Modal model */}
                {
                    revertModal ?
                        <RevertModal
                            current={current}
                            setRevertModal={setRevertModal}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}

                        />
                        : null
                }

                {/* second team model */}
                {
                    secondTeamModal ?
                        <SecondTeam
                            setSecondTeamModal={setSecondTeamModal}
                            current={current}
                            setCurrent={setCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }

                {/* contract preparation model */}
                {
                    contractPreparationModal ?
                        <ContractPreparartion
                            setContractPreparationModal={setContractPreparationModal}
                            setReschduleModal={setReschduleModal}
                            setRescheduleCurrent={setRescheduleCurrent}
                            setRegisterRescheduleFlag={setRegisterRescheduleFlag}
                            setFirstVisitRescheduleFlag={setFirstVisitRescheduleFlag}
                            setContractRescheduleFlag={setContractRescheduleFlag}
                            setSecondVisitRescheduleFlag={setSecondVisitRescheduleFlag}
                            current={current}
                            setCurrent={setCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }

                {/* second visit model */}
                {
                    secondVisitModal ?
                        <SecondVisit
                            setSecondVisitModal={setSecondVisitModal}
                            setReschduleModal={setReschduleModal}
                            setRescheduleCurrent={setRescheduleCurrent}
                            setRegisterRescheduleFlag={setRegisterRescheduleFlag}
                            setFirstVisitRescheduleFlag={setFirstVisitRescheduleFlag}
                            setContractRescheduleFlag={setContractRescheduleFlag}
                            setSecondVisitRescheduleFlag={setSecondVisitRescheduleFlag}
                            current={current}
                            setCurrent={setCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }

                {/* second team second visit model */}
                {
                    teamTwoSecondVisitModal ?
                        <TeamTwoSecondVisit
                            setTeamTwoSecondVisitModal={setTeamTwoSecondVisitModal}
                            // setReschduleModal={setReschduleModal}
                            // setRescheduleCurrent={setRescheduleCurrent}
                            // setRegisterRescheduleFlag={setRegisterRescheduleFlag}
                            // setFirstVisitRescheduleFlag={setFirstVisitRescheduleFlag}
                            // setContractRescheduleFlag={setContractRescheduleFlag}
                            // setSecondVisitRescheduleFlag={setSecondVisitRescheduleFlag}
                            current={current}
                            setCurrent={setCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }

                {/* team three second visit model */}
                {
                    teamThreeSecondVisitModal ?
                        <TeamThreeSecondVisit
                            setTeamThreeSecondVisitModal={setTeamThreeSecondVisitModal}
                            // setReschduleModal={setReschduleModal}
                            // setRescheduleCurrent={setRescheduleCurrent}
                            // setRegisterRescheduleFlag={setRegisterRescheduleFlag}
                            // setFirstVisitRescheduleFlag={setFirstVisitRescheduleFlag}
                            // setContractRescheduleFlag={setContractRescheduleFlag}
                            // setSecondVisitRescheduleFlag={setSecondVisitRescheduleFlag}
                            current={current}
                            setCurrent={setCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }

                {/* final model */}
                {
                    finalModal ?
                        <Final
                            setFinalModal={setFinalModal}
                            setHold={setHold}
                            // setReschduleModal={setReschduleModal}
                            // setRescheduleCurrent={setRescheduleCurrent}
                            // setRegisterRescheduleFlag={setRegisterRescheduleFlag}
                            // setFirstVisitRescheduleFlag={setFirstVisitRescheduleFlag}
                            // setContractRescheduleFlag={setContractRescheduleFlag}
                            // setSecondVisitRescheduleFlag={setSecondVisitRescheduleFlag}
                            current={current}
                            setCurrent={setCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }

                {cancelConfirmModal ?
                    <CancelConfirmScreen
                        setCancelConfirmModal={setCancelConfirmModal}
                        setSuccessModal={setSuccessModal}
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        current={current}
                        updateData={resetApListsAndFetchAllLists}
                        hold={hold}
                    />
                    : null}

                {/* cancelation comment in AF */}
                {showAFComment ?
                    <AFComment
                        setShowAFComment={setShowAFComment}
                        AFCancelComment={AFCancelComment}
                    />
                    : null
                }


                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={resetApListsAndFetchAllLists}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }

            </div>
        )
    }

    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
import React, { useState, useEffect, useContext } from 'react'
import { Button, Form, Table, Modal } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Switch } from 'evergreen-ui'

//components
import AuthorizationRequiredScreen from '../../screens/AuthorizationRequiredScreen/AuthorizationRequiredScreen';
import SuccessModal from '../common/SuccessModal';
import Failed_Modal from '../common/FailedModal';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

//api
import { postToAgel, getFromAgel, putToAgel } from '../../utils/api'

import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';


export default function Pos(props) {

    //permission
    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewWsProfilePosScreenPermission = userValidPermissionAndAction(validPermissionsAndActions, "wholesalerStores", "viewWStorePos")

    const [showCreatePos, setShowCreatePos] = useState(false);
    const [posBody, setPosBody] = useState({});
    const [poses, setPoses] = useState([]);

    const [showChangePosPassword, setShowChangePosPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");

    const [currentPosId, setCurrentPosId] = useState();

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")

    const { id: storeId } = useParams()

    //state checker on buttons 
    const [changePosPasswordBtn, setChangePosPasswordBtn] = useState(false)
    const [createPosBtn, setCreatePosBtn] = useState(false)
    const [togglePosSwitchBtn, setTogglePosSwitchBtn] = useState(false)




    const fetchData = async () => {
        try {
            /* profile store pos screen table */
            const response = await getFromAgel(`${process.env.React_APP_GET_WS_STORE_POS}${storeId}`)
            setPoses(response.data.pos[0] ? response.data.pos[0]["POs"] : [])
        }
        catch (error) {
            // console.log("error=", error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
    const sortedPos = poses && poses.sort((a, b) => (a.id) - (b.id));


    //toggle status of pos
    const handleToggleStatus = async (id) => {
        setTogglePosSwitchBtn(true)

        try {
            /* profile store pos status toggle */
            await putToAgel(`${process.env.React_APP_TOGGLE_STATUS_POS_WS_PROFILE}${id}`)
            fetchData()
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setTogglePosSwitchBtn(false)
    }

    //change pos password
    const handleChangePosPassword = async (e) => {

        e.preventDefault()
        setChangePosPasswordBtn(true)

        try {
            /* profile store pos change password */
            await putToAgel(`${process.env.React_APP_CHANGE_PASSWORD_POS_WS_PROFILE}${currentPosId}`, { newPassword })
            fetchData()
            setSuccessModal(true)
        }
        catch (e) {
            setErrorMsgInFailedModal(e?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setChangePosPasswordBtn(false)
        setShowChangePosPassword(false)
        setNewPassword("") //clean password state
    }

    //create new pos
    const handleCreatePos = async (event) => {

        event.preventDefault();
        setCreatePosBtn(true)

        try {
            /* profile store pos create new pos */
            const response = await postToAgel(`${process.env.React_APP_CREATE_POS}`, { ...posBody, storeId })
            fetchData()
            setSuccessModal(true)
        }
        catch (error) {
            setErrorMsgInFailedModal(error?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setCreatePosBtn(false)
        setShowCreatePos(false)
        setPosBody({}) //clean object
    }



    if (viewWsProfilePosScreenPermission) {

        return (
            <div className="profile-page-layout">
                <div className="d-flex justify-content-center m-5">
                    <img src="/images/posSymbol.png" width="200px" height="150px" />
                </div>
                <Button variant="primary" className="w-100 justify-content-between mt-5 mb-5" onClick={() => { setShowCreatePos(true) }} >Create Pos</Button>

                <Table striped bordered size="sm" className="table-fixed">
                    <thead className="thead-profile">
                        <tr className='role-table-header text-center'>
                            <th>Id</th>
                            <th>Phone Number</th>
                            <th>Created at</th>
                            <th>Status</th>
                            <th>Edit</th>
                        </tr>
                    </thead>

                    <tbody>
                        {sortedPos?.map(pos =>
                            (
                                <tr key={pos.id} className='role-table-header text-center'>
                                    <td>{pos.id}</td>
                                    <td>{pos.phoneNumber}</td>
                                    <td>{new Date(pos.createdAt).toLocaleDateString()}</td>

                                    <td className={pos.status === "Active" ? "profile-active" : "profile-pending"}>{pos.status}</td>
                                    <td><Button onClick={() => { setCurrentPosId(pos.id); setShowChangePosPassword(true) }}>change password</Button></td>
                                    <td>
                                        <span className='d-flex justify-content-center align-items-center'>
                                            <Switch checked={pos.status === "Active" ? true : false} onChange={() => handleToggleStatus(pos.id)} disabled={togglePosSwitchBtn}/>
                                        </span>
                                    </td>
                                </tr>
                            )
                            )
                        }
                    </tbody>
                </Table>


                {/* create new pos */}
                {
                    showCreatePos ?
                        <Modal show={showCreatePos} onHide={() => setShowCreatePos(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>
                            <Modal.Header closeButton>
                                <span className="create-pos-modal-title">Create Pos</span>
                            </Modal.Header>

                            <Form onSubmit={handleCreatePos} >
                                <Modal.Body>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="profile-label-text">phone number</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={posBody.phoneNumber} onChange={(e) => { setPosBody({ ...posBody, phoneNumber: e.target.value }) }} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="profile-label-text">password</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={posBody.password} onChange={(e) => { setPosBody({ ...posBody, password: e.target.value }) }} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="profile-label-text">IMEI</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={posBody.IMEI} onChange={(e) => { setPosBody({ ...posBody, IMEI: e.target.value }) }} required />
                                    </Form.Group>
                                </Modal.Body>

                                <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                    <button className="role-add-btn-with-loader-spinner" type="submit" disabled={createPosBtn ? true : false}>
                                        {createPosBtn ?
                                            (<LoadingBtnSpinner />)
                                            : (<span>create</span>)}
                                    </button>

                                    <button className="roleCloseBtn" onClick={() => setShowCreatePos(false)}>Close</button>
                                </Modal.Footer>
                            </Form>

                        </Modal> : null
                }

                {/* change pos password */}
                {
                    showChangePosPassword ?
                        <Modal show={showChangePosPassword} onHide={() => setShowChangePosPassword(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>

                            <Modal.Header closeButton>
                                <span className="change-pos-password-modal-title">change pos Password</span>
                            </Modal.Header>

                            <Form onSubmit={handleChangePosPassword} >
                                <Modal.Body>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="profile-label-text">new password</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                                    </Form.Group>
                                </Modal.Body>

                                <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                    <button className="role-add-btn-with-loader-spinner" type="submit" disabled={changePosPasswordBtn ? true : false}>
                                        {changePosPasswordBtn ?
                                            (<LoadingBtnSpinner />)
                                            : (<span>Done</span>)}
                                    </button>

                                    <button className="roleCloseBtn" onClick={() => setShowChangePosPassword(false)}>Close</button>
                                </Modal.Footer>
                            </Form>

                        </Modal> : null
                }

                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={fetchData}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }

            </div>
        )
    }

    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
import React, { useState, useEffect } from 'react';
import {
  Modal, Form, Container, Row, Col, Spinner,
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import fileDownload from 'js-file-download';

// icons
import { BiMessageDetail } from 'react-icons/bi';
import { AiOutlineFileImage } from "react-icons/ai";
import { CgSoftwareDownload, CgProfile } from 'react-icons/cg';
import { getFromAgel, getFileFromAgel, putToAgel } from '../../utils/api';

// import component
import ApproveButton from './ApproveButton';
import APActionButtons from '../ActivationProcessCommonModals/APActionButtons';

const Register = (props) => {
  const navigate = useNavigate();

  // props
  const {
    setReschduleModal,
    setRescheduleCurrent,
    setRegisterRescheduleFlag,
    setFirstVisitRescheduleFlag,
    setContractRescheduleFlag,
    setSecondVisitRescheduleFlag,
    setRegisterModal,
    current,
    setSuccessModal,
    setFailedModalWithErrorMsg,
    setErrorMsgInFailedModal,
    updateData,
    setCancelConfirmModal,
    setShowAFComment,
    setAFCancelComment,
  } = props;

  const [teamLeadData, setTeamLeadData] = useState([]);
  // const [logs, setLogs] = useState([1]);
  // const [contractDownload, setContractDownload] = useState("")
  const [downloading, setDownloading] = useState(false);

  // state checker on corrupted images
  const [frontNationIdCorruptedImg, setFrontNationIdCorruptedImg] = useState(false);
  const [backNationIdCorruptedImg, setBackNationIdCorruptedImg] = useState(false);
  const [commercialRegistrationCorruptedImg1, setCommercialRegistrationCorruptedImg1] = useState(false);
  const [commercialRegistrationCorruptedImg2, setCommercialRegistrationCorruptedImg2] = useState(false);
  const [commercialRegistrationCorruptedImg3, setCommercialRegistrationCorruptedImg3] = useState(false);

  // state checker on buttons
  const [saveEditsBtn, setSaveEditsBtn] = useState(false);

  // fetch teamLead form register Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        /* get ws af  */
        const teamLeadDataResponse = await getFromAgel(`${process.env.React_APP_GET_WS_AP}${current.id}`);
        setTeamLeadData(teamLeadDataResponse.data.wholesalerActivationProcess[0]);
        // console.log("register af data=", teamLeadDataResponse.data.wholesalerActivationProcess[0])
      } catch (e) {
        // console.log("error=", e)
        // console.log("id=", current.id)
      }
    };
    fetchData();
  }, []);

  const [name, setName] = useState();
  const [commercial_registration_id, setCommercialRegistrationId] = useState();
  const [commercial_registration_office, setCommercialRegistrationOffice] = useState();
  const [nationalId, setNationalId] = useState();
  const [storeName, setStoreName] = useState();
  const [owner_name, setOwnerName] = useState();
  const [address, setAddress] = useState();
  const [industry, setIndustry] = useState();
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [storeAddressByCust, setStoreAddressByCust] = useState("");

  const saveEdits = async (e) => {
    e.preventDefault();
    setSaveEditsBtn(true);

    const data = {
      finalForm: {
        ...(name ? { name } : {}),
        ...(commercial_registration_id ? { commercial_registration_id } : {}),
        ...(commercial_registration_office ? { commercial_registration_office } : {}),
        ...(nationalId ? { nationalId } : {}),
        ...(storeName ? { storeName } : {}),
        ...(owner_name ? { owner_name } : {}),
        ...(address ? { area: address } : {}),
        ...(industry ? { industry } : {}),
        ...(lat ? { lat } : {}),
        ...(long ? { long } : {}),
        ...(storeAddressByCust ? { storeAddressByCust } : {}),
      },
    };
    try {
      /* ws save af edits */
      await putToAgel(`${process.env.React_APP_WS_AF_SAVE_EDITS}${current.id}/`, data);
      updateData();
      setSuccessModal(true);
    } catch (err) {
      setErrorMsgInFailedModal(err?.response?.data?.message);
      setFailedModalWithErrorMsg(true);
    }
    setRegisterModal(false);
  };

  const downloadPdf = async () => {
    /* get ws contract before signature */
    try {
      const contractResponse = await getFileFromAgel(`${process.env.React_APP_WS_GET_CONTRACT}${current.id}`, { responseType: 'blob' });
      // console.log("contractResponse=", contractResponse)
      fileDownload(contractResponse.data, 'contract.pdf');
      // setSuccessModal(true)
    } catch (err) {
      setErrorMsgInFailedModal(err?.response?.data?.message);
      setFailedModalWithErrorMsg(true);
    }
    setDownloading(false);
  };

  const currentAFCancelComment = teamLeadData.WComments;

  return (
    <div>
      <Modal show onHide={() => setRegisterModal(false)} centered size="lg" className="AP-modal" style={{ marginTop: "5em" }}>
        <Modal.Header closeButton />

        <Modal.Body style={{ marginBottom: "5em" }}>

          <Container style={{ marginTop: "-1.5rem" }}>
            <Row>
              <Col xs={12} className=" d-flex align-items-center">
                <p className="modal-header-title">{teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.name}</p>
                {teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.name
                  ? <CgProfile className="link-to-profile-icon-through-af" onClick={() => navigate(`/wholesalers/list/${teamLeadData && teamLeadData.Wholesaler && teamLeadData.Wholesaler.id}/details`)} />
                  : null}
              </Col>
              <Col xs={12} className="d-flex text-end justify-content-between align-items-start mt-2">
                <p className="modal-title">
                  Agent name
                  <span className="text-muted" style={{ marginLeft: "0.3rem" }}>No agents</span>
                </p>
                <p className="modal-title">
                  request date
                  <span className="text-muted" style={{ marginLeft: "0.3rem" }}>{(new Date(teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.createdAt).toLocaleDateString())}</span>
                </p>
                <p className="modal-title">
                  visit date
                  <span className="text-muted" style={{ marginLeft: "0.3rem" }}>{(new Date(teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.visitDate).toLocaleDateString())}</span>
                </p>
              </Col>
            </Row>

            {/* approve button */}
            <Row>
              <Col xs="2" className="d-flex mt-2" style={{ width: "100%", paddingLeft: 0 }}>
                <ApproveButton
                  model={setRegisterModal}
                  updateData={updateData}
                  setSuccessModal={setSuccessModal}
                  setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                  setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                  current={current}
                />
              </Col>
            </Row>

          </Container>

          <Container className="mt-3" dir="rtl">
            <Form>
              {/* row1 */}
              <Row>
                <Col xs="4">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">الأسم ثلاثى</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.name} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input  mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value={name} onChange={(e) => setName(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              {/* row2 */}
              <Row>
                <Col xs="4">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">رقم السجل التجاري</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_id} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input  mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input  mt-2" value={commercial_registration_id} onChange={(e) => setCommercialRegistrationId(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>

              {/* row3 */}
              <Row>
                <Col xs="4">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">مكتب السجل</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_office} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input  mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input  mt-2" value={commercial_registration_office} onChange={(e) => setCommercialRegistrationOffice(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              {/* row4 */}
              <Row>
                <Col xs="4">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">رقم البطاقة</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdOrPassportNumber} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input  mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input  mt-2" value={nationalId} onChange={(e) => setNationalId(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              {/* row5 */}
              <Row>
                <Col xs="4">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">اسم المنشأة</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore.name && teamLeadData.Wstore.name} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input  mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input  mt-2" value={storeName} onChange={(e) => setStoreName(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              {/* row6 */}
              <Row>
                <Col xs="4">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">رقم هاتف </Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.phoneNumber} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value="" readOnly disabled />
                  </Form.Group>
                </Col>
              </Row>
              {/* row7 */}
              <Row>
                <Col xs="4">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">رقم هاتف اخر </Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.secondNumber} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.secondNumber} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value="" readOnly disabled />
                  </Form.Group>
                </Col>
              </Row>

              {/* row8 */}
              <Row>
                <Col xs="4">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">مدير الفرع</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.owner_name} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value={owner_name} onChange={(e) => setOwnerName(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              {/* row9 */}
              <Row>
                <Col xs="4">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text"> اسم الفرع</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore.name && teamLeadData.Wstore.name} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value={storeName} onChange={(e) => setStoreName(e.target.value)} readOnly disabled />
                  </Form.Group>
                </Col>
              </Row>
              {/* row10 */}
              <Row>
                <Col xs="4" className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">عنوان المنشأة</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.area} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value={address} onChange={(e) => setAddress(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>

              {/* row11 */}
              <Row>
                <Col xs="4" className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">نشاط المنشأة</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.industry} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value={industry} onChange={(e) => setIndustry(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              {/* row12 */}
              <Row>
                <Col xs="4" className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">Lat Store</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.lat} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value={lat} onChange={(e) => setLat(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              {/* row13 */}
              <Row>
                <Col xs="4" className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">Long Store</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.long} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                    <Form.Control type="text" className="role-input mt-2" value={long} onChange={(e) => setLong(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              {/* row14 */}
              <Row>
                <Col xs="4" className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label className="AP-modal-text">برجاء كتابة عنوان المنشأة تفصيلياً مع ذكر أقرب علامة مميزة</Form.Label>
                    <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.storeAddressByCust} disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "3em" }}>
                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3" style={{ marginTop: "3em" }}>
                    <Form.Control type="text" className="role-input mt-2" value={storeAddressByCust} onChange={(e) => setStoreAddressByCust(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>

              {/* row15 */}
              {(!commercialRegistrationCorruptedImg1 && (teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_image))
                ? (
                  <Row>
                    <Col xs="4">
                      <Form.Group>
                        <Form.Label className="AP-modal-text">السجل التجاري ١</Form.Label>
                        <br />
                        <a href={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_image}`} target="_blank">
                          <img onError={() => setCommercialRegistrationCorruptedImg1(true)} src={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_image}`} className="img-fluid Ap-register-image" />
                        </a>
                      </Form.Group>
                    </Col>
                    {/* <Col xs="4" className="mt-auto mb-auto">
                      <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control as="textarea" className="Ap-text-area" rows={2} placeholder="" readOnly />
                      </Form.Group>
                    </Col>
                    <Col xs="4" className="mt-auto mb-auto">
                      <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control as="textarea" className="Ap-text-area" rows={2} placeholder="" readOnly />
                      </Form.Group>
                    </Col> */}
                  </Row>
                )
                : null}

              {/* row16 */}
              {(!commercialRegistrationCorruptedImg2 && (teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one))
                ? (
                  <Row>
                    <Col xs="4">
                      <Form.Group>
                        <Form.Label className="AP-modal-text">السجل التجاري ٢</Form.Label>
                        <br />
                        <a href={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one}`} target="_blank">
                          <img onError={() => setCommercialRegistrationCorruptedImg2(true)} src={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one}`} className="img-fluid Ap-register-image" />
                        </a>
                      </Form.Group>
                    </Col>
                    {/* <Col xs="4" className="mt-auto mb-auto">
                      <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control as="textarea" className="Ap-text-area" rows={2} placeholder="" readOnly />
                      </Form.Group>
                    </Col>
                    <Col xs="4" className="mt-auto mb-auto">
                      <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control as="textarea" className="Ap-text-area" rows={2} placeholder="" readOnly />
                      </Form.Group>
                    </Col> */}
                  </Row>
                )
                : null}

              {/* row17 */}
              {(!commercialRegistrationCorruptedImg3 && (teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two))
                ? (
                  <Row>
                    <Col xs="4">
                      <Form.Group>
                        <Form.Label className="AP-modal-text">السجل التجاري ٣</Form.Label>
                        <br />
                        <a href={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two}`} target="_blank">
                          <img onError={() => setCommercialRegistrationCorruptedImg3(true)} src={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two}`} className="img-fluid Ap-register-image" />
                        </a>
                      </Form.Group>
                    </Col>
                    {/* <Col xs="4" className="mt-auto mb-auto">
                      <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control as="textarea" className="Ap-text-area" rows={2} placeholder="" readOnly />
                      </Form.Group>
                    </Col>
                    <Col xs="4" className="mt-auto mb-auto">
                      <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control as="textarea" className="Ap-text-area" rows={2} placeholder="" readOnly />
                      </Form.Group>
                    </Col> */}
                  </Row>
                )
                : null}

              {/* row18 */}
              {(!frontNationIdCorruptedImg && (teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdOrPassportImage))
                ? (
                  <Row>
                    <Col xs="4">
                      <Form.Group>
                        <Form.Label className="AP-modal-text">الصورة الأمامية للبطاقة</Form.Label>
                        <br />
                        <a href={`${teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdOrPassportImage}`} target="_blank">
                          <img onError={() => setFrontNationIdCorruptedImg(true)} src={`${teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdOrPassportImage}`} className="img-fluid Ap-register-image" />
                        </a>
                      </Form.Group>
                    </Col>
                    {/* <Col xs="4" className="mt-auto mb-auto">
                      <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control as="textarea" className="Ap-text-area" rows={2} placeholder="" readOnly />
                      </Form.Group>
                    </Col>
                    <Col xs="4" className="mt-auto mb-auto">
                      <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control as="textarea" className="Ap-text-area" rows={2} placeholder="" readOnly />
                      </Form.Group>
                    </Col> */}
                  </Row>
                )
                : null}

              {/* row19 */}
              {(!backNationIdCorruptedImg && (teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdBackImage))
                ? (
                  <Row>
                    <Col xs="4">
                      <Form.Group>
                        <Form.Label className="AP-modal-text">الصورة الخلفية للبطاقة</Form.Label>
                        <br />
                        <a href={`${teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdBackImage}`} target="_blank">
                          <img onError={() => setBackNationIdCorruptedImg(true)} src={`${teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdBackImage}`} className="img-fluid Ap-register-image" />
                        </a>
                      </Form.Group>
                    </Col>
                    {/* <Col xs="4" className="mt-auto mb-auto">
                      <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control as="textarea" className="Ap-text-area" rows={2} placeholder="" readOnly />
                      </Form.Group>
                    </Col>
                    <Col xs="4" className="mt-auto mb-auto">
                      <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control as="textarea" className="Ap-text-area" rows={2} placeholder="" readOnly />
                      </Form.Group>
                    </Col> */}
                  </Row>
                )
                : null}

              {/* contract */}
              <Container className="d-flex mt-5">
                <AiOutlineFileImage className="AF-reinstate-comment-icon" />
                <p className="contract-text mt-1">Contract</p>
                {
                  downloading
                    ? <Spinner animation="border" className="loader" />
                    : <CgSoftwareDownload className="AF-reinstate-comment-icon cursor-pointer" onClick={async (e) => { setDownloading(true); await downloadPdf(); }} />
                }
              </Container>

              {/* reinstate comment */}
              {currentAFCancelComment && currentAFCancelComment.length !== 0
                ? (
                  <Container className="mt-3">
                    <BiMessageDetail
                      className="AF-reinstate-comment-icon cursor-pointer"
                      onClick={() => {
                        setRegisterModal(false);
                        setShowAFComment(true);
                        setAFCancelComment(teamLeadData && teamLeadData.WComments);
                      }}
                    />
                    <span className="AF-reinstate-comment-text">
                      reinstate comment
                    </span>
                  </Container>
                )
                : null}

              {/* action btns */}
              <Row>
                <Col xs="6" className="d-flex justify-content-start align-items-center mt-5 ">
                  <APActionButtons
                    permissionName="wholesalerActivationProcess"
                    actionName="cancel wholesaler activation process"
                    btnClassName="cancel-visit"
                    btnType="submit"
                    btnName="Cancel"
                    isLoaderFlagTrue={false}
                    btnActions={(e) => {
                      e.preventDefault();
                      setRegisterModal(false);
                      setCancelConfirmModal(true);
                    }}
                  />

                  <APActionButtons
                    permissionName="wholesalerActivationProcess"
                    actionName="assign agent  wholesaler activation process"
                    btnClassName="action-visit"
                    btnType="submit"
                    btnName="Assign Agent"
                    isLoaderFlagTrue={false}
                    btnActions={(e) => {
                      e.preventDefault();
                      setRegisterRescheduleFlag(true);
                      setFirstVisitRescheduleFlag(false);
                      setSecondVisitRescheduleFlag(false);
                      setContractRescheduleFlag(false);
                      setReschduleModal(true);
                      setRescheduleCurrent(teamLeadData);
                      setRegisterModal(false);
                    }}
                  />
                </Col>

                <Col xs="6" className="d-flex justify-content-end align-items-center mt-5 ">
                  <APActionButtons
                    permissionName=""
                    actionName=""
                    noPermission
                    btnClassName="save-edits"
                    btnType="submit"
                    btnName="Save Edits"
                    isLoaderFlagTrue={saveEditsBtn}
                    btnActions={saveEdits}
                  />
                </Col>
              </Row>

            </Form>
          </Container>
          {/* logs */}
          {/* <Container>
                    <p className="logs-title mt-5">All Logs</p>
                    <div className='Ap-logs' style={{paddingLeft:"1.5em"}}>
                        {logs.map((i)=>
                        <span>
                            <div className='d-flex'>
                                <p className='logs-data'>mohmaed ali</p>
                                <p className='logs-data'>22/10/2022 </p>
                                <p className='logs-data'>3:00pm</p>
                            </div>
                            <div className='info'>
                                created form
                            </div>
                        </span>
                        )}
                    </div>
                </Container> */}

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Register;

import React, { useState, useEffect } from 'react'

import { Modal, Container } from "react-bootstrap"
import { getFromAgel } from '../../utils/api'


const MsgCancellationModal = (props) => {

    const {
        current,
        setMessageCancelReasons
    } = props

    console.log("propsws=", props)

    const [cancelationInfo, setCancelationInfo] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                /* get cancelationInfo by id in retailer rention */
                const cancelationInfoResponse = await getFromAgel(`${process.env.React_APP_GET_CANCEL_REASONS_RETAILER_RETENTION}${current.id}`)
                // console.log('cancelationInfoResponse', cancelationInfoResponse.data.reasons)
                setCancelationInfo(cancelationInfoResponse.data.reasons)
            }
            catch (error) {
                console.log({ error })
            }
        }
        fetchData()
    }, [])


    let sortedCancelationInfo = cancelationInfo && cancelationInfo.sort((a, b) => (b.id) - (a.id));

    return (
        <div>
            <Modal show={true} onHide={() => setMessageCancelReasons(false)} centered size="lg" className="dasboardModal">
                <Modal.Header closeButton className="cancel-msg-modal-header">
                    Cancellation reasons
                </Modal.Header>
                <Modal.Body>
                    {sortedCancelationInfo && sortedCancelationInfo.map((i) =>
                        <Container className="mb-4" key={i.id}>

                            {/* cancelation date */}
                            <div className="cancel-msg-modal-title">
                                <p> Date
                                    <span className="cancelled-by">
                                        {(new Date(i && i.updatedAt && i.updatedAt).toLocaleDateString())}
                                    </span>
                                </p>
                            </div>

                            {/* cancelation info */}
                            <div className="reason-box">
                                <div className='mb-1'>
                                    <span className='retention-comment-title'>
                                        Cancelled by
                                    </span>
                                    <span className='retention-comment-subtitle'>
                                        {i && i.userName && i.userName}
                                    </span>
                                </div>

                                <div className='mb-1'>
                                    <span className='retention-comment-title'>
                                        Comment
                                    </span>
                                    <span className='retention-comment-subtitle'>
                                        {i && i.comment && i.comment}
                                    </span>
                                </div>

                                <div>
                                    <span className='retention-comment-title'>
                                        Reason
                                    </span>
                                    <span className='retention-comment-subtitle'>
                                        {i && i.reason && i.reason}
                                    </span>
                                </div>

                            </div>
                        </Container>
                    )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Container className="text-end">
                        <button className="role-close-btn" onClick={() => setMessageCancelReasons(false)}>Close</button>
                    </Container>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default MsgCancellationModal

import React, { useEffect, useState, useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import { MdAddCircle } from 'react-icons/md'
import './Cities.css'

import { getFromAgel } from '../../utils/api'
import { userPermissionsAndActionsContext } from '../../App'
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions'

//components
import CreateModal from '../../components/cities/CreateModal'
import EditModal from '../../components/cities/EditModal';
import CitiesTable from '../../components/cities/CitiesTable';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen'
import SuccessModal from '../../components/common/SuccessModal'
import Failed_Modal from '../../components/common/FailedModal'


export default function Cities() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity")


    //All cities
    const [cities, setCities] = useState([])

    //modal
    const [editCities, setEditCities] = useState(false);
    const [currentCityModal, setCurrentCityModal] = useState({});
    const [showCreate, setShowCreate] = useState(false);

    //search
    const [citySearch, setCitySearch] = useState("") // search input state
    const [searchResult, setSearchResult] = useState("") //search btn



    //countries coming from api in edit and create form  (this state is required ny most components)
    const [selectedCountries, setSelectedCountries] = useState([])

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")


    const fetchData = async () => {
        try {
            const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
            const countryResponse = await getFromAgel(`${process.env.React_APP_GET_COUNTRIES}`);
            setSelectedCountries(countryResponse.data.countries)
            setCities(cityResponse.data.cities)
        }
        catch (err) {
            // console.log("error", err)
        }
    }

    //fetch all cities
    useEffect(() => {
        fetchData()
    }, [])

    const sortedCities = cities && cities.sort((a, b) => (a.id) - (b.id));



    //search btn fn
    const search = () => {
        setSearchResult(citySearch)
    }
    //update  result
    const searchCountries = searchResult !== "" ? selectedCountries.filter((c) => c.EnName && c.EnName.toLowerCase().includes(searchResult.toLowerCase())) : null
    const searchCountriesId = searchCountries && searchCountries.map((i) => i.id)
    console.log("searchCountries", searchCountries)
    console.log("searchCountriesId", searchCountriesId)

    let citySearchResult = searchResult !== "" ? cities.filter((i) => i.ArName.toLowerCase().includes(searchResult.toLowerCase()) || i.EnName.toLowerCase().includes(searchResult.toLowerCase()) || searchCountriesId.includes(i.CountryId)) : cities


    if (viewCitiesPermission) {
        return (
            <div>
                <Row className="layout-top">
                    <Col xs="3 text-start">
                        {/* input */}
                        <div className='input-wrapper'>
                            <input type="text" placeholder="En/Ar City & Country" value={citySearch} onChange={(e) => setCitySearch(e.target.value)} />
                        </div>
                    </Col>
                    <Col xs="4 text-start">
                        {/* search */}
                        <button className='role-search-btn' onClick={() => search()}>Search</button>
                    </Col>
                    <Col xs="5 text-end">
                        {/* create city btn */}
                        <button className='add-role-btn' onClick={() => { setShowCreate(true); console.log('pressed') }}>
                            <span className='d-flex align-items-center justify-content-center'>
                                <MdAddCircle size={20} className="add-role-btn-icon" />
                                <p>Add City</p>
                            </span>
                        </button>
                        {
                            showCreate ?
                                <CreateModal
                                    showCreate={showCreate}
                                    setShowCreate={setShowCreate}
                                    selectedCountries={selectedCountries}
                                    updateData={fetchData}
                                    setSuccessModal={setSuccessModal}
                                    setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                    setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                />
                                : null
                        }
                    </Col>
                </Row>


                {/* Cities Table  and the search */}
                <CitiesTable
                    cities={citySearchResult}
                    selectedCountries={selectedCountries}
                    setEditCities={setEditCities}
                    setCurrentCityModal={setCurrentCityModal}
                />


                {/* EDit Modal */}
                {
                    // if editCities is true render the edit Modal 
                    editCities ?
                        <EditModal
                            updateData={fetchData}
                            currentCityModal={currentCityModal}
                            setEditCities={setEditCities}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null
                }
                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={fetchData}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }

            </div>
        )
    }

    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
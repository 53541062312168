import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import { getFromAgel } from '../../utils/api'
import './Retention.css'
import Select from 'react-select';

import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';

//components
import RetentionTable from '../../components/RetailerRetention/RetentionTable';
import Reinstate from '../../components/RetailerRetention/Reinstate';
import ReinstateConfirm from '../../components/RetailerRetention/ReinstateConfirmModal';
import CancelConfirmScreen from '../../components/RetailerRetention/CancelConfirmScreen';
import MsgCancellationModal from '../../components/RetailerRetention/MsgCancellationModal';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen';
import SuccessModal from '../../components/common/SuccessModal';
import Failed_Modal from '../../components/common/FailedModal';


export default function RetailerRetention() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewRetailerRetentionPermission = userValidPermissionAndAction(validPermissionsAndActions, "retention", "viewRetailersRetention")
    let viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity")
    let viewIndustriesPermission = userValidPermissionAndAction(validPermissionsAndActions, "industry", "viewIndustry")

    //retention api
    const [rentention, setRentention] = useState([])

    //filters selectors
    const [cities, setCities] = useState([])
    const [industries, setIndustries] = useState([])
    const [reasons, setReasons] = useState(["wrong industry", "wrong zone", "credit", "guaranteer", "religious views"])

    const [textInputSearch, setTextInputSearch] = useState("")
    const [filterOnCity, setFilterOnCity] = useState('');
    const [filterOnIndustry, setFilterOnIndustry] = useState('');
    const [filterOnReason, setFilterOnReason] = useState('');
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const fromDay = new Date(fromDate).toJSON()
    const toDay = new Date(toDate).toJSON()

    //modal
    //action modals
    const [reinstateModal, setReinstateModal] = useState(false)
    const [current, setCurrent] = useState({})
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false)
    const [messageCancelReasons, setMessageCancelReasons] = useState(false)
    const [reinstateConfirmModal, setReinstateConfirmModal] = useState(false)

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")



    const fetchfiltersSelectorsData = async () => {
        try {

            //get cities //view cities permission
            if (viewCitiesPermission) {
                const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
                // // console.log("cityResponse=", cityR esponse.data.cities)
                setCities(cityResponse.data.cities)
            }

            //industries //view industries permission
            if (viewIndustriesPermission) {
                const industryResponse = await getFromAgel(`${process.env.React_APP_GET_INDUSTRIES}`);
                // console.log("industryResponse=", industryResponse.data.industries)
                setIndustries(industryResponse.data.industries)
            }

            // get reasons
            // const reasonsResponse = await getFromAgel(``)
            // console.log('reasonsResponse', reasonsResponse.data.reasons)
            // setReasons(reasonsResponse.data.reasons)
        }
        catch (e) {
            //
        }
    }
    useEffect(() => {
        fetchfiltersSelectorsData()
    }, [])


    async function fetchRetentionTableData() {
        try {
            /* get all retailer Ap  in retention*/
            const retentionResponse = await getFromAgel(`dashboard/retailers/retention`, {
                params: {
                    ...(textInputSearch ? { "filterByPhoneOrName": textInputSearch } : {}),
                    ...(filterOnCity ? { "CityId": filterOnCity } : {}),
                    ...(filterOnIndustry ? { "IndustryId": filterOnIndustry } : {}),
                    ...(filterOnReason ? { "reason": filterOnReason } : {}),
                    ...(fromDay ? { "startDate": fromDay } : {}),
                    ...(toDay ? { "endDate": toDay } : {}),

                }
            })
            setRentention(retentionResponse.data.retailersActivationProcess)
        }
        catch (err) {
            // console.log("error", err)
        }
    }

    //fetch retention
    useEffect(() => {
        fetchRetentionTableData()
    }, [])

    //sorted retention
    const sortedRetention = rentention && rentention.sort((a, b) => (a.id) - (b.id));

    //search btn fn
    const search = () => {
        //bynade 3la nfs el fn ele btgeb el retention table bs byb3tlha el params ele et8ayrt
        fetchRetentionTableData()
    }

    if (viewRetailerRetentionPermission) {

        return (
            <div>

                {/* row1 */}
                <Row className="layout-top">
                    <Col xs="3" className=' text-start'>
                        {/* input */}
                        <div className='input-wrapper'>
                            <input type="text" placeholder="cutomer name / number " value={textInputSearch} onChange={(e) => setTextInputSearch(e.target.value)} />
                        </div>
                    </Col>
                    <Col xs="4" className='text-start'>
                        {/* search */}
                        <button className='role-search-btn' onClick={() => search()}>Search</button>
                    </Col>
                </Row>

                {/* row2 */}
                <Row className="layout-row-2">
                    <Col xs={2}>
                        <Select
                            className="search-select mt-2"
                            defaultValue={{ "label": "City", "value": 0 }}
                            onChange={(e) => { setFilterOnCity(e.value) }}
                            options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                        />
                    </Col>
                    <Col xs={2}>
                        <Select
                            className="search-select mt-2"
                            defaultValue={{ "label": "Industry", "value": 0 }}
                            onChange={(e) => { setFilterOnIndustry(e.value) }}
                            options={industries.map((industry) => ({ "value": industry.id, "label": industry.name }))}
                        />
                    </Col>
                    <Col xs={2}>
                        <Select
                            className="search-select mt-2"
                            defaultValue={{ "label": "reason", "value": 0 }}
                            onChange={(e) => { setFilterOnReason(e.value) }}
                            options={reasons.map((reason) => ({ "value": reason, "label": reason }))}
                        />
                    </Col>
                </Row>

                {/* row3 */}
                <Form onSubmit={search}>
                    <Row className="layout-row-3">

                        <Col xs="3">
                            <Form.Group className='d-flex align-items-center'>
                                <Form.Label className="acquisition-date-flex-label">From</Form.Label>
                                <Form.Control type="date" className="acquisition-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} required />
                            </Form.Group>
                        </Col>
                        <Col xs="3">
                            <Form.Group className='d-flex align-items-center'>
                                <Form.Label className="acquisition-date-flex-label">To</Form.Label>
                                <Form.Control type="date" className="acquisition-date" value={toDate} onChange={(e) => setToDate(e.target.value)} required />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>


                {/* RetentionTable */}
                <RetentionTable
                    rentention={sortedRetention}
                    setCurrent={setCurrent}
                    updateData={fetchRetentionTableData}
                    setCancelConfirmModal={setCancelConfirmModal}
                    setReinstateModal={setReinstateModal}
                    setMessageCancelReasons={setMessageCancelReasons}
                />

                {/* reinstate */}
                {reinstateModal ?
                    <Reinstate
                        setReinstateModal={setReinstateModal}
                        setCancelConfirmModal={setCancelConfirmModal}
                        setReinstateConfirmModal={setReinstateConfirmModal}
                        current={current}
                        updateData={fetchRetentionTableData}
                    />
                    : null
                }

                {/* reinstate confirm modal*/}
                {reinstateConfirmModal ?
                    <ReinstateConfirm
                        setReinstateConfirmModal={setReinstateConfirmModal}
                        setSuccessModal={setSuccessModal}
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        current={current}
                        updateData={fetchRetentionTableData}
                    />
                    : null
                }

                {/* cancel */}
                {cancelConfirmModal ?
                    <CancelConfirmScreen
                        setCancelConfirmModal={setCancelConfirmModal}
                        setSuccessModal={setSuccessModal}
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        current={current}
                        updateData={fetchRetentionTableData}
                    /> : null}

                {/* message of cancel reasons */}
                {
                    messageCancelReasons ?
                        <MsgCancellationModal
                            setMessageCancelReasons={setMessageCancelReasons}
                            current={current}
                        />
                        : null
                }

                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={fetchRetentionTableData}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }

            </div>
        )
    }

    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
import React from 'react';
import { Table, Container } from 'react-bootstrap';
//icons
import { AiOutlineEdit } from 'react-icons/ai'

const CountryTable = (props) => {
    //props
    let countries = props.countries;
    let seteditCountries = props.seteditCountries;
    let setCurrentCountry = props.setCurrentCountry;

    return (
        <div className='tables-layout'>
            <Container fluid>
                <Table responsive>
                    <thead className='text-center'>
                        <tr className='role-table-header'>
                            <th className="text-center" >ID</th>
                            <th className="text-center" >Arabic Name</th>
                            <th className="text-center" >English Name</th>
                            <th className="text-center" >Status</th>
                            <th className="text-center" ></th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {countries.map((i) => //lazm no7t key lkol tr 34an byboz el dnya wel search
                            <tr key={i.id} className='role-table-text'>
                                <td className="text-center">{i.id}</td>
                                <td className="text-center">{i.ArName}</td>
                                <td className="text-center">{i.EnName}</td>
                                <td className='text-center' style={i.isActive ? { color: "#00BD85" } : null}>{i.isActive ? ("Active") : ("Inactive")}</td>
                                <td className='text-center'>
                                    <button
                                        className='role-edit-btn'
                                        style={{ width: "50%" }}
                                        onClick={() => {
                                            seteditCountries(true);
                                            setCurrentCountry(i)
                                            console.log('pressed')
                                        }
                                        }>
                                        Edit <AiOutlineEdit />
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default CountryTable

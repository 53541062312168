import React, { useState, useContext } from 'react'
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Col, Modal } from 'react-bootstrap';
// import { Switch } from 'evergreen-ui'
import Switch from "react-switch";
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';
import { AiFillPlusCircle, AiOutlineDelete } from 'react-icons/ai'
import { BsChatDotsFill } from 'react-icons/bs'
import { VscError } from "react-icons/vsc";
//components
import AddNoteModal from './AddNoteModal';
import SocialLinksModal from './SocialLinksModal';
import SalesNumbersModal from './SalesNumbersModal';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

//api
import { deleteFromAgel, postToAgel, putToAgel } from '../../utils/api'

import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';
import { userPermissionsAndActionsContext } from '../../App';




export default function BasicInfo(props) {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewDeleteWsButtonPermission = userValidPermissionAndAction(validPermissionsAndActions, "wholesaler", "deleteWholesaler")
    let viewGenerateOtpBtnPermission = userValidPermissionAndAction(validPermissionsAndActions, "otp", "generate otp")

    let navigate = useNavigate()


    const { name, status, phoneNumber, id, CityId, regAddress, City, Industry, WMegaStore, discount, cardNumber, expiry, account_owner, bank_name, bank_account_number, UID, salesContactNumber, socialMediaLinks, WholesalersValifyData } = props.wholesaler

    const {
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        allLabels,
        wsLabels,
        setWsDiscount,
        wsUpdatedLabels,
        setWsUpdatedLabels,
        industries,
        cities,
        notes,
        updateData,
        setWholesaler
    } = props

    let singleNote = notes ? notes[0] : null

    const { id: wholesalerId } = useParams()

    const [showSave, setShowSave] = useState(false);
    const [showConfirmationDeleteAccount, setShowConfirmationDeleteAccount] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [showPinModal, setShowPinModal] = useState(false);
    const [newPIN, setNewPIN] = useState("");
    const [pinValidation, setPinValidation] = useState(false);
    const [showGenerateOTPModal, setShowGenerateOTPModal] = useState(false);

    const [userStatus, setUserStatus] = useState("")
    const [ownerName, setOwnerName] = useState("")
    const [storeNickname, setStoreNickname] = useState("")
    const [organizationName, setOrganizationName] = useState("")
    const [accommodationAddress, setAccommodationAddress] = useState("")
    const [storeLegalAddress, setStoreLegalAddress] = useState("")
    const [discount_Number, setDiscount_Number] = useState("")
    const [card_Number, setCard_Number] = useState("")
    const [accountOwner, setAccountOwner] = useState("")
    const [bankName, setBankName] = useState("")
    const [bankAccountNumber, setBankAccountNumber] = useState("")
    const [uniqueIdNumber, setUniqueIdNumber] = useState("")
    const [uniqueIdNumberValidationMsgLength, setUniqueIdNumberValidationMsgLength] = useState(false)
    const [selectedIndustry, setSelectedIndustry] = useState("")
    const [selectedCity, setSelectedCity] = useState("")

    //generate otp value states
    const [otpValue, setOtpValue] = useState("")
    const [otpErrorMsg, setOtpErrorMsg] = useState("")
    const [loadingOtpResponse, setLoadingOtpResponse] = useState(false)


    const [addNote, setAddNote] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [loadMoreVisiblity, setLoadMoreVisiblity] = useState("block")

    const [show_social_links, set_show_social_links] = useState(false)
    const [show_sales_numbers, set_show_sales_numbers] = useState(false)


    // btn state checker
    const [pinBtn, setPinBtn] = useState(false)
    const [changePasswordBtn, setChangePasswordBtn] = useState(false)
    const [deleteAccountBtn, setDeleteAccountBtn] = useState(false)
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)


        //handle change pin
        const saveNewPIN = async (e) => {
            e.preventDefault();
            setPinBtn(true);
    
            if (newPIN.length < 6 || newPIN.length > 6) {
                setPinValidation(true);
            }
            else {
                var data = {
                    "newPin": newPIN,
                    "userType": "Wholesaler"
                }
                try {
                    await putToAgel(`dashboard/user/${wholesalerId}/changePin`, data);
                    setSuccessModal(true);
                }
                catch (error) {
                    setErrorMsgInFailedModal(error&&error.response&&error.response.data&&error.response.data.message&&error.response.data.message[0]);
                    setFailedModalWithErrorMsg(true);
                    // console.log({ error });
                }
                setShowPinModal(false);
                setNewPIN("");
            }
            setPinBtn(false);
        }
    
    const toggleStatus = async (status) => {
        props.wholesaler.status = status === "inactive" || status === "pending" ? "active" : "inactive"
        setWholesaler({ ...props.wholesaler })
        setUserStatus(props.wholesaler.status)
    }



    //change password
    const handleChangePassword = async (e) => {
        e.preventDefault();
        setChangePasswordBtn(true)

        try {
            await putToAgel(`dashboard/wholesaler/${wholesalerId}/changePassword`, { newPassword })
            setSuccessModal(true)
        }
        catch (e) {
            setErrorMsgInFailedModal(e?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setChangePasswordBtn(false)
        setShowPassword(false)
    }


    //handle delete account
    const deleteAccount = async (e) => {
        e.preventDefault()
        setDeleteAccountBtn(true)
        try {
            await deleteFromAgel(`dashboard/wholesaler/${wholesalerId}`)
            // setSuccessModal(true) //m4 byl72 yd5ol hena asln by navigate 3latol
            navigate('/wholesalers/list');
        }
        catch (e) {
            setErrorMsgInFailedModal(e?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setShowConfirmationDeleteAccount(false)
        setDeleteAccountBtn(false)
    }


    //handle generate otp account
    const generateOtp = async (e) => {
        e.preventDefault()
        setOtpErrorMsg("")
        setOtpValue("")
        setLoadingOtpResponse(true)

        var data = {
            "phoneNumber": phoneNumber && phoneNumber
        }

        try {
            const otpResponse = await postToAgel(`dashboard/generate-otp`, data)
            setOtpValue(otpResponse?.data?.OTP)
        }
        catch (e) {
            setOtpErrorMsg(e?.response?.data?.message)
        }
        setLoadingOtpResponse(false)
    }


    //save edits
    const saveBasicInfo = async () => {
        if ((uniqueIdNumber !== "" && uniqueIdNumber.length === 12) || uniqueIdNumber === "") {
            setUniqueIdNumberValidationMsgLength(false)
            setSaveEditsBtn(true)

            var data = {
                "wholesaler": {
                    ...(userStatus ? { "status": userStatus } : {}),
                    ...(ownerName ? { "name": ownerName } : {}),
                    ...(storeNickname ? { "nickname": storeNickname } : {}),
                    ...(accommodationAddress ? { "accommodation_address": accommodationAddress } : {}),
                    ...(discount_Number ? { "discount": discount_Number } : {}),
                    ...(storeLegalAddress ? { "regAddress": storeLegalAddress } : {}),
                    ...(organizationName ? { "mega_store_name": organizationName } : {}),
                    ...(accountOwner ? { "account_owner": accountOwner } : {}),
                    ...(bankName ? { "bank_name": bankName } : {}),
                    ...(bankAccountNumber ? { "bank_account_number": bankAccountNumber } : {}),
                    ...(uniqueIdNumber ? { "UID": uniqueIdNumber } : {}),
                    ...(selectedIndustry ? { "industryId": selectedIndustry } : {}),
                    ...(selectedCity ? { "cityId": selectedCity } : {}),
                    "labels": wsUpdatedLabels.map((i) => i.id ? i.id : i),

                }
            }

            try {
                /* save edits in account */
                await putToAgel(`${process.env.React_APP_WS_SAVE_EDITS_ACCOUNT}${wholesalerId}`, data)
                updateData()
                setSuccessModal(true)
            }
            catch (e) {
                setErrorMsgInFailedModal(e?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
        }
        else {
            setErrorMsgInFailedModal("UID should be equal to 12 numbers!")
            setFailedModalWithErrorMsg(true)
            setUniqueIdNumberValidationMsgLength(true)
        }
        setSaveEditsBtn(false)
        setShowSave(false)
    }



    const onSelectLabel = (selectedList, selectedItem) => {
        let updatedLabels = selectedList.map((i) => i.id)
        setWsUpdatedLabels(updatedLabels)
        // setWholesaler({ ...props.wholesaler, labels: updatedLabels })
        // console.log({ selectedList })
        // console.log({ updatedLabels })
    }

    const onRemoveLabel = (selectedList, selectedItem) => {
        let updatedLabels = selectedList.map((i) => i.id)
        setWsUpdatedLabels(updatedLabels)
        // setWholesaler({ ...props.wholesaler, labels: updatedLabels })
        // console.log({ selectedList })
        // console.log({ updatedLabels })
    }


    //set ws discount to be used in transaction modal
    setWsDiscount(discount)

    return (
        <div>
            <div>
                {/* row 1 */}
                <Row>
                    <Col sm={2}>
                        <div className="d-flex justify-content-start align-items-center wsToggleSwitch">
                            <div className="wsToggleSwitchText">{status}</div>
                            <div className="d-flex justify-content-center align-items-center">
                                <Switch checked={status === "active" ? true : false} onChange={() => toggleStatus(status)}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onColor={"#00bd85"}
                                    offColor={status === "pending" ? "#e3f305" : "#ff0000"}
                                    activeBoxShadow={0}
                                />
                            </div>
                        </div>
                    </Col>


                    <Col sm={2} className="d-flex justify-content-center align-items-center" >
                        {/* delete button only can be viewed if there is a user permission */}
                        {viewDeleteWsButtonPermission &&
                            <button className="action-btns-in-profiles delete-btn-in-profile" onClick={() => setShowConfirmationDeleteAccount(true)}>Delete account</button>
                        }
                    </Col>
                    <Col sm={2} className="d-flex justify-content-center align-items-center"><button className="PIN" onClick={() => setShowPinModal(true)}>PIN</button></Col>
                    <Col sm={3} className="d-flex justify-content-center align-items-center" ><button className="action-btns-in-profiles change-password-btn" onClick={() => setShowPassword(true)}>Change password</button></Col>
                    <Col sm={3} className="d-flex justify-content-center align-items-center"><button className="action-btns-in-profiles save-basic-info" onClick={() => setShowSave(true)}>Save</button></Col>
                </Row>

                {/* row 2 */}
                <Row className='mt-3 d-flex justify-content-end align-items-center'>
                    {viewGenerateOtpBtnPermission &&
                        <Col sm={3} className="d-flex justify-content-center align-items-center"><button className="action-btns-in-profiles generate-otp-in-profile" onClick={(e) => { setShowGenerateOTPModal(true); generateOtp(e) }}>Generate OTP</button></Col>
                    }
                </Row>
            </div>

            <div className="mt-5">
                <Form>
                    <div className="border-bottom">
                        <h5 className='account-profile-titles mb-4'>Basic Info</h5>
                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Owner Name</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={name && name} onChange={(e) => { setOwnerName(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Phone Number</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={phoneNumber && phoneNumber} onChange={() => { "" }} disabled readonly />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">id number</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={WholesalersValifyData?.nationalIdOrPassportNumber} onChange={() => { "" }} disabled readonly />
                                </Form.Group>
                            </Col>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Birthdate</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={WholesalersValifyData?.birthday} onChange={() => { "" }} disabled readonly />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Store nickname</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={WMegaStore && WMegaStore.Wstores && WMegaStore.Wstores[0] && WMegaStore.Wstores[0].nickname && WMegaStore.Wstores[0].nickname} onChange={(e) => { setStoreNickname(e.target.value) }} />
                                </Form.Group>
                            </Col>

                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Nationality</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={WholesalersValifyData?.nationality} onChange={(e) => { "" }} disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Accommodation address</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={WholesalersValifyData?.accommodation_address} onChange={(e) => { setAccommodationAddress(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Labels</Form.Label>
                                    <span style={{ marginLeft: "0.5vw" }} className="profile-label-text">
                                        <span>{wsUpdatedLabels ? wsUpdatedLabels.length : "0"}</span>/<span>{allLabels ? allLabels.length : "0"}</span>
                                    </span>
                                    <Multiselect
                                        className="multi-select-label-accounts"
                                        options={allLabels && allLabels} //haylf 3la kol el labels
                                        onSelect={onSelectLabel}
                                        onRemove={onRemoveLabel}
                                        selectedValues={wsLabels && wsLabels} //selected labels for specific ret
                                        displayValue="name"
                                        showCheckbox={true}
                                        placeholder="Select..."
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Organization name</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={WMegaStore && WMegaStore.name} onChange={(e) => { setOrganizationName(e.target.value) }} />
                                </Form.Group>
                            </Col>

                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">industry name</Form.Label>
                                    <div style={{ width: "80%" }}>
                                        <Select
                                            className='industrySelectbox'
                                            placeholder={Industry?.name || "Select..."}
                                            onChange={(e) => { setSelectedIndustry(e.value) }}
                                            options={industries?.map((industry) => ({ "value": industry.id, "label": industry.name }))}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Store reg. address</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={WMegaStore && WMegaStore.regAddress && WMegaStore.regAddress} onChange={(e) => { setStoreLegalAddress(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">city</Form.Label>
                                    <div style={{ width: "80%" }}>
                                        <Select
                                            className='citySelectbox'
                                            placeholder={City?.EnName || "Select..."}
                                            onChange={(e) => { setSelectedCity(e.value) }}
                                            options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Gender</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={WholesalersValifyData?.gender} onChange={(e) => { "" }} disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>

                    </div>

                    {/* Payment Details */}
                    <div className="mt-5 border-bottom">
                        <h5 style={{ color: "#00BD85" }}>Payment Details</h5>
                        {/* row 1 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Discount</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={discount} onChange={(e) => { setDiscount_Number(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text" >Card Number</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={cardNumber} onChange={(e) => { setCard_Number(e.target.value) }} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Expiry Date</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={expiry} onChange={() => { "" }} disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* row2 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Account owner</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={account_owner && account_owner} onChange={(e) => { setAccountOwner(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text" >Bank name</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={bank_name && bank_name} onChange={(e) => { setBankName(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Bank account number</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={bank_account_number && bank_account_number} onChange={(e) => { setBankAccountNumber(e.target.value) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    {/* Prepaid card */}
                    <div className="mt-5 border-bottom">
                        <h5 style={{ color: "#00BD85" }}>Prepaid card</h5>
                        {/* row 1 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">
                                        UID
                                        <span style={{ color: '#e8a404', marginLeft: "0.3rem", fontSize: "0.8rem" }}>
                                            (must be 12 numbers)
                                        </span>
                                    </Form.Label>

                                    <Form.Control
                                        type="text"
                                        className="profile-input mt-2"
                                        defaultValue={UID}
                                        maxlength="12"
                                        onChange={(e) => {
                                            setUniqueIdNumber(e.target.value);
                                        }}
                                    />
                                    {/* incase user enter uid less than 12 number */}
                                    {uniqueIdNumberValidationMsgLength
                                        && (
                                            <span className='d-flex mt-2'>
                                                <VscError style={{ fontSize: "1.1rem" }} color="red" />
                                                <p className="select-error-msg" style={{ fontSize: "0.8rem", marginLeft: "0.1rem" }} >UID should be equal to 12 numbers!</p>
                                            </span>
                                        )
                                    }

                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    {/* socials */}
                    <div className="mt-5 border-bottom">
                        <h5 style={{ color: "#00BD85" }}>Socials</h5>
                        {/* row 1 */}
                        <Row className='d-flex justify-content-center align-items-center mb-3'>
                            <Col xs="6" className=' text-center d-flex justify-content-center align-items-center'>
                                <div className='ws-social-links-container' onClick={() => set_show_social_links(true)}>
                                    <img src="/images/ws-social-media.png" alt="social-links" className="img-fluid ws-social-links" />
                                </div>


                            </Col>
                            <Col xs="6" className=' text-center d-flex justify-content-center align-items-center'>
                                <div className='ws-sales-number-container' onClick={() => set_show_sales_numbers(true)}>
                                    <img src="/images/agel-form-img.svg" alt="sales-number" className="img-fluid ws-sales-number" />
                                </div>
                            </Col>
                        </Row>
                        {/* <div >

                            <div className='ws-social-container text-end'>
                                <div className='social-links-container'>
                                <img src="/images/ws-social-media.png" alt="social-links" className="img-fluid ws-social-images"/>
                                </div>
                            </div>

                            <div className='ws-social-container text-start'>
                                <img src="/images/agel-form-img.svg" alt="sales-number" className="img-fluid ws-social-images" />
                            </div>

                        </div> */}


                    </div>


                </Form>
            </div>

            <div className="mt-5">
                <Row>

                    {singleNote && (
                        <Col className="d-flex justify-content-start align-items-center">
                            <strong className='add-note-title'>Notes</strong>
                        </Col>

                    )}


                    <Col className="d-flex justify-content-end align-items-center">
                        <button className="add-note-btn" onClick={() => {
                            setAddNote(true)
                        }}>
                            <AiFillPlusCircle className='add-note-icon' />
                            add note
                        </button>
                    </Col>

                </Row>

                <Row>
                    <Col>
                        {!loadMore ?
                            <div>
                                {singleNote &&
                                    <div className="note-container"> {/* hy7ot el singleNote 34an hya el array of [0] y3ne 34an ygeb awl note bs */}
                                        <div className="d-flex">
                                            <p className="note-title-wrapper">
                                                By : {singleNote && singleNote.By}
                                            </p>
                                            <p className="note-title-wrapper">
                                                Date : {new Date(singleNote && singleNote.createdAt).toLocaleDateString()}
                                            </p>
                                            <p className="note-title-wrapper">
                                                Time :
                                                {new Date(singleNote && singleNote.createdAt).getHours()}:{new Date(singleNote && singleNote.createdAt).getMinutes()}:{new Date(singleNote && singleNote.createdAt).getSeconds()}
                                            </p>
                                        </div>
                                        <div className='text-center mt-4 mb-2'>
                                            <p className="note-main-text">
                                                {singleNote && singleNote.note}
                                            </p>
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                {notes && notes.map((note) =>

                                    <div className="note-container">
                                        <div className="d-flex">
                                            <p className="note-title-wrapper">
                                                By : {note.By}
                                            </p>
                                            <p className="note-title-wrapper">
                                                Date : {new Date(note.createdAt).toLocaleDateString()}
                                            </p>
                                            <p className="note-title-wrapper">
                                                Time :
                                                {new Date(note.createdAt).getHours()}:{new Date(note.createdAt).getMinutes()}:{new Date(note.createdAt).getSeconds()}
                                            </p>
                                        </div>
                                        <div className='text-center mt-4 mb-2'>
                                            <p className="note-main-text">
                                                {note.note}
                                            </p>
                                        </div>
                                    </div>
                                )}

                            </div>
                        }

                    </Col>
                </Row>

                {
                    singleNote &&
                    (
                        <Row>
                            <Col style={{ cursor: "pointer", display: loadMoreVisiblity }} className="text-center mt-2" onClick={() => { setLoadMore(true); setLoadMoreVisiblity("none") }}>
                                <span className="load_more_text">load more</span>
                                <img src="/images/loadMoreArrows.png" style={{ width: "1.5vw", marginTop: "-0.1vw" }} className="img-fluid" />
                            </Col>
                        </Row>

                    )
                }

                {
                    addNote ?
                        <AddNoteModal
                            setAddNote={setAddNote}
                            id={id}
                            updateData={updateData}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null
                }
            </div>


            {/* show save confirm modal */}
            {
                showSave ?
                    <Modal show={showSave} onHide={() => setShowSave(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>
                        <Modal.Header closeButton>
                        </Modal.Header>

                        <Modal.Body className='confirm-msg-modal-title' >
                            <strong>Do you want to save changes?</strong>
                        </Modal.Body>

                        <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                            <button className="role-add-btn-with-loader-spinner" onClick={saveBasicInfo} type="submit" disabled={saveEditsBtn ? true : false}>
                                {saveEditsBtn ?
                                    (<LoadingBtnSpinner />)
                                    : (<span>Done</span>)}
                            </button>
                            <button className="roleCloseBtn" onClick={() => setShowSave(false)}>Close</button>
                        </Modal.Footer>

                    </Modal> : null
            }


            {/* show generate otp modal */}
            {
                showGenerateOTPModal ?
                    <Modal show={showGenerateOTPModal} onHide={() => setShowGenerateOTPModal(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>
                        <Modal.Header closeButton>
                        </Modal.Header>

                        <Modal.Body> {/* className='confirm-msg-modal-title text-center'  */}
                            <div className='d-grid justify-content-center align-items-center'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <BsChatDotsFill className='otp-icon' />
                                    <strong className='confirm-msg-modal-title text-center'>OTP</strong>
                                </div>

                                {otpErrorMsg ?
                                    <span className='otp-value-error-msg'>{otpErrorMsg}</span>
                                    : otpValue ?
                                        <span className='otp-value'>{otpValue}</span>
                                        : loadingOtpResponse ?
                                            <span className='otp-value'>Loading...</span>
                                            : null
                                }

                            </div>
                        </Modal.Body>
                    </Modal>
                    : null
            }


            {/* show delete account confirm modal */}
            {
                showConfirmationDeleteAccount ?
                    <Modal show={showConfirmationDeleteAccount} onHide={() => setShowConfirmationDeleteAccount(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>
                        <Modal.Header closeButton>
                        </Modal.Header>

                        <Modal.Body className='confirm-msg-modal-title' >
                            {/* <img src="" className='img-fluid'/> */}
                            <Row>
                                <Col xs="12" className='d-flex justify-content-center align-items-center'>
                                    <AiOutlineDelete color="red" className='recyle-bin-icon-in-profile-confirmation-modal' />
                                </Col>
                                <Col xs="12" className='d-flex justify-content-center align-items-center mt-2'>
                                    <strong>Do you want to delete this account?</strong>
                                </Col>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                            <button className="role-add-btn-with-loader-spinner" onClick={deleteAccount} type="submit" disabled={deleteAccountBtn ? true : false}>
                                {deleteAccountBtn ?
                                    (<LoadingBtnSpinner />)
                                    : (<span>Done</span>)}
                            </button>
                            <button className="roleCloseBtn" onClick={() => setShowConfirmationDeleteAccount(false)}>Close</button>
                        </Modal.Footer>

                    </Modal> : null
            }


            {/* show change password */}
            {
                showPassword ?
                    <Modal show={showPassword} onHide={() => setShowPassword(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>

                        <Modal.Header closeButton>
                        </Modal.Header>

                        <Form onSubmit={handleChangePassword}>
                            <Modal.Body>
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">New Password</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" style={{ width: "100%" }} value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} required />
                                </Form.Group>
                            </Modal.Body>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={changePasswordBtn ? true : false}>
                                    {changePasswordBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>
                                <button className="roleCloseBtn" onClick={() => setShowPassword(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Modal>
                    : null
            }

            {/* show Pin Modal */}
            {
                showPinModal ?
                    <Modal show={showPinModal} onHide={() => setShowPinModal(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Form onSubmit={saveNewPIN}>
                            <Modal.Body>
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Reset PIN <span style={{ color: '#e8a404', marginLeft: "0.3rem" }}> (6 digits) </span> </Form.Label>
                                    <Form.Control type="password" maxLength={6} className="profile-input mt-2" style={{ width: "100%" }} value={newPIN} onChange={(e) => { setNewPIN(e.target.value); setPinValidation(false) }} required />
                                </Form.Group>
                                {
                                    pinValidation &&
                                    <span className='pin-err-msg'>Pin must be 6 digits</span>
                                }
                            </Modal.Body>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={pinBtn ? true : false}>
                                    {pinBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>
                                <button className="roleCloseBtn" onClick={() => setShowPinModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Modal> : null
            }

            {/* social links */}
            {
                show_social_links ?
                    <SocialLinksModal
                        set_show_social_links={set_show_social_links}
                        id={id}
                        updateData={updateData}
                        setSuccessModal={setSuccessModal}
                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        retrievedSocialMediaLinks={socialMediaLinks}
                    />
                    : null
            }

            {/* sales numbers */}
            {
                show_sales_numbers ?
                    <SalesNumbersModal
                        set_show_sales_numbers={set_show_sales_numbers}
                        id={id}
                        updateData={updateData}
                        setSuccessModal={setSuccessModal}
                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        retrievedSalesContactNumber={salesContactNumber}
                    />
                    : null
            }

        </div>
    )
}
import React, { useEffect, useState } from 'react'
//bootstrap
import { Modal, Form, Row, Col, Container, Spinner } from 'react-bootstrap';
//react icons
import { AiOutlineFileImage } from "react-icons/ai"
import { CgSoftwareDownload } from 'react-icons/cg'
//api
import { getFileFromAgel, getFromAgel, putToAgel } from '../../utils/api';

import fileDownload from 'js-file-download'
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


export default function ActivationForm(props) {

    const [afData, setAfData] = useState([])


    const {
        setShowAF,
        id,
        APIdFromEachStore,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        setSuccessModal,
    } = props

    //state checker on corrupted images 
    const [frontNationIdCorruptedImg, setFrontNationIdCorruptedImg] = useState(false)
    const [backNationIdCorruptedImg, setBackNationIdCorruptedImg] = useState(false)
    const [commercialRegistrationCorruptedImg1, setCommercialRegistrationCorruptedImg1] = useState(false)
    const [commercialRegistrationCorruptedImg2, setCommercialRegistrationCorruptedImg2] = useState(false)
    const [commercialRegistrationCorruptedImg3, setCommercialRegistrationCorruptedImg3] = useState(false)

    //state checker on buttons 
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)

    //contract states
    const [contractBeforeSignatureSpinner, setContractBeforeSignatureSpinner] = useState(false)
    const [contractAfterSignatureSpinner, setContractAfterSignatureSpinner] = useState(false)

    //edit fields states
    const [lat, setLat] = useState("");
    const [long, setLong] = useState("");



    const fetchActivationFormData = async () => {
        try {
            /* get ws AF by store id  */
            const responsewsAf = await getFromAgel(`${process.env.React_APP_GET_WS_AF_BY_STORE_ID}${id}`)
            console.log("responsewsAf=", responsewsAf.data)
            setAfData(responsewsAf.data)
            console.log("id=", id)
        }
        catch (e) {
            // console.log("error=", e)
            // console.log("id=", id)
        }
    }

    // fetch Af Data
    useEffect(() => {
        fetchActivationFormData()
    }, [])


    //contract before signature
    const downloadPdfBeforeSignature = async () => {
        try {
            /* get ws contract before signature */
            const contractResponse = await getFileFromAgel(`${process.env.React_APP_WS_GET_CONTRACT}${APIdFromEachStore}`, { responseType: 'blob' })
            console.log("contractResponse=", contractResponse)
            fileDownload(contractResponse.data, 'contract.pdf');
        }
        catch (e) {
            console.log({ e })
        }
        setContractBeforeSignatureSpinner(false)
    }

    //contract after signature
    const downloadPdfAfterSignature = async () => {
        try {
            /* get ws contract after signature */
            const contractResponse = await getFromAgel(`dashboard/wholesaler/activationProcess/${APIdFromEachStore}/downloadContract`)
            const contractData = contractResponse.data.url
            window.open(contractData);

        }
        catch (e) {
            console.log({ e })
        }
        setContractAfterSignatureSpinner(false)
    }

    // const saveEdits = async (e) => {

    //     e.preventDefault();
    //     setSaveEditsBtn(true)

    //     var data = {
    //         "finalForm": {
    //             "lat": lat ? lat : afData?.WholesalerAF?.lat,
    //             "long": long ? long : afData?.WholesalerAF?.long,
    //         }
    //     }
    //     try {
    //         /* ws save af edits */
    //         await putToAgel(`${process.env.React_APP_WS_AF_SAVE_EDITS}${APIdFromEachStore}/`, data)
    //         setShowAF(false)
    //         //fetchActivationFormData()// m4 m7taga a5leh yndh 3l update l2no el model by2fl b3d el save 3latol w automaticlaly lma byft7 tane byb3t request
    //         setSuccessModal(true)
    //     }
    //     catch (err) {
    //         setShowAF(false)
    //         setFailedModalWithErrorMsg(true)
    //         setErrorMsgInFailedModal(err.response.data.message)
    //     }
    // }


    return (
        <Modal show={true} onHide={() => setShowAF(false)} centered size="lg" className="AP-modal" style={{ marginTop: "5em" }}>
            <Modal.Header closeButton>
                <Row>
                    <Col xs={12} className="modal-header-title">
                        <p>{afData && afData.WholesalerAF && afData.WholesalerAF.name}</p>
                    </Col>
                    <Col xs={12} className='d-flex  justify-content-between align-items-start'>
                        <p className="modal-title">Agent name <span className='text-muted'>{afData && afData.Wstore && afData.Wstore.AgentWholeAF && afData.Wstore.AgentWholeAF.Agent.name}</span></p>
                        <p className="modal-title">request date <span className='text-muted'>{(new Date(afData && afData.WholesalerAF && afData.WholesalerAF.createdAt).toLocaleDateString())}</span></p>
                        <p className="modal-title">visit date <span className='text-muted'>{(new Date(afData && afData.WholesalerAF && afData.WholesalerAF.visitDate).toLocaleDateString())}</span></p>
                    </Col>
                </Row>
            </Modal.Header>

            <Modal.Body style={{ marginBottom: "5em" }}>
                <Container className="mt-3" dir="rtl">
                    <Form>
                        {/* row1 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">الأسم ثلاثى</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.WholesalerAF && afData.WholesalerAF.name} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input  mt-2" value={afData && afData.Wstore && afData.Wstore && afData.Wstore.AgentWholeAF && afData.Wstore.AgentWholeAF.name} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* row2 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">رقم السجل التجاري</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.WholesalerAF && afData.WholesalerAF.commercial_registration_id} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input  mt-2" value={afData && afData.Wstore && afData.Wstore && afData.Wstore.AgentWholeAF && afData.Wstore.AgentWholeAF.commercial_registration_id} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input  mt-2" value="" disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* row3 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">مكتب السجل</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.WholesalerAF && afData.WholesalerAF.commercial_registration_office} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input  mt-2" value={afData && afData.Wstore && afData.Wstore && afData.Wstore.AgentWholeAF && afData.Wstore.AgentWholeAF.commercial_registration_office} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input  mt-2" value="" disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* row4 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">رقم البطاقة</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.Wholesaler && afData.Wholesaler.nationalId} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input  mt-2" value={afData && afData.Wstore && afData.Wstore && afData.Wstore.AgentWholeAF && afData.Wstore.AgentWholeAF.nationalId} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input  mt-2" value="" disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* row5 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">اسم المنشأة</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.Wstore && afData.Wstore.name && afData.Wstore.name} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input  mt-2" value={afData && afData.Wstore && afData.Wstore && afData.Wstore.AgentWholeAF && afData.Wstore.AgentWholeAF.storeName} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input  mt-2" value="" disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* row6 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">رقم هاتف </Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.WholesalerAF && afData.WholesalerAF.phoneNumber} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* row7 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">رقم هاتف اخر </Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.WholesalerAF && afData.WholesalerAF.secondNumber} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.Wstore && afData.Wstore && afData.Wstore.AgentWholeAF && afData.Wstore.AgentWholeAF.secondNumber} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* row8 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">مدير الفرع</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.WholesalerAF && afData.WholesalerAF.owner_name} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.Wstore && afData.Wstore && afData.Wstore.AgentWholeAF && afData.Wstore.AgentWholeAF.owner_name} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* row9 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text"> اسم الفرع</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.Wstore && afData.Wstore.name && afData.Wstore.name} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.Wstore && afData.Wstore && afData.Wstore.AgentWholeAF && afData.Wstore.AgentWholeAF.storeName} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* row10 */}
                        <Row>
                            <Col xs="4" className="mb-3">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">عنوان المنشأة</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.WholesalerAF && afData.WholesalerAF.address} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.Wstore && afData.Wstore && afData.Wstore.AgentWholeAF && afData.Wstore.AgentWholeAF.address} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* row11 */}
                        <Row>
                            <Col xs="4" className="mb-3">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">نشاط المنشأة</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.WholesalerAF && afData.WholesalerAF.industry} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.Wstore && afData.Wstore && afData.Wstore.AgentWholeAF && afData.Wstore.AgentWholeAF.industry} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* row12*/}
                        <Row>
                            <Col xs="4" className="mb-3">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">Lat Store</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.WholesalerAF && afData.WholesalerAF.lat} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* row13 */}
                        <Row>
                            <Col xs="4" className="mb-3">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">Long Store</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.WholesalerAF && afData.WholesalerAF.long} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value="" disabled readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* row14 */}
                        <Row>
                            <Col xs="4" className="mb-3">
                                <Form.Group className="mb-3">
                                    <Form.Label className="AP-modal-text">برجاء كتابة عنوان المنشأة تفصيلياً مع ذكر أقرب علامة مميزة</Form.Label>
                                    <Form.Control type="text" className="role-input mt-2" value={afData && afData.WholesalerAF && afData.WholesalerAF.storeAddressByCust} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "3em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className="mb-3" style={{ marginTop: "3em" }}>
                                    <Form.Control type="text" className="role-input mt-2" value={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* row15*/}
                        {(!commercialRegistrationCorruptedImg1 && (afData && afData.WholesalerAF && afData.WholesalerAF.commercial_registration_image)) ?
                            <Row>
                                <Col xs="4">
                                    <Form.Group>
                                        <Form.Label className="AP-modal-text">السجل التجاري ١</Form.Label>
                                        <br />
                                        <a href={`${afData && afData.WholesalerAF && afData.WholesalerAF.commercial_registration_image}`} target="_blank">
                                            <img onError={() => setCommercialRegistrationCorruptedImg1(true)} src={`${afData && afData.WholesalerAF && afData.WholesalerAF.commercial_registration_image}`} className='img-fluid Ap-register-image' />
                                        </a>
                                    </Form.Group>
                                </Col>
                                {/* <Col xs="4" className="mt-auto mb-auto">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4" className="mt-auto mb-auto">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                    </Form.Group>
                                </Col> */}
                            </Row>
                            : null}

                        {/* row16*/}
                        {(!commercialRegistrationCorruptedImg2 && (afData && afData.WholesalerAF && afData.WholesalerAF.rest_commercial_registration_image && afData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one)) ?
                            <Row>
                                <Col xs="4">
                                    <Form.Group>
                                        <Form.Label className="AP-modal-text">السجل التجاري ٢</Form.Label>
                                        <br />
                                        <a href={`${afData && afData.WholesalerAF && afData.WholesalerAF.rest_commercial_registration_image && afData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one}`} target="_blank">
                                            <img onError={() => setCommercialRegistrationCorruptedImg2(true)} src={`${afData && afData.WholesalerAF && afData.WholesalerAF.rest_commercial_registration_image && afData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one}`} className='img-fluid Ap-register-image' />
                                        </a>
                                    </Form.Group>
                                </Col>
                                {/* <Col xs="4" className="mt-auto mb-auto">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4" className="mt-auto mb-auto">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                    </Form.Group>
                                </Col> */}
                            </Row>
                            : null}

                        {/* row17*/}
                        {(!commercialRegistrationCorruptedImg3 && (afData && afData.WholesalerAF && afData.WholesalerAF.rest_commercial_registration_image && afData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two)) ?
                            <Row>
                                <Col xs="4">
                                    <Form.Group>
                                        <Form.Label className="AP-modal-text">السجل التجاري ٣</Form.Label>
                                        <br />
                                        <a href={`${afData && afData.WholesalerAF && afData.WholesalerAF.rest_commercial_registration_image && afData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two}`} target="_blank">
                                            <img onError={() => setCommercialRegistrationCorruptedImg3(true)} src={`${afData && afData.WholesalerAF && afData.WholesalerAF.rest_commercial_registration_image && afData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two}`} className='img-fluid Ap-register-image' />
                                        </a>
                                    </Form.Group>
                                </Col>
                                {/* <Col xs="4" className="mt-auto mb-auto">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4" className="mt-auto mb-auto">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                    </Form.Group>
                                </Col> */}
                            </Row>
                            : null}

                        {/* row18*/}
                        {(!frontNationIdCorruptedImg && (afData && afData.Wholesaler && afData.Wholesaler.nationalIdImage)) ?
                            <Row>
                                <Col xs="4">
                                    <Form.Group>
                                        <Form.Label className="AP-modal-text">الصورة الأمامية للبطاقة</Form.Label>
                                        <br />
                                        <a href={`${afData && afData.Wholesaler && afData.Wholesaler.nationalIdImage}`} target="blank">
                                            <img onError={() => setFrontNationIdCorruptedImg(true)} src={`${afData && afData.Wholesaler && afData.Wholesaler.nationalIdImage}`} className='img-fluid Ap-register-image' />
                                        </a>
                                    </Form.Group>
                                </Col>
                                {/* <Col xs="4" className="mt-auto mb-auto">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4" className="mt-auto mb-auto">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' disabled readOnly />
                                    </Form.Group>
                                </Col> */}
                            </Row>
                            : null}

                        {/* row19*/}
                        {(!backNationIdCorruptedImg && (afData && afData.Wholesaler && afData.Wholesaler.nationalIdBackImage)) ?
                            <Row>
                                <Col xs="4">
                                    <Form.Group>
                                        <Form.Label className="AP-modal-text">الصورة الخلفية للبطاقة</Form.Label>
                                        <br />
                                        <a href={`${afData && afData.Wholesaler && afData.Wholesaler.nationalIdBackImage}`} target="blank">
                                            <img onError={() => setBackNationIdCorruptedImg(true)} src={`${afData && afData.Wholesaler && afData.Wholesaler.nationalIdBackImage}`} className='img-fluid Ap-register-image' />
                                        </a>
                                    </Form.Group>
                                </Col>
                                {/* <Col xs="4" className="mt-auto mb-auto">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4" className="mt-auto mb-auto">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' disabled readOnly />
                                    </Form.Group>
                                </Col> */}
                            </Row>
                            : null}

                        {/* contract */}
                        {/* before signature */}
                        <Container className='d-flex mt-5'>
                            <AiOutlineFileImage size="32" className='img-icon' />
                            <p className='form-text img-name'>Contract before signature</p>
                            {
                                contractBeforeSignatureSpinner ?
                                    <Spinner animation="border" className="loader" />
                                    : <CgSoftwareDownload size="28" className="download-icon" onClick={async (e) => { setContractBeforeSignatureSpinner(true); await downloadPdfBeforeSignature() }} />
                            }
                        </Container>

                        {/* after signature */}
                        <Container className='d-flex mt-5'>
                            <AiOutlineFileImage size="32" className='img-icon' />
                            <p className='form-text img-name'>Contract after signature</p>
                            {
                                contractAfterSignatureSpinner ?
                                    <Spinner animation="border" className="loader" />
                                    : <CgSoftwareDownload size="28" className="download-icon" onClick={async (e) => { setContractAfterSignatureSpinner(true); await downloadPdfAfterSignature() }} />
                            }
                        </Container>

                        {/* action btns */}
                        <Row>
                            <Col xs="6" className='d-flex justify-content-start align-items-center mt-5 ' >
                                <button className="cancel-visit" type="submit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowAF(false)
                                    }}
                                >Close</button>
                            </Col>

                            {/* <Col xs="6" className='d-flex justify-content-end align-items-center mt-5 ' >
                                <button className="save-edits" type="submit" onClick={saveEdits} disabled={saveEditsBtn ? true : false}>
                                    {saveEditsBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Save Edits</span>)}
                                </button>
                            </Col> */}
                        </Row>

                    </Form>
                </Container>

            </Modal.Body>
        </Modal>

    )
}
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Switch from "react-switch";
import {
  Row, Col, Form, Container, Table,
} from 'react-bootstrap';
import { CgProfile } from 'react-icons/cg';
import { AiFillPlusCircle } from 'react-icons/ai';

// components
import AddNoteModal from './AddNoteModal';
import Failed_Modal from '../common/FailedModal';
import SuccessModal from '../common/SuccessModal';
import AuthorizationRequiredScreen from '../../screens/AuthorizationRequiredScreen/AuthorizationRequiredScreen';

// api
import { getFromAgel } from '../../utils/api';

import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';
import { userPermissionsAndActionsContext } from '../../App';

export default function RetailerInvoices(props) {
  // permissions
  const validPermissionsAndActions = useContext(userPermissionsAndActionsContext);
  const viewRetailerProfileInvoiceScreenPermission = userValidPermissionAndAction(validPermissionsAndActions, "retailerPayment", "viewRetailerPaymentDetails");

  // params
  const {
    paymentId, retailerId,
  } = useParams();

  const navigate = useNavigate();

  const [invoicesData, setInvoicesData] = useState([]);
  const [notes, setNotes] = useState([]);
  //   const [checked, setChecked] = useState("");
  const [invoiceImgValidity, setInvoiceImgValidity] = useState(false);
  const [addNote, setAddNote] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreVisiblity, setLoadMoreVisiblity] = useState("block");

  // server modal response success/failed
  const [successModal, setSuccessModal] = useState(false);
  const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false);
  const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("");

  // paid/not paid switch button
  //   const toggleSwitch = async (checked) => {
  //     setChecked(checked);
  //     const data = {
  //       checked,
  //     };
  //     try {
  //       await putToAgel(`/dashboard/..${paymentId}/`, data)
  //       console.log("trydata=", data)
  //       console.log({ paymentId })
  //     } catch (error) {
  //       console.log("errdata=", data)
  //       console.log({ error })
  //     }
  //   };

  const fetchData = async () => {
    try {
      /* get retailer invoices data */
      const invoicesInfoDataResponse = await getFromAgel(`/dashboard/retailer/payments/${retailerId}/paymentid/${paymentId}`);

      // sorting ascending installments by date
      invoicesInfoDataResponse.data.installment.sort((a, b) => new Date(a.paymentDate.split('-').reverse().join('-')) - new Date(b.paymentDate.split('-').reverse().join('-')));

      setInvoicesData(invoicesInfoDataResponse.data);
      setNotes(invoicesInfoDataResponse.data.notes);
    } catch (error) {
      // console.log({ error })
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const singleNote = notes ? notes[0] : null;

  if (viewRetailerProfileInvoiceScreenPermission) {
    return (
      <div className="layout-invoices">
        {/* switch paid/not paid */}
        <Row className="d-flex justify-content-end align-items-center">
          <Col xs="4" className="text-center">
            <span className="switch-text">Paid/Notpaid</span>
            <label>
              <Switch onChange="toggleSwitch" checked={invoicesData?.payments?.status} checkedIcon={false} uncheckedIcon={false} height={23} width={48} disabled />
              {' '}
              {/* disabled={true} 34an sabry m4 3ayzha editable now 8er keda de hattms7 */}
            </label>
          </Col>
        </Row>

        {/* info inputs */}
        <Container fluid className="mt-5">
          <Form>

            {/* row1 */}
            <Row>
              <Col xs="4">
                <Form.Group>
                  <Form.Label className="text-label">
                    Wholesaler Name
                    <CgProfile className="invoices-icon" onClick={() => navigate(`/wholesalers/list/${invoicesData?.payments?.Transaction?.Wholesaler?.id}/details`)} />
                  </Form.Label>
                  <Form.Control type="text" className="invoices-input mt-2" value={invoicesData?.payments?.Transaction?.Wholesaler?.name} onChange="" readOnly disabled />
                </Form.Group>
              </Col>
              <Col xs="4">
                <Form.Group>
                  <Form.Label className="text-label">
                    Retailer Name
                    <CgProfile className="invoices-icon" onClick={() => navigate(`/retailers/list/${invoicesData?.payments?.Transaction?.Retailer?.id}/details`)} />
                  </Form.Label>
                  <Form.Control type="text" className="invoices-input mt-2" value={invoicesData?.payments?.Transaction?.Retailer?.name} onChange="" readOnly disabled />
                </Form.Group>
              </Col>
              <Col xs="4">
                <Form.Group>
                  <Form.Label className="text-label">Date</Form.Label>
                  <Form.Control type="text" className="invoices-input mt-2" value={new Date(invoicesData?.payments?.Transaction?.createdAt).toLocaleDateString()} onChange="" readOnly disabled />
                </Form.Group>
              </Col>
            </Row>

            {/* row2 */}
            <Row className="mt-4">
              <Col xs="4">
                <Form.Group>
                  <Form.Label className="text-label">Wholesaler Store</Form.Label>
                  <Form.Control type="text" className="invoices-input mt-2" value={invoicesData?.payments?.Transaction?.Wstore?.nickname} onChange="" readOnly disabled />
                </Form.Group>
              </Col>
              <Col xs="4">
                <Form.Group>
                  <Form.Label className="text-label">Paid/Notpaid</Form.Label>
                  <Form.Control type="text" className="invoices-input mt-2" value={invoicesData?.payments?.status} onChange="" readOnly disabled />
                </Form.Group>
              </Col>
              <Col xs="4">
                <Form.Group>
                  <Form.Label className="text-label">Status</Form.Label>
                  <Form.Control type="text" className="invoices-input mt-2" value={invoicesData?.payments?.Transaction?.status} onChange="" readOnly disabled />
                </Form.Group>
              </Col>
            </Row>

            {/* row3 */}
            <Row className="mt-4">
              <Col xs="4">
                <Form.Group>
                  <Form.Label className="text-label">wholesaler Total</Form.Label>
                  <Form.Control type="text" className="invoices-input mt-2" value={invoicesData?.payments?.Transaction?.price} onChange="" readOnly disabled />
                </Form.Group>
              </Col>
              <Col xs="4">
                <Form.Group>
                  <Form.Label className="text-label">Retailer Total</Form.Label>
                  <Form.Control type="text" className="invoices-input mt-2" value={invoicesData?.payments?.price} onChange="" readOnly disabled />
                </Form.Group>
              </Col>
              <Col xs="4">
                <Form.Group>
                  <Form.Label className="text-label">Loan id</Form.Label>
                  <Form.Control type="text" className="invoices-input mt-2" value={invoicesData && invoicesData.installment && invoicesData.installment[0] && invoicesData.installment[0].loanId} onChange="" readOnly disabled />
                </Form.Group>
              </Col>
            </Row>

            {/* row4 */}
            <Row className="mt-4">
              <Col xs="4">
                <Form.Group>
                  <Form.Label className="text-label">Number of installments</Form.Label>
                  <Form.Control type="text" className="invoices-input mt-2" value={invoicesData?.numOfInstallments} onChange="" readOnly disabled />
                </Form.Group>
              </Col>
              <Col xs="4">
                <Form.Group>
                  <Form.Label className="text-label">payment plan</Form.Label>
                  <Form.Control type="text" className="invoices-input mt-2" value={invoicesData && invoicesData.installment && invoicesData.installment[0] && invoicesData.installment[0].PaymentPlan && invoicesData.installment[0].PaymentPlan.name} onChange="" readOnly disabled />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>

        <hr />
        {/* invoices image lw mwgoda lazm a3mlha condition lw mwgoda aw l2 */}
        {!invoiceImgValidity && invoicesData?.payments?.Transaction?.invoiceImage

          ? (
            <Container fluid className="mt-5">
              <Row>
                <Col xs="5">
                  <a href={invoicesData?.payments?.Transaction?.invoiceImage} target="_blank">
                    <img onError={() => { setInvoiceImgValidity(true); }} src={invoicesData?.payments?.Transaction?.invoiceImage} alt="invoice img" className="img-fluid invoice-img" />
                  </a>
                  {/* <br />
                                <span className="text-label" style={{ marginLeft: "1.5vw" }}>Invoice image</span> */}
                </Col>
              </Row>
            </Container>
          )
          : null}

        {/* table invoice */}
        <h5 className="account-profile-titles mt-5">Invoice</h5>
        <Container fluid className="mt-5">
          <Table responsive>
            <thead className="text-center">
              <tr className="role-table-header">
                <th className="text-center">Item</th>
                <th className="text-center">Amount</th>
                <th className="text-center">Quantity</th>
                <th className="text-center">Total</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {invoicesData && invoicesData.payments && invoicesData.payments.Transaction && invoicesData.payments.Transaction.itemJS && invoicesData.payments.Transaction.itemJS.map((i) => (
                <tr key={i.id}>
                  <td className="text-center">{i && i.itemName && i.itemName}</td>
                  <td className="text-center">{i && i.price && i.price}</td>
                  <td className="text-center">{i && i.quantity && i.quantity}</td>
                  <td className="text-center">{i && i.ItemtotPrice && i.ItemtotPrice}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>

        {/* table installments */}
        <h5 className="account-profile-titles mt-5">Installments</h5>
        <Container fluid className="mt-5 agelFontFamily">
          <Table responsive>
            <thead className="text-center">
              <tr className="role-table-header">
                <th className="text-center">Id</th>
                <th className="text-center">Date</th>
                <th className="text-center">Installment amount</th>
                <th className="text-center">paid price</th>
                <th className="text-center">penality</th>
                <th className="text-center">status</th>
                <th className="text-center">delay days</th>

              </tr>
            </thead>
            <tbody className="text-center">
              {invoicesData && invoicesData.installment && invoicesData.installment.map((i) => (
                <tr key={i?.id}>
                  <td className="text-center">{i?.id}</td>
                  <td className="text-center">{i.paymentDate ? JSON.stringify(i.paymentDate).replaceAll('"', '') : null}</td>
                  <td className="text-center">{i?.totalPrice}</td>
                  <td className="text-center">{i?.paidPrice}</td>
                  <td className="text-center">{i?.penality}</td>
                  <td className="text-center" style={i.status ? { color: "#00BD85" } : { color: "red" }}>{`${i?.status}`}</td>
                  <td className="text-center">{i?.delayDays}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>

        {/* notes */}
        <Container fluid className="mt-5  mb-5">
          <div>
            <Row>

              {singleNote && (
              <Col className="d-flex justify-content-start align-items-center">
                <strong className="add-note-title">Notes</strong>
              </Col>

              )}

              <Col className="d-flex justify-content-end align-items-center" style={{ marginRight: "9%" }}>
                <button
                  type="button"
                  className="add-note-btn"
                  onClick={() => {
                    setAddNote(true);
                  }}
                >
                  <AiFillPlusCircle className="add-note-icon" />
                  add note
                </button>
              </Col>

            </Row>

            <Row>
              <Col>
                {!loadMore
                  ? (
                    <div>
                      {singleNote
                        && (
                        <div className="note-container" style={{ width: "70%" }}>
                          <div className="d-flex">
                            <p className="note-title-wrapper">
                              By :
                              {' '}
                              {singleNote && singleNote.By}
                            </p>
                            <p className="note-title-wrapper">
                              Date :
                              {' '}
                              {new Date(singleNote && singleNote.createdAt).toLocaleDateString()}
                            </p>
                            <p className="note-title-wrapper">
                              Time :
                              {new Date(singleNote && singleNote.createdAt).getHours()}
                              :
                              {new Date(singleNote && singleNote.createdAt).getMinutes()}
                              :
                              {new Date(singleNote && singleNote.createdAt).getSeconds()}
                            </p>
                          </div>
                          <div className="text-center mt-4 mb-2">
                            <p className="note-main-text">
                              {singleNote && singleNote.note}
                            </p>
                          </div>
                        </div>
                        )}
                    </div>
                  )
                  : (
                    <div>
                      {notes && notes.map((note) => (
                        <div className="note-container" style={{ width: "70%" }}>
                          <div className="d-flex">
                            <p className="note-title-wrapper">
                              By :
                              {' '}
                              {note.By}
                            </p>
                            <p className="note-title-wrapper">
                              Date :
                              {' '}
                              {new Date(note.createdAt).toLocaleDateString()}
                            </p>
                            <p className="note-title-wrapper">
                              Time :
                              {new Date(note.createdAt).getHours()}
                              :
                              {new Date(note.createdAt).getMinutes()}
                              :
                              {new Date(note.createdAt).getSeconds()}
                            </p>
                          </div>
                          <div className="text-center mt-4 mb-2">
                            <p className="note-main-text">
                              {note.note}
                            </p>
                          </div>
                        </div>
                      ))}

                    </div>
                  )}

              </Col>
            </Row>

            {
                            singleNote
                            && (
                            <Row>
                              <Col style={{ cursor: "pointer", display: loadMoreVisiblity, marginLeft: "30%" }} className="mt-2" onClick={() => { setLoadMore(true); setLoadMoreVisiblity("none"); }}>
                                <span className="load_more_text text-start">load more</span>
                                <img src="/images/loadMoreArrows.png" alt="" style={{ width: "1.5vw", marginTop: "-0.1vw" }} className="img-fluid" />
                              </Col>
                            </Row>

                            )
                        }

            {
                            addNote
                              ? (
                                <AddNoteModal
                                  setAddNote={setAddNote}
                                  paymentId={paymentId}
                                  id={retailerId}
                                  setSuccessModal={setSuccessModal}
                                  setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                  setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                  updateData={fetchData}
                                />
                              )
                              : null
                        }
          </div>

        </Container>

        {/* success server response model */}
        {
                    successModal
                      ? (
                        <SuccessModal
                          setSuccessModal={setSuccessModal}
                          updateData={fetchData}
                        />
                      )
                      : null
                }

        {/* failed server response model with error msg */}

        {failedModalWithErrorMsg
          ? (
            <Failed_Modal
              setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
              errorMsg={errorMsgInFailedModal}
            />
          )
          : null}

      </div>
    );
  }

  return (
    <AuthorizationRequiredScreen />
  );
}

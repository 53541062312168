import React, { useState } from 'react'

import { Modal, Form, Container, Row, Col } from "react-bootstrap"
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


const NewShipment = (props) => {

    const {
        setNewShipmentModal,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        updateData
    } = props


    const [addNewShipment, setAddNewShipment] = useState("")

    //Done btn state checker
    const [newShipmentBtn, setNewShipmentBtn] = useState(false)


    //submit adjust
    const Submit = async (e) => {

        e.preventDefault();
        setNewShipmentBtn(true)
        var data = {
            'total': parseInt(addNewShipment)
        }
        try {
            /* add new shipment */
            await putToAgel(`${process.env.React_APP_ADD_NEW_SHIPMENT}`, data)
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        updateData()
        setNewShipmentModal(false)
    }

    return (
        <div>
            <Modal show={true} onHide={() => setNewShipmentModal(false)} centered size="lg" style={{ marginTop: "5em" }} className="posActionModal">
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <span className="dashboard-font">
                            <strong>
                                New shipment
                            </strong>
                        </span>
                        {/* form */}
                        <Form onSubmit={Submit}>
                            <Row className='mt-2'>
                                <Col xs="12">
                                    <Form.Group>
                                        <Form.Control type="text" className="newShipment-input" value={addNewShipment} onChange={(e) => setAddNewShipment(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Modal.Footer className='d-flex justify-content-end align-items-center mt-4' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={newShipmentBtn ? true : false}>
                                    {newShipmentBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>
                                <button className="roleCloseBtn" onClick={() => setNewShipmentModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default NewShipment

export const color = {

    home: {
        name: "Home",
        path: "/",
        icon: "home.png",
        selected: '',
        subPages: [
            {
                //home
                name: "Home",
                path: '/',
                selected: 'home'
            },
        ]
    },

    employee: {
        name: "Employee",
        path: "employee/roles",
        icon: "employees.png",
        selected: 'employee',
        subPages: [
            {
                //Roles
                name: "Roles",
                path: 'employee/roles',
                selected: 'roles'
            },
            {
                //Staff
                name: "Staff",
                path: 'employee/staff',
                selected: 'staff'

            },
            {
                //Agents
                name: "Agents",
                path: 'employee/agents',
                selected: 'agents'
            },
            {
                //collectors
                name: "Collectors",
                path: 'employee/collectors',
                selected: 'collectors'
            }
        ]
    },



    zones: {
        name: "Zones",
        path: "zones/countries",
        icon: "zones.png",
        selected: 'zones',
        subPages: [
            {
                //Countries
                name: "countries",
                path: 'zones/countries',
                selected: 'countries'
            },
            {
                //Cities
                name: "cities",
                path: 'zones/cities',
                selected: 'cities'
            },
            {
                //Areas
                name: "Areas",
                path: 'zones/areas',
                selected: 'areas'
            }

        ]
    }
    ,

    products: {
        name: "Products",
        path: "products/industries",
        icon: "products.png",
        selected: 'products',
        subPages: [

            {
                //Industries
                name: "Industries",
                path: 'products/industries',
                selected: 'industries'
            }
        ]
    },

    wholesalers: {
        name: "Wholesalers",
        path: "wholesalers/list",
        icon: "ws-img.png",
        selected: 'wholesalers',
        subPages: [{
            //Ws Accounts
            name: "Accounts",
            path: 'wholesalers/list',
            selected: 'list'
        },
        {
            //Activation Process
            name: "Activation Process",
            path: 'wholesalers/WsActivationProcess',
            selected: 'WsActivationProcess'

        }
        ]
    },

    retailers: {
        name: "Retailers",
        path: "retailers/list",
        icon: "retailer.png",
        selected: 'retailers',
        subPages: [
            {
                //retailer Accounts
                name: "Accounts",
                path: 'retailers/list',
                selected: 'list'
            },
            {
                //Activation Process
                name: "Activation Process",
                path: 'retailers/RetailerActivationProcess',
                selected: 'RetailerActivationProcess'
            }
        ]
    },

    stock: {
        name: "Pos Stock",
        path: "stock/posStock",
        icon: "posStock.png",
        selected: 'stock',
        subPages: [
            {
                //Pos Stock
                name: "Pos Stock",
                path: 'stock/posStock',
                selected: 'posStock'
            },
        ]
    },


    retention: {
        name: "Retention",
        path: "retention/wholesalerRetentionQueue",
        icon: "retention.png",
        selected: 'retention',
        subPages: [
            {
                //ws Retention queue
                name: "Wholesaler",
                path: 'retention/wholesalerRetentionQueue',
                selected: 'wholesalerRetentionQueue'
            },
            {
                //retailer Retention queue
                name: "Retailer",
                path: 'retention/retailerRetentionQueue',
                selected: 'retailerRetentionQueue'
            },
        ]
    },

    report: {
        name: "Report",
        path: "report/paymentReport",
        icon: "report.png",
        selected: 'Report',
        subPages: [
            {
                //payment report extractor
                name: "Report",
                path: 'report/paymentReport',
                selected: 'paymentReport'
            },
        ]
    },

    otp: {
        name: "OTP",
        path: "otp/generateOtp",
        icon: "otp.png",
        selected: 'OTP',
        subPages: [

            {
                //otp
                name: "Generate OTP",
                path: 'otp/generateOtp',
                selected: 'generateOtp'
            }
        ]
    },

    compliance: {
        name: "Compliance",
        path: "compliance/relation",
        icon: "compliance.png",
        selected: 'compliance',
        subPages: [

            {
                //relation
                name: "Relation",
                path: 'compliance/relation',
                selected: 'relation'
            }
        ]
    },



    // acquisition: {
    //     name: "Acquisition",
    //     path: "acquisition/wholesaler",
    //     icon: "acquisition.png",
    //     selected: 'acquisition',
    //     subPages: [
    //         {
    //             //ws acquisition
    //             name: "Wholesaler",
    //             path: 'acquisition/wholesaler',
    //             selected: 'wholesaler'
    //         },
    //         {
    //             //retailer acquisition
    //             name: "Retailer",
    //             path: 'acquisition/retailer',
    //             selected: 'retailer'
    //         },
    //     ]
    // },
}
import React from 'react';
import { Table, Container } from 'react-bootstrap';

//icons
import { AiOutlineEdit } from 'react-icons/ai'
import { IoRefreshOutline } from 'react-icons/io5'


const StaffTable = (props) => {
    //props
    let {
        staff,
        // cities,
        // roles,
        setEditStaffModal,
        setCurrentStaffModal,
        setResetPasswordStaffModal,
        // deletestaff
    } = props;


    return (
        <div  className='tables-layout'>
            <Container fluid>
                <Table responsive>
                <thead className='text-center'>
                    <tr className='role-table-header'>
                    <th className="text-center" >ID</th>
                        <th className="text-center" >Name</th>
                        <th className="text-center" >Email</th>
                        <th className="text-center" >CreatedDate</th>
                        <th className="text-center" >City</th>
                        <th className="text-center" >Role</th>
                        <th className="text-center" >Status</th>
                        {/* <th>Phone Number</th>
                        <th>Role</th> */}
                        {/* <th>Actions</th> */}
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {staff.map((index) =>
                    <tr key={index.id} className='role-table-text'>
                            <td className="text-center" >{index.id}</td>
                            <td className="text-center" >{index.name}</td>
                            <td className="text-center" >{index.email}</td>
                            <td className="text-center" >
                                {
                                     (new Date(index.createdAt&&index.createdAt).toLocaleDateString())
                                }
                            </td>
                            <td className="text-center" >{index.City&&index.City.EnName}</td>
                            <td className="text-center" >{index.Role&&index.Role.name}</td>
                            <td className='text-center' style={index.isActive?{color:"#00BD85"}:null}>{index.isActive?("Active"):("Inactive")}</td>
                            {/* <td className="text-center" >{index.phoneNumber}</td>*/}
                            
                            <td className="text-center d-flex" >
                            <button className='staff-edit-btn' onClick={() => {
                                        setEditStaffModal(true);
                                        setCurrentStaffModal(index)
                                    }}>
                                    Edit <AiOutlineEdit/>
                                </button>
                                <button className='reset-password-btn-staff' onClick={() => {
                                        setResetPasswordStaffModal(true);
                                        setCurrentStaffModal(index)
                                    }}>
                                        Reset <IoRefreshOutline />
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            </Container>
        </div>
    )
}

export default StaffTable

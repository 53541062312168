import React, { useState } from 'react'
import { Form, Modal, Container } from 'react-bootstrap';
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

export default function EditRelationStatus(props) {
    const {
        setEditRelationStatusModal,
        currentRelation,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props


    const [editStatus, setEditStatus] = useState("")

    //state checker on buttons 
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)

    // edit relation status
    const editRelationStatus = async (e) => {

        e.preventDefault();
        setSaveEditsBtn(true);

        var data = {
            'status': editStatus === "" ? currentRelation.status : editStatus,
        }
        try {
            await putToAgel(`/dashboard/transactionRelation/${currentRelation?.id}`, data)
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        updateData()
        setEditRelationStatusModal(false)
        setSaveEditsBtn(false)
    }

    //update status fn to fit BE needs active/inactive
    const updateStatus = (e) => {
        e.target.checked === true ? setEditStatus("active") : setEditStatus("inactive")
    }


    return (
        <div>
            <Modal show={true} onHide={() => setEditRelationStatusModal(false)} centered>
                <Modal.Header closeButton className="cancel-msg-modal-header">
                    Edit relation status
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Form onSubmit={editRelationStatus}>

                            <Form.Group className="mb-5">
                                <Form.Check type="checkbox" className=" status mt-2 compliance-status agelFontFamily" label="Is active" defaultChecked={currentRelation.status === "active" ? true : false} onChange={updateStatus} />
                            </Form.Group>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={saveEditsBtn ? true : false}>
                                    {saveEditsBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>
                                <button className="roleCloseBtn" onClick={() => setEditRelationStatusModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>

        </div>
    )
}
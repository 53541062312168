import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { VscError } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { login } from "../../utils/auth";

import "./Login.css";
import LoadingBtnSpinner from "../../components/common/LoadingBtn/LoadingBtn";
import FailedModal from "../../components/common/FailedModal";

export default function Login(props) {
  const { state } = useLocation();
  const prevLocation = state.location.pathname;

  // permissions object fill process
  const { setUserPermissionsAndActions } = props;

  const [phoneValue, setPhone] = useState("");
  const [passwordValue, setPassword] = useState("");
  const [passValidity, setPassValidity] = useState(false);

  // sign in btn state checker
  const [signInBtn, setSignInBtn] = useState(false);

  // serever response state

  const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false);
  const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    if (token) {
      navigate("/");
    }
  }, []);

  const Submit = async (e) => {
    e.preventDefault();
    setSignInBtn(true);

    if (passwordValue.length >= 8) {
      setPassValidity(false);
      const data = {
        phoneNumber: phoneValue,
        password: passwordValue,
      };
      await login(data).then((loginResponse) => {
        if (loginResponse?.data) {
          // if true => return permission/actions for this user
          setUserPermissionsAndActions(loginResponse.data);
          localStorage.setItem(
            "PermissionsInlocalStorage",
            JSON.stringify(loginResponse.data),
          );
          navigate(prevLocation);
          setSignInBtn(false);
        } else {
          setSignInBtn(false);
          setErrorMsgInFailedModal(loginResponse?.response?.data?.message);
          setFailedModalWithErrorMsg(true);
        }
      });
    } else {
      setSignInBtn(false);
      setPassValidity(true);
    }
  };

  return (
    <div className="login-container">
      {/* row1 */}
      <Row>
        <Col xs="12">
          <div className="d-flex justify-content-end align-items-center">
            <img src="/images/Decorator-top.png" className="decorator" />
          </div>
        </Col>
      </Row>

      {/* row2 */}
      <Row className="text-center d-flex justify-content-center align-items-center">
        <Col xs="10">
          <Row>
            {/* col1 */}
            <Col
              lg="6"
              md="12"
              className="d-flex justify-content-center align-items-center"
            >
              <div className="">
                <img
                  src="/images/sign-in.png"
                  alt="sign-in-img"
                  className="img-fluid sign-in-img"
                />
              </div>
            </Col>

            {/* col2 */}
            <Col lg="6" md="12" className="text-center">
              {/* logo img */}
              <div className="d-lg-flex justify-content-end align-items-center d-none ">
                <img
                  src="/images/logo.png"
                  alt="logo-img"
                  className="logo-img"
                />
              </div>
              {/* form */}
              <Container className="text-start">
                <Form onSubmit={Submit}>
                  <Form.Group className="mt-5">
                    <Form.Label className="phone-label">
                      Phone number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone number"
                      className="phone-btn"
                      value={phoneValue}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mt-5">
                    <Form.Label className="password-label">Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter Password"
                      className="password-btn"
                      value={passwordValue}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                  {/* check password length validity  */}
                  {passValidity && (
                    <span className="dashboard-error-msg mt-5">
                      <VscError />
                      Password should be at least 8 digits.
                    </span>
                  )}

                  <button
                    className="submit-btn d-flex justify-content-center align-items-center mt-5"
                    type="submit"
                    disabled={!!signInBtn}
                  >
                    {signInBtn ? <LoadingBtnSpinner /> : <span>sign in</span>}
                  </button>
                </Form>
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* row3 */}
      <Row>
        <Col xs="12">
          <div className="d-flex justify-content-start align-items-center">
            <img src="/images/Decorator-bottom.png" className="decorator" />
          </div>
        </Col>
      </Row>

      {failedModalWithErrorMsg
        ? (
          <FailedModal
            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
            errorMsg={errorMsgInFailedModal}
          />
        )
        : null}
    </div>
  );
}

import React, { useState, useEffect } from 'react'
import { Modal, Form, Container, Row, Col } from 'react-bootstrap';
import { deleteFromAgel, getFromAgel, putToAgel } from '../../utils/api';
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


const EditRoleModal = (props) => {

    //props


    let {
        setShowEdit,
        currentEditRoleId,
        allPermissions,
        currentSelectedPermission,
        setCurrentSelectedPermission,
        actionsOfSpecificPermission,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props

    //current selected role
    const [currentEditRole, setCurrentEditRole] = useState({})

    //edit btn values
    const [editName, setEditName] = useState('');
    const [editStatus, setEditStatus] = useState("");
    const [showActionList, setShowActionList] = useState(true)
    const [addedPermissionAndActionsName, setAddedPermissionAndActionsName] = useState({})
    const [removedPermissionAndActionsName, setRemovedPermissionAndActionsName] = useState({})
    const [allowRadioButtons, setAllowRadioButtons] = useState(true)
    const [radioAddCheckFlag, setRadioAddCheckFlag] = useState(false)
    const [radioRemoveCheckFlag, setRadioRemoveCheckFlag] = useState(false)
    const [retrievedPermissionsAndActions, setRetrievedPermissionsAndActions] = useState([])

    //state checker on buttons 
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)



    //el data el hatgele m el id el hab3to ll back bta3 el role el mo3yn da 34an ygble el permissions btat3o kolha
    useEffect(() => {
        async function fetchData() {
            try {
                // get specific role
                const currentEditRoleResponse = await getFromAgel(`${process.env.React_APP_GET_SPECIFIC_ROLES}${currentEditRoleId}`)
                setCurrentEditRole(currentEditRoleResponse.data.role)
                setRetrievedPermissionsAndActions(currentEditRoleResponse.data.role.permissions)
            }
            catch (err) {
                // console.log('error=', err)
            }
        }
        fetchData()
    }, [])



    //submit edits
    const editSubmit = async (e) => {

        e.preventDefault();
        setSaveEditsBtn(true);

        var addedData = {
            ...(editName ? { name: editName } : {}),
            "isActive": editStatus === "" ? currentEditRole.isActive : editStatus,
            'actions': addedPermissionAndActionsName,
        }

        //deleted data bs lazm ta5od esm var "data" 34an yb3t payload f el network wytms7 mn el data base 8er kda m4 hayb3t 7aga fa m4 hayms7 7aga wlazm a7otha ben {}
        var data = {
            'actions': removedPermissionAndActionsName,
        }

        try {
            //add permission and actions
            if (Object.keys(addedPermissionAndActionsName).length !== 0 || editName !== "" || editStatus !== "") {
                await putToAgel(`${process.env.React_APP_ADDED_PERMISSION_ACTIONS_EDIT_ROLE}${currentEditRole.id}`, addedData); //api el add
            }

            //remove permission and actions
            Object.keys(removedPermissionAndActionsName).length !== 0 && await deleteFromAgel(`${process.env.React_APP_DELETED_PERMISSION_ACTIONS_EDIT_ROLE}${currentEditRole.id}`, { data }) //api el delete

            setSuccessModal(true)
        }
        catch (error) {
            setErrorMsgInFailedModal(error?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        updateData()
        setShowEdit(false)
    }



    //choose a permission to add or delete
    const SelectPermissionToAddOrDelete = (e) => {
        let selectedPermissionName = e.label
        setCurrentSelectedPermission(selectedPermissionName)
        setAllowRadioButtons(false)
        setRadioAddCheckFlag(false)
        setRadioRemoveCheckFlag(false)
        setShowActionList(true) //34an may5tr4 action 2ela lma y5tar case added/removed ll permission
    }

    const addNewPermission = () => {

        setRadioAddCheckFlag(true)
        setShowActionList(false)

        if (!(addedPermissionAndActionsName[currentSelectedPermission])) {
            addedPermissionAndActionsName[currentSelectedPermission] = []
            setAddedPermissionAndActionsName({ ...addedPermissionAndActionsName })
        }

        //removing same permission if it was added in the removed list
        if (removedPermissionAndActionsName[currentSelectedPermission]) {
            delete removedPermissionAndActionsName[currentSelectedPermission];
            setRemovedPermissionAndActionsName({ ...removedPermissionAndActionsName })
        }

    }

    const removePermission = (e) => {
        setRadioRemoveCheckFlag(true)
        setShowActionList(false)

        if (!(removedPermissionAndActionsName[currentSelectedPermission])) {
            removedPermissionAndActionsName[currentSelectedPermission] = []
            setRemovedPermissionAndActionsName({ ...removedPermissionAndActionsName })
        }

        //removing permission if it was added in the added list
        if (addedPermissionAndActionsName[currentSelectedPermission]) {
            delete addedPermissionAndActionsName[currentSelectedPermission];
            setAddedPermissionAndActionsName({ ...addedPermissionAndActionsName })
        }


    }


    //select action 
    const onSelectActions = (selectedList, selectedItem) => {


        //incase permission is in add list
        if (addedPermissionAndActionsName[currentSelectedPermission]) {
            if (!addedPermissionAndActionsName[currentSelectedPermission].includes(selectedItem.name)) {
                addedPermissionAndActionsName[currentSelectedPermission].push(selectedItem.name)
                setAddedPermissionAndActionsName({ ...addedPermissionAndActionsName })
            }
        }

        //incase permission is in removed list
        else if (removedPermissionAndActionsName[currentSelectedPermission]) {
            if (!removedPermissionAndActionsName[currentSelectedPermission].includes(selectedItem.name)) {
                removedPermissionAndActionsName[currentSelectedPermission].push(selectedItem.name)
                setRemovedPermissionAndActionsName({ ...removedPermissionAndActionsName })
            }
        }

    }

    //remove action
    const onRemoveActions = (selectedList, selectedItem) => {
        let remainedItemsAfterDeletion = []


        //incase permission is in add list
        if (addedPermissionAndActionsName[currentSelectedPermission]) {
            remainedItemsAfterDeletion = addedPermissionAndActionsName[currentSelectedPermission].filter((i) => i !== selectedItem.name)
            addedPermissionAndActionsName[currentSelectedPermission] = remainedItemsAfterDeletion
            setAddedPermissionAndActionsName({ ...addedPermissionAndActionsName })
        }

        //incase permission is in removed list
        else if (removedPermissionAndActionsName[currentSelectedPermission]) {
            remainedItemsAfterDeletion = removedPermissionAndActionsName[currentSelectedPermission].filter((i) => i !== selectedItem.name)
            removedPermissionAndActionsName[currentSelectedPermission] = remainedItemsAfterDeletion
            setRemovedPermissionAndActionsName({ ...removedPermissionAndActionsName })
        }

    }


    return (
        <div>

            <Modal show={true} onHide={() => setShowEdit(false)} size="lg" className="dashboard-modal" style={{ marginTop: "4.5rem" }} centered >
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={editSubmit}>
                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Role Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" style={{ width: "50%", borderRadius: "5px" }} placeholder="Enter Name" defaultValue={currentEditRole.name} onChange={(e) => setEditName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group>
                                <Form.Check type="checkbox" className="status form-text mt-3" label={"Active"}
                                    defaultChecked={currentEditRole.isActive} onClick={(e) => setEditStatus(e.target.checked)}
                                />
                            </Form.Group>


                            {/* select permission */}
                            <Form.Group className="mt-4">
                                <Row className='d-flex align-items-center'>
                                    <Col xs="6">
                                        <Form.Label className="form-text">
                                            permissions
                                        </Form.Label>
                                    </Col>
                                    <Col xs="6">
                                        <Select
                                            className="role-permissions-select"
                                            defaultValue={{ "value": 0, "label": " select..." }}
                                            options={allPermissions?.map((permission) => ({ "value": permission?.id, "label": permission?.name }))}
                                            onChange={SelectPermissionToAddOrDelete}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            {/* select actions */}
                            <Form.Group className="mt-4">
                                <Row className='d-flex align-items-center'>
                                    <Col xs="6">
                                        <Form.Label className="form-text">
                                            Actions
                                        </Form.Label>
                                    </Col>
                                    <Col xs="6">
                                        <div className="role-multiselect-actions">
                                            <Multiselect
                                                options={actionsOfSpecificPermission}
                                                displayValue="name"
                                                placeholder="select..."
                                                // selectedValues={[{ name: 'act 1', id: 1 }, { name: 'act 2', id: 2 }]}
                                                onSelect={onSelectActions}
                                                onRemove={onRemoveActions}
                                                showCheckbox={true}
                                                disable={showActionList}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Form.Group>

                            {/* added permissions and actions */}

                            <Form.Group className="mt-4">
                                <Row className='d-flex justify-content-center align-items-center'>

                                    {/* added part */}
                                    <Col xs="6">
                                        <Form.Check
                                            className="form-text"
                                            type="radio"
                                            label="Added"
                                            name="permission"
                                            onChange={addNewPermission}
                                            checked={radioAddCheckFlag}
                                            disabled={allowRadioButtons}
                                        />

                                        <div className="role-permission-list" style={{ padding: "0.3rem 0.5rem", minHeight: "3rem" }}>

                                            {Object.keys(addedPermissionAndActionsName).length === 0 ?

                                                //incase no permission is added yet
                                                <span className='permission-sub-list'>
                                                    no added yet!
                                                </span>
                                                ://incase user choose permission
                                                <ol>
                                                    {Object.keys(addedPermissionAndActionsName).map((i) => (
                                                        <>
                                                            {/* permission name */}
                                                            <li className='mt-3'>{i}</li>
                                                            {/* actions list */}
                                                            <ul>
                                                                {Object.values(addedPermissionAndActionsName[i]).map((action) =>
                                                                    <li>
                                                                        <div>
                                                                            <span className='permission-sub-list'>
                                                                                {action}
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </>
                                                    ))}
                                                </ol>
                                            }
                                        </div>
                                    </Col>

                                    {/* removed part */}
                                    <Col xs="6">
                                        <Form.Check
                                            className="form-text"
                                            type="radio"
                                            label="Removed"
                                            name="permission"
                                            onChange={removePermission}
                                            checked={radioRemoveCheckFlag}
                                            disabled={allowRadioButtons}
                                        />
                                        <div className="role-permission-list" style={{ padding: "0.3rem 0.5rem", minHeight: "3rem" }}>

                                            {Object.keys(removedPermissionAndActionsName).length === 0 ?

                                                //incase no permission is added yet
                                                <span className='permission-sub-list'>
                                                    no removed yet!
                                                </span>
                                                ://incase user choose permission
                                                <ol>
                                                    {Object.keys(removedPermissionAndActionsName).map((i) => (
                                                        <>
                                                            {/* permission name */}
                                                            <li className='mt-3'>{i}</li>
                                                            {/* actions list */}
                                                            <ul>
                                                                {Object.values(removedPermissionAndActionsName[i]).map((action) =>
                                                                    <li>
                                                                        <div>
                                                                            <span className='permission-sub-list'>
                                                                                {action}
                                                                            </span>
                                                                        </div>

                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </>
                                                    ))}
                                                </ol>
                                            }
                                        </div>
                                    </Col>

                                </Row>
                            </Form.Group>

                            {/* retrieved part */}
                            <div className="mt-4">
                                <span className='form-text'>
                                    Retrieved permissions and actions
                                </span>
                                <ol className="role-permission-list mt-1" style={{ minHeight: "6rem" }}>
                                    {retrievedPermissionsAndActions.map((permission) => (
                                        <div key={permission.id}>

                                            {/* permission name */}
                                            <li className='mt-3'>{permission.name}</li>

                                            {/* actions list */}
                                            <ul>
                                                {permission.permissionActions?.map((action) =>
                                                    <li key={action.id} className='permission-sub-list'>
                                                        {action.name}
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    ))
                                    }
                                </ol>
                            </div>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={saveEditsBtn ? true : false}>
                                    {saveEditsBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setShowEdit(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>


        </div>
    )
}

export default EditRoleModal

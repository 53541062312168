import React from 'react'
import { Table, Container } from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai'

const AreasTable = (props) => {
    // props
    const {
        areas,
        cities,
        setEditAreas,
        setCurrent,
    } = props

    return (
        <div className='tables-layout'>
            {/* Table */}
            <Container fluid>
                <Table responsive>
                    <thead className='text-center'>
                        <tr className='role-table-header'>
                            <th className="text-center" >ID</th>
                            <th className="text-center" >Arabic Name</th>
                            <th className="text-center" >English Name</th>
                            <th className="text-center" >City</th>
                            <th className="text-center" >Status</th>
                            <th className="text-center" ></th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {areas && areas.map((i) =>
                            <tr key={i.id} className='role-table-text'>
                                <td className="text-center">{i && i.id}</td>
                                <td className="text-center">{i && i.ArName}</td>
                                <td className="text-center">{i && i.EnName}</td>

                                <td className="text-center">
                                    {cities && cities.map((city) => {
                                        if (city.id === i.CityId) {
                                            return (
                                                <p key={city.id}>{city.EnName}</p>
                                            )
                                        }
                                        return null;
                                    }
                                    )}
                                </td>
                                <td className='text-center' style={i.isActive ? { color: "#00BD85" } : null}>{i.isActive ? ("Active") : ("Inactive")}</td>
                                <td className='text-center'>
                                    <button
                                        className='role-edit-btn-cities'
                                        style={{ width: "50%" }}
                                        onClick={() => {
                                            setEditAreas(true);
                                            setCurrent(i);
                                        }}>
                                        Edit <AiOutlineEdit />
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default AreasTable

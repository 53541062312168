import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import { getFromAgel } from '../../utils/api';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';
import { userPermissionsAndActionsContext } from '../../App';

//components
import BasicInfo from '../../components/Wholesalers/BasicInfo'
import WsCreateInvoice from '../../components/Wholesalers/WsCreateInvoice'
import SideBar from '../../components/Wholesalers/SideBar'
import PaymentsDetails from '../../components/Wholesalers/PaymentsDetails'
import Stores from '../../components/Wholesalers/Stores'
import UpdateProfileIdImg from '../../components/Wholesalers/UpdateIdImages';
import WsCreateInvoiceSecondModal from '../../components/Wholesalers/WsCreateInvoiceModal2';
import Failed_Modal from '../../components/common/FailedModal';
import SuccessModal from '../../components/common/SuccessModal';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen';



export default function WsProfile(props) {

  const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
  let viewWsProfileDetailsPermission = userValidPermissionAndAction(validPermissionsAndActions, "wholesaler", "viewWholesalerById")
  let viewStoreTablePermission = userValidPermissionAndAction(validPermissionsAndActions, "wholesalerStores", "viewRStoresByWholesalerId")
  let viewPaymentTablePermission = userValidPermissionAndAction(validPermissionsAndActions, "wholesalerPayment", "viewWholesalerPayment")
  let viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity")
  let viewIndustriesPermission = userValidPermissionAndAction(validPermissionsAndActions, "industry", "viewIndustry")

  // params
  const params = useParams()

  // state
  const [wholesaler, setWholesaler] = useState({})
  const [createInvoicesModal, setCreateInvoicesModal] = useState(false)
  const [createInvoicesModalTwo, setCreateInvoicesModalTwo] = useState(false)
  const [toggleStorePayment, setToggleStorePayment] = useState(false)
  const [wholesalerPayment, setWholesalerPayment] = useState([])
  const [wholesalerStores, setWholesalerStores] = useState([])
  const [imgSrc, setImgSrc] = useState("")
  const [allLabels, setAllLabels] = useState([])
  const [industries, setIndustries] = useState([])
  const [cities, setCities] = useState([])
  const [wsLabels, setWsLabels] = useState([])
  const [wsUpdatedLabels, setWsUpdatedLabels] = useState([])
  const [notes, setNotes] = useState([])
  const [update_id_images_modal, set_update_id_images_modal] = useState(false)
  const [current_selected_id_image, set_current_selected_id_image] = useState(false)
  const [wsDiscount, setWsDiscount] = useState("")
  const [sumOfTotalOfAllItems, setSumOfTotalOfAllItems] = useState("")
  const [transactionId, setTransactionId] = useState("")
  const [serialNumber, setSerialNumber] = useState("")

  const [ws_front_id_image, set_ws_front_id_image] = useState(false)
  const [ws_back_id_image, set_ws_back_id_image] = useState(false)
  const [frontIdcorruptedImg, setFrontIdcorruptedImg] = useState(false)
  const [backIdcorruptedImg, setBackIdcorruptedImg] = useState(false)

  //server modal response success/failed
  const [successModal, setSuccessModal] = useState(false)
  const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
  const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")



  // fetch wsData
  const fetchData = async () => {
    try {

      /* get ws info account */
      if (viewWsProfileDetailsPermission) {
        let response = await getFromAgel(`${process.env.React_APP_GET_WS_ACCOUNT_INFO}${params.id}/`);
        setWholesaler({ ...response.data.wholesaler })
        setImgSrc(response.data.wholesaler.logo_image)
        setWsLabels(response.data.labels)
        setWsUpdatedLabels(response.data.labels)
        setNotes(response.data.notes)
        // console.log("notes ws data=", response.data.notes)
      }

      //get cities //view cities permission
      if (viewCitiesPermission) {
        const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
        // // console.log("cityResponse=", cityR esponse.data.cities)
        setCities(cityResponse.data.cities)
      }

      //industries //view industries permission
      if (viewIndustriesPermission) {
        const industryResponse = await getFromAgel(`${process.env.React_APP_GET_INDUSTRIES}`);
        // console.log("industryResponse=", industryResponse.data.industries)
        setIndustries(industryResponse.data.industries)
      }

      // payments //view payments permission
      if (viewPaymentTablePermission) {
        let paymentResponse = await getFromAgel(`dashboard/wholesaler/${params.id}/payments`);
        setWholesalerPayment(paymentResponse.data.payments)
      }

      //fetch all labels
      const allLabelsResponse = await getFromAgel(`${process.env.React_APP_GET_LABELS_INSIDE_WS_ACCOUNT}${params.id}`)
      // console.log("allLabelsResponse=", allLabelsResponse.data.labels)
      setAllLabels(allLabelsResponse.data.labels)


      // stores //view stores permission
      if (viewStoreTablePermission) {
        let storeResponse = await getFromAgel(`dashboard/wholesaler/${params.id}/stores`);
        setWholesalerStores(storeResponse.data.stores)
      }

    }
    catch (e) {
      // console.log("error=", e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])


  return (
    <div className="profile-page-layout">

      {/* permission for basic info */}
      {viewWsProfileDetailsPermission ?
        <Row>
          <Col className="side-bar-profile" md={2} >
            <SideBar
              wholesaler={wholesaler}
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              setSuccessModal={setSuccessModal}
              setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
              setErrorMsgInFailedModal={setErrorMsgInFailedModal}
            />
          </Col>

          <Col className="mt-5" md={{ span: 9, offset: 1 }}>
            <BasicInfo
              wholesaler={wholesaler}
              setWholesaler={setWholesaler}
              setSuccessModal={setSuccessModal}
              setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
              setErrorMsgInFailedModal={setErrorMsgInFailedModal}
              allLabels={allLabels}
              wsLabels={wsLabels}
              wsUpdatedLabels={wsUpdatedLabels}
              setWsUpdatedLabels={setWsUpdatedLabels}
              notes={notes}
              setWsDiscount={setWsDiscount}
              industries={industries}
              cities={cities}
              updateData={fetchData}
            />

            {/* id's images */}
            <Row className="mt-5 mb-5 d-flex justify-content-center align-items-center">

              {wholesaler?.WholesalersValifyData?.nationalIdOrPassportImage || wholesaler?.WholesalersValifyData?.nationalIdBackImage ?
                <h5 className='account-profile-titles mb-5'>Wholesaler id images</h5>
                : null
              }

              <Col xs="6" className='d-flex justify-content-center align-items-center'>
                {
                  !frontIdcorruptedImg && (wholesaler?.WholesalersValifyData?.nationalIdOrPassportImage != null) ?
                    <img src={wholesaler?.WholesalersValifyData?.nationalIdOrPassportImage} alt="profile-nationalIdImage" className='img-fluid' style={{ width: "50%" }} onError={() => setFrontIdcorruptedImg(true)} onClick={() => { set_current_selected_id_image(wholesaler?.WholesalersValifyData?.nationalIdOrPassportImage); set_ws_front_id_image(true); set_ws_back_id_image(false); set_update_id_images_modal(true); }} />
                    : null}
              </Col>
              <Col xs="6" className='d-flex justify-content-center align-items-center'>
                {
                  !backIdcorruptedImg && (wholesaler?.WholesalersValifyData?.nationalIdBackImage != null) ?
                    <img src={wholesaler?.WholesalersValifyData?.nationalIdBackImage} alt="profile-nationalIdBackImage" className='img-fluid' style={{ width: "50%" }} onError={() => setBackIdcorruptedImg(true)} onClick={() => { set_current_selected_id_image(wholesaler?.WholesalersValifyData?.nationalIdBackImage); set_ws_front_id_image(false); set_ws_back_id_image(true); set_update_id_images_modal(true); }} />
                    : null
                }
              </Col>
            </Row>

          </Col>

        </Row>
        : <AuthorizationRequiredScreen />
      }

      {/* lower table for payment and stores and create invoice */}
      <Row className="mt-5 m-3">
        {/* 1st col tables */}
        <Col xs="5" className='tables-in-profile-container'>
          <Row>
            <Col xs="6" className='d-flex justify-content-center align-items-center'>
              <button onClick={() => setToggleStorePayment(false)} className={toggleStorePayment === false ? "profile-store-payments-btn-selected" : "profile-store-payments-btn-unselected"}>
                <span>Stores</span>
              </button>
            </Col>
            <Col xs="6" className='d-flex justify-content-center align-items-center'>
              <button onClick={() => setToggleStorePayment(true)} className={toggleStorePayment === true ? "profile-store-payments-btn-selected" : "profile-store-payments-btn-unselected"}>
                <span>Payments</span>
              </button>
            </Col>
          </Row>
        </Col>

        {/* 2nd col invoice button */}
        <Col xs="6" className='d-flex justify-content-end'>
          <button onClick={() => setCreateInvoicesModal(true)} className="create-invoice-btn">
            <span>create invoices</span>
          </button>
        </Col>

      </Row>

      <div className="mb-5">
        {toggleStorePayment ?
          viewPaymentTablePermission ?
            <PaymentsDetails
              wsID={params.id}
              updateData={fetchData}
              wholesalerPayment={wholesalerPayment} />
            :
            <AuthorizationRequiredScreen />
          :
          viewStoreTablePermission ?
            <Stores
              wholesalerStores={wholesalerStores}
            />
            :
            <AuthorizationRequiredScreen />
        }
      </div>

      {/* update profile id's images */}
      {update_id_images_modal ?
        <UpdateProfileIdImg
          set_update_id_images_modal={set_update_id_images_modal}
          imgSrc={current_selected_id_image}
          setImgSrc={set_current_selected_id_image}
          id={params.id}
          ws_front_id_image={ws_front_id_image}
          ws_back_id_image={ws_back_id_image}
          updateData={fetchData}
          setSuccessModal={setSuccessModal}
          setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
          setErrorMsgInFailedModal={setErrorMsgInFailedModal}
        />
        : null
      }

      {/* create transaction modal1 */}
      {createInvoicesModal ?
        <WsCreateInvoice
          setCreateInvoicesModal={setCreateInvoicesModal}
          wholesalerStores={wholesalerStores}
          wholesalerName={wholesaler.name}
          wsID={params.id}
          wsDiscount={wsDiscount}
          createInvoicesModalTwo={createInvoicesModalTwo}
          setCreateInvoicesModalTwo={setCreateInvoicesModalTwo}
          setSumOfTotalOfAllItems={setSumOfTotalOfAllItems}
          setTransactionId={setTransactionId}
          setSerialNumber={setSerialNumber}
          setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
          setErrorMsgInFailedModal={setErrorMsgInFailedModal}
        />
        : null}

      {/*  open transaction modal2 */}
      {createInvoicesModalTwo ?
        (<WsCreateInvoiceSecondModal
          sumOfTotalOfAllItems={sumOfTotalOfAllItems}
          setCreateInvoicesModalTwo={setCreateInvoicesModalTwo}
          setCreateInvoicesModal={setCreateInvoicesModal}
          serialNumber={serialNumber}
          transactionId={transactionId}
          setSuccessModal={setSuccessModal}
          setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
          setErrorMsgInFailedModal={setErrorMsgInFailedModal}
        />)
        : null
      }
      {/* success server response model */}
      {
        successModal ?
          <SuccessModal
            setSuccessModal={setSuccessModal}
            updateData={fetchData}
          />
          : null
      }

      {/* failed server response model with error msg */}

      {failedModalWithErrorMsg ?
        (<Failed_Modal
          setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
          errorMsg={errorMsgInFailedModal}
        />)
        : null
      }

    </div>
  )
}

import React, { useEffect, useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';
import './Areas.css';

import { getFromAgel } from '../../utils/api';
import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';

// components
import AreasTable from '../../components/Areas/AreasTable';
import CreateModal from '../../components/Areas/CreateModal';
import EditModal from '../../components/Areas/EditModal';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen';
import SuccessModal from '../../components/common/SuccessModal';
import Failed_Modal from '../../components/common/FailedModal';

export default function Areas() {
  const validPermissionsAndActions = useContext(userPermissionsAndActionsContext);
  const viewAreasPermission = userValidPermissionAndAction(validPermissionsAndActions, "area", "viewArea");

  // All Area
  const [areas, setAreas] = useState([]);

  // modal
  const [editArea, setEditAreas] = useState(false);
  const [current, setCurrent] = useState({});
  const [createArea, setCreateArea] = useState(false);

  // server modal response success/failed
  const [successModal, setSuccessModal] = useState(false);
  const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false);
  const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("");

  // search
  const [areaSearch, setAreaSearch] = useState(""); // search input state
  const [searchResult, setSearchResult] = useState(""); // search btn

  // cities coming from api in edit and create form
  const [cities, setCities] = useState([]);

  const fetchData = async () => {
    try {
      // cities api
      const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
      // console.log("cityResponse=", cityResponse.data.cities);
      setCities(cityResponse.data.cities);

      // areas api
      const areaResponse = await getFromAgel(`${process.env.React_APP_GET_AREAS}`);
      // console.log("areaResponse=", areaResponse.data.area)
      setAreas(areaResponse.data.area);
    } catch (err) {
      // console.log("error", err)
    }
  };

  // fetch all cities
  useEffect(() => {
    fetchData();
  }, []);

  const sortedAreas = areas && areas.sort((a, b) => (a.id) - (b.id));

  // search btn fn
  const search = () => {
    setSearchResult(areaSearch);
  };
  // update  result
  // filter all cities by search result entered to get filtered city id to test on matching it with cityid in areas
  const searchCities = searchResult !== "" ? cities.filter((c) => c.EnName && c.EnName.toLowerCase().includes(searchResult.toLowerCase())) : null;
  const searchCitiesId = searchCities && searchCities.map((i) => i.id);
  // console.log("searchCountries",searchCities)
  // console.log("searchCountriesId",searchCitiesId)

  const areaSearchResult = searchResult !== "" ? sortedAreas.filter((i) => i.ArName.toLowerCase().includes(searchResult.toLowerCase()) || i.EnName.toLowerCase().includes(searchResult.toLowerCase()) || searchCitiesId.includes(i.CityId)) : sortedAreas;

  if (viewAreasPermission) {
    return (
      <div>
        <Row className="layout-top">
          <Col xs="3 text-start">
            {/* input */}
            <div className="input-wrapper">
              <input type="text" placeholder="En/Ar Area & City" value={areaSearch} onChange={(e) => setAreaSearch(e.target.value)} />
            </div>
          </Col>
          <Col xs="4 text-start">
            {/* search */}
            <button
              className="role-search-btn"
              onClick={() => search()}
            >
              Search
            </button>
          </Col>
          <Col xs="5 text-end">
            {/* create area btn */}
            <button className="add-role-btn" onClick={() => { setCreateArea(true); console.log('pressed'); }}>
              <span className="d-flex align-items-center justify-content-center">
                <MdAddCircle size={20} className="add-role-btn-icon" />
                <p>Add Area</p>
              </span>
            </button>
            {
                            createArea
                              ? (
                                <CreateModal
                                  setCreateArea={setCreateArea}
                                  cities={cities}
                                  updateData={fetchData}
                                  setSuccessModal={setSuccessModal}
                                  setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                  setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                />
                              )
                              : null
                        }
          </Col>
        </Row>

        {/* Areas Table */}
        <AreasTable
          areas={areaSearchResult}
          cities={cities}
          setEditAreas={setEditAreas}
          setCurrent={setCurrent}
        />

        {/* EDit MOdal */}
        {
                    editArea
                      ? (
                        <EditModal
                          setEditAreas={setEditAreas}
                          current={current}
                          updateData={fetchData}
                          setSuccessModal={setSuccessModal}
                          setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                          setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                      ) : null
                }

        {/* success server response model */}
        {
                    successModal
                      ? (
                        <SuccessModal
                          setSuccessModal={setSuccessModal}
                          updateData={fetchData}
                        />
                      )
                      : null
                }

        {/* failed server response model with error msg */}

        {failedModalWithErrorMsg
          ? (
            <Failed_Modal
              setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
              errorMsg={errorMsgInFailedModal}
            />
          )
          : null}

      </div>
    );
  }

  return (
    <AuthorizationRequiredScreen />
  );
}

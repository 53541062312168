
import React, { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import { Col, Row } from "react-bootstrap";
import './Staff.css'

// components
import StaffTable from "../../components/Staff/StaffTable";
import EditStaffModal from '../../components/Staff/EditStaffModal';
import CreateStaffModal from "../../components/Staff/CreateStaffModal";
import ResetPasswordStaffModal from "../../components/Staff/ResetPasswordStaffModal";
import Failed_Modal from "../../components/common/FailedModal";
import SuccessModal from "../../components/common/SuccessModal";
import AuthorizationRequiredScreen from "../AuthorizationRequiredScreen/AuthorizationRequiredScreen";

//icons
import { MdAddCircle } from 'react-icons/md'

//api
import { getFromAgel } from "../../utils/api";

import { userPermissionsAndActionsContext } from "../../App";
import { userValidPermissionAndAction } from "../../utils/permissionsAndActions";




export default function Staff() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewStaffPermission = userValidPermissionAndAction(validPermissionsAndActions, "", "")
    let viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity")
    let viewRolePermission = userValidPermissionAndAction(validPermissionsAndActions, "", "")

    const [staff, setStaff] = useState([])
    const [cities, setCities] = useState([])
    const [roles, setRoles] = useState([])


    ///Modal
    const [createStaffMOdal, setCreateStaffModal] = useState(false);
    const [resetPasswordStaffModal, setResetPasswordStaffModal] = useState(false);
    const [editStaffModal, setEditStaffModal] = useState(false);
    const [currentStaffModal, setCurrentStaffModal] = useState({});

    // serever response state
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")




    //search
    const [stuffSearch, setStuffSearch] = useState("") // search input state
    const [searchResult, setSearchResult] = useState("") //search btn
    const [searchResultSelect, setSearchResultSelect] = useState("");
    const [searchRoleSelect, setSearchRoleSelect] = useState("");
    const [searchCity, setSearchCity] = useState('');
    const [searchRole, setSearchRole] = useState('');

    const fetchStaff = async () => {
        try {

            // get staff
            const staffResponse = await getFromAgel(`${process.env.React_APP_GET_Staff}`)
            // console.log('staffResponse', staffResponse.data.staff)
            setStaff(staffResponse.data.staff)

            //get cities //view cities permission
            if (viewCitiesPermission) {
                const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
                setCities(cityResponse.data.cities)
            }

            //get roles //view roles permission
            if (viewRolePermission) {
                const rolesResponse = await getFromAgel(`${process.env.React_APP_GET_ROLES}`)
                setRoles(rolesResponse.data.roles)
            }

        }
        catch (err) {
            console.log('error=', err)
        }
    }


    useEffect(() => {
        fetchStaff()
    }, [])

    const sortedStaff = staff && staff.sort((a, b) => (a.id) - (b.id));

    //search btn fn
    const search = () => {
        setSearchResult(stuffSearch);
        setSearchResultSelect(searchCity);
        setSearchRoleSelect(searchRole);
    }
    //update  result
    let staffSearchResult = sortedStaff

    if (searchResult !== "" && searchResultSelect === "" && searchRoleSelect === "") {
        // console.log("searchcity1=", searchResultSelect)
        // console.log("searchresult1=", searchResult)
        staffSearchResult = sortedStaff.filter((i) => i.name && i.name.toLowerCase().includes(searchResult.toLowerCase()) || i.email && i.email.toLowerCase().includes(searchResult.toLowerCase()))
    }
    else if (searchResultSelect !== "" && searchResult === "" && searchRoleSelect === "") {
        // console.log("searchcity2=", searchResultSelect)
        // console.log("searchresult2=", searchResult)
        staffSearchResult = sortedStaff.filter((i) => i.City && i.City.EnName.toLowerCase().includes(searchResultSelect.toLowerCase()))
    }
    else if (searchRoleSelect !== "" && searchResult === "" && searchResultSelect === "") {
        // console.log("searchcity2=", searchResultSelect)
        // console.log("searchresult2=", searchResult)
        staffSearchResult = sortedStaff.filter((i) => i.Role && i.Role.name.toLowerCase().includes(searchRoleSelect.toLowerCase()))
    }
    else if (searchResult !== "" && searchRoleSelect !== "" && searchResultSelect === "") {
        // console.log("searchcity3=", searchResultSelect)
        // console.log("searchresult3=", searchResult)
        staffSearchResult = sortedStaff.filter((i) => i.name && i.name.toLowerCase().includes(searchResult.toLowerCase()) || i.email && i.email.toLowerCase().includes(searchResult.toLowerCase())).filter((i) => i.Role && i.Role.name.toLowerCase().includes(searchRoleSelect.toLowerCase()))
    }
    else if (searchResult !== "" && searchResultSelect !== "" && searchRoleSelect === "") {
        // console.log("searchcity3=", searchResultSelect)
        // console.log("searchresult3=", searchResult)
        staffSearchResult = sortedStaff.filter((i) => i.name && i.name.toLowerCase().includes(searchResult.toLowerCase()) || i.email && i.email.toLowerCase().includes(searchResult.toLowerCase())).filter((i) => i.City && i.City.EnName.toLowerCase().includes(searchResultSelect.toLowerCase()))
    }
    else if (searchResultSelect !== "" && searchRoleSelect !== "" && searchResult === "") {
        // console.log("searchcity3=", searchResultSelect)
        // console.log("searchresult3=", searchResult)
        staffSearchResult = sortedStaff.filter((i) => i.City && i.City.EnName.toLowerCase().includes(searchResultSelect.toLowerCase())).filter((i) => i.Role && i.Role.name.toLowerCase().includes(searchRoleSelect.toLowerCase()))
    }
    else if (searchResult !== "" && searchResultSelect !== "" && searchRoleSelect !== "") {
        // console.log("searchcity3=", searchResultSelect)
        // console.log("searchresult3=", searchResult)
        staffSearchResult = sortedStaff.filter((i) => i.City && i.City.EnName.includes(searchResultSelect)).filter((i) => i.name && i.name.toLowerCase().includes(searchResult.toLowerCase()) || i.email && i.email.toLowerCase().includes(searchResult.toLowerCase())).filter((i) => i.Role && i.Role.name.toLowerCase().includes(searchRoleSelect.toLowerCase()))
    }
    else {
        staffSearchResult = sortedStaff
    }

    if (viewStaffPermission) {

        return (
            <div>
                {/* row1 */}
                <Row className="layout-top">

                    {/* input */}
                    <Col xs="3 text-start">
                        <div className='input-wrapper'>
                            <input type="text" placeholder="Staff Name / Email " value={stuffSearch} onChange={(e) => setStuffSearch(e.target.value)} />
                        </div>
                    </Col>

                    {/* search */}
                    <Col xs="4" className='text-start'>
                        <button className='role-search-btn' onClick={() => search()}>Search</button>
                    </Col>

                    {/* create Stuff btn */}
                    <Col xs="5 text-end">
                        <div>
                            <button className='add-role-btn' onClick={() => { setCreateStaffModal(true) }}>
                                <span className='d-flex align-items-center justify-content-center'>
                                    <MdAddCircle size={20} className="add-role-btn-icon" />
                                    <p>Add Staff</p>
                                </span>
                            </button>
                            {
                                createStaffMOdal ?
                                    <CreateStaffModal
                                        cities={cities}
                                        roles={roles}
                                        updateData={fetchStaff}
                                        setCreateStaffModal={setCreateStaffModal}
                                        setSuccessModal={setSuccessModal}
                                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                    />
                                    : null
                            }
                        </div>
                    </Col>
                </Row>

                {/* row2 */}
                <Row className="layout-row-2">
                    <Col xs="2 text-start">
                        {/* select city */}
                        <Select
                            className="search-select"
                            defaultValue={{ "label": "City", "value": 0 }}
                            onChange={(e) => { setSearchCity(e.label); }}
                            options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                        />
                    </Col>
                    <Col xs="2 text-start">
                        {/* select role*/}
                        <Select
                            className="search-select"
                            defaultValue={{ "label": "Role", "value": 0 }}
                            onChange={(e) => { setSearchRole(e.label) }}
                            options={roles.map((role) => ({ "value": role.id, "label": role.name }))}
                        />
                    </Col>
                </Row>

                {/* staff table  */}
                <StaffTable
                    staff={staffSearchResult}
                    cities={cities}
                    roles={roles}
                    setEditStaffModal={setEditStaffModal}
                    setCurrentStaffModal={setCurrentStaffModal}
                    setResetPasswordStaffModal={setResetPasswordStaffModal}

                />
                {/* EDit model */}
                {
                    editStaffModal ?
                        <EditStaffModal
                            setEditStaffModal={setEditStaffModal}
                            currentStaffModal={currentStaffModal}
                            updateData={fetchStaff}
                            cities={cities}
                            roles={roles}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null
                }

                {/* Reset Password model */}
                {
                    resetPasswordStaffModal ?
                        <ResetPasswordStaffModal
                            setEditStaffModal={setEditStaffModal}
                            currentStaffModal={currentStaffModal}
                            updateData={fetchStaff}
                            setResetPasswordStaffModal={setResetPasswordStaffModal}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null
                }

                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={fetchStaff}
                        />
                        : null
                }

                {/* failed server response model */}
                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }




            </div>
        )
    }

    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
import React, { useState } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { AiFillPlusCircle } from 'react-icons/ai'
import Select from 'react-select';
import { postToAgel } from '../../utils/api';
//components
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';
import InvoiceItem from './invoiceItem';

export default function WsCreateInvoice(props) {
    const {
        setCreateInvoicesModal,
        wholesalerStores,
        wholesalerName,
        wsID,
        wsDiscount,
        setCreateInvoicesModalTwo,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        setSerialNumber,
        setTransactionId,
        setSumOfTotalOfAllItems
    } = props;

    //done btn state checker
    const [nextBtnLoader, setNextBtnLoader] = useState(false)

    const [selectWsStore, setSelectWsStore] = useState("")

    const [itemsList, setItemsList] = useState([{ itemName: "", price: "", ItemtotPrice: "", quantity: "" }]) //34an y create awl component

    const [selectWsStoreValidation, setSelectWsStoreValidation] = useState(false)

    const addNewItem = () => {
        //byrg3 el adem w ydef object gded fa keda el array zad fa keda hay create new component
        setItemsList(prev => [...prev, { itemName: "", price: "", ItemtotPrice: "", quantity: "", key: Math.random() * 1000 }]) //key 34an y3rf y remove unique item
    }

    let grandTotal = 0;
    let grandTotalWithDiscount = 0;


    //da ele hab3to ll BE mn 8er key 
    const formatedList = itemsList.map(item => {
        //kol mara by render by7sb el grandtotal m el awl zyada 3leh el total el gded
        grandTotal += item.ItemtotPrice
        grandTotalWithDiscount = Math.round(grandTotal - (grandTotal * ((wsDiscount) / 100)))
        setSumOfTotalOfAllItems(grandTotal)
        const newItem = { ...item }
        delete newItem.key;
        return newItem
    })
    // console.log({formatedList})


    const sendItems = async (e) => {
        e.preventDefault()
        let invoicesItems = formatedList.map((i) => {
            i.ItemtotPrice = `${i.ItemtotPrice}`
            return (i)
        }
        )
        var data = {
            "WholesalerId": wsID,
            "WsStoreId": selectWsStore,
            "invoice": {
                "totalPrice": parseInt(grandTotal),
                "items": invoicesItems
            }
        }
        if (selectWsStore !== "") {
            setSelectWsStoreValidation(false)
            setNextBtnLoader(true)
            try {
                const wsTransactionResponse = await postToAgel('dashboard/transaction/wholesaler', data)
                setCreateInvoicesModalTwo(true);
                // console.log("serial number=",wsTransactionResponse.data.serialNumber)
                setSerialNumber(wsTransactionResponse.data.serialNumber);
                setTransactionId(wsTransactionResponse.data.id)
            }
            catch (error) {
                setFailedModalWithErrorMsg(true)
                setErrorMsgInFailedModal(error?.response?.data?.message)
            }
            setCreateInvoicesModal(false)
        }
        else {
            setSelectWsStoreValidation(true)
            setNextBtnLoader(false)
        }
    }
    return (
        <div>
            <Modal show={true} onHide={() => setCreateInvoicesModal(false)} centered className="dashboard-modal" size="lg" style={{ marginTop: "5em" }}>

                <Modal.Header closeButton>
                </Modal.Header>

                <Form onSubmit={(e) => sendItems(e)}>

                    <Modal.Body>
                        <>
                            <span className='profile-label-text' style={{ fontSize: "0.95em" }}>
                                Wholesaler name
                            </span>
                            <span className='ws-name-in-invoice-modal'>
                                {wholesalerName && wholesalerName}
                            </span>
                        </>

                        <Row className='mt-3 d-flex'>
                            <Col xs="5">
                                <Select
                                    className="search-select"
                                    defaultValue={{ "label": "Stores", "value": 0 }}
                                    onChange={(e) => { setSelectWsStore(e.value); setSelectWsStoreValidation(false) }}
                                    options={wholesalerStores?.map((i) => ({ "value": i.store.id, "label": i.store.name }))}
                                />
                            </Col>
                            <Col xs="6" className='profile-label-text text-start mt-3' style={{ fontSize: "0.95em" }}>
                                <span className='profile-label-text'>
                                    Discount
                                </span>
                                <span className='ws-name-in-invoice-modal'>
                                    {wsDiscount && wsDiscount}%
                                </span>

                            </Col>
                            {selectWsStoreValidation &&
                                <Col xs="12" className='mt-2'>
                                    <p className="select-error-msg" style={{ fontSize: "0.9rem" }}>select a wholesaler store!</p>
                                </Col>
                            }

                        </Row>

                        {/* invoice items */}

                        {
                            itemsList?.map((item, index) => {
                                return <InvoiceItem
                                    key={item.key}
                                    index={index}
                                    setItemsList={setItemsList}
                                    itemsList={itemsList}
                                />
                            })
                        }

                        <span
                            className="add-new-item-in-invoice"
                            onClick={addNewItem}
                        >
                            <AiFillPlusCircle className='add-note-icon' style={{ fontSize: "0.95em" }} />
                            Add new item
                        </span>

                        <div className='d-flex justify-content-around align-items-center mt-5'>
                            <div className='d-grid text-center'>
                                <span className='ws-name-in-invoice-modal borderBottom'>{isNaN(grandTotal) ? 0 : grandTotal}</span>

                                <span className='profile-label-text mt-3' style={{ fontSize: "0.95em" }}>Total</span>

                            </div>
                            <div className='d-grid text-center'>
                                <span className='ws-name-in-invoice-modal borderBottom'>{isNaN(grandTotalWithDiscount) ? 0 : grandTotalWithDiscount}</span>

                                <span className='profile-label-text mt-3' style={{ fontSize: "0.95em" }}>Total after discount % </span>

                            </div>

                        </div>


                        <Modal.Footer className='d-flex justify-content-end align-items-center mt-5' style={{ width: "100%", paddingRight: "0" }}>
                            <button className="role-add-btn-with-loader-spinner" type="submit" disabled={nextBtnLoader ? true : false}>
                                {nextBtnLoader ?
                                    (<LoadingBtnSpinner />)
                                    : (<span>Next</span>)}
                            </button>

                            <button className="roleCloseBtn" onClick={() => setCreateInvoicesModal(false)}>Close</button>
                        </Modal.Footer>


                    </Modal.Body>
                </Form>

            </Modal>
        </div>
    )
}
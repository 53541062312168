import React from 'react'
import { Container } from 'react-bootstrap'
import HomeCardDetails from './HomeCardDetails'


export default function HomeCards(props) {
    const {
        statisticsData,
    } = props


    let registeredWs = Math.round((parseInt(statisticsData && statisticsData.wholesaler && statisticsData.wholesaler.active)) + (parseInt(statisticsData && statisticsData.wholesaler && statisticsData.wholesaler.inactive)) + (parseInt(statisticsData && statisticsData.wholesaler && statisticsData.wholesaler.pending)))
    let registeredRetailer = Math.round((parseInt(statisticsData && statisticsData.retailer && statisticsData.retailer.active)) + (parseInt(statisticsData && statisticsData.retailer && statisticsData.retailer.inactive)) + (parseInt(statisticsData && statisticsData.retailer && statisticsData.retailer.pending)))

    return (
        <div>
            {/* cards */}
            <div className="tables-layout">
                {/* container 1 related to numbers */}
                <Container fluid className="d-flex align-items-center justify-content-around">
                    <HomeCardDetails title="Active wholesalers" number={statisticsData && statisticsData.wholesaler && statisticsData.wholesaler.active ? statisticsData && statisticsData.wholesaler && statisticsData.wholesaler.active : 0} bgColor={""} mt="20%" />
                    <HomeCardDetails title="Active retailers" number={statisticsData && statisticsData.retailer && statisticsData.retailer.active ? statisticsData && statisticsData.retailer && statisticsData.retailer.active : 0} bgColor={""} mt="20%" />
                    <HomeCardDetails title="Registered wholesalers" number={registeredWs ? registeredWs : 0} bgColor={""} mt="10%" />
                    <HomeCardDetails title="Registered retailers" number={registeredRetailer ? registeredRetailer : 0} bgColor={""} mt="10%" />
                </Container>

                {/* container 2 related to money */}
                <Container fluid className="d-flex align-items-center justify-content-around mt-5">
                    <HomeCardDetails title="Number of successful transactions" number={statisticsData && statisticsData.transaction && statisticsData.transaction.success ? Math.ceil(statisticsData && statisticsData.transaction && statisticsData.transaction.success) : 0} bgColor={""} mt="1%" /> {/* 34an y2rb el rkm Math.ceil */}
                    <HomeCardDetails title="Wholesaler amounts" number={statisticsData && statisticsData.wholesalerAmount && statisticsData.wholesalerAmount ? Math.ceil(statisticsData && statisticsData.wholesalerAmount && statisticsData.wholesalerAmount) : 0} bgColor={""} mt="10%" />
                    <HomeCardDetails title="Retailer amounts" number={statisticsData && statisticsData.retailerAmount && statisticsData.retailerAmount ? Math.ceil(statisticsData && statisticsData.retailerAmount && statisticsData.retailerAmount) : 0} bgColor={""} mt="20%" />
                    <HomeCardDetails title="Wholesaler discount revenue" number={statisticsData && statisticsData.revenue && statisticsData.revenue ? Math.ceil(statisticsData && statisticsData.revenue && statisticsData.revenue) : 0} bgColor={""} mt="10%" />
                </Container>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { color } from '../color';

import { Link } from 'react-router-dom'
// import { logout } from '../../../utils/auth'
import './Sidebar.css'
export default function Sidebar(props) {
    let navigate = useNavigate()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', () => setWindowWidth(window.innerWidth))

    }, [])




    const [employeesSidemenu, setEmployeesSidemenu] = useState(false);
    const [zonesSidemenu, setZonesSidemenu] = useState(false);
    const [productsSidemenu, setProductsSidemenu] = useState(false);
    const [wholesaleSidemenu, setWholesaleSidemenu] = useState(false);
    const [retailerSidemenu, setRetailerSidemenu] = useState(false);
    const [homeSidemenu, setHomeSidemenu] = useState(false);
    const [posStockSideMenu, setPosStockSideMenu] = useState(false);
    const [retentionSideMenu, setRetentionSideMenu] = useState(false);
    const [paymentReportSideMenu, setpaymentReportSideMenu] = useState(false);
    const [complianceSidemenu, setComplianceSidemenu] = useState(false);
    const [otpSidemenu, setOtpSidemenu] = useState(false);
    const [acquisitionSidemenu, setAcquisitionSidemenu] = useState(false);



    const menuStates = {
        home: {
            state: homeSidemenu,
            setState: setHomeSidemenu
        },
        employee: {
            state: employeesSidemenu,
            setState: setEmployeesSidemenu
        },
        zones: {
            state: zonesSidemenu,
            setState: setZonesSidemenu
        },
        products: {
            state: productsSidemenu,
            setState: setProductsSidemenu
        },
        wholesalers: {
            state: wholesaleSidemenu,
            setState: setWholesaleSidemenu
        },
        retailers: {
            state: retailerSidemenu,
            setState: setRetailerSidemenu
        },
        stock: {
            state: posStockSideMenu,
            setState: setPosStockSideMenu
        },
        retention: {
            state: retentionSideMenu,
            setState: setRetentionSideMenu
        },
        report: {
            state: paymentReportSideMenu,
            setState: setpaymentReportSideMenu
        },
        otp: {
            state: otpSidemenu,
            setState: setOtpSidemenu
        },
        compliance: {
            state: complianceSidemenu,
            setState: setComplianceSidemenu
        },
        acquisition: {
            state: acquisitionSidemenu,
            setState: setAcquisitionSidemenu
        },
    }






    const { pathname } = useLocation();
    var group = pathname.substring(1).split('/')[0]
    var subPage = pathname.substring(1).split('/')[1]

    const bgcolor = {
        background: "rgba(0, 189, 133, 0.05)",
        borderRadius: "19px",
        color: "#00BD85",
        paddingLeft: "1em",
        marginRight: "1em",
        marginLeft: "2em"
    }
    const greenColor = { color: "#00BD85" }
    const keys = Object.keys(color);
    // console.log({ keys })

    const changeSideElement = (element) => {

        let state = menuStates[element].state
        let setState = menuStates[element].setState

        let menuStatesKeys = Object.keys(menuStates)
        setState(!state)
        menuStatesKeys.forEach((key) => {
            if (key != element) {
                // console.log({key})
                // console.log(menuStates[key])
                menuStates[key].setState(false)
            }
        })
    }


    if (!subPage) {
        subPage = 'home'
    }



    if (windowWidth > 1100) {
        return (
            <div className='agel-sidebar'>
                <img src="/images/sidebarImg.png" className='sidebar-img' />
                <ul className="list-unstyled">
                    {keys.map((key) =>


                        <li key={key}>
                            <div className='li-wrapper' >
                                <Link to={color[key].path} className="link d-flex justify-content-between align-items-center" onClick={(e) => { changeSideElement(key) }}>
                                    <div className='d-flex'>
                                        <img src={`/images/${color[key].icon}`} className='sidebar-li-img' />
                                        <p className="sidebar-title" style={group == color[key].selected.toLowerCase() ? greenColor : null} >{color[key].name}</p>
                                    </div>
                                    <img src="/images/sidebarArrow.png" className='arrow' />
                                </Link>
                            </div>
                            {menuStates[key].state === true &&
                                <div>
                                    <ul className='list-unstyled text-center'>
                                        {
                                            color[key].subPages.length > 1 ?
                                                color[key].subPages.map((i) =>
                                                    <li style={subPage == i.selected ? bgcolor : null} key={i.path} className="sidebar-sub-title" onClick={() => navigate(`${i.path}`)}>{i.name}</li>
                                                )
                                                : null}
                                    </ul>
                                </div>
                            }
                        </li>

                    )}
                </ul>
            </div>
        )
    }
    else {
        return null
    }
}
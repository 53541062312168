import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select';
import { getFromAgel, postToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';



export default function AssignCollectorsToRetailers(props) {
    const {
        setAssignCollectorsToRetailersModal,
        collectors,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
    } = props

    const [retailers, setRetailers] = useState([])
    const [selectedRetailer, setSelectedRetailer] = useState("")
    const [retailerSelectValidity, setRetailerSelectValidity] = useState(false)
    const [selectedCollector, setSelectedCollector] = useState("")
    const [collectorSelectValidity, setCollectorSelectValidity] = useState(false)

    //assign btn loader checker
    const [assignBtn, setAssignBtn] = useState(false)

    const fetchRetailers = async () => {
        try {
            //get all active retailers
            const getRetailers = await getFromAgel('dashboard/retailers/names')
            setRetailers(getRetailers.data.retailersNames)
        }
        catch (error) {
            // console.log({ error })
        }
    }

    useEffect(() => {
        fetchRetailers()
    }, [])

    const assignCollectorToRetailer = async (e) => {
        e.preventDefault();
        var data = {
            "RetailerId": selectedRetailer,
            "CollectorId": selectedCollector,
        }

        if (selectedCollector !== "" && selectedRetailer !== "") {
            setCollectorSelectValidity(false)
            setRetailerSelectValidity(false)
            setAssignBtn(true);

            try {
                await postToAgel(`dashboard/collector/assign`, data)
                setSuccessModal(true)
            }
            catch (error) {
                setErrorMsgInFailedModal(error?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
            setAssignCollectorsToRetailersModal(false);
            setAssignBtn(false);
        }
        else if (selectedCollector === "") {
            setCollectorSelectValidity(true)
        }
        else if (selectedRetailer === "") {
            setRetailerSelectValidity(true)
        }

    }

    return (
        <div>
            <Modal show={true} onHide={() => setAssignCollectorsToRetailersModal(false)} centered size="lg" className="dasboardModal">
                <Modal.Header closeButton className="cancel-msg-modal-header">
                    Assign a collector to a retailer
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={assignCollectorToRetailer}>
                        <Row>
                            <Col xs={6} style={{ zIndex: "999" }}>
                                <Select
                                    className="role-permissions-select mt-2"
                                    defaultValue={{ "label": "collectors", "value": 0 }}
                                    onChange={(e) => { setSelectedCollector(e.value); setCollectorSelectValidity(false) }}
                                    options={collectors?.map((i) => ({ "value": i.id, "label": i.name }))}
                                />
                                {/* collector validation msg */}
                                {collectorSelectValidity && (<p className="select-error-msg">select a collector!</p>)}
                            </Col>
                            <Col xs={6} style={{ zIndex: "999" }}>
                                <Select
                                    className="role-permissions-select mt-2"
                                    defaultValue={{ "label": "Retailers", "value": 0 }}
                                    onChange={(e) => { setSelectedRetailer(e.value); setRetailerSelectValidity(false) }}
                                    options={retailers && retailers.map((retailer) => ({ "value": retailer.id, "label": retailer.name }))}
                                />
                                {/* retailer validation msg */}
                                {retailerSelectValidity && (<p className="select-error-msg">select a retailer!</p>)}
                            </Col>
                        </Row>

                        <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0", marginTop: "9rem" }}>
                            <button className="role-add-btn-with-loader-spinner" type="submit" disabled={assignBtn ? true : false}>
                                {assignBtn ?
                                    (<LoadingBtnSpinner />)
                                    : (<span>Done</span>)}
                            </button>
                            <button className="roleCloseBtn" onClick={() => setAssignCollectorsToRetailersModal(false)}>Close</button>
                        </Modal.Footer>

                    </Form>
                </Modal.Body>

            </Modal>
        </div>
    )
}
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Container } from 'react-bootstrap';

// icons
import { AiOutlineEdit } from 'react-icons/ai';
import { IoRefreshOutline } from 'react-icons/io5';

const AgentTable = (props) => {
  // props
  const {
    agents,
    setResetPasswordAgentsModal,
    setCurrentAgentPasswordModal,
    setEditAgentModal,
    setCurrentAgentModal,
  } = props;

  return (
    <div className="tables-layout">
      <Container fluid>
        <Table responsive>
          <thead className="text-center">
            <tr className="role-table-header">
              <th className="text-center">ID</th>
              <th className="text-center">Name</th>
              <th className="text-center">Phone Number</th>
              <th className="text-center">National ID</th>
              <th className="text-center">City</th>
              <th className="text-center">collector</th>
              <th className="text-center">Status</th>
              {/* <th>Updates</th> */}
            </tr>
          </thead>
          <tbody className="text-center">
            {agents?.map((index) => (
              <tr key={index.id} className="role-table-text">
                <td className="text-center">{index.id}</td>
                <td className="text-center">{index.name}</td>
                <td className="text-center">{index.phoneNumber}</td>
                <td className="text-center">{index.nationalID}</td>
                <td className="text-center">{index.City && index.City.EnName}</td>
                <td className="text-center" style={index.isCollector ? { color: "#00BD85" } : null}>{index.isCollector ? ("True") : ("False")}</td>
                <td className="text-center" style={index.isActive ? { color: "#00BD85" } : null}>{index.isActive ? ("Active") : ("Inactive")}</td>
                <td className="text-center d-flex justify-content-center align-items-center">
                  <button
                    type="button"
                    className="agent-edit-btn"
                    onClick={() => {
                      setEditAgentModal(true);
                      setCurrentAgentModal(index);
                    }}
                  >
                    Edit
                    {' '}
                    <AiOutlineEdit />
                  </button>

                  <button
                    type="button"
                    className="agent-reset-btn"
                    onClick={() => {
                      setResetPasswordAgentsModal(true);
                      setCurrentAgentPasswordModal(index);
                    }}
                  >
                    Reset
                    <IoRefreshOutline />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default AgentTable;

import React, { useState, useContext } from 'react'
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import Switch from "react-switch";
import ReactThreeToggle from 'react-three-toggle'
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';
import { AiFillPlusCircle, AiOutlineDelete } from 'react-icons/ai'
import { BsChatDotsFill } from 'react-icons/bs'
import { VscError } from "react-icons/vsc";

//components
import AddNoteModal from './AddNoteModal';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

//api
import { deleteFromAgel, getFromAgel, postToAgel, putToAgel } from '../../utils/api'

import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';





export default function BasicInfo(props) {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewDeleteRetailerButtonPermission = userValidPermissionAndAction(validPermissionsAndActions, "retailer", "deleteRetailer")
    let viewGenerateOtpBtnPermission = userValidPermissionAndAction(validPermissionsAndActions, "otp", "generate otp")


    let navigate = useNavigate()

    const { name, phoneNumber, organizationType, wallet, credit, id, City, Industry, mega_store_name, UID, RetailersValifyData, status } = props.retailer
    // const setRetailer = props.setRetailer
    const {
        retailerStoreLocation
    } = props

    const {
        retailerStatus,
        setRetailerStatus,
        setSuccessModal,
        setErrorMsgInFailedModal,
        setFailedModalWithErrorMsg,
        allLabels,
        retailerLabels,
        retailerUpdatedLabels,
        setRetailerUpdatedLabels,
        industries,
        cities,
        notes,
        updateData,
    } = props

    let singleNote = notes ? notes[0] : null
    // console.log("notes=", singleNote)


    const { id: retailerId } = useParams()
    const [showSave, setShowSave] = useState(false);
    const [showConfirmationDeleteAccount, setShowConfirmationDeleteAccount] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState();
    const [showPinModal, setShowPinModal] = useState(false);
    const [newPIN, setNewPIN] = useState("");
    const [pinValidation, setPinValidation] = useState(false)
    const [showGenerateOTPModal, setShowGenerateOTPModal] = useState(false);


    const [addNote, setAddNote] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [loadMoreVisiblity, setLoadMoreVisiblity] = useState("block")


    //edits btn states
    //m4 7aten el status state bta3t el retailer hena 34an fe lag f el setting bta3 el value fa 7atenha f el fetch fn bta3t el retailer profile nfsha
    const [ownerName, setOwnerName] = useState("")
    const [changedStatus, setChangedStatus] = useState("") //34an y save retailer status awl matt8yr w yb3tha wlw mat8yrt4 m4 hayb3t 7aga
    const [organizationName, setOrganizationName] = useState("")
    const [organizationLocation, setOrganizationLocation] = useState("")
    const [storeLocation, setStoreLocation] = useState("")
    const [uniqueIdNumber, setUniqueIdNumber] = useState("")
    const [uniqueIdNumberValidationMsgLength, setUniqueIdNumberValidationMsgLength] = useState(false)
    const [selectedIndustry, setSelectedIndustry] = useState("")
    const [selectedCity, setSelectedCity] = useState("")

    //generate otp value states
    const [otpValue, setOtpValue] = useState("")
    const [otpErrorMsg, setOtpErrorMsg] = useState("")
    const [loadingOtpResponse, setLoadingOtpResponse] = useState(false)


    // btn state checker
    const [pinBtn, setPinBtn] = useState(false)
    const [changePasswordBtn, setChangePasswordBtn] = useState(false)
    const [deleteAccountBtn, setDeleteAccountBtn] = useState(false)
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)


    const statusNames = ["inactive", "idle", "active"]//34an a3rf ast5dmha t7t f el ReactThreeToggle library 



    //handle change pin
    const saveNewPIN = async (e) => {
        e.preventDefault();
        setPinBtn(true);

        if (newPIN.length < 6 || newPIN.length > 6) {
            setPinValidation(true);
        }
        else {
            var data = {
                "newPin": newPIN,
                "userType": "Retailer"
            }
            try {
                await putToAgel(`dashboard/user/${retailerId}/changePin`, data);
                setSuccessModal(true);
            }
            catch (error) {
                setErrorMsgInFailedModal(error&&error.response&&error.response.data&&error.response.data.message&&error.response.data.message[0]);
                setFailedModalWithErrorMsg(true);
                console.log({ error });
            }
            setShowPinModal(false);
            setNewPIN("");
        }
        setPinBtn(false);
    }

    const toggleStatus = async (status) => {
        if (retailerStatus === "inactive" || retailerStatus === "pending") {
            setRetailerStatus("active")
            setChangedStatus("active")
        }
        else {
            setRetailerStatus("inactive")
            setChangedStatus("inactive")
        }
    }

    //handle change password
    const handleChangePassword = async (e) => {
        e.preventDefault();
        setChangePasswordBtn(true)

        try {
            await putToAgel(`dashboard/retailer/${retailerId}/changePassword`, { newPassword })
            setSuccessModal(true)
        }
        catch (e) {
            setErrorMsgInFailedModal(e?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setChangePasswordBtn(false)
        setShowPassword(false)
    }

    //handle delete account
    const deleteAccount = async (e) => {
        e.preventDefault()
        setDeleteAccountBtn(true)
        try {
            await deleteFromAgel(`dashboard/retailer/${retailerId}`)
            // setSuccessModal(true)
            navigate('/retailers/list');
        }
        catch (e) {
            setErrorMsgInFailedModal(e?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setShowConfirmationDeleteAccount(false)
        setDeleteAccountBtn(false)
    }

    //handle generate otp account
    const generateOtp = async (e) => {
        e.preventDefault()
        setOtpErrorMsg("")
        setOtpValue("")
        setLoadingOtpResponse(true)

        var data = {
            "phoneNumber": phoneNumber && phoneNumber
        }

        try {
            const otpResponse = await postToAgel(`dashboard/generate-otp`, data)
            setOtpValue(otpResponse?.data?.OTP)
        }
        catch (e) {
            setOtpErrorMsg(e?.response?.data?.message)
        }
        setLoadingOtpResponse(false)
    }

    const saveBasicInfo = async () => {
        if (uniqueIdNumber !== "" && uniqueIdNumber.length === 12 || uniqueIdNumber === "") {
            setUniqueIdNumberValidationMsgLength(false)
            setSaveEditsBtn(true)

            var data = {
                "retailer": {
                    ...(changedStatus ? { "status": changedStatus } : {}),
                    ...(ownerName ? { "name": ownerName } : {}),
                    ...(organizationLocation ? { "accommodation_address": organizationLocation } : {}),
                    ...(organizationName ? { "mega_store_name": organizationName } : {}),
                    ...(storeLocation ? { "store_location": storeLocation } : {}),
                    ...(uniqueIdNumber ? { "UID": uniqueIdNumber } : {}),
                    ...(selectedIndustry ? { "industryId": selectedIndustry } : {}),
                    ...(selectedCity ? { "cityId": selectedCity } : {}),
                    "labels": retailerUpdatedLabels.map((i) => i.id ? i.id : i),
                }

            }
            try {
                /* save edits in retailer account */
                await putToAgel(`${process.env.React_APP_RETAILER_SAVE_EDITS_ACCOUNT}${retailerId}`, data)
                updateData()
                setSuccessModal(true)
            }
            catch (e) {
                setErrorMsgInFailedModal(e?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
        }
        else {
            setErrorMsgInFailedModal("UID should be equal to 12 numbers!")
            setFailedModalWithErrorMsg(true)
            setUniqueIdNumberValidationMsgLength(true)
        }
        setSaveEditsBtn(false)
        setShowSave(false)
    }



    const onSelectLabel = (selectedList, selectedItem) => {
        let updatedLabels = selectedList.map((i) => i.id)
        setRetailerUpdatedLabels(updatedLabels)
        // setRetailer({ ...props.retailer, labels: updatedLabels })
        // console.log({ selectedList })
        // console.log({ updatedLabels })
    }

    const onRemoveLabel = (selectedList, selectedItem) => {
        let updatedLabels = selectedList.map((i) => i.id)
        setRetailerUpdatedLabels(updatedLabels)
        // setRetailer({ ...props.retailer, labels: updatedLabels })
        // console.log({ selectedList })
        // console.log({ updatedLabels })
    }

    return (
        <div>
            <div>
                {/* row 1 */}
                <Row>
                    <Col sm={2}>
                        {/* if status is pending in get request so we just need double normal switch */}
                        {retailerStatus === "pending" ?
                            <div className="d-flex justify-content-start align-items-center wsToggleSwitch">
                                <div className="wsToggleSwitchText">{status}</div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <Switch checked={status === "active" ? true : false} onChange={() => toggleStatus(status)}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        onColor={"#00bd85"}
                                        offColor={status === "pending" ? "#e3f305" : "#ff0000"}
                                        activeBoxShadow={0}
                                    />
                                </div>
                            </div>
                            :
                            //else we will need triple toggle switch for idle status needing
                            <div className="d-flex justify-content-start align-items-center retailerTrippleToggleSwitch">
                                <div className="retailerTrippleToggleSwitchText" >{retailerStatus && retailerStatus}</div>
                                <div className="text-end">
                                    <ReactThreeToggle initialValue={retailerStatus === "active" ? statusNames[2] : retailerStatus === "idle" ? statusNames[1] : statusNames[0]} values={statusNames} onChange={(e) => { setRetailerStatus(e); setChangedStatus(e) }} /> {/* lazm a update el etnen 34an y8yr f el behavior wf nfs el w2t y-indicate eno status et8ayrt fa yb3tha */} {/* initialValue 3mlnaha bl if else 34an mabta5od4 8er array [index]*/}
                                </div>
                            </div>
                        }
                    </Col>

                    <Col sm={2} className="d-flex justify-content-center align-items-center" >
                        {/* delete button only can be viewed if there is a user permission */}
                        {viewDeleteRetailerButtonPermission &&
                            <button className="action-btns-in-profiles delete-btn-in-profile" onClick={() => setShowConfirmationDeleteAccount(true)}>Delete account</button>
                        }
                    </Col>
                    <Col sm={2} className="d-flex justify-content-center align-items-center"><button className="PIN" onClick={() => setShowPinModal(true)}>PIN</button></Col>
                    <Col sm={3} className="d-flex justify-content-center align-items-center" ><button className="action-btns-in-profiles change-password-btn" onClick={() => setShowPassword(true)}>Change password</button></Col>
                    <Col sm={3} className="d-flex justify-content-center align-items-center"><button className="action-btns-in-profiles save-basic-info" onClick={() => setShowSave(true)}>Save</button></Col>
                </Row>

                {/* row 2 */}
                <Row className='mt-3 d-flex justify-content-end align-items-center'>
                    {viewGenerateOtpBtnPermission &&
                        <Col sm={3} className="d-flex justify-content-center align-items-center"><button className="action-btns-in-profiles generate-otp-in-profile" onClick={(e) => { setShowGenerateOTPModal(true); generateOtp(e) }}>Generate OTP</button></Col>
                    }
                </Row>
            </div>


            <div className="mt-5">
                <Form>
                    <div className="border-bottom">
                        <h5 className='account-profile-titles mb-4'>Basic Info</h5>
                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Owner Name</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={name && name} onChange={(e) => { setOwnerName(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Phone Number</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={phoneNumber && phoneNumber} onChange={() => { "" }} disabled readonly />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">id number</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={RetailersValifyData?.nationalIdOrPassportNumber} onChange={() => { "" }} disabled readonly />
                                </Form.Group>
                            </Col>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Birthdate</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={RetailersValifyData?.birthday} onChange={() => { "" }} disabled readonly />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Organization Location</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={retailerStoreLocation && retailerStoreLocation.store_location} onChange={(e) => { setStoreLocation(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">organization name</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={mega_store_name && mega_store_name} onChange={(e) => { setOrganizationName(e.target.value) }} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Accommodation address</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={RetailersValifyData?.accommodation_address} onChange={(e) => { setOrganizationLocation(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Wallet</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" defaultValue={wallet && wallet} onChange={() => { "" }} disabled readonly />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Credit</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={credit} onChange={() => { "" }} disabled readonly />
                                </Form.Group>
                            </Col>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">city</Form.Label>
                                    <div style={{ width: "80%" }}>
                                        <Select
                                            className='citySelectbox'
                                            placeholder={City?.EnName || "Select..."}
                                            onChange={(e) => { setSelectedCity(e.value) }}
                                            options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Gender</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={RetailersValifyData?.gender} onChange={(e) => { "" }} disabled readOnly />
                                </Form.Group>
                            </Col>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">industry name</Form.Label>
                                    <div style={{ width: "80%" }}>
                                        <Select
                                            className='industrySelectbox'
                                            placeholder={Industry?.name || "Select..."}
                                            onChange={(e) => { setSelectedIndustry(e.value) }}
                                            options={industries?.map((industry) => ({ "value": industry.id, "label": industry.name }))}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Nationality</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={RetailersValifyData?.nationality} onChange={(e) => { "" }} disabled readonly />
                                </Form.Group>
                            </Col>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Labels</Form.Label>
                                    <span style={{ marginLeft: "0.5vw" }} className="profile-label-text">
                                        <span>{retailerUpdatedLabels ? retailerUpdatedLabels.length : "0"}</span>/<span>{allLabels ? allLabels.length : "0"}</span>
                                    </span>
                                    <Multiselect
                                        className="multi-select-label-accounts"
                                        options={allLabels && allLabels} //haylf 3la kol el labels
                                        onSelect={onSelectLabel}
                                        onRemove={onRemoveLabel}
                                        selectedValues={retailerLabels && retailerLabels} //selected labels for specific ret
                                        displayValue="name"
                                        showCheckbox={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">نوع العميل</Form.Label>
                                    <Form.Control type="text" className="profile-input  mt-2" defaultValue={organizationType == "association" ? "جمعية" : organizationType == "company" ? "شركة" : ""} onChange={(e) => { "" }} disabled readonly />
                                </Form.Group>
                            </Col>
                        </Row>

                    </div>

                    {/* Prepaid card */}
                    <div className="mt-5 border-bottom">
                        <h5 style={{ color: "#00BD85" }}>Prepaid card</h5>
                        {/* row 1 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">
                                        UID
                                        <span style={{ color: '#e8a404', marginLeft: "0.3rem", fontSize: "0.8rem" }}>
                                            (must be 12 numbers)
                                        </span>
                                    </Form.Label>

                                    <Form.Control
                                        type="text"
                                        className="profile-input mt-2"
                                        defaultValue={UID}
                                        maxlength="12"
                                        onChange={(e) => {
                                            setUniqueIdNumber(e.target.value);
                                        }}
                                    />
                                    {/* incase user enter uid less than 12 number */}
                                    {uniqueIdNumberValidationMsgLength
                                        && (
                                            <span className='d-flex mt-2'>
                                                <VscError style={{ fontSize: "1.1rem" }} color="red" />
                                                <p className="select-error-msg" style={{ fontSize: "0.8rem", marginLeft: "0.1rem" }} >UID should be equal to 12 numbers!</p>
                                            </span>
                                        )
                                    }

                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                </Form>
            </div>


            <div className="mt-5">
                <Row>

                    {singleNote && (
                        <Col className="d-flex justify-content-start align-items-center">
                            <strong className='add-note-title'>Notes</strong>
                        </Col>

                    )}


                    <Col className="d-flex justify-content-end align-items-center">
                        <button className="add-note-btn" onClick={() => {
                            setAddNote(true)
                        }}>
                            <AiFillPlusCircle className='add-note-icon' />
                            add note
                        </button>
                    </Col>

                </Row>

                <Row>
                    <Col>
                        {!loadMore ?
                            <div>
                                {singleNote &&
                                    <div className="note-container"> {/* hy7ot el array of [0] y3ne 34an ygeb awl wa7da bs */}
                                        <div className="d-flex">
                                            <p className="note-title-wrapper">
                                                By : {singleNote && singleNote.By}
                                            </p>
                                            <p className="note-title-wrapper">
                                                Date : {new Date(singleNote && singleNote.createdAt).toLocaleDateString()}
                                            </p>
                                            <p className="note-title-wrapper">
                                                Time :
                                                {new Date(singleNote && singleNote.createdAt).getHours()}:{new Date(singleNote && singleNote.createdAt).getMinutes()}:{new Date(singleNote && singleNote.createdAt).getSeconds()}
                                            </p>
                                        </div>
                                        <div className='text-center mt-4 mb-2'>
                                            <p className="note-main-text">
                                                {singleNote && singleNote.note}
                                            </p>
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                {notes && notes.map((note) =>

                                    <div className="note-container"> {/* hy7ot el array of [0] y3ne 34an ygeb awl wa7da bs */}
                                        <div className="d-flex">
                                            <p className="note-title-wrapper">
                                                By : {note.By}
                                            </p>
                                            <p className="note-title-wrapper">
                                                Date : {new Date(note.createdAt).toLocaleDateString()}
                                            </p>
                                            <p className="note-title-wrapper">
                                                Time :
                                                {new Date(note.createdAt).getHours()}:{new Date(note.createdAt).getMinutes()}:{new Date(note.createdAt).getSeconds()}
                                            </p>
                                        </div>
                                        <div className='text-center mt-4 mb-2'>
                                            <p className="note-main-text">
                                                {note.note}
                                            </p>
                                        </div>
                                    </div>
                                )}

                            </div>
                        }
                    </Col>
                </Row>

                {
                    singleNote &&
                    (
                        <Row>
                            <Col style={{ cursor: "pointer", display: loadMoreVisiblity }} className="text-center mt-2" onClick={() => { setLoadMore(true); setLoadMoreVisiblity("none") }}>
                                <span className="load_more_text">load more</span>
                                <img src="/images/loadMoreArrows.png" style={{ width: "1.5vw", marginTop: "-0.1vw" }} className="img-fluid" />
                            </Col>
                        </Row>

                    )
                }

                {
                    addNote ?
                        <AddNoteModal
                            setAddNote={setAddNote}
                            id={id}
                            updateData={updateData}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null
                }
            </div>

            {/* show save confirm modal */}
            {
                showSave ?
                    <Modal show={showSave} onHide={() => setShowSave(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>
                        <Modal.Header closeButton>
                        </Modal.Header>

                        <Modal.Body className='confirm-msg-modal-title' >
                            <strong>Do you want to save changes?</strong>
                        </Modal.Body>

                        <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                            <button className="role-add-btn-with-loader-spinner" onClick={saveBasicInfo} type="submit" disabled={saveEditsBtn ? true : false}>
                                {saveEditsBtn ?
                                    (<LoadingBtnSpinner />)
                                    : (<span>Done</span>)}
                            </button>
                            <button className="roleCloseBtn" onClick={() => setShowSave(false)}>Close</button>
                        </Modal.Footer>

                    </Modal> : null
            }


            {/* show generate otp modal */}
            {
                showGenerateOTPModal ?
                    <Modal show={showGenerateOTPModal} onHide={() => setShowGenerateOTPModal(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>
                        <Modal.Header closeButton>
                        </Modal.Header>

                        <Modal.Body> {/* className='confirm-msg-modal-title text-center'  */}
                            <div className='d-grid justify-content-center align-items-center'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <BsChatDotsFill className='otp-icon' />
                                    <strong className='confirm-msg-modal-title text-center'>OTP</strong>
                                </div>

                                {otpErrorMsg ?
                                    <span className='otp-value-error-msg'>{otpErrorMsg}</span>
                                    : otpValue ?
                                        <span className='otp-value'>{otpValue}</span>
                                        : loadingOtpResponse ?
                                            <span className='otp-value'>Loading...</span>
                                            : null
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                    : null
            }



            {/* show delete account confirm modal */}
            {
                showConfirmationDeleteAccount ?
                    <Modal show={showConfirmationDeleteAccount} onHide={() => setShowConfirmationDeleteAccount(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>
                        <Modal.Header closeButton>
                        </Modal.Header>

                        <Modal.Body className='confirm-msg-modal-title' >
                            {/* <img src="" className='img-fluid'/> */}
                            <Row>
                                <Col xs="12" className='d-flex justify-content-center align-items-center'>
                                    <AiOutlineDelete color="red" className='recyle-bin-icon-in-profile-confirmation-modal' />
                                </Col>
                                <Col xs="12" className='d-flex justify-content-center align-items-center mt-2'>
                                    <strong>Do you want to delete this account?</strong>
                                </Col>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                            <button className="role-add-btn-with-loader-spinner" onClick={deleteAccount} type="submit" disabled={deleteAccountBtn ? true : false}>
                                {deleteAccountBtn ?
                                    (<LoadingBtnSpinner />)
                                    : (<span>Done</span>)}
                            </button>
                            <button className="roleCloseBtn" onClick={() => setShowConfirmationDeleteAccount(false)}>Close</button>
                        </Modal.Footer>

                    </Modal> : null
            }


            {/* show change password */}
            {
                showPassword ?
                    <Modal show={showPassword} onHide={() => setShowPassword(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>

                        <Modal.Header closeButton>
                        </Modal.Header>

                        <Form onSubmit={handleChangePassword}>
                            <Modal.Body>

                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">New Password</Form.Label>
                                    <Form.Control type="text" className="profile-input mt-2" style={{ width: "100%" }} value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} required />
                                </Form.Group>

                            </Modal.Body>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={changePasswordBtn ? true : false}>
                                    {changePasswordBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>
                                <button className="roleCloseBtn" onClick={() => setShowPassword(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Modal> : null
            }


            {/* show Pin Modal */}
            {
                showPinModal ?
                    <Modal show={showPinModal} onHide={() => setShowPinModal(false)} centered className="dashboard-modal" style={{ marginTop: "5em" }}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Form onSubmit={saveNewPIN}>
                            <Modal.Body>
                                <Form.Group className="mb-3">
                                    <Form.Label className="profile-label-text">Reset PIN <span style={{ color: '#e8a404', marginLeft: "0.3rem" }}> (6 digits) </span> </Form.Label>
                                    <Form.Control type="password" maxLength={6} className="profile-input mt-2" style={{ width: "100%" }} value={newPIN} onChange={(e) => { setNewPIN(e.target.value); setPinValidation(false) }} required />
                                </Form.Group>
                                {
                                    pinValidation &&
                                    <span className='pin-err-msg'>Pin must be 6 digits</span>
                                }
                            </Modal.Body>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={pinBtn ? true : false}>
                                    {pinBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>
                                <button className="roleCloseBtn" onClick={() => setShowPinModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Modal> : null
            }


        </div>
    )
}
import React, { useContext } from 'react'
import './OTP.css'
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions'
import { userPermissionsAndActionsContext } from '../../App'
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen'
import GenerateOTPBody from '../../components/OTP/OTP'


export default function GenerateOTP() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewOTPScreenPermission = userValidPermissionAndAction(validPermissionsAndActions, "otp", "generate otp")


    if (viewOTPScreenPermission) {
        return (
            <div className="layout-top">
                <GenerateOTPBody />
            </div>
        )
    }
    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
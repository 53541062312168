import React, { useState } from 'react'
import { Modal, Container, Form, Row, Col } from "react-bootstrap"
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

const ReinstateConfirm = (props) => {

    const {
        current,
        setReinstateConfirmModal,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        updateData,
    } = props

    //comment
    const [reinstateComment, setReinstateComment] = useState("")

    //state checker on buttons 
    const [doneBtn, setDoneBtn] = useState(false)


    // reinstate
    const reinstate = async (e) => {

        e.preventDefault();
        setDoneBtn(true);

        var data = {
            "comment": reinstateComment
        }

        try {
            await putToAgel(`dashboard/retailer/activationProcess/${current.id}/retentionBack`, data)
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setReinstateConfirmModal(false)
        updateData()
        setDoneBtn(false);
    }


    return (
        <div>
            <Modal show={true} onHide={() => setReinstateConfirmModal(false)} centered style={{ marginTop: "5em" }} className="dashboard-modal">
                <Modal.Header closeButton></Modal.Header>

                <Modal.Body>
                    <Container className="mt-3 text-center">
                        <img src="/images/revert.svg" className="img-fluid revertImg" alt="revertImg" />
                        <p className="revert-text mt-3"> Are you sure , You want to Reinstate ?</p>
                    </Container>

                    <Container>
                        <Form onSubmit={reinstate}>
                            <Row className='mt-5'>
                                <Col xs="12">
                                    <Form.Group>
                                        <Form.Label className="cancelation-label-form">Reinstate comment</Form.Label>
                                        <Form.Control type="text" className="newShipment-input" placeholder="Write here..." value={reinstateComment} onChange={(e) => setReinstateComment(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Modal.Footer className='d-flex justify-content-end align-items-center mt-5' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={doneBtn ? true : false}>
                                    {doneBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>
                                <button className="roleCloseBtn" onClick={() => setReinstateConfirmModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ReinstateConfirm

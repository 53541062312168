import React, { useEffect, useState } from 'react'
import { AiOutlineEdit } from 'react-icons/ai'
import { Table, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
//icons
import { BiMessageDetail } from 'react-icons/bi'

import TableSkeletonUI from '../common/loadingSkeletonUi/tableLoadingSkeletonUi';
import NotFoundText from '../common/notFoundText/notFoundText';

export default function RelationTable(props) {

    const {
        relations,
        setCurrentRelation,
        setEditRelationStatusModal,
        setRelationCommentModal,
        setCurrentCommentTxt,
        setCurrentCommentMaker,
        allFiltersChecker,
    } = props





    return (
        <div className='tables-layout text-center'>
            {relations.length === 0 && allFiltersChecker === true ?
                <NotFoundText />
                :
                <Container fluid>
                    <Table responsive>
                        <thead className='text-center'>
                            <tr className='role-table-header'>
                                <th className="text-center" >ID</th>
                                <th className="text-center" >wholesaler Name</th>
                                <th className="text-center" >retailer name</th>
                                <th className="text-center" >date</th>
                                <th className="text-center" >comment</th>
                                <th className="text-center" >status</th>
                                <th className="text-center" >actions</th>
                            </tr>
                        </thead>
                            <tbody className='text-center'>
                                {relations.map((i) =>
                                    <tr key={i.id} className='role-table-text'>
                                        <td className="text-center">{i?.id}</td>
                                        <td className="text-center">{i?.wholesalerName}</td>
                                        <td className="text-center">{i?.retailerName}</td>
                                        <td className="text-center">{(new Date(i?.createdAt).toLocaleDateString())}</td>
                                        <td className="text-center d-flex justify-content-center align-items-center" style={{ verticalAlign: "middle" }} >
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Check relation comment</Tooltip>}
                                            >
                                                <span style={{ margin: "0" }}>
                                                    <BiMessageDetail className='message-btn' style={{ margin: "0" }}
                                                        onClick={() => {
                                                            setRelationCommentModal(true);
                                                            setCurrentCommentTxt(i?.comment)
                                                            setCurrentCommentMaker(i?.createdBy)

                                                        }} />
                                                </span>
                                            </OverlayTrigger>
                                        </td>


                                        <td className='text-center' style={i.status === "active" ? { color: "#00BD85" } : null}>{i.status}</td>
                                        <td className="text-center" >
                                            {/* edit relation status */}
                                            <button
                                                className='role-edit-btn'
                                                style={{ width: "80%" }}
                                                onClick={() => {
                                                    setEditRelationStatusModal(true);
                                                    setCurrentRelation(i)
                                                }
                                                }>
                                                Edit<AiOutlineEdit />
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                    </Table>

                </Container>
            }
        </div>
    )
}
import React, { useState } from 'react'

import { Modal, Container } from "react-bootstrap"
import { putToAgel } from '../../utils/api';

import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

const RevertModal = (props) => {

    const {
        current,
        setRevertModal,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        updateData
    } = props


    //state checker on buttons 
    const [doneBtn, setDoneBtn] = useState(false)


    // revert
    const revert = async (e) => {

        e.preventDefault();
        setDoneBtn(true);

        var data = {
            "comment": "revert"
        }
        try {
            await putToAgel(`dashboard/wholesaler/activationProcess/${current.id}/revert`, data)
            updateData()
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setRevertModal(false)
    }


    return (
        <div>
            <Modal show={true} onHide={() => setRevertModal(false)} centered size="lg" style={{ marginTop: "5em" }} className="revertModal">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Container className="mt-3 text-center">
                        <img src="/images/revert.svg" className="img-fluid revertImg" alt="revertImg" />
                        <p className="revert-text mt-3"> Are you sure , You want to revert ?</p>
                    </Container>
                </Modal.Body>

                <Modal.Footer as="container" className='mt-3 d-flex justify-content-end align-items-center'>
                    <button className="role-add-btn-with-loader-spinner" type="submit" onClick={revert} disabled={doneBtn ? true : false}>
                        {doneBtn ?
                            (<LoadingBtnSpinner />)
                            : (<span>Done</span>)}
                    </button>

                    <button className="roleCloseBtn" onClick={() => setRevertModal(false)}>Close</button>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default RevertModal

import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router';
import { Col, Row } from 'react-bootstrap';

import { getFromAgel } from '../../utils/api';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';
import { userPermissionsAndActionsContext } from '../../App';

//components
import BasicInfo from '../../components/Retailers/BasicInfo'
import SideBar from '../../components/Retailers/SideBar';
import PaymentsDetails from '../../components/Retailers/payments';
import Stores from '../../components/Retailers/stores';
import UpdateProfileIdImg from '../../components/Retailers/UpdateIdImages';
import SuccessModal from '../../components/common/SuccessModal';
import Failed_Modal from '../../components/common/FailedModal';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen';

export default function WsProfile(props) {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext);
    let viewRetailerProfileDetailsPermission = userValidPermissionAndAction(validPermissionsAndActions, "retailer", "viewRetailerById");
    let viewStoreTablePermission = userValidPermissionAndAction(validPermissionsAndActions, "retailerStores", "viewRStoresByRetailerId");
    let viewPaymentTablePermission = userValidPermissionAndAction(validPermissionsAndActions, "retailerPayment", "viewRetailerPayment");
    let viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity");
    let viewIndustriesPermission = userValidPermissionAndAction(validPermissionsAndActions, "industry", "viewIndustry");

    // params
    const params = useParams();

    // state
    const [retailer, setRetailer] = useState({});
    //we are intializing retailer status state here to be set in the switch at the same time i opened profile cuz there is lag in the basic info component setting status
    const [retailerStatus, setRetailerStatus] = useState("") //de han7ot feha el status el gya b eh
    const [retailerStoreLocation, setRetailerStoreLocation] = useState({});
    const [imgSrc, setImgSrc] = useState("");
    const [retailerPayment, setRetailerPayment] = useState([]);
    const [retailerStores, setRetailerStores] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [cities, setCities] = useState([]);
    const [toggleStorePayment, setToggleStorePayment] = useState(false);
    const [allLabels, setAllLabels] = useState([]);
    const [retailerLabels, setRetailerLabels] = useState([]);
    const [retailerUpdatedLabels, setRetailerUpdatedLabels] = useState([]);
    const [notes, setNotes] = useState([]);

    const [retailer_front_id_image, set_retailer_front_id_image] = useState(false);
    const [retailer_back_id_image, set_retailer_back_id_image] = useState(false);

    const [update_id_images_modal, set_update_id_images_modal] = useState(false);
    const [current_selected_id_image, set_current_selected_id_image] = useState(false);
    //retailer corrupted img test
    const [frontIdcorruptedImg, setFrontIdcorruptedImg] = useState(false);
    const [backIdcorruptedImg, setBackIdcorruptedImg] = useState(false);
    //guarantor corrupted img test
    const [guarantorFrontIdCorruptedImg, setGuarantorFrontIdCorruptedImg] = useState(false);
    const [guarantorBackIdCorruptedImg, setGuarantorBackIdCorruptedImg] = useState(false);

    const [guarantor_front_id_image, set_guarantor_front_id_image] = useState(false);
    const [guarantor_back_id_image, set_guarantor_back_id_image] = useState(false);

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false);
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false);
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("");



    // fetch retailer Data
    const fetchData = async () => {
        try {
            /* get retailer info account */
            if (viewRetailerProfileDetailsPermission) {
                const response = await getFromAgel(`${process.env.React_APP_GET_RETAILER_ACCOUNT_INFO}${params.id}`);
                setRetailer({ ...response.data.retailer });
                // console.log("stat=",response?.data?.retailer?.status)
                setRetailerStatus(response?.data?.retailer?.status);
                // console.log("org location=", response.data.store)
                setRetailerStoreLocation({ ...response.data.store });
                setImgSrc(response.data.retailer.logo_image);
                setRetailerLabels(response.data.labels);
                setRetailerUpdatedLabels(response.data.labels);
                setNotes(response.data.notes);
                // console.log("notes ret data=", response.data.notes)
            }

            //get cities //view cities permission
            if (viewCitiesPermission) {
                const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
                // // console.log("cityResponse=", cityR esponse.data.cities) 
                setCities(cityResponse.data.cities);
            }

            //industries //view industries permission
            if (viewIndustriesPermission) {
                const industryResponse = await getFromAgel(`${process.env.React_APP_GET_INDUSTRIES}`);
                // console.log("industryResponse=", industryResponse.data.industries)
                setIndustries(industryResponse.data.industries);
            }

            // payments //view payments permission
            if (viewPaymentTablePermission) {
                const paymentResponse = await getFromAgel(`dashboard/retailer/${params.id}/payments`);
                setRetailerPayment(paymentResponse.data.payments);
            }

            //fetch all labels
            const allLabelsResponse = await getFromAgel(`${process.env.React_APP_GET_LABELS_INSIDE_RETAILER_ACCOUNT}${params.id}`);
            // console.log("allLabelsResponse=", allLabelsResponse.data.labels)
            setAllLabels(allLabelsResponse.data.labels);
            // console.log("retid",params.id)

            // stores //view stores permission
            if (viewStoreTablePermission) {
                const storeResponse = await getFromAgel(`dashboard/retailer/${params.id}/stores`);
                setRetailerStores(storeResponse.data.stores);
            }



        }
        catch (e) {
            // console.log("error= ", e)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])


    return (
        <div className="profile-page-layout">

            {/* permission for basic info */}
            {viewRetailerProfileDetailsPermission ?
                <Row>
                    <Col className="side-bar-profile" sm={2}>
                        <SideBar
                            retailer={retailer}
                            setRetailer={setRetailer}
                            imgSrc={imgSrc}
                            setImgSrc={setImgSrc}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                    </Col>

                    <Col className="mt-5" sm={{ offset: 1, span: 9 }}>
                        <BasicInfo
                            retailer={retailer}
                            retailerStatus={retailerStatus}
                            setRetailerStatus={setRetailerStatus}
                            retailerStoreLocation={retailerStoreLocation}
                            setRetailer={setRetailer}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            allLabels={allLabels}
                            retailerLabels={retailerLabels}
                            retailerUpdatedLabels={retailerUpdatedLabels}
                            setRetailerUpdatedLabels={setRetailerUpdatedLabels}
                            notes={notes}
                            industries={industries}
                            cities={cities}
                            updateData={fetchData}
                        />

                        {/* id's images */}

                        {/* user id images */}
                        <Row className="mt-5 d-flex justify-content-center align-items-center">

                            {retailer?.RetailersValifyData?.nationalIdOrPassportImage || retailer?.RetailersValifyData?.nationalIdBackImage ?
                                <h5 className='account-profile-titles mb-4'>Retailer id images</h5>
                                : null
                            }

                            <Col xs="6" className='d-flex justify-content-center align-items-center'>
                                {
                                    !frontIdcorruptedImg && (retailer?.RetailersValifyData?.nationalIdOrPassportImage != null) ?
                                        <img src={retailer?.RetailersValifyData?.nationalIdOrPassportImage} alt="profile-nationalIdImage" className='img-fluid' style={{ width: "50%" }} onError={() => setFrontIdcorruptedImg(true)} onClick={() => { set_current_selected_id_image(retailer?.RetailersValifyData?.nationalIdOrPassportImage); set_retailer_front_id_image(true); set_retailer_back_id_image(false); set_guarantor_front_id_image(false); set_guarantor_back_id_image(false); set_update_id_images_modal(true); }} />
                                        : null}
                            </Col>
                            <Col xs="6" className='d-flex justify-content-center align-items-center'>
                                {
                                    !backIdcorruptedImg && (retailer?.RetailersValifyData?.nationalIdBackImage != null) ?
                                        <img src={retailer?.RetailersValifyData?.nationalIdBackImage} alt="profile-nationalIdBackImage" className='img-fluid' style={{ width: "50%" }} onError={() => setBackIdcorruptedImg(true)} onClick={() => { set_current_selected_id_image(retailer?.RetailersValifyData?.nationalIdBackImage); set_retailer_front_id_image(false); set_retailer_back_id_image(true); set_guarantor_front_id_image(false); set_guarantor_back_id_image(false); set_update_id_images_modal(true); }} />
                                        : null
                                }
                            </Col>
                        </Row>

                        {/* guarantor id images */}
                        <Row className="mt-5 d-flex justify-content-center align-items-center">
                            {retailer && retailer.guarantorIdImage || retailer && retailer.guarantorIdBackImage ?
                                <h5 className='account-profile-titles mb-4'>Guarantor id images</h5>
                                : null
                            }

                            <Col xs="6" className='d-flex justify-content-center align-items-center'>

                                {
                                    !guarantorFrontIdCorruptedImg && (retailer && retailer.guarantorIdImage != null) ?
                                        <img src={retailer && retailer.guarantorIdImage} alt="profile-nationalIdImage" className='img-fluid' style={{ width: "50%" }} onError={() => setGuarantorFrontIdCorruptedImg(true)} onClick={() => { set_current_selected_id_image(retailer && retailer.guarantorIdImage); set_retailer_front_id_image(false); set_retailer_back_id_image(false); set_guarantor_front_id_image(true); set_guarantor_back_id_image(false); set_update_id_images_modal(true); }} />
                                        :
                                        null
                                    // <span>
                                    //     Guarantor front id Image is not found. click to choose one!
                                    // </span>
                                }
                            </Col>
                            <Col xs="6" className='d-flex justify-content-center align-items-center'>
                                {
                                    !guarantorBackIdCorruptedImg && (retailer && retailer.guarantorIdBackImage != null) ?
                                        <img src={retailer && retailer.guarantorIdBackImage} alt="profile-nationalIdBackImage" className='img-fluid' style={{ width: "50%" }} onError={() => setGuarantorBackIdCorruptedImg(true)} onClick={() => { set_current_selected_id_image(retailer && retailer.guarantorIdBackImage); set_retailer_front_id_image(false); set_retailer_back_id_image(false); set_guarantor_front_id_image(false); set_guarantor_back_id_image(true); set_update_id_images_modal(true); }} />
                                        : null
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                : <AuthorizationRequiredScreen />
            }

            {/* lower table for payment and stores */}
            <Row className="mt-5 m-3">
                <Col xs="5" className='tables-in-profile-container'>
                    <Row>
                        <Col xs="6" className='d-flex justify-content-center align-items-center'>
                            <button onClick={() => setToggleStorePayment(false)} className={toggleStorePayment === false ? "profile-store-payments-btn-selected" : "profile-store-payments-btn-unselected"}>
                                <span>Stores</span>
                            </button>
                        </Col>
                        <Col xs="6" className='d-flex justify-content-center align-items-center'>
                            <button onClick={() => setToggleStorePayment(true)} className={toggleStorePayment === true ? "profile-store-payments-btn-selected" : "profile-store-payments-btn-unselected"}>
                                <span>Payments</span>
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <div className="mb-5">
                {toggleStorePayment ?
                    viewPaymentTablePermission ?
                        <PaymentsDetails
                            retailerPayment={retailerPayment}
                            retailer={retailer}
                            retailerId={params.id} />
                        :
                        <AuthorizationRequiredScreen />
                    :
                    viewStoreTablePermission ?
                        <Stores
                            retailerStores={retailerStores}
                        />
                        :
                        <AuthorizationRequiredScreen />
                }
            </div>


            {/* update profile id's images */}
            {update_id_images_modal ?
                <UpdateProfileIdImg
                    set_update_id_images_modal={set_update_id_images_modal}
                    imgSrc={current_selected_id_image}
                    setImgSrc={set_current_selected_id_image}
                    id={params.id}
                    retailer_front_id_image={retailer_front_id_image}
                    retailer_back_id_image={retailer_back_id_image}
                    guarantor_front_id_image={guarantor_front_id_image}
                    guarantor_back_id_image={guarantor_back_id_image}
                    updateData={fetchData}
                    setSuccessModal={setSuccessModal}
                    setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                    setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                />
                : null
            }

            {/* success server response model */}
            {
                successModal ?
                    <SuccessModal
                        setSuccessModal={setSuccessModal}
                        updateData={fetchData}
                    />
                    : null
            }

            {/* failed server response model with error msg */}

            {failedModalWithErrorMsg ?
                (<Failed_Modal
                    setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                    errorMsg={errorMsgInFailedModal}
                />)
                : null
            }

        </div>
    )
}

import React, { useState } from 'react'
import { Form, Modal, Container } from "react-bootstrap"
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

const ResetPasswordStaffModal = (props) => {
    //props
    let {
        setResetPasswordStaffModal,
        currentStaffModal,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props;

    //password input Btn's values
    const [newPassword, setPassword] = useState("");
    const [passwordMsgLength, setPasswordMsgLength] = useState(false);

    // btn state checker
    const [resetBtn, setResetBtn] = useState(false)

    const submitPassword = async (e) => {
        e.preventDefault();
        setResetBtn(true)

        if (newPassword.length >= 8) {
            setPasswordMsgLength(false);
            var data = {
                "newPassword": newPassword
            }
            try {
                await putToAgel(`${process.env.React_APP_RESET_STAFF_PASSWORD}${currentStaffModal.id}`, data)
                setSuccessModal(true)
            }
            catch (err) {
                setErrorMsgInFailedModal(err?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
            updateData();
            setResetPasswordStaffModal(false)
        }
        else {
            setPasswordMsgLength(true);
            setResetBtn(false)
        }
    }

    return (
        <div>
            <Modal show={true} onHide={() => setResetPasswordStaffModal(false)} centered>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Reset staff password </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <Container className="mt-3">
                        <Form onSubmit={submitPassword}>

                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Password</Form.Label>
                                <Form.Control type="Password" className="role-input mt-2" placeholder="Enter Password" value={newPassword} onChange={(e) => setPassword(e.target.value)} required />
                            </Form.Group>

                            {/* incase password length is too small */}
                            {passwordMsgLength && (<p className="password-confirm-msg">Password should be at least 8 digits!</p>)}


                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={resetBtn ? true : false}>
                                    {resetBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setResetPasswordStaffModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default ResetPasswordStaffModal

import React, { useState } from 'react'
import { Form, Modal, Container } from 'react-bootstrap';
import { postToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

const CreateModal = (props) => {

    // props
    const {
        setShowCreate,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
    } = props

    //create btn vars
    const [createArabicName, setCreateArabicName] = useState('')
    const [createEnglishName, setCreateEnglishName] = useState('')
    const [createCountryCode, setCreateCountryCode] = useState('')
    const [createActive, setCreateActive] = useState(false)

    //create btn state checker
    const [createBtn, setCreateBtn] = useState(false)


    //post create
    const submitCreate = async (e) => {

        e.preventDefault();
        setCreateBtn(true);

        var data = {
            'ArName': createArabicName,
            'EnName': createEnglishName,
            'isActive': createActive,
            // 'countryCode': createCountryCode

        }
        try {
            await postToAgel(`${process.env.React_APP_CREATE_NEW_COUNTRY}`, data)
            setSuccessModal(true)
        }
        catch (e) {
            setErrorMsgInFailedModal(e?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setCreateBtn(false);
        updateData();
        setShowCreate(false)
    }

    return (
        <div>
            <Modal show={true} onHide={() => setShowCreate(false)} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                
                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={submitCreate}>
                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Arabic Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Arabic Name" value={createArabicName} onChange={(e) => setCreateArabicName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">English Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter English Name" value={createEnglishName} onChange={(e) => setCreateEnglishName(e.target.value)} required />
                            </Form.Group>

                            {/* <Form.Group className="mb-3">
                                <Form.Label className="form-text">Country Code</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Country Code" value={createCountryCode} onChange={(e) => setCreateCountryCode(e.target.value)} required />
                            </Form.Group> */}

                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" className="status form-text mt-3" label="isActive" value={createActive} onChange={(e) => setCreateActive(e.target.checked)} />
                            </Form.Group>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={createBtn ? true : false}>
                                    {createBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setShowCreate(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CreateModal

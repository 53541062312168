import React, { useState, useContext } from 'react'
import { Form } from 'react-bootstrap'
import { postToAgel } from '../../utils/api'
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions'
import { userPermissionsAndActionsContext } from '../../App'


export default function GenerateOTPBody() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewOTPScreenPermission = userValidPermissionAndAction(validPermissionsAndActions, "otp", "generate otp")


    const [phoneNumber, setPhoneNumber] = useState("")
    //generate otp value states
    const [otpValue, setOtpValue] = useState("")
    const [otpErrorMsg, setOtpErrorMsg] = useState("")
    const [loadingOtpResponse, setLoadingOtpResponse] = useState(false)


    //handle generate otp in separate screen
    const generateOtp = async (e) => {
        e.preventDefault()
        setOtpErrorMsg("")
        setOtpValue("")
        setLoadingOtpResponse(true)

        var data = {
            "phoneNumber": phoneNumber && phoneNumber
        }

        try {
            const otpResponse = await postToAgel(`dashboard/generate-otp`, data)
            setOtpValue(otpResponse?.data?.OTP)
        }
        catch (e) {
            setOtpErrorMsg(e?.response?.data?.message)
        }
        setLoadingOtpResponse(false)
    }


    if (viewOTPScreenPermission) {
        return (
            <div className='mt-5'>

                {/* title */}
                <div className='d-grid justify-content-center align-items-center'>
                    <span className='screen-otp-title'>
                        Enter a phone number
                    </span>

                    {/* form */}
                    <Form className="d-flex jsutify-content-center align-items-center mt-5" onSubmit={generateOtp}>
                        <Form.Group style={{ marginRight: "2rem" }}>
                            <Form.Control type="text" placeholder="type here..." className='screen-otp-phoneNumber' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                        </Form.Group>

                        <button type="submit" className='screen-otp-submit-btn'>
                            Generate OTP
                        </button>
                    </Form>

                    {/* otp value result */}
                    <div className='screen-otp--value-result'>
                        {otpErrorMsg ?
                            <span className='screen-otp-error-msg'>{otpErrorMsg}</span>
                            : otpValue ?
                                <span className='screen-otp-value'>{otpValue}</span>
                                : loadingOtpResponse ?
                                    <span className='screen-otp-loading'>Loading...</span>
                                    : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}
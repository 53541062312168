import React from 'react';
import './notFoundText.css';

export default function NotFoundText (){
    return(
        <div className='d-flex justify-content-center align-items-center mt-5'>
           <p className='notFountText'>
           No Data Found! 
           </p>
        </div>
    )
}
import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { getFromAgel } from '../../utils/api'
import Select from 'react-select';
import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';


export default function StatisticsDataFilters(props) {
    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity")

    const {
        fromDate,
        setFromDate,
        setToDate,
        toDate,
        setFilteredCity,
        fetchStatisticsData
    } = props


    const [cities, setCities] = useState([]);


    useEffect(() => {
        const fetchCities = async () => {
            try {
                //get cities //view cities permission
                if (viewCitiesPermission) {
                    const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
                    // // console.log("cityResponse=", cityR esponse.data.cities)
                    setCities(cityResponse.data.cities)
                }
            }
            catch (err) {
                // console.log('error=', err)
            }
        }
        fetchCities()
    }, [])


    //send filters to get updated statistics data at specefic time and zone
    const filterDate = async (e) => {
        e.preventDefault()
        fetchStatisticsData()
    }


    //cities with selectAll option b id=0 bta3o
    let dashboardCities = cities && cities.map((city) => ({ "value": city.id, "label": city.EnName }))
    let selectAllOption = { "value": 0, "label": "All Cities" }
    let allCitiesOption = [selectAllOption, ...dashboardCities] //rest operator by merge array b ay 7aga tane

    return (
        //filter form
        <Form onSubmit={filterDate}>
            <Row className="layout-top">
                <Col xs="3">
                    <Form.Group>
                        <Form.Label className="home-label-form">From</Form.Label>
                        <Form.Control type="date" className="date-home-input" value={fromDate} onChange={(e) => setFromDate(e.target.value)} required />
                    </Form.Group>
                </Col>
                <Col xs="3">
                    <Form.Group>
                        <Form.Label className="home-label-form">To</Form.Label>
                        <Form.Control type="date" className="date-home-input" value={toDate} onChange={(e) => setToDate(e.target.value)} required />
                    </Form.Group>
                </Col>
                <Col xs="3 text-start">
                    <Form.Group>
                        <Form.Label className="home-label-form">City</Form.Label>
                        <Select
                            className="search-select home-select-city-filter"
                            defaultValue={{ "label": "Select city...", "value": 0 }}
                            onChange={(e) => { setFilteredCity(e.value) }}
                            options=
                            {
                                //if user has cities permission "All Cities" option will appear else it wil disapear just like the other cities options
                                validPermissionsAndActions?allCitiesOption:[]
                            }
                        />
                    </Form.Group>
                </Col>
                <Col xs="3" className='d-flex align-items-end justify-content-start'>
                    <button type="submit" className='home-filter-date-btn' >Filter</button>
                </Col>
            </Row>
        </Form>

    )

}
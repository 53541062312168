import React, { useState } from 'react'
import { Modal, Form, Container } from 'react-bootstrap'
import { postToAgel } from '../../utils/api'

//components
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn'

export default function AddNoteModal(props) {

    let {
        setAddNote,
        id,
        paymentId,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
    } = props

    const [newNote, setNewNote] = useState("")

    //submit btn state checker
    const [submitBtn, setSubmitBtn] = useState(false)


    const submitNote = async (e) => {
        e.preventDefault();
        setSubmitBtn(true)

        var data = paymentId ?
            {
                "location": "invoice",
                "paymentId": paymentId,
                "note": newNote
            }
            : {
                "location": "account",
                "note": newNote
            }

        try {
            /* create new note */
            await postToAgel(`${process.env.React_APP_RETAILER_CREATE_NEW_NOTE}${id}`, data)
            updateData()
            setSuccessModal(true)
        }
        catch (error) {
            setFailedModalWithErrorMsg(true)
            setErrorMsgInFailedModal(e?.response?.data?.message)
        }
        setSubmitBtn(false)
        setAddNote(false)
    }



    return (
        <Modal show={true} onHide={() => setAddNote(false)} className="dashboard-modal mt-5" centered>
            <Modal.Header closeButton>
                <strong className="add-note-title">Add Notes</strong>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form onSubmit={submitNote}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="add-note-form-label">Note</Form.Label>
                            <Form.Control as="textarea" className="add-note-form-textarea" placeholder="Note..." rows={4} value={newNote} onChange={(e) => setNewNote(e.target.value)} required />
                        </Form.Group>

                        <Modal.Footer className='addNote-footer'>
                            <div className='d-flex justify-content-center align-items-center' style={{ width: "100%" }}>
                                <button className='submit-addNote-btn' type="submit" disabled={submitBtn ? true : false}>
                                    {submitBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Submit</span>)}
                                </button>

                                <button className='cancel-addNote-btn' onClick={() => setAddNote(false)} style={{ marginLeft: "0.5em" }}>Cancel</button>
                            </div>
                        </Modal.Footer>

                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
}
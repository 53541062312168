import React from 'react';
import { Container, Modal } from "react-bootstrap";

const TransferMoneyImageModal = (props) => {
  // props
  const {
    setTransferedMoneyImageModalFlag,
    transferedMoneyImage,
    setBillCorruptedImg,
  } = props;

  return (
    <div>

      <Modal show onHide={() => setTransferedMoneyImageModalFlag(false)} className="dashboard-modal mt-5" centered>
        <Modal.Header closeButton />
        <Container>
          <img onError={() => setBillCorruptedImg(true)} className="img-fluid" alt="transfered-money-img" src={transferedMoneyImage && transferedMoneyImage} />
        </Container>
        <Modal.Body>

          <Modal.Footer className="d-flex justify-content-end align-items-center" style={{ width: "100%" }}>
            <button type="button" className="roleCloseBtn" onClick={() => setTransferedMoneyImageModalFlag(false)}>Close</button>
          </Modal.Footer>

        </Modal.Body>
      </Modal>

    </div>
  );
};

export default TransferMoneyImageModal;

import React, { useEffect, useState } from "react";
import {
    Form, Row, Col, InputGroup, Spinner,
} from "react-bootstrap";
import { CgCloseR } from "react-icons/cg";
import { getFromAgel } from "../../utils/api";

export default function SelectedCustomers(props) {
    const {
        searchByNumber,
        setSearchByNumber,
        userType,
        searchedResultNames,
        setSearchedResultNames,
    } = props;

    const [disableInputSearch, setDisableInputSearch] = useState(false);
    const [notExistNumber, setNotExistNumber] = useState(false);

    const searchUser = async () => {
        if (searchByNumber.length === 11) {
            setDisableInputSearch(true);
            try {
                if (userType === "Retailer") {
                    const retailerResponse = await getFromAgel(`${process.env.React_APP_GET_RETAILER_DATA_TABLE}`, {
                        params: {
                            page: 0,
                            size: 20,
                            filterByPhoneOrName: searchByNumber,
                        },

                    });
                    retailerResponse.data.retailers.rows.length !== 0 ? setSearchedResultNames((prev) => [...prev, retailerResponse.data.retailers.rows[0]]) : setNotExistNumber(true);
                } else if (userType === "Wholesaler") {
                    const wholesalerResponse = await getFromAgel(`${process.env.React_APP_GET_WS_DATA_TABLE}`, {
                        params: {
                            page: 0,
                            size: 20,
                            filterByPhoneOrName: searchByNumber,
                        },

                    });
                    wholesalerResponse.data.wholesalers.rows.length !== 0 ? setSearchedResultNames((prev) => [...prev, wholesalerResponse.data.wholesalers.rows[0]]) : setNotExistNumber(true);
                }
            } catch (err) {
                //
            }
            setSearchByNumber('');
            setDisableInputSearch(false);
        }
    };

    // this only for setstate of number update lag
    useEffect(() => {
        searchUser();
    }, [searchByNumber]);

    // remove user from list
    const removeUser = (id) => {
        const selectedNumbers = searchedResultNames.filter((i) => i.id !== id);
        setSearchedResultNames(selectedNumbers);
    };

    return (
        <>
            <div className="mt-4">
                <span className="notification-search-by-number-title">
                    Search by number
                </span>
                <Form className="mt-2">
                    {disableInputSearch
                        ? (
                            <InputGroup>
                                <Form.Control type="text" placeholder="Search by number" className="notification-disabled-search-input" value={searchByNumber} disabled />
                                <InputGroup.Text className="notification-search-by-number-loader">
                                    <Spinner animation="border" />
                                </InputGroup.Text>
                            </InputGroup>
                        )
                        : (
                            <Form.Group>
                                <Form.Control type="text" placeholder="Search by number" maxLength={11} className="notification-search-by-number-input" value={searchByNumber} onChange={(e) => { setSearchByNumber(e.target.value); setNotExistNumber(false); }} required />
                            </Form.Group>
                        )}
                </Form>
                {notExistNumber &&
                    <span className="notification-search-by-number-error-msg"> phone number not exist!</span>
                }
                <div className="mt-2 notification-result-names">
                    <Row>
                        {searchedResultNames?.map((i) => (
                            <Col key={i?.id} xs="6" className="mb-2" dir="rtl">
                                <div className="d-flex justify-content-between align-items-center notification-searched-name">
                                    <span>{i?.name}</span>
                                    <CgCloseR className="notification-delete-name-icon" onClick={() => { removeUser(i.id); }} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </>
    );
}

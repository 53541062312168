import React, { useState, useContext } from 'react';

// components
import ActivationForm from './ActivationForm';
import UpdateProfileImg from './UpdateProfileImg';
import DashboardLogs from '../common/DashboardLogs/DashboardLogs';

import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';

export default function BasicInfo(props) {
  const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
  let viewLogsButtonPermission = userValidPermissionAndAction(validPermissionsAndActions, "", "")

  const {
    name, createdAt, id, status,
  } = props.retailer;
  const {
    setImgSrc,
    imgSrc,
    setSuccessModal,
    setFailedModalWithErrorMsg,
    setErrorMsgInFailedModal,
  } = props;

  const [showAF, setShowAF] = useState(false);
  const [profileImgModal, setProfileImgModal] = useState(false);
  const [dashboardLogsOffcanvas, setDashboardLogsOffcanvas] = useState(false);

  return (
    <div className="mt-5">
      <div className="text-center mt-2">
        <img
          src={imgSrc}
          className="img-fluid"
          style={{ width: "100%", height: "100%" }}
          alt="account-img"
          onClick={() => {
            setProfileImgModal(true); console.log("profileImgSet");
          }}
        />
      </div>
      <div className="mt-4 text-start">
        <span className="span-profile text-muted">Name</span>
        <span className="span-profile">{name}</span>
      </div>
      <div className="mt-3 text-start">
        <span className="span-profile text-muted">Created at</span>
        <span className="span-profile">{new Date(createdAt).toLocaleDateString()}</span>
      </div>
      <div className="mt-3 text-start">
        <span className="span-profile text-muted">Status</span>
        <span className="span-profile">{status}</span>
      </div>
      <div className="mt-3 text-start">
        <span className="span-profile text-muted">ID</span>
        <span className="span-profile">{id}</span>
      </div>

      {/* logs only visible for superadmin */}
      {/* {viewLogsButtonPermission &&
        <button className="mt-3 retailer-profile-logs dashboard-logs-btn" type="button" onClick={() => setDashboardLogsOffcanvas(true)}>
          Logs
        </button>
      } */}

      {profileImgModal
        ? (
          <UpdateProfileImg
            setProfileImgModal={setProfileImgModal}
            imgSrc={imgSrc}
            setImgSrc={setImgSrc}
            id={id}
            setSuccessModal={setSuccessModal}
            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
          />
        )
        : null}

      {dashboardLogsOffcanvas
        ? (
          <DashboardLogs
            setDashboardLogsOffcanvas={setDashboardLogsOffcanvas}
          />
        )
        : null}

      {showAF
        ? (
          <ActivationForm
            setShowAF={setShowAF}
            showAF={showAF}
            retailerId={id}
          />
        )
        : null}
    </div>

  );
}

import React, { useState, useEffect } from "react";
import { Modal, CloseButton, Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import { getFromAgel, postToAgel, putToAgel } from '../../utils/api';
//components
import LoadingBtnSpinner from "../common/LoadingBtn/LoadingBtn";

export default function WsCreateInvoiceSecondModal(props) {
    let {
        setCreateInvoicesModal,
        setCreateInvoicesModalTwo,
        sumOfTotalOfAllItems,
        serialNumber,
        transactionId,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        setSuccessModal,

    } = props

    const [retailers, setRetailers] = useState([])
    const [selectedRetailer, setSelectedRetailer] = useState("")
    const [selectedRetailerValidation, setSelectedRetailerValidation] = useState(false)


    const [retailerWallet, setRetailerWallet] = useState("")
    const [retailerStores, setRetailerStores] = useState([])


    const [paymentPlan, setPaymentPlan] = useState([])
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState("")
    const [selectedPaymentPlanValidation, setSelectedPaymentPlanValidation] = useState(false)

    const [installment, setInstallment] = useState("")
    const [total, setTotal] = useState("")
    const [dates, setDates] = useState([])
    const [paymentPlanErrorMsg, setPaymentPlanErrorMsg] = useState("")

    //submit btn state checker
    const [submitBtn, setSubmitBtn] = useState(false)





    const backToTransactionModalOne = async () => {
        setCreateInvoicesModalTwo(false);
        setCreateInvoicesModal(true);
    }


    //fetch retailers
    async function fetchRetailer() {
        try {

            //retailers
            const retailersResponse = await getFromAgel(`dashboard/retailers/names`, { params: { filterByPhoneOrName: filterByNameOrPhoneInRetailers } }) //,
            setRetailers(retailersResponse.data.retailersNames)
        }
        catch (e) {
            // console.log({ e })
        }
    }
    useEffect(() => {
        fetchRetailer()
    }, [])



    //search in retailer select bar
    const [filterByNameOrPhoneInRetailers, setFilterByNameOrPhoneInRetailers] = useState("")

    const updateRetailerSearchText = (e) => {
        // console.log("key=", e.key)
        let temp = e.key
        if (temp !== "Backspace") {
            setFilterByNameOrPhoneInRetailers((prev) => prev + temp)
        }
        else if (temp === "Backspace") {
            setFilterByNameOrPhoneInRetailers((prev) => prev.slice(0, -1))
        }
        // console.log({ filterByNameOrPhoneInRetailers })
    }

    useEffect(() => { //34an el late ele f el state
        fetchRetailer(filterByNameOrPhoneInRetailers)
    }, [filterByNameOrPhoneInRetailers])



    const getStorenameAndWalletAndPaymentPlanForSpecificRetailer = async (selectedRetailerId) => {
        setSelectedRetailer(selectedRetailerId)
        setPaymentPlanErrorMsg("")
        setSelectedRetailerValidation(false)
        setPaymentPlan([])
        try {
            const storenameAndWalletForSpecificRetailerResponse = await getFromAgel(`dashboard/retailers/store/${selectedRetailerId}`)
            setRetailerWallet(storenameAndWalletForSpecificRetailerResponse.data.retailer.wallet)
            setRetailerStores(storenameAndWalletForSpecificRetailerResponse.data.retailer.storesNames)

            //paymentPlans
            const paymentPlanResponse = await getFromAgel(`dashboard/transaction/retailer/${transactionId}/${selectedRetailerId}`)
            // console.log("paymentPlanResponse=", paymentPlanResponse?.data?.plans)
            setPaymentPlan(paymentPlanResponse.data.plans)
            setPaymentPlanErrorMsg("")
            // console.log("paymentPlanResponse=", paymentPlanResponse)
        }
        catch (e) {
            // console.log("error=",e)
            e && e.response && e.response.data && e.response.data.message && setPaymentPlanErrorMsg(e.response.data.message)
            // console.log("msg=", e.response.data.message)
        }
    }



    const getInstallmentAndTotalAndDates = (paymentPlanId) => {
        setSelectedPaymentPlanValidation(false)

        paymentPlan?.map((i) => {
            if (i.plan_id === paymentPlanId) {
                setInstallment(i.payment_amount)
                setTotal(i.loan_amount_total)
                setDates(i.dates)
                setSelectedPaymentPlan(paymentPlanId)
            }
        })
    }



    const createRetaierTransaction = async (e) => {
        e.preventDefault();

        var data = {
            "plan_id": selectedPaymentPlan,
            "selected_dates": dates
        }
        if (selectedPaymentPlan !== "" && selectedRetailer !== "") {
            setSubmitBtn(true)
            try {
                await postToAgel(`dashboard/transaction/retailer/submit/${transactionId}/${selectedRetailer}`, data)
                setSuccessModal(true)
            }
            catch (e) {
                setFailedModalWithErrorMsg(true)
                setErrorMsgInFailedModal(e?.response?.data?.message)
            }
            setCreateInvoicesModalTwo(false)
        }
        else if (selectedRetailer === "") {
            setSelectedRetailerValidation(true)
        }
        else if (selectedPaymentPlan === "") {
            setSelectedPaymentPlanValidation(true)

        }
        setSubmitBtn(false)
    }



    const cancelTransaction = async () => {
        try {
            await putToAgel(`dashboard/transaction/cancel/${transactionId}`)
            setCreateInvoicesModalTwo(false);
            setFailedModalWithErrorMsg(true)
            setErrorMsgInFailedModal("Transaction method has been canceled!")
        }
        catch (e) {
            //
        }
    }


    return (
        <>
            <Modal show={true} onHide={() => { setCreateInvoicesModalTwo(false); cancelTransaction() }} centered className="dashboard-modal" style={{ marginTop: "5em" }}>

                <Modal.Header>
                    <CloseButton onClick={cancelTransaction} />
                </Modal.Header>
                <Form onSubmit={(e) => createRetaierTransaction(e)}>
                    <Modal.Body>
                        {/* select retailer */}
                        <Row>
                            <Col xs="6">
                                <span className='profile-label-text' style={{ fontSize: "0.95em" }}>
                                    Retailers
                                </span>

                                <Select
                                    className="search-select mt-2"
                                    defaultValue={{ "label": "Select...", "value": 0 }}
                                    onChange={(e) => { getStorenameAndWalletAndPaymentPlanForSpecificRetailer(e.value) }}
                                    onKeyDown={(e) => { updateRetailerSearchText(e); }}
                                    onBlur={() => setFilterByNameOrPhoneInRetailers("")}
                                    options={retailers.map((i) => ({ "value": i && i.id, "label": i && i.name }))}
                                />
                            </Col>
                            {selectedRetailerValidation &&
                                <Col xs="12" className='mt-2'>
                                    <p className="select-error-msg" style={{ fontSize: "0.9rem" }}>select a retailer!</p>
                                </Col>
                            }

                        </Row>


                        <Row className="mt-4">
                            <Col xs="12">
                                <span className='profile-label-text' style={{ fontSize: "0.95em" }}>
                                    Wallet
                                </span>
                                <span className='ws-name-in-invoice-modal'>
                                    {retailerWallet && retailerWallet}
                                </span>
                            </Col>
                            <Col xs="12" className="mt-2">
                                <span className='profile-label-text' style={{ fontSize: "0.95em" }}>
                                    store name
                                </span>

                                {retailerStores && retailerStores.map((retailer, index) =>
                                    <span key={retailer.id} className='ws-name-in-invoice-modal'>
                                        {index === 0 ? retailer.name : `/ ${retailer.name}`}
                                    </span>
                                )}

                            </Col>
                        </Row>

                        {/* payment plan flag */}
                        {paymentPlanErrorMsg ?
                            <Row className="mt-4">
                                <span className="select-error-msg" style={{ fontSize: "0.9rem" }}>
                                    {paymentPlanErrorMsg}
                                </span>
                            </Row>
                            : null
                        }


                        {/* select payment plan */}
                        <Row className="mt-4">

                            <Col xs="6">

                                <span className='profile-label-text' style={{ fontSize: "0.95em" }}>
                                    payment plans
                                </span>

                                <Select
                                    className="search-select mt-2"
                                    defaultValue={{ "label": "select...", "value": 0 }}
                                    onChange={(e) => { getInstallmentAndTotalAndDates(e.value) }}
                                    options={paymentPlan?.map((i) => ({ "value": i && i.plan_id, "label": i?.name }))}
                                />
                            </Col>
                            {selectedPaymentPlanValidation &&
                                <Col xs="12" className='mt-2'>
                                    <p className="select-error-msg" style={{ fontSize: "0.9rem" }}>select a payment plan!</p>
                                </Col>
                            }

                        </Row>

                        <Row className="mt-4">
                            <Col xs="6">
                                <span className='profile-label-text' style={{ fontSize: "0.95em" }}>
                                    Installment
                                </span>
                                <span className='ws-name-in-invoice-modal'>
                                    {installment && installment}
                                </span>
                            </Col>
                            <Col xs="6">
                                <span className='profile-label-text' style={{ fontSize: "0.95em" }}>
                                    Total
                                </span>
                                <span className='ws-name-in-invoice-modal'>
                                    {total && total}
                                </span>
                            </Col>
                            <Col xs="6" className="mt-2">
                                <span className='profile-label-text' style={{ fontSize: "0.95em" }}>
                                    wholesaler Total
                                </span>
                                <span className='ws-name-in-invoice-modal'>
                                    {sumOfTotalOfAllItems && sumOfTotalOfAllItems}
                                </span>
                            </Col>
                        </Row>
                        {dates.length !== 0 ?
                            <>
                                <Row className="mt-3 text-center">
                                    <Col xs="12" className=" mt-2">
                                        <span className='profile-label-text transaction-border-bottom-dates' style={{ fontSize: "0.95em" }}>
                                            Dates
                                        </span>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    {dates && dates.map((i) =>

                                        <Col xs="6" className="ws-dates-in-invoice-modal">
                                            {i?.date}
                                        </Col>

                                    )}

                                </Row>
                            </>

                            : null}


                        <Modal.Footer className='addNote-footer'>
                            <div className='d-flex justify-content-center align-items-center' style={{ width: "100%" }}>
                                <button className='transaction-back-btn' onClick={() => { backToTransactionModalOne(); cancelTransaction() }}>Back</button>

                                <button className='submit-addNote-btn' type="submit" style={{ marginLeft: "0.5em" }} disabled={submitBtn ? true : false}>
                                    {submitBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Submit</span>)}
                                </button>

                            </div>
                        </Modal.Footer>

                    </Modal.Body>
                </Form>
            </Modal>

        </>
    )
}
import React from "react";
import { BiTransfer } from 'react-icons/bi';
import { GiReceiveMoney } from 'react-icons/gi';
import { BsCalendarEvent, BsFillPersonFill } from 'react-icons/bs'
import { Col, Row } from "react-bootstrap";

export default function CollectorTablesDetails(props) {
  const {
    collectorDetailsData,
    setTransferedMoneyImageModalFlag,
    setTransferedMoneyImage,
    billCorruptedImg,
    type,
  } = props;

  return (
    <div className="Collector-details-container mb-5" dir="rtl">

      {/* first info */}
      <Row>
        <Col xs="6" className="d-flex">
          <div className="d-flex justify-content-center align-items-center">
            <div className="collector-details-icon-container">
              <GiReceiveMoney className="collector-details-icon" />
            </div>
            <span>
              المبلغ المحصل
            </span>
          </div>
        </Col>
        <Col xs="6" className="text-center">
          <span>
            {collectorDetailsData?.amount} ج.م
          </span>
        </Col>
      </Row>

      {type == 'cashIn' ?
        <>
          < Row>
            <Col xs="6" className="d-flex">
              <div className="d-flex justify-content-center align-items-center">
                <div className="collector-details-icon-container">
                  <BsFillPersonFill className="collector-details-icon" />
                </div>
                <span>
                  اسم التاجر
                </span>
              </div>
            </Col>
            <Col xs="6" className="text-center">
              <span>
                {collectorDetailsData?.CollectorAssignedUser?.Retailer?.name}
              </span>
            </Col>
          </Row>

          <Row>
            <Col xs="6" className="d-flex">
              <div className="d-flex justify-content-center align-items-center">
                <div className="collector-details-icon-container">
                  <BsCalendarEvent className="collector-details-icon" />
                </div>
                <span>
                  تاريخ الاستلام
                </span>
              </div>
            </Col>
            <Col xs="6" className="text-center">
              <span>
                {collectorDetailsData?.date}
              </span>
            </Col>
          </Row>
        </>
        : null}

      {/* if imae not null and not corrupted*/}
      {
        type == 'cashIn' && !billCorruptedImg && (collectorDetailsData && collectorDetailsData.billImage) ?
          (
            <Row>
              <Col xs="6" className="d-flex">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="collector-details-icon-container">
                    <BiTransfer
                      className="collector-details-icon collector-clickable-icon"
                      onClick={() => {
                        setTransferedMoneyImageModalFlag(true);
                        setTransferedMoneyImage(collectorDetailsData?.billImage);
                      }}
                    />
                  </div>
                  <span>
                    صورة التحويل الألكتروني
                  </span>
                </div>
              </Col>
            </Row>
          )
          : null
      }

    </div >
  );
}

import React, { useState, useEffect } from 'react'

import { Modal, Container } from "react-bootstrap"
import { getFromAgel } from '../../utils/api'


const AFComment = (props) => {

    const {
        setShowAFComment,
        AFCancelComment,
} = props

    let sortedCancelationInfo = AFCancelComment && AFCancelComment.sort((a, b) => (b.id) - (a.id));

    return (
        <div>
            <Modal show={true} onHide={() => setShowAFComment(false)} centered size="lg" className="dasboardModal">
                <Modal.Header closeButton className="cancel-msg-modal-header">
                    Cancellation reasons
                </Modal.Header>
                <Modal.Body>
                    {sortedCancelationInfo && sortedCancelationInfo.map((i) =>
                        <Container className="mb-4" key={i.id}>

                            {/* cancelation date */}
                            <div className="cancel-msg-modal-title">
                                <p> Date
                                    <span className="cancelled-by">
                                        {(new Date(i && i.createdAt && i.createdAt).toLocaleDateString())}
                                    </span>
                                </p>
                            </div>

                            {/* cancelation info */}
                            <div className="reason-box">
                                <div className='mb-1'>
                                    <span className='retention-comment-title'>
                                        Cancelled by
                                    </span>
                                    <span className='retention-comment-subtitle'>
                                        {i && i.userName && i.userName}
                                    </span>
                                </div>

                                <div className='mb-1'>
                                    <span className='retention-comment-title'>
                                        Comment
                                    </span>
                                    <span className='retention-comment-subtitle'>
                                        {i && i.comment && i.comment}
                                    </span>
                                </div>

                            </div>
                        </Container>
                    )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Container className="text-end">
                        <button className="role-close-btn" onClick={() => setShowAFComment(false)}>Close</button>
                    </Container>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AFComment

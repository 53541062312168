import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IoArrowBackCircle } from 'react-icons/io5'
import { Row, Col } from 'react-bootstrap'
import './ServerErrorScreen.css'


export default function ServerErrorScreen() {
    let navigate = useNavigate();

    return (
        <div className="text-center">
            <div>
                <img src="/images/server-error.png" alt="not found img" className="img-error-screen" />
            </div>
            <Row className="d-flex justify-content-center align-items-center">
                <Col lg="2" md="3" xs="4">
                <button className='back-home-btn' onClick={() => { navigate(-1) }}><IoArrowBackCircle size={"18"} /> Go Back</button>
                </Col>
            </Row>
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import { Modal, Container, Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { deleteFromAgel, getFromAgel, postToAgel } from '../../../utils/api';


export default function EditLead(props) {

    let navigate = useNavigate()

    const {
        setEditLeadModal,
        currentLead,//ha5od mnha el id 34an am3l request ageb beh el data
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        userType
    } = props


    const [currentLeadData, setCurrentLeadData] = useState({})
    const [currentLeadHistory, setCurrentLeadHistory] = useState([{ id: 1, actionType: "changed", actionTaker: "gina", updatedRowName: "phone", currentValue: "02222", previousValue: "01111", updatedAt: "2022-1-14t13:13:14.072z" }])

    //data array of eact select box
    const [cities, setCities] = useState([])
    const [industries, setIndustries] = useState([])
    const [sources, setSources] = useState([])
    const [accountManagers, setAccountManagers] = useState([])
    const [customerAccounts, setCustomerAccounts] = useState([])

    // inputs states
    const [name, setName] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")
    const [alternativeNumber, setAlternativeNumber] = useState("")
    const [storeName, setStoreName] = useState("")
    const [address, setAddress] = useState("")

    // selection states
    const [filterOnCity, setFilterOnCity] = useState('');
    const [filterOnIndustry, setFilterOnIndustry] = useState('');
    const [filterOnSources, setFilterOnSources] = useState('');
    const [filterOnAccountManagers, setFilterOnAccountManagers] = useState('');
    const [filterOnCustomerAccounts, setFilterOnCustomerAccounts] = useState('');

    //btns flag
    const [showSaveDiscardEditsBtn, setShowSaveDiscardEditsBtn] = useState(false)
    const [showHistory, setShowHistory] = useState(false)



    const fetchfiltersSelectorsData = async () => {
        try {
            //get cities
            const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
            console.log("cityResponse=", cityResponse.data.cities)
            setCities(cityResponse.data.cities)

            //get industries
            const industryResponse = await getFromAgel(`${process.env.React_APP_GET_INDUSTRIES}`);
            console.log("industryResponse=", industryResponse.data.industries)
            setIndustries(industryResponse.data.industries)

            //get sources
            const sourcesResponse = await getFromAgel(`${""}`);
            console.log("sourcesResponse=", sourcesResponse.data)
            // setSources(sourcesResponse.data)

            //get accountManagers
            const accountManagersResponse = await getFromAgel(`${""}`);
            console.log("accountManagersResponse=", accountManagersResponse.data)
            // setAccountManagers(accountManagersResponse.data)

            //get customerAccounts
            const customerAccountsResponse = await getFromAgel(`${""}`, { params: { userType: userType } }); //lazm ab3t hena params hya ws wla retailer
            console.log("customerAccountsResponse=", customerAccountsResponse.data) //ha5od names w id w phones 
            // setCustomerAccounts(customerAccountsResponse.data)            
        }
        catch (e) {
            //
        }
    }
    useEffect(() => {
        fetchfiltersSelectorsData()
    }, [])


    //retrieve current lead data
    const getCurrentLeadData = async () => {
        try {
            const currentLeadDataResponse = await getFromAgel("")
            console.log("currentLeadDataResponse=", currentLeadDataResponse.data)
            // setCurrentLeadData(currentLeadDataResponse.data) //lma tgebe el data 7oteha f el intial value bta3t el state
            // setCurrentLeadHistory(currentLeadDataResponse.data.history)
        }
        catch (e) {
            //
        }
    }
    useEffect(() => {
        getCurrentLeadData()
    }, [])


    //edit btns behaviour 
    const editLead = (e) => {
        e.preventDefault();
        setShowSaveDiscardEditsBtn(true)
    }

    const discardEdits = (e) => {
        e.preventDefault();
        setShowSaveDiscardEditsBtn(false)
        setEditLeadModal(false)
    }


    //save edits of lead
    const saveEdits = async (e) => {
        e.preventDefault();

        var data = {
            "name": name,
            "phoneNumber": mobileNumber,
            "secondNumber": alternativeNumber,
            "CityId": filterOnCity,
            "IndustryId": filterOnIndustry,
            "SourceId": filterOnSources,
            "storeAdress": address,
            "storeName": storeName,
            "accountManagerId": filterOnAccountManagers,
            "userType": userType,
        }
        try {
            await postToAgel(``, data)
            setSuccessModal(true)
        }
        catch (error) {
            // console.log({error})
            // error.response.data.message[0] ? setErrorMsgInFailedModal(error.response.data.message[0]) : setErrorMsgInFailedModal(error.response.data.message)
            setFailedModalWithErrorMsg(true)
        }
        setEditLeadModal(false);
        updateData();
    }


    //delete lead
    const deleteLead = async () => {
        try {
            await deleteFromAgel(`dashboard/Lead/${currentLead.id}`)
            setSuccessModal(true)
        }
        catch (err) {
            // console.log({error})
            // error.response.data.message[0] ? setErrorMsgInFailedModal(error.response.data.message[0]) : setErrorMsgInFailedModal(error.response.data.message)
            setFailedModalWithErrorMsg(true)
        }
        updateData();
        setEditLeadModal(false);
    }

        //customer account filteration and navigation
    
        const searchForAccountsUsingPhoneNumber = (e) => {
            let temp = e.key
            if (temp !== "Backspace") {
                setFilterOnCustomerAccounts((prev) => prev + temp)
            }
            else if (temp === "Backspace") {
                setFilterOnCustomerAccounts((prev) => prev.slice(0, -1))
            }
        }
        // console.log({ filterOnCustomerAccounts })
    
        
        //34an lma yege y search feha b phone number
        let customerAccountAfterFilteration = filterOnCustomerAccounts !== "" ? customerAccounts.filter((i) => i && i.phonenumber.includes(filterOnCustomerAccounts)) : customerAccounts
    
        const navigateToCustomerAccountProfile = (id) => {
            if (userType === "wholesaler") {
                navigate(`/wholesalers/list/${id}/details`)
            }
            else if (userType === "retailer") {
                navigate(`/retailers/list/${id}/details`)
            }
        }

        
    return (
        <div>
            <Modal show={true} onHide={() => setEditLeadModal(false)} centered size="lg" className="acquisitionModal"> {/* dasboardModal 37rem */}
                <Modal.Header closeButton className="cancel-msg-modal-header">
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        {/* m7taga a3ml hena el row ele fe col ll btns bta3t el edit w col ll created by */}
                        <Row className="d-flex align-items-center mb-5" style={{ marginLeft: "-1rem", marginRight: "-4.7rem" }}>
                            <Col xs="6">
                                {
                                    !showSaveDiscardEditsBtn ?
                                        (<button className='acquisition-modal-action-btn acquisition-edit-btn' onClick={editLead}>
                                            edit lead
                                        </button>)
                                        :
                                        (
                                            <div className='d-flex justify-content-start align-items-center'>
                                                <button className='acquisition-modal-action-btn acquisition-edit-btn' type='submit' onClick={saveEdits}>
                                                    save
                                                </button>
                                                <button className='acquisition-modal-action-btn acquisition-discard-btn' onClick={discardEdits}>
                                                    Discard
                                                </button>
                                            </div>
                                        )

                                }
                            </Col>
                            <Col xs="6">
                                <span className='profile-label-text'>
                                    created by:
                                </span>
                                <span className='profile-label-text' style={{ fontWeight: "900", marginLeft: "0.3rem" }}>
                                    {"gina aayad mahfouz"}
                                </span>
                            </Col>
                        </Row>


                        <Row style={{ marginLeft: "-1rem", marginRight: "-4.7rem" }}> {/* 7aten el margins dol 34an nozbot sora w fara8at el modal */}
                            <Col xs="10">

                                {/* personal info */}
                                <div>
                                    <span className='profile-label-text'>
                                        personal info
                                    </span>

                                    <div className='acquisition-modals-containers mt-2' style={{ backgroundColor: "#fcf0e1" }}>
                                        <Form.Group className='d-flex align-items-center'>
                                            <Form.Label className="acquisition-create-lead-labels">Name: </Form.Label>
                                            <Form.Control type="text" className="acquisition-create-lead-inputs" placeholder="type..." value={name} onChange={(e) => setName(e.target.value)} />
                                        </Form.Group>

                                        <Form.Group className='d-flex align-items-center'>
                                            <Form.Label className="acquisition-create-lead-labels">mobile number: </Form.Label>
                                            <Form.Control type="text" className="acquisition-create-lead-inputs" placeholder="type..." value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                        </Form.Group>


                                        <Form.Group className='d-flex align-items-center'>
                                            <Form.Label className="acquisition-create-lead-labels">alternative: </Form.Label>
                                            <Form.Control type="text" className="acquisition-create-lead-inputs" placeholder="type..." value={alternativeNumber} onChange={(e) => setAlternativeNumber(e.target.value)} />
                                        </Form.Group>

                                        <Form.Group className='d-flex align-items-center mt-1'>
                                            <Form.Label className="acquisition-create-lead-labels">city: </Form.Label>
                                            <Select
                                                className="acquisition-create-lead-select"
                                                defaultValue={{ "label": "select...", "value": 0 }}
                                                onChange={(e) => { setFilterOnCity(e.value) }}
                                                options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                                            />
                                        </Form.Group>

                                    </div>
                                </div>

                                {/* Business info */}
                                <div className='mt-3'>
                                    <span className='profile-label-text'>
                                        Business info
                                    </span>

                                    <div className='acquisition-modals-containers mt-2' style={{ backgroundColor: "#d9f5e5" }}>
                                        <Form.Group className='d-flex align-items-center'>
                                            <Form.Label className="acquisition-create-lead-labels">store name: </Form.Label>
                                            <Form.Control type="text" className="acquisition-create-lead-inputs" placeholder="type..." value={storeName} onChange={(e) => setStoreName(e.target.value)} />
                                        </Form.Group>

                                        <Form.Group className='d-flex align-items-center mt-1 mb-1'>
                                            <Form.Label className="acquisition-create-lead-labels">industry: </Form.Label>
                                            <Select
                                                className="acquisition-create-lead-select"
                                                defaultValue={{ "label": "select...", "value": 0 }}
                                                onChange={(e) => { setFilterOnIndustry(e.value) }}
                                                options={industries.map((industry) => ({ "value": industry.id, "label": industry.name }))}
                                            />
                                        </Form.Group>

                                        <Form.Group className='d-flex align-items-center'>
                                            <Form.Label className="acquisition-create-lead-labels">address: </Form.Label>
                                            <Form.Control type="text" className="acquisition-create-lead-inputs" placeholder="type..." value={address} onChange={(e) => setAddress(e.target.value)} />
                                        </Form.Group>
                                    </div>
                                </div>


                                {/* other */}
                                <div className='mt-3'>
                                    <span className='profile-label-text'>
                                        other...
                                    </span>

                                    <div className='acquisition-modals-containers mt-2' style={{ backgroundColor: "rgb(209, 209, 209)" }}>
                                        <Form.Group className='d-flex align-items-center'>
                                            <Form.Label className="acquisition-create-lead-labels">source: </Form.Label>
                                            <Select
                                                className="acquisition-create-lead-select"
                                                defaultValue={{ "label": "select...", "value": 0 }}
                                                onChange={(e) => { setFilterOnSources(e.value) }}
                                                options={sources.map((source) => ({ "value": source.id, "label": source.name }))}
                                            />
                                        </Form.Group>


                                        <Form.Group className='d-flex align-items-center'>
                                            <Form.Label className="acquisition-create-lead-labels">account manager: </Form.Label>
                                            <Select
                                                className="acquisition-create-lead-select"
                                                defaultValue={{ "label": "select...", "value": 0 }}
                                                onChange={(e) => { setFilterOnAccountManagers(e.value) }}
                                                options={accountManagers.map((accountManager) => ({ "value": accountManagers.id, "label": accountManagers.name }))}
                                            />
                                        </Form.Group>


                                        <Form.Group className='d-flex align-items-center'>
                                            <Form.Label className="acquisition-create-lead-labels"> customer account: </Form.Label>
                                            <Select
                                                className="acquisition-create-lead-select"
                                                defaultValue={{ "label": "select...", "value": 0 }}
                                                onChange={(e) => { navigateToCustomerAccountProfile(e.value) }} //hena awl may3ml onchange hay navigate ll account
                                                onKeyDown={(e) => searchForAccountsUsingPhoneNumber(e)}
                                                options={customerAccountAfterFilteration && customerAccountAfterFilteration.map((customerAccount) => ({ "value": customerAccount.id, "label": customerAccount.name }))}
        
                                           />
                                        </Form.Group>

                                    </div>
                                </div>
                            </Col>
                            <Col xs="2" style={{ padding: "0", margin: "0" }}>
                                <img src="/images/acquisitionMan.png" alt="acquisition man" className='img-fluid' style={{ width: "63%" }} />
                            </Col>

                        </Row>

                        {/* history */}
                        <Row style={{ marginLeft: "-1rem", marginRight: "-4.7rem", marginTop: "7rem" }}> {/* 7aten el margins dol 34an nozbot sora w fara8at el modal */}
                            <Col xs="10">
                                <button className='acquisition-modal-action-btn acquisition-edit-btn' onClick={(e) => { e.preventDefault(); setShowHistory(true) }}>
                                    click to load
                                </button>
                                {showHistory &&
                                    currentLeadHistory?.map((i) => (
                                        <>
                                            <Container key={i.id} className="history-container">
                                                <span>
                                                    {(new Date(i.updatedAt).toLocaleDateString())} {i.actionTaker} {i.actionType}  {i.updatedRowName} from {i.previousValue} to {i.currentValue}
                                                </span>
                                            </Container>
                                        </>
                                    ))
                                }
                            </Col>
                            
                            <Col xs="2" style={{ padding: "0", margin: "0" }}>
                                <img src="/images/acquisitionMan.png" alt="acquisition man" className='img-fluid' style={{ width: "63%" }} />
                            </Col>
                        </Row>


                        <Row className='mt-5'>
                            <Col xs="6" className='d-flex justify-content-start'>
                                <button className="acquisition-modal-action-btn acquisition-delete-btn" onClick={deleteLead}>Delete lead</button> {/* role-done-btnb */}
                            </Col>
                            <Col xs="6" className='d-flex justify-content-end'>
                                <button className="acquisition-modal-action-btn acquisition-close-btn" onClick={() => setEditLeadModal(false)}>Close</button>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>

            </Modal>
        </div>
    )
}

import React from "react";
import { Table, Card } from 'react-bootstrap';
import APLoadingSkeletonUi from "../common/loadingSkeletonUi/activationProcessLoadingSkeletonUi ";
import LoadingBtnSpinner from "../common/LoadingBtn/LoadingBtn";


export default function ActivationProcessTablesChunks(props) {
    const {
        visitsList,
        setCurrent,
        pendingLoadingSkeleton,
        firstVisitLoadingSkeleton,
        firstTeamLoadingSkeleton,
        secondTeamLoadingSkeleton,
        contractPhaseLoadingSkeleton,
        secondVisitLoadingSkeleton,
        secondVisitFirstTeamLoadingSkeleton,
        secondVisitSecondTeamLoadingSkeleton,
        finalLoadingSkeleton,
        setModal,
        tableTitle,
        tableStatus,
        btnName,
        btnClass,
        pendingCounter,
        firstVisitCounter,
        firstTeamCounter,
        secondTeamCounter,
        contractPhaseCounter,
        secondVisitCounter,
        secondVisitFirstTeamCounter,
        secondVisitSecondTeamCounter,
        finalCounter
    } = props


    return (
        <>
            <Table>
                <thead className='text-center'>
                    <tr className='role-table-header'>
                        <th className="text-center" style={{ minWidth: "10em" }}>{tableTitle}</th>
                    </tr>
                </thead>
                {(tableStatus === "pending" && pendingLoadingSkeleton && pendingCounter === 0) || (tableStatus === "firstVisit" && firstVisitLoadingSkeleton && firstVisitCounter === 0) || (tableStatus === "firstTeam" && firstTeamLoadingSkeleton && firstTeamCounter === 0) || (tableStatus === "secondTeam" && secondTeamLoadingSkeleton && secondTeamCounter === 0) ||
                    (tableStatus === "contractPreparation" && contractPhaseLoadingSkeleton && contractPhaseCounter === 0) ||
                    (tableStatus === "secondVisit" && secondVisitLoadingSkeleton && secondVisitCounter === 0) ||
                    (tableStatus === "secondVisitFirstTeam" && secondVisitFirstTeamLoadingSkeleton && secondVisitFirstTeamCounter === 0) ||
                    (tableStatus === "secondVisitSecondTeam" && secondVisitSecondTeamLoadingSkeleton && secondVisitSecondTeamCounter === 0) ||
                    (tableStatus === "final" && finalLoadingSkeleton && finalCounter === 0) ?
                    <APLoadingSkeletonUi />
                    :
                    <tbody className='text-center Ap'>
                        {visitsList?.map((i) =>
                            <tr key={i.id}>
                                <td>
                                    <div className=" d-flex justify-content-center align-items-center">
                                        <Card
                                            className="text-start tableCard"
                                            onClick={() => {
                                                setModal(true)
                                                setCurrent(i)
                                            }}>
                                            <Card.Body>
                                                <Card.Title className="cardTitle">{i?.Retailer?.RMegaStore?.name}</Card.Title>
                                                <Card.Text className='cardText'>
                                                    <span>{i?.Retailer?.name}</span>
                                                    <br />
                                                    <span>{i?.Retailer?.phoneNumber}</span>
                                                </Card.Text>
                                                <button className={`${btnClass} d-flex justify-content-center align-items-center`}>{btnName}</button>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                }

            </Table>
            <div>
                {
                    (tableStatus === "pending" && pendingLoadingSkeleton && pendingCounter != 0) || (tableStatus === "firstVisit" && firstVisitLoadingSkeleton && firstVisitCounter != 0) || (tableStatus === "firstTeam" && firstTeamLoadingSkeleton && firstTeamCounter != 0) || (tableStatus === "secondTeam" && secondTeamLoadingSkeleton && secondTeamCounter != 0) ||
                        (tableStatus === "contractPreparation" && contractPhaseLoadingSkeleton && contractPhaseCounter !== 0) ||
                        (tableStatus === "secondVisit" && secondVisitLoadingSkeleton && secondVisitCounter != 0) ||
                        (tableStatus === "secondVisitFirstTeam" && secondVisitFirstTeamLoadingSkeleton && secondVisitFirstTeamCounter != 0) ||
                        (tableStatus === "secondVisitSecondTeam" && secondVisitSecondTeamLoadingSkeleton && secondVisitSecondTeamCounter != 0) ||
                        (tableStatus === "final" && finalLoadingSkeleton && finalCounter != 0) ?

                        <span className="d-flex justify-content-center align-items-center activation-process-loading-text mb-3">
                            Loading...
                        </span>
                        : null}
            </div>
        </>
    )
}
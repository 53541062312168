import React, { useState } from 'react'
import { Form, Modal, Container } from 'react-bootstrap';
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

const EditModal = (props) => {
    // props
    const {
        currentCountry,
        seteditCountries,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props


    //edit btn vars
    const [editArabicName, setEditArabicName] = useState('')
    const [editEnglishName, setEditEnglishName] = useState('')
    const [editActive, setEditActive] = useState("")

    //state checker on buttons 
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)


    //put edits
    const submitEdit = async (e) => {

        e.preventDefault();
        setSaveEditsBtn(true);

        var data = {
            'ArName': editArabicName === '' ? currentCountry.ArName : editArabicName,
            'EnName': editEnglishName === "" ? currentCountry.EnName : editEnglishName,
            'isActive': editActive === "" ? currentCountry.isActive : editActive,
            'countryCode': currentCountry.countryCode
        }
        try {
            await putToAgel(`${process.env.React_APP_EDIT_COUNTRY}${currentCountry.id}`, data)
            setSuccessModal(true)
            updateData();
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        seteditCountries(false)
    }





    return (
        <div>
            <Modal show={true} onHide={() => seteditCountries(false)} centered>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={submitEdit}>
                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Arabic Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Arabic Name" defaultValue={currentCountry.ArName} onChange={(e) => setEditArabicName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">English Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter English Name" defaultValue={currentCountry.EnName} onChange={(e) => setEditEnglishName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group>
                                <Form.Check type="checkbox" className="status form-text mt-3" label="isActive" defaultChecked={currentCountry.isActive} onChange={(e) => setEditActive(e.target.checked)} />
                            </Form.Group>


                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={saveEditsBtn ? true : false}>
                                    {saveEditsBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => seteditCountries(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditModal

import React, { useState } from 'react'
import Select from 'react-select';
import { Form, Modal, Row, Col, Container } from "react-bootstrap"
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


const EditCollector = (props) => {
    //props
    let {
        setEditCollectorModal,
        currentCollector,
        cities,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
    } = props;

    //Edit input Btn's values
    const [editName, setEditName] = useState('');
    const [editPhone, setEditPhone] = useState('');
    const [editCity, setEditCity] = useState();
    const [editNationalId, setEditNationalId] = useState('');
    const [editActive, setEditActive] = useState("");

    //state checker on buttons 
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)


    const submitEdit = async (e) => {

        e.preventDefault();
        setSaveEditsBtn(true);

        var data = {
            ...editName ? { 'name': editName } : {},
            ...editPhone ? { 'phoneNumber': editPhone } : {},
            ...editCity ? { 'CityId': editCity } : {},
            ...editNationalId ? { 'nationalID': editNationalId } : {},
            ...editName ? { 'name': editName } : {},
            ...editActive !== "" ? { 'isActive': editActive } : {},
        }
        try {
            await putToAgel(`dashboard/collector/${currentCollector.id}/`, data)
            setSuccessModal(true)
        }
        catch (e) {
            setErrorMsgInFailedModal(e?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        updateData();
        setEditCollectorModal(false)
    }

    return (
        <div>

            <Modal show={true} onHide={() => setEditCollectorModal(false)} className="dashboard-modal mt-5" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={submitEdit}>
                            <Row style={{ marginTop: "-3em" }}>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">Full name</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" placeholder="Enter Full Name" defaultValue={currentCollector?.name} onChange={(e) => setEditName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">City</Form.Label>
                                        <Select
                                            className="search-select mt-2"
                                            defaultValue={{ "label": currentCollector?.City?.EnName, "value": currentCollector?.City?.id }}
                                            onChange={(e) => { setEditCity(e.value) }}
                                            options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">Phone number</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" placeholder="Enter Phone number" defaultValue={currentCollector?.phoneNumber} onChange={(e) => setEditPhone(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">National ID</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" placeholder="Enter National ID" defaultValue={currentCollector?.nationalID} onChange={(e) => setEditNationalId(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Form.Group className="mb-3">
                                    <Form.Check type="checkbox" className="status form-text mt-3" label="isActive" defaultChecked={currentCollector.isActive} onChange={(e) => setEditActive(e.target.checked)} />
                                </Form.Group>
                            </Row>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={saveEditsBtn ? true : false}>
                                    {saveEditsBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setEditCollectorModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default EditCollector

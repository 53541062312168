import React, { useState, useContext } from 'react'

import { Modal, Form, Container, Row, Col } from "react-bootstrap"
import { postToAgel } from '../../utils/api';
import Select from 'react-select';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

const CreateCollector = (props) => {

    //props
    let {
        cities,
        setCreateCollectorModal,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props;

    //create btn state checker
    const [createBtn, setCreateBtn] = useState(false)

    //create input Btn's values
    const [createName, setCreateName] = useState('');
    const [createPhone, setCreatePhone] = useState('');
    const [createPassword, setCreatePassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMsg, setPasswordMsg] = useState(false);
    const [passwordMsgLength, setPasswordMsgLength] = useState(false);
    const [createCity, setCreateCity] = useState("");
    const [selectCityFlag, setSelectCityFlag] = useState(false)
    const [createNationalID, setCreateNationalID] = useState('');
    const options = cities.map(city => ({ "value": city.id, "label": city.EnName }))

    //submit new staff in create btn
    const Submit = async (e) => {
        e.preventDefault();
        setCreateBtn(true);

        if (createPassword === confirmPassword && createPassword.length >= 8 && createCity !== "") {
            var data = {
                'name': createName,
                'phoneNumber': createPhone,
                'password': createPassword,
                'CityId': createCity,
                'nationalID': createNationalID,
            }
            try {
                await postToAgel('dashboard/collector/register', data)
                setSuccessModal(true)
            }
            catch (err) {
                setErrorMsgInFailedModal(err?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
                console.log({err})
            }
            updateData();
            setCreateCollectorModal(false)
        }
        //doesnot match
        else if (createPassword.length >= 8 && createPassword !== confirmPassword && createCity !== "") {
            setPasswordMsgLength(false);
            setPasswordMsg(true);
            setSelectCityFlag(false);
            setCreateBtn(false);
        }
        //length small
        else if (createPassword.length < 8 && createPassword === confirmPassword && createCity !== "") {
            setPasswordMsgLength(true);
            setPasswordMsg(false);
            setSelectCityFlag(false);
            setCreateBtn(false);
        }
        //city missed
        else if (createPassword.length >= 8 && createPassword === confirmPassword && createCity === "") {
            setPasswordMsgLength(false);
            setPasswordMsg(false);
            setSelectCityFlag(true);
            setCreateBtn(false);
        }
        // small length and empty city
        else if (createPassword.length < 8 && createCity === "" && createPassword === confirmPassword) {
            setPasswordMsgLength(true);
            setPasswordMsg(false);
            setSelectCityFlag(true);
            setCreateBtn(false);
        }
        // pass doesnot match and empty city
        else if (createPassword.length >= 8 && createCity === "" && createPassword !== confirmPassword) {
            setPasswordMsgLength(false);
            setPasswordMsg(true);
            setSelectCityFlag(true);
            setCreateBtn(false);
        }
        // pass doesnot match and small length
        else if (createPassword.length < 8 && createCity !== "" && createPassword !== confirmPassword) {
            setPasswordMsgLength(true);
            setPasswordMsg(true);
            setSelectCityFlag(false);
            setCreateBtn(false);
        }
        //pass doesnot match and small length and empty city
        else if (createPassword.length < 8 && createCity === "" && createPassword !== confirmPassword) {
            setPasswordMsgLength(true);
            setPasswordMsg(true);
            setSelectCityFlag(true);
            setCreateBtn(false);
        }
        else {
            setPasswordMsgLength(true);
            setPasswordMsg(true);
            setSelectCityFlag(true);
            setCreateBtn(false);
        }

    }

    return (
        <div>
            <Modal show={true} onHide={() => setCreateCollectorModal(false)} className="dashboard-modal mt-5" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={Submit}>
                            <Row style={{ marginTop: "-3em" }}>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">Full name</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" placeholder="Enter Name" value={createName} onChange={(e) => setCreateName(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">City</Form.Label>
                                        <Select
                                            className="search-select mt-2"
                                            onChange={(e) => { setCreateCity(e.value); setSelectCityFlag(false) }}
                                            options={options}
                                        />
                                    </Form.Group>
                                    {selectCityFlag && (<p className="select-error-msg">Please select a city!</p>)}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">Phone number</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" placeholder="Enter Phone number" defaultValue={createPhone} onChange={(e) => setCreatePhone(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">National ID</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" placeholder="Enter National ID" defaultValue={createNationalID} onChange={(e) => setCreateNationalID(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">Password</Form.Label>
                                        <Form.Control type="Password" className="role-input mt-2" placeholder="Enter Password" defaultValue={createPassword} onChange={(e) => setCreatePassword(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">Confirm password</Form.Label>
                                        <Form.Control type="Password" className="role-input mt-2" placeholder="Confirm Password" defaultValue={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* incase password isnot the same */}
                            {passwordMsg && (<p className="password-confirm-msg">Password isn't match!</p>)}

                            {/* incase password length is too small */}
                            {passwordMsgLength && (<p className="password-confirm-msg">Password should be at least 8 digits!</p>)}

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={createBtn ? true : false}>
                                    {createBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setCreateCollectorModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default CreateCollector
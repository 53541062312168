import React from "react";
import { Table, Container } from "react-bootstrap";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function TableSkeletonUI(props) {

    const { cols, rows } = props; //numbers of cols/rows of each parent table


    // create skeleton ui for table cols
    let skeletonCols = []
    for (let i = 0; i < cols; i++) {
        console.log({ i })
        skeletonCols.push(
            <td className="text-center">
                <Skeleton borderRadius={"17px"} width={"9rem"}/>
            </td>
        )
    }

    // create skeleton ui for table rows
    let skeletonRows = [];
    for (let i = 0; i < rows; i++) {
        console.log({ i })
        skeletonRows.push(

            <tr className='role-table-text'>
                {skeletonCols}
            </tr>

        )

    }
    return (
        <tbody className='text-center'>
            {skeletonRows}
        </tbody>
    )
} 
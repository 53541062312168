import React, { useContext, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import './Countries.css'

// components
import EditModal from '../../components/Countries/EditModal';
import CreateModal from '../../components/Countries/CreateModal';
import CountryTable from '../../components/Countries/CountryTable';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen';
import SuccessModal from '../../components/common/SuccessModal';
import Failed_Modal from '../../components/common/FailedModal';

//react icons
import { MdAddCircle } from 'react-icons/md'

import { getFromAgel } from '../../utils/api'
import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';




export default function Countries() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewCountriesPermission = userValidPermissionAndAction(validPermissionsAndActions, "country", "viewCountry")

    const [countries, setCountries] = useState([])

    //modal
    const [editCountries, seteditCountries] = useState(false)
    const [showCreate, setShowCreate] = useState(false);
    const [currentCountry, setCurrentCountry] = useState({});

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")

    //search
    const [countrySearch, setCountrySearch] = useState("") // search input state
    const [searchResult, setSearchResult] = useState("") //search btn


    const sortedCountries = countries && countries.sort((a, b) => (a.id) - (b.id));


    async function fetchData() {
        try {
            const response = await getFromAgel(`${process.env.React_APP_GET_COUNTRIES}`);
            // console.log("response=", response.data.countries)
            setCountries(response.data.countries)
        }
        catch (err) {
            console.log("error", err)
        }
    }

    //fetch Countries
    useEffect(() => {
        fetchData()
    }, [])

    //search btn fn
    const search = () => {
        setSearchResult(countrySearch)
    }
    //update role result
    let countrySearchResult = searchResult !== "" ? countries.filter((i) => i.ArName && i.ArName.toLowerCase().includes(searchResult.toLowerCase()) || i.EnName && i.EnName.toLowerCase().includes(searchResult.toLowerCase())) : countries

    //filter search

    if (viewCountriesPermission) {

        return (
            <div>
                <Row className="layout-top">
                    <Col xs="3 text-start">
                        {/* input */}
                        <div className='input-wrapper'>
                            <input type="text" placeholder="English / Arabic Country" value={countrySearch} onChange={(e) => setCountrySearch(e.target.value)} />
                        </div>
                    </Col>
                    <Col xs="4 text-start">
                        {/* search */}
                        <button className='role-search-btn' onClick={() => search()}>Search</button>
                    </Col>
                    <Col xs="5 text-end">
                        {/* create country btn */}
                        <div>
                            <button className='add-role-btn' onClick={() => { setShowCreate(true) }}>
                                <span className='d-flex align-items-center justify-content-center'>
                                    <MdAddCircle size={20} className="add-role-btn-icon" />
                                    <p>Add Country</p>
                                </span>
                            </button>
                            {
                                showCreate ?
                                    <CreateModal
                                        updateData={fetchData}
                                        setShowCreate={setShowCreate}
                                        setSuccessModal={setSuccessModal}
                                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                    />
                                    : null
                            }
                        </div>
                    </Col>
                </Row>


                {/* table */}
                <CountryTable
                    countries={countrySearchResult}
                    seteditCountries={seteditCountries}
                    setCurrentCountry={setCurrentCountry}
                />

                {/* edite modal  */}
                {
                    editCountries ?
                        <EditModal
                            updateData={fetchData}
                            seteditCountries={seteditCountries}
                            currentCountry={currentCountry}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null

                }

                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={fetchData}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }

            </div>
        )
    }

    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
import React, { useState, useEffect } from 'react'

import { Modal, Form, Container, Row, Col, ProgressBar } from "react-bootstrap"
import { getFromAgel, postToAgel, putToAgel } from '../../utils/api';

//icons
import { BiMessageDetail } from 'react-icons/bi'
import { IoReloadCircle } from "react-icons/io5"
import { AiOutlineFileImage } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'

import { useNavigate } from 'react-router-dom'

//import component
import ApproveButton from './ApproveButton';
import APActionButtons from '../ActivationProcessCommonModals/APActionButtons';



const FirstTeam = (props) => {

    let navigate = useNavigate()

    //props
    let {
        setFirstTeamModal,
        current,
        setRevertModal,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        updateData,
        setCancelConfirmModal,
        setShowAFComment,
        setAFCancelComment
    } = props;


    const [teamLeadData, setTeamLeadData] = useState([]);
    const [logs, setLogs] = useState([1]);
    const [selectedFile, setSelectedFile] = useState("");
    const [selectedFileName, setSelectedFileName] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [progressBarRatio, setProgressBarRatio] = useState(0);

    // fetch teamLead form register Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                /* get ws af  */
                const teamLeadDataResponse = await getFromAgel(`${process.env.React_APP_GET_WS_AP}${current.id}`)
                setTeamLeadData(teamLeadDataResponse.data.wholesalerActivationProcess[0])
                console.log("register af=", teamLeadDataResponse.data.wholesalerActivationProcess[0])

                //logs
                // const logsResponse=await getFromAgel(`/${current.id}`)
                // console.log("id=",current.id)
                // console.log("logsResponse=",logsResponse)
                // // setLogs(logsResponse)

            }
            catch (e) {
                // console.log("error=", e)
                // console.log("id=", current.id)
            }
        }
        fetchData()
    }, [])

    const [name, setName] = useState();
    const [commercial_registration_id, setCommercialRegistrationId] = useState();
    const [commercial_registration_office, setCommercialRegistrationOffice] = useState();
    const [nationalId, setNationalId] = useState();
    const [storeName, setStoreName] = useState();
    const [owner_name, setOwnerName] = useState();
    const [address, setAddress] = useState();
    const [industry, setIndustry] = useState();
    const [lat, setLat] = useState("");
    const [long, setLong] = useState("");
    const [storeAddressByCust, setStoreAddressByCust] = useState("");

    //state checker on corrupted images 
    const [frontNationIdCorruptedImg, setFrontNationIdCorruptedImg] = useState(false)
    const [backNationIdCorruptedImg, setBackNationIdCorruptedImg] = useState(false)
    const [commercialRegistrationCorruptedImg1, setCommercialRegistrationCorruptedImg1] = useState(false)
    const [commercialRegistrationCorruptedImg2, setCommercialRegistrationCorruptedImg2] = useState(false)
    const [commercialRegistrationCorruptedImg3, setCommercialRegistrationCorruptedImg3] = useState(false)

    //state checker on buttons 
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)

    const saveEdits = async (e) => {

        e.preventDefault();
        setSaveEditsBtn(true)

        var data = {
            "finalForm": {
                ...(name ? { "name": name } : {}),
                ...(commercial_registration_id ? { "commercial_registration_id": commercial_registration_id } : {}),
                ...(commercial_registration_office ? { "commercial_registration_office": commercial_registration_office } : {}),
                ...(nationalId ? { "nationalId": nationalId } : {}),
                ...(storeName ? { "storeName": storeName } : {}),
                ...(owner_name ? { "owner_name": owner_name } : {}),
                ...(address ? { "area": address } : {}),
                ...(industry ? { "industry": industry } : {}),
                ...(lat ? { "lat": lat } : {}),
                ...(long ? { "long": long } : {}),
                ...(storeAddressByCust ? { "storeAddressByCust": storeAddressByCust } : {}),
            }
        }
        try {
            /* ws save af edits */
            await putToAgel(`${process.env.React_APP_WS_AF_SAVE_EDITS}${current.id}/`, data)
            updateData()
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setFirstTeamModal(false)
    }


    const uploadContract = async (e) => {
        e.preventDefault();
        setDownloading(true);

        var formData = new FormData();
        formData.set("contract", selectedFile && selectedFile);
        var data = formData

        try {
            await postToAgel(`dashboard/wholesaler/activationProcess/${current.id}/uploadPdf`, data, {
                onUploadProgress: (event) => {
                    setProgressBarRatio(Math.round(100 * event.loaded / event.total));
                },
            });
            updateData();
            setSuccessModal(true);
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message);
            setFailedModalWithErrorMsg(true);
        }
        setProgressBarRatio(0);
        setDownloading(false);
        setFirstTeamModal(false);
    }


    const currentAFCancelComment = teamLeadData.WComments


    return (
        <div>
            <Modal show={true} onHide={() => setFirstTeamModal(false)} centered size="lg" className="AP-modal" style={{ marginTop: "5em" }}>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body style={{ marginBottom: "5em" }}>

                    <Container style={{ marginTop: "-1.5rem" }}>
                        <Row>
                            <Col xs={12} className=" d-flex align-items-center">
                                <p className='modal-header-title'>{teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.name}</p>
                                {teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.name ?
                                    <CgProfile className="link-to-profile-icon-through-af" onClick={() => navigate(`/wholesalers/list/${teamLeadData && teamLeadData.Wholesaler && teamLeadData.Wholesaler.id}/details`)} />
                                    : null}
                            </Col>
                            <Col xs={12} className='d-flex text-end justify-content-between align-items-start mt-2'>
                                <p className="modal-title">Agent name <span className='text-muted' style={{ marginLeft: "0.3rem" }}>{teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.Agent.name}</span></p>
                                <p className="modal-title">request date <span className='text-muted' style={{ marginLeft: "0.3rem" }}>{(new Date(teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.createdAt).toLocaleDateString())}</span></p>
                                <p className="modal-title">visit date <span className='text-muted' style={{ marginLeft: "0.3rem" }}>{(new Date(teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.visitDate).toLocaleDateString())}</span></p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="2" className='d-flex mt-2' style={{ width: "100%", paddingLeft: 0 }}>
                                <ApproveButton
                                    model={setFirstTeamModal}
                                    updateData={updateData}
                                    setSuccessModal={setSuccessModal}
                                    setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                    setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                    current={current}
                                />
                            </Col>
                        </Row>

                    </Container>

                    <Container className="mt-3" dir="rtl">
                        <Form>
                            {/* row1 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">الأسم ثلاثى</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={name} onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row2 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">رقم السجل التجاري</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_id} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.commercial_registration_id} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={commercial_registration_id} onChange={(e) => setCommercialRegistrationId(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* row3 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">مكتب السجل</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_office} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.commercial_registration_office} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={commercial_registration_office} onChange={(e) => setCommercialRegistrationOffice(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row4 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">رقم البطاقة</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdOrPassportNumber} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.nationalId} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={nationalId} onChange={(e) => setNationalId(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row5 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">اسم المنشأة</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore.name && teamLeadData.Wstore.name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.storeName} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input  mt-2" value={storeName} onChange={(e) => setStoreName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row6 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">رقم هاتف </Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.phoneNumber} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.phoneNumber} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row7 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">رقم هاتف اخر </Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.secondNumber} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.secondNumber} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* row8 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">مدير الفرع</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.owner_name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.owner_name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={owner_name} onChange={(e) => setOwnerName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row9 */}
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text"> اسم الفرع</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore.name && teamLeadData.Wstore.name} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.storeName} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={storeName} onChange={(e) => setStoreName(e.target.value)} readOnly disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row10 */}
                            <Row>
                                <Col xs="4" className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">عنوان المنشأة</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.area} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.address} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={address} onChange={(e) => setAddress(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* row11 */}
                            <Row>
                                <Col xs="4" className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">نشاط المنشأة</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.industry} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.Wstore && teamLeadData.Wstore && teamLeadData.Wstore.AgentWholeAF && teamLeadData.Wstore.AgentWholeAF.industry} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={industry} onChange={(e) => setIndustry(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row12 */}
                            <Row>
                                <Col xs="4" className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">Lat Store</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.lat} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={lat} onChange={(e) => setLat(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row13 */}
                            <Row>
                                <Col xs="4" className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">Long Store</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.long} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={long} onChange={(e) => setLong(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* row14 */}
                            <Row>
                                <Col xs="4" className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="AP-modal-text">برجاء كتابة عنوان المنشأة تفصيلياً مع ذكر أقرب علامة مميزة</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" value={teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.storeAddressByCust} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "3em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                                    </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Form.Group className="mb-3" style={{ marginTop: "3em" }}>
                                        <Form.Control type="text" className="role-input mt-2" value={storeAddressByCust} onChange={(e) => setStoreAddressByCust(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* row15*/}
                            {(!commercialRegistrationCorruptedImg1 && (teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_image)) ?
                                <Row>
                                    <Col xs="4">
                                        <Form.Group>
                                            <Form.Label className="AP-modal-text">السجل التجاري ١</Form.Label>
                                            <br />
                                            <a href={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_image}`} target="_blank">
                                                <img onError={() => setCommercialRegistrationCorruptedImg1(true)} src={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.commercial_registration_image}`} className='img-fluid Ap-register-image' />
                                            </a>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                : null}

                            {/* row16*/}
                            {(!commercialRegistrationCorruptedImg2 && (teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one)) ?
                                <Row>
                                    <Col xs="4">
                                        <Form.Group>
                                            <Form.Label className="AP-modal-text">السجل التجاري ٢</Form.Label>
                                            <br />
                                            <a href={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one}`} target="_blank">
                                                <img onError={() => setCommercialRegistrationCorruptedImg2(true)} src={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_one}`} className='img-fluid Ap-register-image' />
                                            </a>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                : null}

                            {/* row17*/}
                            {(!commercialRegistrationCorruptedImg3 && (teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two)) ?
                                <Row>
                                    <Col xs="4">
                                        <Form.Group>
                                            <Form.Label className="AP-modal-text">السجل التجاري ٣</Form.Label>
                                            <br />
                                            <a href={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two}`} target="_blank">
                                                <img onError={() => setCommercialRegistrationCorruptedImg3(true)} src={`${teamLeadData && teamLeadData.WholesalerAF && teamLeadData.WholesalerAF.rest_commercial_registration_image && teamLeadData.WholesalerAF.rest_commercial_registration_image.commercial_registration_image_two}`} className='img-fluid Ap-register-image' />
                                            </a>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                : null}

                            {/* row18*/}
                            {(!frontNationIdCorruptedImg && (teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdOrPassportImage)) ?
                                <Row>
                                    <Col xs="4">
                                        <Form.Group>
                                            <Form.Label className="AP-modal-text">الصورة الأمامية للبطاقة</Form.Label>
                                            <br />
                                            <a href={`${teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdOrPassportImage}`} target="_blank">
                                                <img onError={() => setFrontNationIdCorruptedImg(true)} src={`${teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdOrPassportImage}`} className='img-fluid Ap-register-image' />
                                            </a>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                : null}

                            {/* row19*/}
                            {(!backNationIdCorruptedImg && (teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdBackImage)) ?
                                <Row>
                                    <Col xs="4">
                                        <Form.Group>
                                            <Form.Label className="AP-modal-text">الصورة الخلفية للبطاقة</Form.Label>
                                            <br />
                                            <a href={`${teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdBackImage}`} target="_blank">
                                                <img onError={() => setBackNationIdCorruptedImg(true)} src={`${teamLeadData?.Wholesaler?.WholesalersValifyData?.nationalIdBackImage}`} className='img-fluid Ap-register-image' />
                                            </a>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4" className="mt-auto mb-auto">
                                        <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                                            <Form.Control as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                : null}

                            {/* upload contract */}
                            {selectedFile === "" ?
                                <Container className='mt-5'>
                                    <label className="text-center">
                                        <img src="/images/uploadImg.png" alt="upload image" className="img-fluid" />
                                        <input type="file" style={{ display: "none" }} onChange={(e) => { setSelectedFile(e.target.files[0]); setSelectedFileName(e.target.files[0].name); console.log("pdf=", e.target.files[0], "selectedFileName=", selectedFileName) }} />
                                    </label>
                                </Container>
                                :
                                <Container className=' d-grid mt-5'>
                                    <div className='d-flex'>
                                        <AiOutlineFileImage className='AF-reinstate-comment-icon' />
                                        <p className='contract-text mt-1'>{selectedFileName}</p>
                                        <label className="text-center">
                                            <IoReloadCircle className="AF-reinstate-comment-icon cursor-pointer" />
                                            <input type="file" style={{ display: "none" }} onChange={(e) => { setSelectedFile(e.target.files[0]); setSelectedFileName(e.target.files[0].name); console.log("pdf=", e.target.files[0], "selectedFileName=", selectedFileName) }} />
                                        </label>
                                    </div>

                                    {/* progress bar of uploading contract file */}
                                    {progressBarRatio > 0 &&
                                        <ProgressBar dir='ltr' animated now={progressBarRatio} label={`${progressBarRatio}%`} />
                                    }
                                </Container>
                            }

                            {/* reinstate comment */}
                            {currentAFCancelComment && currentAFCancelComment.length !== 0 ?
                                <Container className="mt-3">
                                    <BiMessageDetail
                                        className='AF-reinstate-comment-icon cursor-pointer'
                                        onClick={() => {
                                            setFirstTeamModal(false);
                                            setShowAFComment(true);
                                            setAFCancelComment(teamLeadData && teamLeadData.WComments)
                                        }} />
                                    <span className="AF-reinstate-comment-text">
                                        reinstate comment
                                    </span>
                                </Container>
                                : null
                            }


                            {/* action btns */}
                            <Row>
                                <Col xs="6" className='d-flex justify-content-start align-items-center mt-5 ' >
                                    <APActionButtons
                                        permissionName={"wholesalerActivationProcess"}
                                        actionName={"cancel wholesaler activation process"}
                                        btnClassName={"cancel-visit"}
                                        btnType={"submit"}
                                        btnName={"Cancel"}
                                        isLoaderFlagTrue={false}
                                        btnActions={(e) => {
                                            e.preventDefault();
                                            setFirstTeamModal(false);
                                            setCancelConfirmModal(true)
                                        }}
                                    />

                                    <APActionButtons
                                        permissionName={"wholesalerActivationProcess"}
                                        actionName={"reviewed wholesaler activation process"}
                                        btnClassName={"reviewed-btn-ws-activation-form"}
                                        btnType={"submit"}
                                        btnName={"reviewed"}
                                        isLoaderFlagTrue={downloading}
                                        btnActions={uploadContract}
                                    />

                                    <APActionButtons
                                        permissionName={"wholesalerActivationProcess"}
                                        actionName={"revert wholesaler activation process"}
                                        btnClassName={"revert-visit"}
                                        btnType={"submit"}
                                        btnName={"Revert"}
                                        isLoaderFlagTrue={false}
                                        btnActions={(e) => {
                                            e.preventDefault();
                                            setRevertModal(true);
                                            setFirstTeamModal(false)
                                        }}
                                    />

                                </Col>
                                <Col xs="6" className='d-flex justify-content-end align-items-center mt-5 ' >
                                    <APActionButtons
                                        permissionName={""}
                                        actionName={""}
                                        noPermission={true}
                                        btnClassName={"save-edits"}
                                        btnType={"submit"}
                                        btnName={"Save Edits"}
                                        isLoaderFlagTrue={saveEditsBtn}
                                        btnActions={saveEdits}
                                    />

                                </Col>

                            </Row>

                        </Form>
                    </Container>

                    {/* logs */}
                    {/* <Container>
                        <p className="logs-title mt-5">All Logs</p>
                        <div className='Ap-logs' style={{paddingLeft:"1.5em"}}>
                            {logs.map((i)=>
                            <span>
                                <div className='d-flex'>
                                    <p className='logs-data'>mohmaed ali</p>
                                    <p className='logs-data'>22/10/2022 </p>
                                    <p className='logs-data'>3:00pm</p>
                                </div>
                                <div className='info'>
                                    created form
                                </div>
                            </span>
                            )}
                        </div>
                    </Container> */}

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default FirstTeam

import React from "react";
import { Placeholder, Card } from "react-bootstrap";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './loadingSkeletonUi.css'

export default function APLoadingSkeletonUi(props) {


    let rows = 2 //numbers of rows of each parent table ap


    // create skeleton ui for AP table rows
    let skeletonRows = [];
    for (let i = 0; i < rows; i++) {
        console.log({ i })
        skeletonRows.push(

            <tr>
                <td>
                    <div className=" d-flex justify-content-center align-items-center">
                        <Card className="text-start tableCard">
                            <Card.Body>

                                <Card.Title>
                                    <Skeleton borderRadius={"17px"} width="50%" height="14px" />
                                </Card.Title>

                                <Card.Text >
                                    <span>
                                        <Skeleton borderRadius={"17px"} width="100%" height="10px" />
                                    </span>
                                    <span>
                                        <Skeleton borderRadius={"17px"} width="100%" height="10px" />
                                    </span>
                                </Card.Text>

                                <button className="AP-skeleton-ui-actions-btn">
                                    <Skeleton />
                                </button>

                            </Card.Body>
                        </Card>
                    </div>
                </td>
            </tr>

        )

    }
    return (
        <tbody className='text-center'>
            {skeletonRows}
        </tbody>
    )
} 
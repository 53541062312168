import React, { useEffect, useState, useContext } from 'react';
import {
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import Multiselect from 'multiselect-react-dropdown';
import { Row, Col, Form } from 'react-bootstrap';
import { DatePicker } from 'antd';
import { MdDownloadForOffline } from 'react-icons/md';
import fileDownload from 'js-file-download';
import './wholesaler.css';

import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';

// api
import { getExcelFileFromAgel, getFromAgel } from '../../utils/api';

// components
import WholesalerTable from '../../components/Wholesalers/WholesalerTable';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen';
import Failed_Modal from '../../components/common/FailedModal';
import SuccessModal from '../../components/common/SuccessModal';
import DashboardLogs from '../../components/common/DashboardLogs/DashboardLogs';
import DahboardNotification from '../../components/Notifications/DahboardNotification';

export default function Wholesaler() {
  // for going back to same pagination page when navigate
  const [searchParams, setSearchParams] = useSearchParams(); // to update query param
  const location = useLocation(); // to access location search which carry the query param value
  const urlSearchString = location.search;
  const queryParam = new URLSearchParams(urlSearchString);
  const pageNumberQueryParam = queryParam.get('page_number');

  const validPermissionsAndActions = useContext(userPermissionsAndActionsContext);
  const viewAllWholesalersListPermission = userValidPermissionAndAction(validPermissionsAndActions, "wholesaler", "viewWholesaler");
  const viewOnlyFilteredWholesalerPermission = userValidPermissionAndAction(validPermissionsAndActions, "wholesaler", "viewWholesalerWithPhoneNumber");

  const viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity");
  const viewIndustriesPermission = userValidPermissionAndAction(validPermissionsAndActions, "industry", "viewIndustry");
  const viewStatusPermission = userValidPermissionAndAction(validPermissionsAndActions, "wholesaler", "viewWholesalerStatus");

  const viewIconForDownloadAccountsTablePermission = userValidPermissionAndAction(validPermissionsAndActions, "wholesaler", "wholesalerAccountReport");

  const viewLogsButtonPermission = userValidPermissionAndAction(validPermissionsAndActions, "", "");

  const { RangePicker } = DatePicker;

  const [wholesaler, setWholesaler] = useState([]);
  const [pagination_count_of_ws, set_pagination_count_of_ws] = useState("");
  const [current_pagination_item, set_current_pagination_item] = useState(pageNumberQueryParam || 0);

  const [cities, setCities] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [statusArray, setStatusArray] = useState([]);

  // ws filter states
  const [textInputSearch, setTextInputSearch] = useState("");
  const [onlySearchForPhonenumberPermissionFlag, setOnlySearchForPhonenumberPermissionFlag] = useState(false);
  const [filterOnCity, setFilterOnCity] = useState('');
  const [filterOnIndustry, setFilterOnIndustry] = useState('');
  const [filterOnStatus, setFilterOnStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // check on all filters thats not empty for loading checker
  const [allFiltersChecker, setAllFiltersChecker] = useState(false);

  const [showNotification, setShowNotification] = useState(false);
  const [dashboardLogsOffcanvas, setDashboardLogsOffcanvas] = useState(false);

  // server modal response success/failed
  const [successModal, setSuccessModal] = useState(false);
  const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false);
  const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("");
  const [errorMsgResponseOfViewOnlyFilteredWholesalerPermission, setErrorMsgResponseOfViewOnlyFilteredWholesalerPermission] = useState("");

  // loading indicator for requests send
  const [requestLoader, setRequestLoader] = useState(false);
  const [downloadAccountsLoader, setDownloadAccountsLoader] = useState(false);

  // fetch filters drop down menus data
  useEffect(() => {
    async function fetchFiltersData() {
      // get cities //view cities permission
      if (viewAllWholesalersListPermission) {
        if (viewCitiesPermission) {
          const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
          // // console.log("cityResponse=", cityR esponse.data.cities)
          setCities(cityResponse.data.cities);
        }

        // industries //view industries permission
        if (viewIndustriesPermission) {
          const industryResponse = await getFromAgel(`${process.env.React_APP_GET_INDUSTRIES}`);
          // console.log("industryResponse=", industryResponse.data.industries)
          setIndustries(industryResponse.data.industries);
        }

        // status  //view status permission
        if (viewStatusPermission) {
          const statusResponse = await getFromAgel(`${process.env.React_APP_GET_WS_STATUS}`);
          // a for loop to create key for multiselect display value cuz it's sent without key names
          for (let i = 0; i < statusResponse?.data?.status?.length; i++) {
            setStatusArray((prev) => [...prev, { statusName: statusResponse.data.status[i] }]);
          }
        }
      }
    }
    fetchFiltersData();
  }, []);

  // fetch wholesalers
  async function fetchData() {
    if (viewAllWholesalersListPermission || (viewOnlyFilteredWholesalerPermission && !onlySearchForPhonenumberPermissionFlag)) {
      try {
        setRequestLoader(true);
        setSearchParams({ page_number: current_pagination_item });
        /* get ws table data */
        const wholesalerResponse = await getFromAgel(`${process.env.React_APP_GET_WS_DATA_TABLE}`, {
          params: {
            page: current_pagination_item,
            size: 20,
            ...(textInputSearch ? { filterByPhoneOrName: textInputSearch } : {}),
            ...(filterOnCity ? { CityId: filterOnCity } : {}),
            ...(filterOnIndustry ? { IndustryId: filterOnIndustry } : {}),
            ...(filterOnStatus ? { status: filterOnStatus } : {}),
            ...(startDate ? { startDate: `${new Date(startDate).getFullYear()}-${new Date(startDate).getMonth() + 1}-${new Date(startDate).getDate()}` } : {}),
            ...(endDate ? { endDate: `${new Date(endDate).getFullYear()}-${new Date(endDate).getMonth() + 1}-${new Date(endDate).getDate()}` } : {}),
          },
        });

        // console.log("wholesalerResponse=", wholesalerResponse.data.wholesalers.rows)
        setWholesaler(wholesalerResponse.data.wholesalers.rows);
        set_pagination_count_of_ws(wholesalerResponse.data.wholesalers.count);
      } catch (err) {
        // if ((viewOnlyFilteredWholesalerPermission && onlySearchForPhonenumberPermissionFlag)) {
        setErrorMsgResponseOfViewOnlyFilteredWholesalerPermission(err?.response?.data?.message);
        // }
      }
      setRequestLoader(false);
    }
    setOnlySearchForPhonenumberPermissionFlag(false);
  }

  useEffect(() => {
    fetchData();
  }, [current_pagination_item]);

  // sort ws results
  const sortedWs = wholesaler && wholesaler.sort((a, b) => (b.id) - (a.id));

  // search btn fn
  const search = () => {
    setErrorMsgResponseOfViewOnlyFilteredWholesalerPermission("");
    setOnlySearchForPhonenumberPermissionFlag(true);
    setWholesaler([]);

    fetchData();
    if (textInputSearch !== "" || filterOnCity !== "" || filterOnIndustry !== "" || filterOnStatus !== "") {
      setAllFiltersChecker(true);
    } else {
      setAllFiltersChecker(false);
    }
  };

  const dowloadAccounts = async (e) => {
    e.preventDefault();
    setDownloadAccountsLoader(true);
    try {
      const reportResponse = await getExcelFileFromAgel("dashboard/wholesaler/report", {
        params: {
          ...(textInputSearch ? { filterByPhoneOrName: textInputSearch } : {}),
          ...(filterOnCity ? { CityId: filterOnCity } : {}),
          ...(filterOnIndustry ? { IndustryId: filterOnIndustry } : {}),
          ...(filterOnStatus ? { status: filterOnStatus } : {}),
          ...(startDate ? { startDate: `${new Date(startDate).getFullYear()}-${new Date(startDate).getMonth() + 1}-${new Date(startDate).getDate()}` } : {}),
          ...(endDate ? { endDate: `${new Date(endDate).getFullYear()}-${new Date(endDate).getMonth() + 1}-${new Date(endDate).getDate()}` } : {}),
        },
      });
      fileDownload(reportResponse.data, 'report.xlsx');
      setSuccessModal(true);
    } catch (err) {
      setErrorMsgInFailedModal(e?.response?.data?.message);
      setFailedModalWithErrorMsg(true);
    }
    setDownloadAccountsLoader(false);
  };

  // select city logic
  const onSelectCity = (selectedList, selectedItem) => {
    const selectedCityIds = selectedList.map((i) => i.id);
    setFilterOnCity(selectedCityIds.toString());
    set_current_pagination_item(0);
    // console.log({ selectedList });
    // console.log({ filterOnCity });
  };
  const onRemoveCity = (selectedList, selectedItem) => {
    const updatedCityIdsAfterRemoval = selectedList.map((i) => i.id);
    setFilterOnCity(updatedCityIdsAfterRemoval.toString());
    set_current_pagination_item(0);
    // console.log({ selectedList });
    // console.log({ filterOnCity });
  };

  // select industry logic
  const onSelectIndustry = (selectedList, selectedItem) => {
    const selectedIndustryIds = selectedList.map((i) => i.id);
    setFilterOnIndustry(selectedIndustryIds.toString());
    set_current_pagination_item(0);
  };
  const onRemoveIndustry = (selectedList, selectedItem) => {
    const updatedIndustryIdsAfterRemoval = selectedList.map((i) => i.id);
    setFilterOnIndustry(updatedIndustryIdsAfterRemoval.toString());
    set_current_pagination_item(0);
  };

  // select status logic
  const onSelectStatus = (selectedList, selectedItem) => {
    const selectedStatusIds = selectedList.map((i) => i.statusName);
    setFilterOnStatus(selectedStatusIds.toString());
    set_current_pagination_item(0);
  };
  const onRemoveStatus = (selectedList, selectedItem) => {
    const updatedStatusIdsAfterRemoval = selectedList.map((i) => i.statusName);
    setFilterOnStatus(updatedStatusIdsAfterRemoval.toString());
    set_current_pagination_item(0);
  };

  if (viewAllWholesalersListPermission || viewOnlyFilteredWholesalerPermission) {
    return (
      <div>
        <Row className="layout-top">
          <Col xs="3" className=" text-start">
            {/* input */}
            <div className="input-wrapper">
              <input type="text" placeholder={viewAllWholesalersListPermission ? "Names / phone number" : viewOnlyFilteredWholesalerPermission ? "only exact phone number" : ""} value={textInputSearch} onChange={(e) => { setTextInputSearch(e.target.value); set_current_pagination_item(0); }} />
            </div>
          </Col>
          <Col xs="4" className="text-start">
            {/* search */}
            <button className="role-search-btn" onClick={() => { search(); }}>Search</button>
          </Col>
          {viewIconForDownloadAccountsTablePermission
            ? (
              <Col xs="5" className="d-flex justify-content-end align-items-center">
                <MdDownloadForOffline className={downloadAccountsLoader ? `sync-btn-progress-behavior` : `sync-btn-pointer-behavior`} onClick={dowloadAccounts} />
              </Col>
            )
            : null}
        </Row>
        {/* m4 hy4of el filters bta3t el city wel industry wel status wel notification btn 2ela lw kan leh permission y4of kol el listu */}
        {viewAllWholesalersListPermission
          ? (
            <Row className="layout-row-2">
              <Col xs={2}>
                <Multiselect
                  className="multi-select-filters-accounts mt-2"
                  options={cities && cities}
                  onSelect={onSelectCity}
                  onRemove={onRemoveCity}
                  displayValue="EnName"
                  showCheckbox
                  placeholder="City"
                />
              </Col>

              <Col xs={2}>
                <Multiselect
                  className="multi-select-filters-accounts mt-2"
                  options={industries && industries}
                  onSelect={onSelectIndustry}
                  onRemove={onRemoveIndustry}
                  displayValue="name"
                  showCheckbox
                  placeholder="Industry"
                />
              </Col>

              <Col xs={2}>
                <Multiselect
                  className="multi-select-filters-accounts mt-2"
                  options={statusArray && statusArray}
                  onSelect={onSelectStatus}
                  onRemove={onRemoveStatus}
                  displayValue="statusName"
                  showCheckbox
                  placeholder="Status"
                />
              </Col>

              <Col xs={2}>
                <RangePicker className="accounts-date-filter" onChange={(e) => { set_current_pagination_item(0); setStartDate(e && e[0] && e[0].$d); setEndDate(e && e[1] && e[1].$d); }} />
              </Col>
            </Row>
          )
          : null}

        <Row className="layout-row-3 d-flex justify-content-end">
          <Col xs={6} className="d-flex justify-content-end align-items-center" style={{ marginRight: "-1rem" }}>
            <div>
              <button type="button" className="accounts-table-action-btns notification-btn" onClick={() => { setShowNotification(true); }}>
                <span>send notification</span>
              </button>
            </div>
            {/* {viewLogsButtonPermission &&
              <div style={{ marginLeft: "1rem" }}>
                <button type="button" className="accounts-table-action-btns dashboard-logs-btn" onClick={() => setDashboardLogsOffcanvas(true)}>
                  Logs
                </button>
              </div>
            } */}
          </Col>

        </Row>

        {/* ws table */}

        {(viewAllWholesalersListPermission || (viewOnlyFilteredWholesalerPermission))
          ? (
            <WholesalerTable
              wholesaler={sortedWs}
              pagination_count_of_ws={pagination_count_of_ws}
              current_pagination_item={current_pagination_item}
              set_current_pagination_item={set_current_pagination_item}
              fetchData={fetchData}
              industries={industries}
              allFiltersChecker={allFiltersChecker}
              viewAllWholesalersListPermission={viewAllWholesalersListPermission}
              viewOnlyFilteredWholesalerPermission={viewOnlyFilteredWholesalerPermission}
              errorMsgResponseOfViewOnlyFilteredWholesalerPermission={errorMsgResponseOfViewOnlyFilteredWholesalerPermission}
              requestLoader={requestLoader}
            />
          )
          : null}

        {showNotification
          ? (
            <div className="notification">
              <DahboardNotification
                setShowNotification={setShowNotification}
                cities={cities}
                industries={industries}
                userType="Wholesaler"
                setSuccessModal={setSuccessModal}
                setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                setErrorMsgInFailedModal={setErrorMsgInFailedModal}
              />
            </div>
          )
          : null}

        {dashboardLogsOffcanvas
          ? (
            <DashboardLogs
              setDashboardLogsOffcanvas={setDashboardLogsOffcanvas}
            />
          )
          : null}

        {/* success server response model */}
        {
          successModal
            ? (
              <SuccessModal
                setSuccessModal={setSuccessModal}
                updateData={fetchData}
              />
            )
            : null
        }

        {/* failed server response model with error msg */}

        {failedModalWithErrorMsg
          ? (
            <Failed_Modal
              setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
              errorMsg={errorMsgInFailedModal}
            />
          )
          : null}

      </div>
    );
  }

  return (
    <AuthorizationRequiredScreen />
  );
}

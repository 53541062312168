import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select';
import { getFromAgel, postToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';



export default function CreateRelation(props) {
    const {
        setCreateRelationModal,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
    } = props

    const [retailers, setRetailers] = useState([])
    const [selectedRetailer, setSelectedRetailer] = useState("")
    const [wholesalers, setWholesalers] = useState([])
    const [selectedWholesaler, setSelectedWholesaler] = useState("")
    const [retailerSelectValidity, setRetailerSelectValidity] = useState(false)
    const [wsSelectValidity, setWsSelectValidity] = useState(false)
    const [comment, setComment] = useState("")

    //create btn state checker
    const [createBtn, setCreateBtn] = useState(false)


    const fetchRetailersAndWholesalers = async () => {
        try {
            //get all active retailers
            const getRetailers = await getFromAgel('dashboard/retailers/names')
            setRetailers(getRetailers.data.retailersNames)

            //get all active ws
            const getWholesalers = await getFromAgel('/dashboard/wholesalers/names')
            setWholesalers(getWholesalers.data.wholesalersNames)
        }
        catch (error) {
            // console.log({ error })
        }
    }

    useEffect(() => {
        fetchRetailersAndWholesalers()
    }, [])


    const addNewRelation = async (e) => {

        e.preventDefault();

        var data = {
            "wholesalerId": selectedWholesaler,
            "retailerId": selectedRetailer,
            "comment": comment
        }

        if (selectedWholesaler !== "" && selectedRetailer !== "") {
            setWsSelectValidity(false)
            setRetailerSelectValidity(false)
            setCreateBtn(true);

            try {
                await postToAgel(`dashboard/transactionRelation/create`, data)
                setSuccessModal(true)
            }
            catch (error) {
                //sometimes error msg returned in array if there is a problem in body we send
                error.response.data.message[0] ? setErrorMsgInFailedModal(error.response.data.message[0]) : setErrorMsgInFailedModal(error.response.data.message)
                setFailedModalWithErrorMsg(true)
            }
            updateData();
            setCreateRelationModal(false);
            setCreateBtn(false);
        }
        else if (selectedRetailer === "") {
            setRetailerSelectValidity(true)
        }
        else if (selectedWholesaler === "") {
            setWsSelectValidity(true)
        }

    }

    return (
        <div>
            <Modal show={true} onHide={() => setCreateRelationModal(false)} centered size="lg" className="dasboardModal">
                <Modal.Header closeButton className="cancel-msg-modal-header">
                    Add new relation
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={addNewRelation}>
                        <Row>
                            <Col xs={6} style={{ zIndex: "999" }}>
                                <Select
                                    className="role-permissions-select mt-2"
                                    defaultValue={{ "label": "wholesalers", "value": 0 }}
                                    onChange={(e) => { setSelectedWholesaler(e.value); setWsSelectValidity(false) }}
                                    options={wholesalers && wholesalers.map((wholesaler) => ({ "value": wholesaler.id, "label": wholesaler.name }))}
                                />
                                {/* wholesaler validation msg */}
                                {wsSelectValidity && (<p className="select-error-msg">select a wholesaler!</p>)}
                            </Col>
                            <Col xs={6} style={{ zIndex: "999" }}>
                                <Select
                                    className="role-permissions-select mt-2"
                                    defaultValue={{ "label": "Retailers", "value": 0 }}
                                    onChange={(e) => { setSelectedRetailer(e.value); setRetailerSelectValidity(false) }}
                                    options={retailers && retailers.map((retailer) => ({ "value": retailer.id, "label": retailer.name }))}
                                />
                                {/* retailer validation msg */}
                                {retailerSelectValidity && (<p className="select-error-msg">select a retailer!</p>)}

                                <span>

                                </span>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col xs="12">
                                <span className='profile-label-text'>
                                    wholesaler
                                </span>
                                <span className='ws-name-in-invoice-modal'>
                                    {wholesalers.map((i) => i.id === selectedWholesaler ? i.name : null)}
                                </span>
                            </Col>
                            <Col xs="12" className="mt-2">
                                <span className='profile-label-text'>
                                    retailer
                                </span>
                                <span className='ws-name-in-invoice-modal'>
                                    {retailers.map((i) => i.id === selectedRetailer ? i.name : null)}
                                </span>
                            </Col>

                            <Col xs="12" className="mt-2">
                                <span className='profile-label-text'>
                                    Leave a comment
                                </span>
                                <Form.Control as="textarea" value={comment} onChange={(e) => setComment(e.target.value)} className='agelFontFamily text-area-relation-comment mt-2' rows={5} placeholder='Type here...' required />
                            </Col>

                        </Row>


                        <Modal.Footer className='d-flex justify-content-end align-items-center mt-2' style={{ width: "100%", paddingRight: "0" }}>
                            <button className="role-add-btn-with-loader-spinner" type="submit" disabled={createBtn ? true : false}>
                                {createBtn ?
                                    (<LoadingBtnSpinner />)
                                    : (<span>Done</span>)}
                            </button>
                            <button className="roleCloseBtn" onClick={() => setCreateRelationModal(false)}>Close</button>
                        </Modal.Footer>

                    </Form>
                </Modal.Body>

            </Modal>
        </div>
    )
}
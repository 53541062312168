import React from 'react'
import { Container, Card } from 'react-bootstrap'
export default function PosStockCards(props) {
    const {
        pos,
        logs
    } = props
    return (
        <div className='tables-layout'>
            <Container fluid className="d-flex align-items-center justify-content-around">
                {/* card1 */}
                <div>
                    <Card className="posCard">
                        <Card.Body>
                            <Card.Title>
                                <img src="/images/stock.png" className="posCard-images"/>
                            </Card.Title>
                            <Card.Text className='posCard-title'>
                                <strong>Stock</strong>
                            </Card.Text>
                            <span className="posCard-end">
                                <span className="poscard-number">{(pos&&pos.total)?pos&&pos.total:0}</span>
                                <span className="poscard-end-text">Piece</span>
                            </span>
                        </Card.Body>
                    </Card>
                </div>
                {/* card2 */}
                <div>
                    <Card className="posCard">
                        <Card.Body>
                            <Card.Title>
                                <img src="/images/posStock.png" className="posCard-images"/>
                            </Card.Title>
                            <Card.Text className='posCard-title'>
                                <strong>Available</strong>
                            </Card.Text>
                            <span className="posCard-end">
                                <span className="poscard-number">{(pos&&pos.available)?pos&&pos.available:0}</span>
                                <span className="poscard-end-text">Piece</span>
                            </span>
                        </Card.Body>
                    </Card>
                </div>
                {/* card3 */}
                <div>
                    <Card className="posCard">
                        <Card.Body>
                            <Card.Title>
                                <img src="/images/booked.png" className="posCard-images"/>
                            </Card.Title>
                            <Card.Text className='posCard-title'>
                                <strong>Booked</strong>
                            </Card.Text>
                            <span className="posCard-end">
                                <span className="poscard-number">{(pos&&pos.booked)?pos&&pos.booked:0}</span>
                                <span className="poscard-end-text">Piece</span>
                            </span>
                        </Card.Body>
                    </Card>
                </div>
                {/* card4 */}
                <div>
                    <Card className="posCard">
                        <Card.Body>
                            <Card.Title>
                                <img src="/images/damaged.png" className="posCard-images"/>
                            </Card.Title>
                            <Card.Text className='posCard-title'>
                                <strong>Damaged</strong>
                            </Card.Text>
                            <span className="posCard-end">
                                <span className="poscard-number">{(pos&&pos.damaged)?pos&&pos.damaged:0}</span>
                                <span className="poscard-end-text">Piece</span>
                            </span>
                        </Card.Body>
                    </Card>
                </div>
                {/* card5 */}
                <div>
                    <Card className="posCard">
                        <Card.Body>
                            <Card.Title>
                                <img src="/images/delivered.png" className="posCard-images"/>
                            </Card.Title>
                            <Card.Text className='posCard-title'>
                                <strong>Delivered</strong>
                            </Card.Text>
                            <span className="posCard-end">
                                <span className="poscard-number">{(pos&&pos.delivered)?pos&&pos.delivered:0}</span>
                                <span className="poscard-end-text">Piece</span>
                            </span>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
            {/* logs */}
                {/* <Container className="posLogs">
                    <div className="d-flex">
                        <span className="posLogs-title">
                            <strong>
                                All Logs
                            </strong>
                        </span>
                        <img src="/images/logsArrows.png" className="logs-arrows-img"/>
                    </div>
                    <div>
                    <div className='logs-container'>
                        {logs.map((i)=>
                        <span key={i}>
                            <div className='d-flex'>
                                <p className='logs-data'>mohmaed ali</p>
                                <p className='logs-data'>22/10/2022 </p>
                                <p className='logs-data'>3:00pm</p>
                            </div>
                            <div className='logs-info'>
                                Add 100 piece from new shippment
                            </div>
                        </span>
                        )}
                </div>
                    </div>
                </Container> */}


        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { MdPerson } from 'react-icons/md';
import { BsTelephoneFill } from 'react-icons/bs';
import { FaRegTrashAlt } from 'react-icons/fa';
import { getFromAgel, postToAgel } from '../../utils/api';


export default function ViewAssignedRetailers(props) {
    const {
        setViewAssignedRetailersModal,
        currentCollector,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
    } = props;

    const [assignedRetailers, setAssignedRetailers] = useState([]);
    const [loadingDataIndicator, setLoadingDataIndicator] = useState(true);
    const [trashEvent, setTrashEvent] = useState({ loader: 'auto', clickedItem: '' });

    const fetchAssignedRetailer = async () => {
        setLoadingDataIndicator(true)
        try {
            const response = await getFromAgel(`dashboard/collector/${currentCollector.id}/assginedRetailers`);
            setAssignedRetailers(response.data)
        }
        catch (err) {
            //
        }
        setLoadingDataIndicator(false);
    }
    useEffect(() => {
        fetchAssignedRetailer();
    }, [])

    const unassignCollectorToRetailer = async (retailerId) => {
        setTrashEvent({ loader: 'none', clickedItem: retailerId });
        const data = {
            RetailerId: retailerId
        }
        try {
            await postToAgel('dashboard/collector/unAssign', data);
            fetchAssignedRetailer();
            setSuccessModal(true);
        }
        catch (e) {
            setErrorMsgInFailedModal(e?.response?.data?.message);
            setFailedModalWithErrorMsg(true);
        }
        setTrashEvent({ loader: 'auto', clickedItem: retailerId });
        // setViewAssignedRetailersModal(false);
    }

    return (
        <div>
            <Modal show={true} onHide={() => setViewAssignedRetailersModal(false)} centered size="lg" className="dasboardModal">
                <Modal.Header closeButton className="cancel-msg-modal-header">
                    Assigned retailers
                </Modal.Header>

                <Modal.Body>
                    {loadingDataIndicator && assignedRetailers.length === 0 ?
                        <span className='retailer-info-in-view-modal d-flex justify-content-center align-items-center'>
                            Loading...
                        </span>

                        : assignedRetailers?.map((i) =>
                            <Row key={i?.Retailer?.id} className='d-flex align-items-center'>
                                <Col xs="10">
                                    <div className='d-grid justify-content-start align-items-center mb-3'>
                                        <div key={i?.id} className='d-flex justify-content-start align-items-center mb-2'>
                                            <MdPerson className='retailers-icons-in-view-modal' />
                                            <span className='retailer-info-in-view-modal'>
                                                {i?.Retailer?.name}
                                            </span>
                                        </div>

                                        <div className='d-flex justify-content-start align-items-center'>
                                            <BsTelephoneFill className='retailers-icons-in-view-modal' />
                                            <span className='retailer-info-in-view-modal'>
                                                {i?.Retailer?.phoneNumber}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="2" className='text-end'>
                                    <FaRegTrashAlt className={(trashEvent.loader === 'none' && trashEvent.clickedItem == i.Retailer.id) ? 'collector-trash-icon-clicked' : 'collector-trash-icon'} onClick={() => { unassignCollectorToRetailer(i?.Retailer?.id) }} pointerEvents={trashEvent.loader} />
                                </Col>
                            </Row>
                        )}
                </Modal.Body>

                <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%" }}>
                    <button className="roleCloseBtn" onClick={() => setViewAssignedRetailersModal(false)}>Close</button>
                </Modal.Footer>

            </Modal>
        </div>
    )
}
import React, { useState } from 'react';
import {
  Table, Container, Pagination, Row, Col,
} from 'react-bootstrap';

// icons
import { AiOutlineEdit, AiFillEye } from 'react-icons/ai';
import { MdAssignmentInd } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { IoRefreshOutline } from 'react-icons/io5';

import { useNavigate } from 'react-router-dom';
import TableSkeletonUI from '../common/loadingSkeletonUi/tableLoadingSkeletonUi';

export default function CollectorsTable(props) {
  // props
  const {
    collectors,
    pagination_count_of_collector,
    current_pagination_item,
    set_current_pagination_item,
    collectorReuestLoader,
    setCurrentCollector,
    setEditCollectorModal,
    setAssignCollectorsToRetailersModal,
    setResetCollectorPasswordModal,
    setViewAssignedRetailersModal,
  } = props;

  // navigate to cash in/out screen
  const navigate = useNavigate();

  const navigateToCashInCashOutScreen = (id) => {
    navigate(`/employee/collectors/${id}/cashIn/cashOut`);
  };

  const [items, setItems] = useState([0, 1, 2, 3]);
  const [nextBtnPaginationItemsFlag, setNextBtnPaginationItemsFlag] = useState(false);

  const paginationItemsCount = Math.ceil(parseInt(pagination_count_of_collector) / 10);

  const incrementPaginationItem = () => {
    if (items[3] >= (paginationItemsCount - 4)) {
      setNextBtnPaginationItemsFlag(false);
      const tempItems = [paginationItemsCount - 4, paginationItemsCount - 3, paginationItemsCount - 2, paginationItemsCount - 1];
      setItems([...tempItems]);
    } else {
      setNextBtnPaginationItemsFlag(true);
      const tempItems = [current_pagination_item + 1, current_pagination_item + 2, current_pagination_item + 3, current_pagination_item + 4];
      setItems([...tempItems]);
    }
    fetchNewCollectorPage(current_pagination_item + 1);
  };

  const fetchLastPaginationPage = () => {
    if (paginationItemsCount < 4) {
      const tempItems = [];
      for (let i = 0; i < paginationItemsCount; i++) {
        tempItems.push(i); // append in array
      }
      setItems([...tempItems]);
    } else {
      const tempItems = [paginationItemsCount - 4, paginationItemsCount - 3, paginationItemsCount - 2, paginationItemsCount - 1];
      setItems([...tempItems]);
    }
    fetchNewCollectorPage(paginationItemsCount - 1);
    setNextBtnPaginationItemsFlag(false); // so that > disappear on click on >>
  };

  const decrementPaginationItem = () => {
    setItems((prev) => prev.map((i) => i - 1));
    fetchNewCollectorPage(current_pagination_item - 1);
    setNextBtnPaginationItemsFlag(true);
  };

  const fetchFirstPaginationPage = () => {
    const tempItems = [0, 1, 2, 3];
    setItems([...tempItems]);
    fetchNewCollectorPage(0);
    setNextBtnPaginationItemsFlag(true);
  };

  // updating pagination item to update el current page
  const fetchNewCollectorPage = (currentItem) => {
    set_current_pagination_item(currentItem);
  };

  return (
    <div className="tables-layout">
      <Container fluid>
        <Table responsive>
          <thead className="text-center">
            <tr className="role-table-header">
              <th className="text-center">ID</th>
              <th className="text-center">Name</th>
              <th className="text-center">Phone Number</th>
              <th className="text-center">National ID</th>
              <th className="text-center">City</th>
              <th className="text-center">Total cash in wallet</th>
              <th className="text-center">wallet</th>
              <th className="text-center">Status</th>
              <th className="text-center">Cash in/ out</th>
              <th className="text-center">Assigned retailers</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          {collectorReuestLoader
            ? <TableSkeletonUI rows={11} cols={11} />
            : (
              <tbody className="text-center">
                {collectors?.map((i) => (
                  <tr key={i.id} className="role-table-text">
                    <td className="text-center">{i?.id}</td>
                    <td className="text-center">{i?.name}</td>
                    <td className="text-center">{i?.phoneNumber}</td>
                    <td className="text-center">{i?.nationalID}</td>
                    <td className="text-center">{i.City && i.City.EnName}</td>
                    <td className="text-center">{i?.totalCashInWallet}</td>
                    <td className="text-center">{i?.wallet}</td>
                    <td className="text-center" style={i.isActive ? { color: "#00BD85" } : null}>{i.isActive ? ("Active") : ("Inactive")}</td>
                    <td className="text-center">
                      <CgProfile
                        className="collectors-table-profile-details-icons"
                        onClick={() => navigateToCashInCashOutScreen(i.id)}
                      />
                    </td>
                    {/* view assigned retailers */}
                    <td className="text-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <button
                        type='button'
                          className="collectors-table-btns"
                          onClick={() => {
                            setViewAssignedRetailersModal(true);
                            setCurrentCollector(i);
                          }}
                        >
                          View
                          <AiFillEye className="collectors-table-btns-icons" />
                        </button>
                      </div>
                    </td>

                    {/* actions */}
                    <td className="text-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <button
                        type='button'
                          className="collectors-table-btns"
                          onClick={() => {
                            setCurrentCollector(i);
                            setEditCollectorModal(true);
                          }}
                        >
                          Edit
                          <AiOutlineEdit className="collectors-table-btns-icons" />
                        </button>

                        <button
                        type='button'
                          className="collectors-table-btns"
                          style={{ marginLeft: "0.3rem" }}
                          onClick={() => {
                            setAssignCollectorsToRetailersModal(true);
                          }}
                        >
                          assign
                          <MdAssignmentInd className="collectors-table-btns-icons" />
                        </button>

                        <button
                        type='button'
                          className="collectors-table-btns"
                          style={{ marginLeft: "0.3rem" }}
                          onClick={() => {
                            setResetCollectorPasswordModal(true);
                            setCurrentCollector(i);
                          }}
                        >
                          reset
                          <IoRefreshOutline className="collectors-table-btns-icons" />
                        </button>

                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
        </Table>
      </Container>

      {/* pagination won't appear till the collectors intial table appear */}
      {(collectors?.length !== 0) ? (
        <Row>
          <Col xs="12" className="d-flex justify-content-center align-items-center mt-4">
            <Pagination className="retailer-table-pagination" style={{ width: "50%", flexFlow: "wrap" }}>
              {/* first page */}
              {current_pagination_item !== 0
                && <Pagination.First onClick={() => { fetchFirstPaginationPage(); }} />}

              {/* prev page */}
              {current_pagination_item > 0 && items[0] !== 0 ? (
                <Pagination.Prev onClick={() => { decrementPaginationItem(); }} />
              ) : null}

              {/* all pages */}
              {
                items.map((i) => (
                  <Pagination.Item active={current_pagination_item === i} key={i} onClick={() => { fetchNewCollectorPage(i); }}>
                    {i + 1}
                  </Pagination.Item>
                ))
              }

              {/* next page */}
              {nextBtnPaginationItemsFlag && items[3] <= paginationItemsCount
                && <Pagination.Next onClick={() => { incrementPaginationItem(); }} />}

              {/* last page */}
              {current_pagination_item + 1 < paginationItemsCount
                && (<Pagination.Last onClick={() => { fetchLastPaginationPage(); }} />)}

            </Pagination>
          </Col>
        </Row>
      )
        : null}

    </div>
  );
}

import React, { useState } from 'react'
import { Form, Modal, Container } from 'react-bootstrap';
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';



const EditIndustryModal = (props) => {
    let {
        currentIndustry,
        setEditIndustry,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props

    //edit btn vars
    const [editIndustryName, setEditIndustryName] = useState('')
    const [editActive, setEditActive] = useState("")

    //state checker on buttons 
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)

    //put edits
    const submitEdit = async (e) => {

        e.preventDefault();
        setSaveEditsBtn(true);

        var data = {
            'name': editIndustryName === '' ? currentIndustry.name : editIndustryName,
            'isActive': editActive === "" ? `${currentIndustry.isActive}` : `${editActive}`,
        }
        try {
            await putToAgel(`${process.env.React_APP_EDIT_INDUSTRY}${currentIndustry.id}`, data)
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        updateData();
        setEditIndustry(false)
    }



    return (
        <div>
            <Modal show={true} onHide={() => setEditIndustry(false)} centered>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={submitEdit}>
                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Industry Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Industry Name" defaultValue={currentIndustry.name} onChange={(e) => setEditIndustryName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" className="status form-text mt-3" label="isActive" defaultChecked={currentIndustry.isActive} onChange={(e) => setEditActive(e.target.checked)} />
                            </Form.Group>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={saveEditsBtn ? true : false}>
                                    {saveEditsBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>
                                <button className="roleCloseBtn" onClick={() => setEditIndustry(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditIndustryModal

import React, { useEffect, useState } from 'react'
import { Modal, Container, Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select';
import { getFromAgel, postToAgel } from '../../../utils/api';


export default function MoveLeadComment(props) {
    const {
        setMoveLeadCommentModal,
        currentLead,//ha5od mnha el id 
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        fromPhase,
        toPhase
    } = props

    console.log({props})
    
    const [comment, setComment] = useState("")


    const sendComment = async (e) => {
        e.preventDefault();

        var data = {

            "status": toPhase&&toPhase,
            "comment": comment
          }
        try {
            // await postToAgel(`dashboard/CRM/lead/status/edit/${currentLead.id}`, data)
            setSuccessModal(true)
        }
        catch (error) {
            // console.log({error})
            // error.response.data.message[0] ? setErrorMsgInFailedModal(error.response.data.message[0]) : setErrorMsgInFailedModal(error.response.data.message)
            setFailedModalWithErrorMsg(true)
        }
        setMoveLeadCommentModal(false);
        updateData();
    }


    return (
        <div>
            <Modal show={true} onHide={() => setMoveLeadCommentModal(false)} centered size="lg" className="dasboardModal">
                <Modal.Header closeButton>
                    {/* create new lead */}
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs="12">
                            <span className="profile-label-text">
                                wirte detailed comment on transition from"phase {fromPhase&&fromPhase}" to "phase {toPhase&&toPhase}"
                            </span>
                        </Col>
                    </Row>
                    <Form onSubmit={sendComment}>

                        <Container>
                            <Form.Control as="textarea" value={comment} onChange={(e) => setComment(e.target.value)} className='agelFontFamily acquisitionComment mt-4' rows={3} placeholder='Comment...' required />
                        </Container>

                        <Modal.Footer>
                            <button type="submit" className="acquisition-confirm-btn mt-4">Confirm</button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal>
        </div>
    )
}

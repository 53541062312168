import React from "react";
import { Link } from 'react-router-dom'
import { logout } from '../../../utils/auth'
import { AiOutlineLogout } from 'react-icons/ai'
import { useLocation } from "react-router-dom"
import { color } from '../color';
import './Navbar.css'
export default function Navbar(props) {

    const { pathname } = useLocation();

    var group = pathname.substring(1).split('/')[0]
    var subPage = pathname.substring(1).split('/')[1]
    if (group == '') {
        group = 'home'
    }
    if (!subPage) {
        subPage = 'home'
    }

    const bgcolor = {
        backgroundColor: "#00BD85",
        color: "white"
    }





    return (

        <div>
            <div className="agel-navbar d-flex">
                <div className="nav-Btns d-flex justify-content-between align-items-center text-center ">
                    {
                        color[group].subPages.map((page) =>
                            <Link to={`${page.path}`} key={page.name} className="page-name-wrapper" style={subPage == page.selected ? bgcolor : null}>
                                <p className="page-name">{page.name}</p>
                            </Link>
                        )}
                </div>
                <div>
                    <AiOutlineLogout size={35} color="red" className="logout-btn" onClick={e => logout()} />
                </div>
                {/* <button className="logout-btn" onClick={e => logout()} >
                        Logout
                    </button> */}
            </div>
        </div>


    )
}
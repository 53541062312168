import axios from "axios";
import Cookies from "universal-cookie";

// axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.withCredentials = true;
axios.defaults.adapter = require("axios/lib/adapters/http");

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["accept-language"] = "en";
// axios.defaults.headers.common['Accept-Language'] = 'en';

export function setAuthorizationToken() {
  const cookies = new Cookies();
  const token = cookies.get("token");
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}
setAuthorizationToken();

const url = process.env.REACT_APP_API_URL;
const odooUrl = process.env.REACT_APP_ODOO_URL;

// const dev = 'https://agel.com/';
// const relative = "/";
// const domain = document.location.hostname;

// let url = domain.includes("localhost")?  local : relative;
// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
//     url = local;
// }

// const jsonConfig = {
//     headers: {
//         'Content-Type': 'application/json'
//     }
// };
function checkResponse(response) {
  let newResponse = null;
  // lw m3ndo4 account
  if (response.data.status === 0) {
    // console.log("response.data.status =", response.data.status);
    const error = new Error(response.data.message);
    error.name = "invalid request";
    // window.location.replace("/authorizationRequired");
    throw error;
  } else if (response.data.status === 500) {
    window.location.replace("/500");
  } else {
    newResponse = {
      ...response.data,
    };
  }
  return newResponse;
}

export async function postToAgel(path, body, config) {
  const response = await axios
    .create({
      baseURL: url,
    })
    .post(path, body, config);

  return checkResponse(response);
}

export async function postToOdoo(path, body, config) {
  const response = await axios
    .create({
      baseURL: odooUrl,
    })
    .post(path, body, config);

  return checkResponse(response);
}

export async function getFromAgel(path, config) {
  const response = await axios
    .create({
      baseURL: url,
    })
    .get(path, config);

  return checkResponse(response);
}
export async function getFileFromAgel(path, config) {
  const response = await axios
    .create({
      baseURL: url,
    })
    .get(path, config);

  return response;
}
export async function getExcelFileFromAgel(path, config) {
  const response = await axios
    .create({
      baseURL: url,
      responseType: "blob",
    })
    .get(path, config);

  return response;
}

export async function putToAgel(path, body, config) {
  const response = await axios
    .create({
      baseURL: url,
    })
    .put(path, body, config);

  return checkResponse(response);
}

export async function deleteFromAgel(path, body, config) {
  const response = await axios
    .create({
      baseURL: url,
    })
    .delete(path, body, config);

  return checkResponse(response);
}

// export function getFromAgel(path) {
//     return axios.create({
//         baseURL: url
//     }).get(path)
// }

// export function postToAgel(path, body) {
//     return axios.create({
//         baseURL: url
//     }).post(path, body)
// }

// export function patchToAgel(path, body) {
//     return axios.create({
//         baseURL: url
//     }).patch(path, body)
// }

// export function putToAgel(path, body) {
//     return axios.create({
//         baseURL: url
//     }).put(path, body)
// }

// export function removeFromAgel(path) {
//     return axios.create({
//         baseURL: url
//     }).delete(path)
// }

export default axios.create({ baseURL: url });

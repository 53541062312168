import React, { useState } from 'react'

import { Modal, Form, Container, Row, Col } from "react-bootstrap"
import { putToAgel } from '../../utils/api';
import Select from 'react-select';

import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

const CancelConfirmScreen = (props) => {

    const {
        current,
        setCancelConfirmModal,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        updateData,
        hold
    } = props


    //comment
    const [cancelComment, setCancelComment] = useState("")


    //reason
    const [cancelReason, setCancelReason] = useState(["wrong industry", "wrong zone", "percentage"])
    const [selectedReason, setSelectedReason] = useState("")

    //reason select styles classess
    const [reasonsSelectClass, setReasonsSelectClass] = useState("cancelation-reasons-dropdown")

    //select validation
    const [selectReasonFlag, setSelectReasonFlag] = useState(false)


    //state checker on buttons 
    const [doneBtn, setDoneBtn] = useState(false)

    // cancel
    const cancel = async (e) => {

        e.preventDefault();

        var data = {
            "reason": selectedReason,
            "comment": cancelComment
        }

        //lw el select ele f el onchange m4 fadya hay set el flag b true
        // selectedReason
        if (selectedReason !== "") {
            setDoneBtn(true);
            try {
                if (hold) {
                    await putToAgel(`dashboard/wholesaler/activationProcess/${current.id}/hold`, data)
                }
                else {
                    await putToAgel(`dashboard/wholesaler/activationProcess/${current.id}/cancel`, data)
                }
                updateData()
                setSuccessModal(true)
            }
            catch (err) {
                setErrorMsgInFailedModal(err?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
            setCancelConfirmModal(false)
        }
        else {
            setSelectReasonFlag(true)
        }

    }


    return (
        <div>
            <Modal show={true} onHide={() => setCancelConfirmModal(false)} centered style={{ marginTop: "5em" }} className="dashboard-modal">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Container className="mt-3 text-center">
                        <img src="/images/revert.svg" className="img-fluid revertImg" alt="revertImg" />
                        {/* <p className="revert-text"> Are you sure , You want to Cancel ?</p> */}
                    </Container>
                    <Container>
                        <Form onSubmit={cancel}>
                            <Row className='mt-5'>
                                <Col xs="12">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="cancelation-label-form">Cancelation reasons</Form.Label>
                                        <Select className={`${reasonsSelectClass} mt-2`}
                                            onFocus={() => setReasonsSelectClass("cancelation-reasons-dropdown-active")}
                                            onBlur={() => setReasonsSelectClass("cancelation-reasons-dropdown")}
                                            defaultValue={{ "value": 0, "label": " select..." }}
                                            onChange={(e) => { setSelectedReason(e.value); setSelectReasonFlag(false) }}
                                            options={cancelReason.map((reason) => ({ value: reason, label: reason }))}
                                        />
                                        {selectReasonFlag && (<p className="select-error-msg mt-2">Please select reason!</p>)}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label className="cancelation-label-form">Cancelation comment</Form.Label>
                                        <Form.Control type="text" className="newShipment-input" placeholder="Write here..." value={cancelComment} onChange={(e) => setCancelComment(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Modal.Footer className='mt-3 d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={doneBtn ? true : false}>
                                    {doneBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setCancelConfirmModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CancelConfirmScreen
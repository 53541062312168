import React, { useState, useContext } from 'react'
import { putToAgel } from '../../utils/api';
import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';

export default function ApproveButton(props) {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewApproveVisitAnywayPermission = userValidPermissionAndAction(validPermissionsAndActions, "retailerActivationProcess", "updateRActivationProcessActionById")
    //props
    let {
        model,
        current,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        updateData,
    } = props;

    //state checker on approve button
    const [approvedBtn, setApproveBtn] = useState(false)

    //move the current card to the next phase
    const approve = async (e) => {

        e.preventDefault();
        setApproveBtn(true)

        var data = {
            "comment": "approve"
        }
        try {
            await putToAgel(`dashboard/retailer/activationProcess/${current.id}/approve`, data)
            updateData()
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        model(false)
        setApproveBtn(false)
    }

    if (viewApproveVisitAnywayPermission) {
        return (
            <div>
                <button className="action-visit" type="submit"
                    onClick={approve}
                    disabled={approvedBtn ? true : false}>
                    {approvedBtn ?
                        (<LoadingBtnSpinner />)
                        : (<span>Approve</span>)}
                </button>
            </div>
        )
    }
    else {
        return (
            <div></div>
        )
    }
}

import React, { useEffect, useState, useContext } from 'react'
import './home.css'
import { getFromAgel } from '../../utils/api'
import HomeCards from '../../components/Home/HomeCards'
import HomePieChart from '../../components/Home/HomePieChart'
import StatisticsDataFilters from '../../components/Home/StatisticsDataFilters'
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen'
import { userPermissionsAndActionsContext } from '../../App'
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions'



export default function Home() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)

    let viewHomePermission = userValidPermissionAndAction(validPermissionsAndActions,"statistics", "viewCustomerStatistics")



    const [statisticsData, setStatisticsData] = useState([])

    //filters
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [filteredCity, setFilteredCity] = useState(0);

    const fromDateInJSON = new Date(fromDate).toJSON()
    const toDateInJSON = new Date(toDate).toJSON()



    const fetchStatisticsData = async () => {
        let city = filteredCity !== 0 ? filteredCity : null // if filteredCity=0 so selectall is choosen
        try {
            //get data home with filters if found
            const homeDataResponse = await getFromAgel(`${process.env.React_APP_GET_HOME_DATA}`, { params: { startDate: fromDateInJSON, endDate: toDateInJSON, CityId: city } })
            setStatisticsData(homeDataResponse.data)
        }
        catch (error) {
            console.log({ error })
        }
    }

    useEffect(() => {
        fetchStatisticsData()
    }, [])



    if (viewHomePermission) {

        return (
            <div>
                <StatisticsDataFilters
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    toDate={toDate}
                    setFilteredCity={setFilteredCity}
                    fetchStatisticsData={fetchStatisticsData}
                />

                <HomeCards
                    statisticsData={statisticsData}
                />

                <HomePieChart
                    statisticsData={statisticsData}
                />
            </div>
        )
    }

    //if not auth
    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}


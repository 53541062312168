import React, { useState, useEffect } from 'react';
import {
  Modal, Form, Container, Row, Col, ProgressBar,
} from "react-bootstrap";
import { CgProfile } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';

// import component
import { BiMessageDetail } from 'react-icons/bi';
import { IoReloadCircle } from "react-icons/io5";
import { AiOutlineFileImage } from 'react-icons/ai';
import ApproveButton from './ApproveButton';
import DynamicAF from './DynamicAFAfterAgentVisit ';
import APActionButtons from '../ActivationProcessCommonModals/APActionButtons';

// icons
import { putToAgel, postToAgel } from '../../utils/api';

const TeamTwoSecondVisit = (props) => {
  const navigate = useNavigate();

  // props
  const {
    setTeamTwoSecondVisitModal,
    current,
    setSuccessModal,
    setFailedModalWithErrorMsg,
    setErrorMsgInFailedModal,
    updateData,
    setCancelConfirmModal,
    setShowAFComment,
    setAFCancelComment,
  } = props;

  const [AFDynamicData, setAFDynamicData] = useState([]);
  const [AFStaticData, setAFStaticData] = useState([]);
  const [AFRetailerData, setAFRetailerData] = useState([]);
  const [AFCancelCommentInfo, setAFCancelCommentInfo] = useState([]);
  const [agentAFStaticData, setAgentAFStaticData] = useState([]);
  const [estimatedCredit, setEstimatedCredit] = useState("");
  // const [logs, setLogs] = useState([1]);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [userEdits, setUserEdits] = useState([]);
  const [colEditsData, setColEditsData] = useState({});
  const [progressBarRatio, setProgressBarRatio] = useState(0);
  // state checker on buttons
  const [saveEditsBtn, setSaveEditsBtn] = useState(false);
  const [uploadContractBtn, setUploadContractBtn] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // logs
        // const logsResponse=await getFromAgel(`/${current.id}`)
        // console.log("id=",current.id)
        // console.log("logsResponse=",logsResponse)
        // setLogs(logsResponse)

      } catch (e) {
        // console.log("error=", e)
        // console.log("id=", current.id)
      }
    };
    fetchData();
  }, []);

  const saveEdits = async (e) => {
    e.preventDefault();
    setSaveEditsBtn(true);

    const data = {
      Static: colEditsData,
      Dynamic: userEdits,
    };
    try {
      /* retailer save Af edits */
      await putToAgel(`${process.env.React_APP_RETAILER_AF_SAVE_EDITS}${current.id}`, data);
      updateData();
      setSuccessModal(true);
    } catch (err) {
      setErrorMsgInFailedModal(err?.response?.data?.message);
      setFailedModalWithErrorMsg(true);
    }
    setTeamTwoSecondVisitModal(false);
  };

  const uploadContract = async (e) => {
    e.preventDefault();
    setUploadContractBtn(true);

    const formData = new FormData();
    formData.set("contract", selectedFile && selectedFile);
    const data = formData;

    try {
      await postToAgel(`dashboard/retailer/activationProcess/${current.id}/uploadPdf`, data, {
        onUploadProgress: (event) => {
          setProgressBarRatio(Math.round(100 * event.loaded / event.total));
        },
      });
      updateData();
      setSuccessModal(true);
    } catch (err) {
      setErrorMsgInFailedModal(err?.response?.data?.message);
      setFailedModalWithErrorMsg(true);
    }
    setProgressBarRatio(0);
    setUploadContractBtn(false);
    setTeamTwoSecondVisitModal(false);
  };

  const currentAFCancelComment = AFCancelCommentInfo;

  return (
    <div>
      <Modal show onHide={() => setTeamTwoSecondVisitModal(false)} centered size="lg" className="AP-modal" style={{ marginTop: "5em" }}>
        {/* top */}
        <Modal.Header closeButton />

        <Modal.Body style={{ marginBottom: "5em" }}>
          <Container style={{ marginTop: "-1.5rem" }}>

            <Row>
              <Col xs={12} className=" d-flex align-items-center">
                <p className="modal-header-title">{AFStaticData && AFStaticData.name}</p>
                {AFStaticData && AFStaticData.name
                  ? <CgProfile className="link-to-profile-icon-through-af" onClick={() => navigate(`/retailers/list/${AFDynamicData && AFDynamicData.RetailerId}/details`)} />
                  : null}
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="d-flex  justify-content-between align-items-start">
                <p className="modal-title">
                  Agent name
                  <span className="text-muted" style={{ marginLeft: "0.3rem" }}>{agentAFStaticData && agentAFStaticData.name && agentAFStaticData.name}</span>
                </p>
                <p className="modal-title">
                  request date
                  <span className="text-muted" style={{ marginLeft: "0.3rem" }}>{(new Date(AFDynamicData && AFDynamicData.createdAt).toLocaleDateString())}</span>
                </p>
                <p className="modal-title">
                  visit date
                  <span className="text-muted" style={{ marginLeft: "0.3rem" }}>{(new Date(AFDynamicData && AFDynamicData.updatedAt).toLocaleDateString())}</span>
                </p>
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="d-flex text-end justify-content-between align-items-start mt-2">
                <p className="modal-title">
                  Estimated credit
                  <span className="text-muted" style={{ marginLeft: "0.3rem" }}>{estimatedCredit && estimatedCredit}</span>
                </p>
              </Col>
            </Row>

            <Row>
              <Col xs="2" className="d-flex mt-2" style={{ width: "100%", paddingLeft: 0 }}>
                <ApproveButton
                  model={setTeamTwoSecondVisitModal}
                  updateData={updateData}
                  setSuccessModal={setSuccessModal}
                  setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                  setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                  current={current}
                />
              </Col>
            </Row>

          </Container>
          <Container className="mt-3" dir="rtl">
            <Form>
              {/* dynamic  */}
              <DynamicAF
                setUserEdits={setUserEdits}
                userEdits={userEdits}
                AFDynamicData={AFDynamicData}
                AFstaticData={AFStaticData}
                setAFDynamicData={setAFDynamicData}
                setAFStaticData={setAFStaticData}
                AFRetailerData={AFRetailerData}
                setAFRetailerData={setAFRetailerData}
                setAFCancelCommentInfo={setAFCancelCommentInfo}
                setAgentAFStaticData={setAgentAFStaticData}
                agentAFStaticData={agentAFStaticData}
                setColEditsData={setColEditsData}
                setEstimatedCredit={setEstimatedCredit}
                id={current.id}
                editCase={false}
              />

              {/* upload contract */}
              {selectedFile === ""
                ? (
                  <Container className="mt-5">
                    <label className="text-center">
                      <img src="/images/uploadImg.png" alt="upload image" className="img-fluid" />
                      <input type="file" style={{ display: "none" }} onChange={(e) => { setSelectedFile(e.target.files[0]); setSelectedFileName(e.target.files[0].name); console.log("pdf=", e.target.files[0], "selectedFileName=", selectedFileName); }} />
                    </label>
                  </Container>
                )
                : (
                  <Container className="d-grid mt-5">
                    <div className='d-flex'>
                      <AiOutlineFileImage className="AF-reinstate-comment-icon" />
                      <p className="contract-text mt-1">{selectedFileName}</p>
                      <label>
                        <IoReloadCircle className="AF-reinstate-comment-icon cursor-pointer" />
                        <input type="file" style={{ display: "none" }} onChange={(e) => { setSelectedFile(e.target.files[0]); setSelectedFileName(e.target.files[0].name); console.log("pdf=", e.target.files[0], "selectedFileName=", selectedFileName); }} />
                      </label>
                    </div>

                    {/* progress bar of uploading contract file */}
                    {progressBarRatio > 0 &&
                      <ProgressBar dir="ltr" animated now={progressBarRatio} label={`${progressBarRatio}%`} />
                    }
                  </Container>
                )}

              {/* reinstate comment */}
              {currentAFCancelComment && currentAFCancelComment.length !== 0
                ? (
                  <Container className="mt-3">
                    <BiMessageDetail
                      className="AF-reinstate-comment-icon cursor-pointer"
                      onClick={() => {
                        setTeamTwoSecondVisitModal(false);
                        setShowAFComment(true);
                        setAFCancelComment(currentAFCancelComment);
                      }}
                    />
                    <span className="AF-reinstate-comment-text">
                      reinstate comment
                    </span>
                  </Container>
                )
                : null}

              {/* action btns */}
              <Row>
                <Col xs="6" className="d-flex justify-content-start align-items-center mt-5">
                  <APActionButtons
                    permissionName="retailerActivationProcess"
                    actionName="cancel retailer activation process"
                    btnClassName="cancel-visit"
                    btnType="submit"
                    btnName="Cancel"
                    isLoaderFlagTrue={false}
                    btnActions={(e) => {
                      e.preventDefault();
                      setTeamTwoSecondVisitModal(false);
                      setCancelConfirmModal(true);
                    }}
                  />

                  <APActionButtons
                    permissionName="retailerActivationProcess"
                    actionName="submit team 2 retailer activation process"
                    btnClassName="Assignment-accepted"
                    btnType="button"
                    btnName="Submitted"
                    isLoaderFlagTrue={uploadContractBtn}
                    btnActions={uploadContract}
                  />
                </Col>

                <Col xs="6" className="d-flex justify-content-end align-items-center mt-5 ">
                  <APActionButtons
                    permissionName=""
                    actionName=""
                    noPermission
                    btnClassName="save-edits"
                    btnType="submit"
                    btnName="Save Edits"
                    isLoaderFlagTrue={saveEditsBtn}
                    btnActions={saveEdits}
                  />
                </Col>
              </Row>

            </Form>
          </Container>

          {/* logs */}

          {/* <Container>
                    <p className="logs-title mt-5">All Logs</p>
                    <div className='Ap-logs' style={{paddingLeft:"1.5em"}}>
                        {logs.map((i)=>
                        <span>
                            <div className='d-flex'>
                                <p className='logs-data'>mohmaed ali</p>
                                <p className='logs-data'>22/10/2022 </p>
                                <p className='logs-data'>3:00pm</p>
                            </div>
                            <div className='info'>
                                created form
                            </div>
                        </span>
                        )}
                    </div>
                </Container> */}

        </Modal.Body>
      </Modal>
    </div>

  );
};

export default TeamTwoSecondVisit;

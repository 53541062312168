import React, { useState, useEffect } from 'react'
import { Row, Col, Form, FormGroup, FormControl } from "react-bootstrap"
import { getFromAgel } from '../../utils/api';

const DynamicAF = (props) => {
    //props

    let {
        id,
        setAFDynamicData,
        setAFStaticData,
        setAFRetailerData,
        setAFCancelCommentInfo,
        AFstaticData,
        AFRetailerData,
        userEdits,
        setUserEdits,
        AFDynamicData,
        setColEditsData,
        editCase,
        AFRetailerStore,
        setEstimatedCredit
    } = props;

    const [name, setName] = useState("");
    const [home_address, setHome_address] = useState("");
    const [nationalId, setNationalId] = useState("");
    const [storeLocation, setStoreLocation] = useState("");
    const [lat, setLat] = useState("");
    const [long, setLong] = useState("");
    const [fields, setFields] = useState([]);

    // state checker on corrupted images 
    const [frontNationIdCorruptedImg, setFrontNationIdCorruptedImg] = useState(false);
    const [backNationIdCorruptedImg, setBackNationIdCorruptedImg] = useState(false);
    const [commercialRegistrationCorruptedImg1, setCommercialRegistrationCorruptedImg1] = useState(false);
    const [commercialRegistrationCorruptedImg2, setCommercialRegistrationCorruptedImg2] = useState(false);
    const [commercialRegistrationCorruptedImg3, setCommercialRegistrationCorruptedImg3] = useState(false);



    // fetch AF Data before agent visit
    useEffect(() => {
        const fetchData = async () => {
            try {

                if (AFRetailerStore === true) {

                    //DynamicAF data for store Af
                    const StoresDynamicAFResponse = await getFromAgel(`${process.env.React_APP_GET_RETAILER_AF_BY_STORE_ID}${id}`)
                    console.log("DynamicAFResponse=", StoresDynamicAFResponse.data)
                    setAFDynamicData(StoresDynamicAFResponse.data.RetailerDAF)
                    setAFStaticData(StoresDynamicAFResponse.data.RetailerSAF)
                    setAFRetailerData(StoresDynamicAFResponse.data.retailer)
                    setAFCancelCommentInfo(StoresDynamicAFResponse.data.RComment)
                    setEstimatedCredit(StoresDynamicAFResponse.data.credit)
                    console.log("id=", id)

                }
                else {
                    //DynamicAF data for Ap phases
                    const DynamicAFResponse = await getFromAgel(`${process.env.React_APP_GET_RETAILER_AF}${id}`)
                    console.log("DynamicAFResponse=", DynamicAFResponse.data)
                    setAFDynamicData(DynamicAFResponse.data.RetailerDAF)
                    setAFStaticData(DynamicAFResponse.data.RetailerSAF)
                    setAFRetailerData(DynamicAFResponse.data.retailer)
                    setAFCancelCommentInfo(DynamicAFResponse.data.RComment)
                    setEstimatedCredit(DynamicAFResponse.data.credit)
                    console.log("id=", id)

                }
            }
            catch (error) {
                console.log("error=", error)
                console.log("id=", id)
            }
        }
        fetchData()
    }, [])


    let staticEditColData = {};

    useEffect(() => {
        staticEditColData = {
            ...(name ? { "name": name } : {}),
            ...(home_address ? { "home_address": home_address } : {}),
            ...(nationalId ? { "nationalId": nationalId } : {}),
            ...(storeLocation ? { "storeLocation": storeLocation } : {}),
            ...(lat ? { "lat": lat } : {}),
            ...(long ? { "long": long } : {}),
        };

        //only called when any of static data is changed then set all updates in setstate setColEditsData then send it it to back end on edit btn when clicked
        setColEditsData && setColEditsData(staticEditColData)

    }, [
        name, home_address,
        storeLocation, nationalId,
        lat, long
    ]);

    const saveInputs = (QID, AID, value) => {
        let flag = null
        let temp = fields

        for (let i = 0; i < temp.length; i++) {
            if (temp[i].id == QID) {
                flag = i;
                break;
            }

        }
        if (flag == null) {
            temp.push(
                {
                    id: QID,
                    answer: [{
                        id: AID,
                        value: value
                    }]
                }
            )
        }
        else {
            temp[flag].answer[0].value = value
        }
        setFields(temp)
        setUserEdits(fields)
        console.log({ userEdits })
    }


    return (
        <div>

            {/* static  */}

            {/* row1 */}
            <Row>
                <Col xs="4">
                    <Form.Group className="mb-3">
                        <Form.Label className="AP-modal-text">الأسم ثلاثى</Form.Label>
                        <Form.Control type="text" className="role-input mt-2" value={AFstaticData && AFstaticData.name && AFstaticData.name} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={""} onChange={() => ""} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={name} onChange={(e) => { setName(e.target.value) }} disabled={editCase} readOnly={editCase} />
                    </Form.Group>
                </Col>
            </Row>

            {/* row2 */}
            <Row>
                <Col xs="4">
                    <Form.Group className="mb-3">
                        <Form.Label className="AP-modal-text">رقم التليفون</Form.Label>
                        <Form.Control type="text" className="role-input mt-2" value={AFRetailerData?.phoneNumber} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={""} onChange={() => ""} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={""} disabled={true} readOnly={true} />
                    </Form.Group>
                </Col>
            </Row>

            {/* row3 */}
            <Row>
                <Col xs="4">
                    <Form.Group className="mb-3">
                        <Form.Label className="AP-modal-text">عنوان السكن</Form.Label>
                        <Form.Control type="text" className="role-input mt-2" value={AFstaticData && AFstaticData.home_address && AFstaticData.home_address} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={""} onChange={() => ""} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={home_address} onChange={(e) => { setHome_address(e.target.value) }} disabled={editCase} readOnly={editCase} />
                    </Form.Group>
                </Col>
            </Row>

            {/* row4 */}
            <Row>
                <Col xs="4">
                    <Form.Group className="mb-3">
                        <Form.Label className="AP-modal-text">المحافظة</Form.Label>
                        <Form.Control type="text" className="role-input mt-2" value={AFstaticData && AFstaticData.City && AFstaticData.City.EnName && AFstaticData.City.EnName} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={""} onChange={() => ""} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={""} onChange={() => { "" }} disabled={true} readOnly={true} />
                    </Form.Group>
                </Col>
            </Row>

            {/* row5 */}
            <Row>
                <Col xs="4">
                    <Form.Group className="mb-3">
                        <Form.Label className="AP-modal-text">المصنع</Form.Label>
                        <Form.Control type="text" className="role-input mt-2" value={AFstaticData && AFstaticData.Industry && AFstaticData.Industry.name && AFstaticData.Industry.name} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={""} onChange={() => ""} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={""} onChange={() => { "" }} disabled={true} readOnly={true} />
                    </Form.Group>
                </Col>
            </Row>


            {/* row6 */}
            <Row>
                <Col xs="4">
                    <Form.Group className="mb-3">
                        <Form.Label className="AP-modal-text">رقم البطاقة</Form.Label>
                        <Form.Control type="text" className="role-input mt-2" value={AFstaticData && AFstaticData.nationalId && AFstaticData.nationalId} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={""} onChange={() => ""} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={nationalId} onChange={(e) => { setNationalId(e.target.value) }} disabled={editCase} readOnly={editCase} />
                    </Form.Group>
                </Col>
            </Row>

            {/* row7 */}
            <Row>
                <Col xs="4">
                    <Form.Group className="mb-3">
                        <Form.Label className="AP-modal-text">عنوان المحل</Form.Label>
                        <Form.Control type="text" className="role-input mt-2" value={AFRetailerData?.RMegaStore?.Rstores[0]?.store_location} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={""} onChange={() => ""} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={""} onChange={(e) => {""}} disabled readOnly  />
                    </Form.Group>
                </Col>
            </Row>


            {/* row8 */}
            <Row>
                <Col xs="4">
                    <Form.Group className="mb-3">
                        <Form.Label className="AP-modal-text">نوع النشاط</Form.Label>
                        {AFRetailerData && AFRetailerData.labels && AFRetailerData.labels.length !== 0 ?
                            AFRetailerData && AFRetailerData.labels && AFRetailerData.labels.map((i) =>
                                    <Form.Control type="text" className="role-input mt-2" value={i?.name} disabled readOnly />
                            ) :
                            <Form.Control type="text" className="role-input mt-2" value={""} disabled readOnly />
                        }
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value="" onChange={() => ""} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value="" onChange={() => ""} disabled readOnly />
                    </Form.Group>
                </Col>
            </Row>

            {/* row9 */}
            <Row>
                <Col xs="4">
                    <Form.Group className="mb-3">
                        <Form.Label className="AP-modal-text">Lat store</Form.Label>
                        <Form.Control type="text" className="role-input mt-2" value={AFstaticData && AFstaticData.lat && AFstaticData.lat} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value="" onChange={() => ""} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={lat} onChange={(e) => { setLat(e.target.value) }} disabled={editCase} readOnly={editCase} />
                    </Form.Group>
                </Col>
            </Row>

            {/* row10 */}
            <Row>
                <Col xs="4">
                    <Form.Group className="mb-3">
                        <Form.Label className="AP-modal-text">Long store</Form.Label>
                        <Form.Control type="text" className="role-input mt-2" value={AFstaticData && AFstaticData.long && AFstaticData.long} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value="" onChange={() => ""} disabled readOnly />
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="mb-3" style={{ marginTop: "2.2em" }}>
                        <Form.Control type="text" className="role-input mt-2" value={long} onChange={(e) => { setLong(e.target.value) }} disabled={editCase} readOnly={editCase} />
                    </Form.Group>
                </Col>
            </Row>





            {/* dynamic user QA and agent comments */}
            {AFDynamicData && AFDynamicData.RetailerDAFQAs && AFDynamicData.RetailerDAFQAs.map((i) =>
                <Row key={i.id}>
                    <Col xs="4">
                        <FormGroup className="mb-3">
                            <label className="AP-modal-text">{i && i.Question && i.Question.name}</label>
                            {/* multi answers */}
                            {/* {i&&i.answer&&i.answer.map((answers)=>
                            <FormControl type="text" className="role-input mt-2" value={answers.value} readOnly />
                            )} */}
                            <FormControl type="text" className="role-input mt-2" value={i && i.Answer && i.Answer.name !== null ? i && i.Answer && i.Answer.name : i && i.userAnswer && i.userAnswer} onChange={() => ""} disabled readOnly />
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                            <FormControl type="text" className="role-input  mt-2" disabled readOnly />
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                            <FormControl type="text" className="role-input mt-2" defaultValue={i && i.comment && i.comment} onChange={(e) => { saveInputs(i && i.Question && i.Question.id, i && i.Answer && i.Answer.id, e.target.value) }} disabled={editCase} readOnly={editCase} />
                        </FormGroup>
                    </Col>
                </Row>
            )}

            {/* dynamic agent QA and agent comments */}
            {/* {AFDynamicData && AFDynamicData.AgentRetailerDAFQAs && AFDynamicData.AgentRetailerDAFQAs.map((agent) =>
                <Row key={agent.id}>
                    <Col xs="4">
                        <FormGroup className="mb-3">
                            <label className="AP-modal-text">{agent && agent.Question&&agent.Question.name}</label> */}
            {/* multi answers */}
            {/* {i&&i.answer&&i.answer.map((answers)=>
                            <FormControl type="text" className="role-input mt-2" value={answers.value} readOnly />
                            )} */}
            {/* <FormControl type="text" className="role-input mt-2" value={agent && agent.Answer&&agent.Answer.name} onChange={() => ""} disabled readOnly />
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                            <FormControl type="text" className="role-input  mt-2" disabled readOnly />
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                            <FormControl type="text" className="role-input mt-2" onChange={(e) => { saveInputs(agent && agent.Question && agent.Question.id, agent && agent.Answer && agent.Answer.id, e.target.value) }} disabled={editCase} readOnly={editCase} />
                        </FormGroup>
                    </Col>
                </Row>
            )} */}


            {/* static images */}

            {/* row img1*/}
            {(!frontNationIdCorruptedImg && (AFstaticData && AFstaticData.nationalIdImage && AFstaticData.nationalIdImage)) ?
                <div>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <label className="AP-modal-text">الصورة الأمامية للبطاقة</label>
                                <br />
                                <a href={`${AFstaticData && AFstaticData.nationalIdImage && AFstaticData.nationalIdImage}`} target="blank">
                                    <img onError={() => setFrontNationIdCorruptedImg(true)} src={`${AFstaticData && AFstaticData.nationalIdImage && AFstaticData.nationalIdImage}`} className='img-fluid Ap-register-image' />
                                </a>
                            </FormGroup>
                        </Col>
                        {/* <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col>
                        <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col> */}
                    </Row>
                    <br />
                </div>
                : null}

            {/* row img2*/}
            {(!backNationIdCorruptedImg && (AFstaticData && AFstaticData.nationalIdBackImage && AFstaticData.nationalIdBackImage)) ?
                <div>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <label className="AP-modal-text">الصورة الخلفية للبطاقة</label>
                                <br />
                                <a href={`${AFstaticData && AFstaticData.nationalIdBackImage && AFstaticData.nationalIdBackImage}`} target="blank">
                                    <img onError={() => setBackNationIdCorruptedImg(true)} src={`${AFstaticData && AFstaticData.nationalIdBackImage && AFstaticData.nationalIdBackImage}`} className='img-fluid Ap-register-image' />
                                </a>
                            </FormGroup>
                        </Col>
                        {/* <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col>
                        <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col> */}
                    </Row>
                    <br />
                </div>
                : null}

            {/* row img3*/}
            {(!commercialRegistrationCorruptedImg1 && (AFstaticData && AFstaticData.commercial_registration_image && AFstaticData.commercial_registration_image)) ?
                <div>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <label className="AP-modal-text">السجل التجاري ١</label>
                                <br />
                                <a href={`${AFstaticData && AFstaticData.commercial_registration_image && AFstaticData.commercial_registration_image}`} target="blank">
                                    <img onError={() => setCommercialRegistrationCorruptedImg1(true)} src={`${AFstaticData && AFstaticData.commercial_registration_image && AFstaticData.commercial_registration_image}`} className='img-fluid Ap-register-image' />
                                </a>
                            </FormGroup>
                        </Col>
                        {/* <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col>
                        <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col> */}
                    </Row>
                    <br />
                </div>
                : null}

            {/* row img4*/}
            {(!commercialRegistrationCorruptedImg2 && (AFstaticData && AFstaticData.rest_commercial_registration_image && AFstaticData.rest_commercial_registration_image.commercial_registration_image_one)) ?
                <div>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <label className="AP-modal-text">السجل التجاري ٢</label>
                                <br />
                                <a href={`${AFstaticData && AFstaticData.rest_commercial_registration_image && AFstaticData.rest_commercial_registration_image.commercial_registration_image_one}`} target="blank">
                                    <img onError={() => setCommercialRegistrationCorruptedImg2(true)} src={`${AFstaticData && AFstaticData.rest_commercial_registration_image && AFstaticData.rest_commercial_registration_image.commercial_registration_image_one}`} className='img-fluid Ap-register-image' />
                                </a>
                            </FormGroup>
                        </Col>
                        {/* <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col>
                        <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col> */}
                    </Row>
                    <br />
                </div>
                : null}

            {/* row img5*/}
            {(!commercialRegistrationCorruptedImg3 && (AFstaticData && AFstaticData.rest_commercial_registration_image && AFstaticData.rest_commercial_registration_image.commercial_registration_image_two)) ?
                <div>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <label className="AP-modal-text">السجل التجاري ٣</label>
                                <br />
                                <a href={`${AFstaticData && AFstaticData.rest_commercial_registration_image && AFstaticData.rest_commercial_registration_image.commercial_registration_image_two}`} target="blank">
                                    <img onError={() => setCommercialRegistrationCorruptedImg3(true)} src={`${AFstaticData && AFstaticData.rest_commercial_registration_image && AFstaticData.rest_commercial_registration_image.commercial_registration_image_two}`} className='img-fluid Ap-register-image' />
                                </a>
                            </FormGroup>
                        </Col>
                        {/* <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col>
                        <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col> */}
                    </Row>
                    <br />
                </div>
                : null}

            {/* other files rows */}
            {AFstaticData&&AFstaticData.other_images?.map((i) =>
                <div>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <label className="AP-modal-text">ملفات إضافية</label>
                                <br />
                                <a href={`${i}`} target="blank">
                                    <img onError={() => { }} src={`${i}`} className='img-fluid Ap-register-image' />
                                </a>
                            </FormGroup>
                        </Col>
                        {/* <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col>
                        <Col xs="4" className="mt-auto mb-auto">
                            <FormGroup className="mb-3" style={{ marginTop: "2.2em" }}>
                                <FormControl as="textarea" className='Ap-text-area' rows={2} placeholder='' readOnly />
                            </FormGroup>
                        </Col> */}
                    </Row>
                    <br />
                </div>
            )}

        </div>
    )
}

export default DynamicAF

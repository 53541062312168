import React, { useState, useEffect, useContext } from 'react'
import './PosStock.css'

//api
import { getFromAgel } from "../../utils/api";

// components
import PosStockCards from '../../components/PosStock/PosStockCards';
import Adjust from '../../components/PosStock/Adjust';
import DefineDamage from '../../components/PosStock/DefineDamage';
import NewShipment from '../../components/PosStock/NewShipment';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen';
import Failed_Modal from '../../components/common/FailedModal';
import SuccessModal from '../../components/common/SuccessModal';

import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';






export default function PosStock() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewPosStockPermission = userValidPermissionAndAction(validPermissionsAndActions, "pos", "viewPos")


    //pos state
    const [pos, setPos] = useState([]);
    const [logs, setLogs] = useState([1, 2, 9]);


    //Modals
    const [newShipmentModal, setNewShipmentModal] = useState(false);
    const [defineDamagedModal, setDefineDamagedModal] = useState(false);
    const [adjustModal, setAdjustModal] = useState(false);

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")




    const fetchPos = async () => {
        try {

            /* get api pos stock main screen  */
            const posResponse = await getFromAgel(`${process.env.React_APP_GET_POS}`)
            setPos(posResponse.data.posStock)

            //logs
            // const logsResponse=await getFromAgel(`/`)
            // console.log("logsResponse=",logsResponse)
            // setLogs(logsResponse)

        }
        catch (err) {
            // console.log('error=', err)
        }
    }

    useEffect(() => {
        fetchPos()
    }, [])


    if (viewPosStockPermission) {
        return (
            <div>
                {/* row1 */}
                <div className="layout-top d-flex justify-content-end align-items-center">

                    {/* adjust btn */}
                    <div>
                        <div>
                            <button className='adjust-btn' onClick={() => { setAdjustModal(true) }}>
                                Adjust
                            </button>
                            {
                                adjustModal ?
                                    <Adjust
                                        setAdjustModal={setAdjustModal}
                                        pos={pos}
                                        setSuccessModal={setSuccessModal}
                                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                        updateData={fetchPos}
                                    />
                                    : null
                            }
                        </div>
                    </div>

                    {/* defin damage btn */}
                    <div>
                        <div>
                            <button className='damage-btn' onClick={() => { setDefineDamagedModal(true) }}>
                                Define damaged
                            </button>
                            {
                                defineDamagedModal ?
                                    <DefineDamage
                                        setDefineDamagedModal={setDefineDamagedModal}
                                        pos={pos}
                                        setSuccessModal={setSuccessModal}
                                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                        updateData={fetchPos}
                                    />
                                    : null
                            }
                        </div>
                    </div>

                    {/* add new shipment btn */}
                    <div>
                        <div>
                            <button className='add-new-shipment' onClick={() => { setNewShipmentModal(true) }}>
                                Add new shipment
                            </button>
                            {
                                newShipmentModal ?
                                    <NewShipment
                                        setNewShipmentModal={setNewShipmentModal}
                                        setSuccessModal={setSuccessModal}
                                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                        updateData={fetchPos}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                </div>

                {/* pos Cards */}
                <PosStockCards
                    pos={pos}
                    logs={logs}
                />

                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={fetchPos}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }

            </div>
        )
    }

    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Select from 'react-select';

export default function AllCustomersOption(props) {
  const {
    cities,
    industries,
    setSelectedCityId,
    setSelectedIndustryId,
    selectedStatus,
    setSelectedStatus,
  } = props;

  const handleCheckboxChange = (value) => {
    setSelectedStatus(value === setSelectedStatus ? null : value);
  };

  return (
    <>
      {/* status */}
      <div className="mt-4">
        <span className="notification-status-title">
          Status
        </span>
        <Form className="notification-status-checkbox mt-2">
          <Row>
            <Col xs="4">
              <Form.Check
                type="checkbox"
                label="All"
                checked={selectedStatus === "all"}
                onChange={() => handleCheckboxChange("all")}
              />
            </Col>
            <Col xs="4">
              <Form.Check
                type="checkbox"
                label="Active"
                checked={selectedStatus === "active"}
                onChange={() => handleCheckboxChange("active")}
              />
            </Col>
            <Col xs="4">
              <Form.Check
                type="checkbox"
                label="Pending"
                checked={selectedStatus === "pending"}
                onChange={() => handleCheckboxChange("pending")}
              />
            </Col>
          </Row>
        </Form>
      </div>

      {/* filter selection */}
      <div className="mt-4">
        <Row>
          <Col xs="6">
            <span className="notification-city-title">
              City
            </span>
            <Select
              className="mt-2 notification-city-filter"
              placeholder="select city"
              onChange={(e) => setSelectedCityId(e.value)}
              options={cities.map((city) => ({ value: city.id, label: city.EnName }))}
            />
          </Col>
          <Col xs="6">
            <span className="notification-industry-title">
              Industry
            </span>
            <Select
              className="mt-2 notification-industry-filter"
              placeholder="select industry"
              onChange={(e) => setSelectedIndustryId(e.value)}
              options={industries.map((industry) => ({ value: industry.id, label: industry.name }))}
            />
          </Col>

        </Row>
      </div>

    </>
  );
}

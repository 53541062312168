import React, { useState } from 'react'
import { Modal, Container, Form, Row, Col } from 'react-bootstrap';
import { postToAgel } from '../../utils/api';
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';




const CreateRoleModal = (props) => {
    //props

    let {
        setShowCreate,
        allPermissions,
        currentSelectedPermission,
        setCurrentSelectedPermission,
        actionsOfSpecificPermission,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props

    //create btn values
    const [createName, setCreateName] = useState('');
    const [status, setStatus] = useState(false);

    const [showActionList, setShowActionList] = useState(true)
    const [selectedPermissionAndActionsName, setSelectedPermissionAndActionsName] = useState({})

    //create btn state checker
    const [createBtn, setCreateBtn] = useState(false)


    //submit new role in create btn
    const Submit = async (e) => {

        e.preventDefault();
        setCreateBtn(true);

        var data = {
            'name': createName,
            'actions': selectedPermissionAndActionsName,
            'isActive': status
        }
        try {
            await postToAgel(`${process.env.React_APP_CREATE_NEW_ROLE}`, data)
            setSuccessModal(true)
        }
        catch (error) {
            setErrorMsgInFailedModal(error.response.data.message)
            setFailedModalWithErrorMsg(true)
        }
        updateData();
        setShowCreate(false)
    }

    //user select a permission 
    const SelectPermission = async (e) => {
        let selectedPermissionName = e.label
        setCurrentSelectedPermission(selectedPermissionName)
        setShowActionList(false)

        //lw el permission m4 mwgod abl keda  fa handefo ll list
        if (!(selectedPermissionAndActionsName[selectedPermissionName])) {
            selectedPermissionAndActionsName[selectedPermissionName] = []
            setSelectedPermissionAndActionsName({ ...selectedPermissionAndActionsName })
        }
    }

    //user select action 
    const onSelectActions = (selectedList, selectedItem) => {
        selectedPermissionAndActionsName[currentSelectedPermission]?.push(selectedItem.name)
        setSelectedPermissionAndActionsName({ ...selectedPermissionAndActionsName })
        // console.log("added selectedPermissionAndActionsName=",selectedPermissionAndActionsName)
    }

    //user remove action 
    const onRemoveActions = (selectedList, selectedItem) => {
        let remainedItems = selectedPermissionAndActionsName[currentSelectedPermission]?.filter((i) => i !== selectedItem.name)
        // console.log("remainedItems=",remainedItems)
        selectedPermissionAndActionsName[currentSelectedPermission] = remainedItems
        setSelectedPermissionAndActionsName({ ...selectedPermissionAndActionsName })
        // console.log("removed selectedPermissionAndActionsName=",selectedPermissionAndActionsName)
    }


    return (
        <div>

            <Modal show={true} onHide={() => setShowCreate(false)} className="dashboard-modal mt-5" centered>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Create Role</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <Container className="mt-3">
                        {/* keda hy3ml form */}
                        <Form onSubmit={Submit}>
                            <Form.Group style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Role Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" style={{ borderRadius: "5px" }} placeholder="Enter Name" value={createName} onChange={(e) => setCreateName(e.target.value)} required />
                            </Form.Group>


                            <Form.Group>
                                <Form.Check type="checkbox" className="status form-text mt-3" label={"Active"}
                                    onChange={(e) => { setStatus(e.target.checked); console.log("status=", status) }}
                                />
                            </Form.Group>

                            {/* select permission */}
                            <Form.Group className="mt-4">
                                <Row className='d-flex align-items-center'>
                                    <Col xs="4">
                                        <Form.Label className="form-text">
                                            permissions
                                        </Form.Label>
                                    </Col>
                                    <Col xs="8">
                                        <Select
                                            className="role-permissions-select"
                                            defaultValue={{ "value": 0, "label": " select..." }}
                                            options={allPermissions?.map((permission) => ({ "value": permission?.id, "label": permission?.name }))}
                                            onChange={SelectPermission}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            {/* select actions */}
                            <Form.Group className="mt-4">
                                <Row className='d-flex align-items-center'>
                                    <Col xs="4">
                                        <Form.Label className="form-text">
                                            Actions
                                        </Form.Label>
                                    </Col>
                                    <Col xs="8">
                                        <div className="role-multiselect-actions">
                                            <Multiselect
                                                options={actionsOfSpecificPermission}
                                                displayValue="name"
                                                placeholder="select..."
                                                onSelect={onSelectActions}
                                                onRemove={onRemoveActions}
                                                showCheckbox={true}
                                                disable={showActionList}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Form.Group>

                            {/* added permissions and actions */}

                            <Form.Group className="mt-4">
                                <Row className='d-flex justify-content-center align-items-center'>
                                    <Col xs="12">
                                        <Form.Label className="form-text">
                                            Added
                                        </Form.Label>

                                        <div className={Object.keys(selectedPermissionAndActionsName).length === 0 ? "d-flex justify-content-center align-items-center role-permission-list pt-3 pb-2" : "role-permission-list"}>

                                            {Object.keys(selectedPermissionAndActionsName).length === 0 ?

                                                //incase no permission is added yet
                                                <h6 className='permission-sub-list'>
                                                    no added permissions yet!
                                                </h6>
                                                ://incase user choose permission
                                                <ol>
                                                    {Object.keys(selectedPermissionAndActionsName).map((i) => (
                                                        <>
                                                            {/* permission name */}
                                                            <li className='mt-3'>{i}</li>
                                                            {/* actions list */}
                                                            <ul>
                                                                {Object.values(selectedPermissionAndActionsName[i]).map((action) =>
                                                                    <li className='permission-sub-list'>
                                                                        {action}
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </>
                                                    ))}
                                                </ol>
                                            }

                                        </div>

                                    </Col>
                                </Row>
                            </Form.Group>



                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={createBtn ? true : false}>
                                    {createBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setShowCreate(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>

        </div >
    )
}

export default CreateRoleModal
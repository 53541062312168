import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function LoadingBtnSpinner(){
    return(
        <span disabled dir='ltr' className='d-flex justify-content-center align-items-center'  style={{marginLeft:"0.3vw"}}>
        <Spinner
            as="span"
            animation="grow"
            // size="sm"
            role="status"
            aria-hidden="true"
            style={{width:"1.1vw",height:"1.1vw",textAlign:"center"}}
        />
        <span> Loading...</span>
    </span>

    )
}
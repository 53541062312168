import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import './DashboardLogs.css';
import { getFromAgel } from "../../../utils/api";

export default function DashboardLogs(props) {
  const {
    setDashboardLogsOffcanvas,
  } = props;

  const [logs, setLogs] = useState([1]);
  const [loadingRequest, setLoadingRequest] = useState(false);

  useEffect(() => {
    const fetchLogs = async () => {
      setLoadingRequest(true);
      try {
        const logsResponse = await getFromAgel('');
        // setLogs(logsResponse.data)
      } catch (e) {
        //
      }
      setLoadingRequest(false);
    };
    fetchLogs();
  }, []);

  return (
    <>
      <Offcanvas show onHide={() => setDashboardLogsOffcanvas(false)} placement="bottom" scroll backdrop className="dashboard-logs-offcanvas">

        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <b>Logs</b>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          {loadingRequest
            ? (
              <h4 className="text-center mt-2">
                Loading...
              </h4>
            )
            : (!loadingRequest && logs.length !== 0)
              ? logs.map((i) => (
                <p>
                  staff did action days ago
                </p>
              ))
              : null}
        </Offcanvas.Body>

      </Offcanvas>
    </>
  );
}

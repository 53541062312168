import React, { useState } from 'react'
import Select from 'react-select';
import { postToAgel } from '../../utils/api'

import { Modal, Form, Container } from 'react-bootstrap'
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';



const CreateModal = (props) => {
    // props
    const {
        setShowCreate,
        selectedCountries,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
    } = props


    //create btn vars
    const [createSelectCountry, setCreateSelectCountry] = useState('')
    const [createArabicName, setCreateArabicName] = useState('')
    const [createEnglishName, setCreateEnglishName] = useState('')
    const [createActive, setCreateActive] = useState(false)

    //create btn state checker
    const [createBtn, setCreateBtn] = useState(false)


    //post create

    const submitCreate = async (e) => {

        e.preventDefault();
        setCreateBtn(true);

        var data = {

            'ArName': createArabicName,
            'EnName': createEnglishName,
            'isActive': createActive,
            'CountryId': createSelectCountry,
        }
        try {
            await postToAgel(`${process.env.React_APP_CREATE_NEW_CITY}`, data)
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        updateData();
        setShowCreate(false)
        setCreateBtn(false);
    }


    return (
        <div>

            <Modal show={true} onHide={() => setShowCreate(false)} centered className="mt-5">
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={submitCreate}>

                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Arabic Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Arabic Name" value={createArabicName} onChange={(e) => setCreateArabicName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">English Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter English Name" value={createEnglishName} onChange={(e) => setCreateEnglishName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" className="status form-text mt-3" label="isActive" defaultChecked={createActive} onChange={(e) => setCreateActive(e.target.checked)} />
                            </Form.Group>

                            {/* ha5od mno el countries fe api w a map 3lehom gwa option el select wb3den arg3homlo f el edit wel create bs mo2ktn han7oto f state string*/}
                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">Country Name(English)</Form.Label>
                                <Select
                                    className="search-select mt-2"
                                    defaultValue={{ "label": "Countries", "value": "0" }}
                                    onChange={(e) => { setCreateSelectCountry(e.value) }}
                                    options={selectedCountries.map((country) => ({ "value": country && country.id, "label": country && country.EnName }))}
                                />
                            </Form.Group>


                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={createBtn ? true : false}>
                                    {createBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setShowCreate(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CreateModal

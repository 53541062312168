import React, { useState, useEffect } from 'react';

import {
  Modal, Form, Container, Row, Col,
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { BiMessageDetail } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import { putToAgel } from '../../utils/api';

// import component
import ApproveButton from './ApproveButton';
import DynamicAF from './DynamicAFAfterAgentVisit ';
import APActionButtons from '../ActivationProcessCommonModals/APActionButtons';

// icons

const SecondVisit = (props) => {
  const navigate = useNavigate();

  // props
  const {
    setSecondVisitModal,
    setReschduleModal,
    setRescheduleCurrent,
    setRegisterRescheduleFlag,
    setFirstVisitRescheduleFlag,
    setContractRescheduleFlag,
    setSecondVisitRescheduleFlag,
    current,
    setSuccessModal,
    setFailedModalWithErrorMsg,
    setErrorMsgInFailedModal,
    updateData,
    setCancelConfirmModal,
    setShowAFComment,
    setAFCancelComment,
  } = props;

  const [AFDynamicData, setAFDynamicData] = useState([]);
  const [AFStaticData, setAFStaticData] = useState([]);
  const [AFRetailerData, setAFRetailerData] = useState([]);
  const [AFCancelCommentInfo, setAFCancelCommentInfo] = useState([]);
  const [agentAFStaticData, setAgentAFStaticData] = useState([]);
  const [estimatedCredit, setEstimatedCredit] = useState("");
  // const [logs, setLogs] = useState([1]);
  const [userEdits, setUserEdits] = useState([]);
  const [colEditsData, setColEditsData] = useState({});

  // state checker on buttons
  const [saveEditsBtn, setSaveEditsBtn] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // logs
        // const logsResponse = await getFromAgel(`/${current.id}`)
        // console.log("id=", current.id)
        // console.log("logsResponse=", logsResponse)
        // setLogs(logsResponse)
      } catch (e) {
        // console.log("error=", e)
        // console.log("id=", current.id)
      }
    };
    fetchData();
  }, []);

  const saveEdits = async (e) => {
    e.preventDefault();
    setSaveEditsBtn(true);

    const data = {
      Static: colEditsData,
      Dynamic: userEdits,
    };
    try {
      /* retailer save Af edits */
      await putToAgel(`${process.env.React_APP_RETAILER_AF_SAVE_EDITS}${current.id}`, data);
      updateData();
      setSuccessModal(true);
    } catch (err) {
      setErrorMsgInFailedModal(err?.response?.data?.message);
      setFailedModalWithErrorMsg(true);
    }
    setSecondVisitModal(false);
  };

  const currentAFCancelComment = AFCancelCommentInfo;

  return (
    <div>
      <Modal show onHide={() => setSecondVisitModal(false)} centered size="lg" className="AP-modal" style={{ marginTop: "5em" }}>
        {/* top */}
        <Modal.Header closeButton />

        <Modal.Body style={{ marginBottom: "5em" }}>
          <Container style={{ marginTop: "-1.5rem" }}>

            <Row>
              <Col xs={12} className=" d-flex align-items-center">
                <p className="modal-header-title">{AFStaticData && AFStaticData.name}</p>
                {AFStaticData && AFStaticData.name
                  ? <CgProfile className="link-to-profile-icon-through-af" onClick={() => navigate(`/retailers/list/${AFDynamicData && AFDynamicData.RetailerId}/details`)} />
                  : null}
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="d-flex  justify-content-between align-items-start">
                <p className="modal-title">
                  Agent name
<span className="text-muted" style={{ marginLeft: "0.3rem" }}>{agentAFStaticData && agentAFStaticData.name && agentAFStaticData.name}</span>
                </p>
                <p className="modal-title">
                  request date
<span className="text-muted" style={{ marginLeft: "0.3rem" }}>{(new Date(AFDynamicData && AFDynamicData.createdAt).toLocaleDateString())}</span>
                </p>
                <p className="modal-title">
                  visit date
<span className="text-muted" style={{ marginLeft: "0.3rem" }}>{(new Date(AFDynamicData && AFDynamicData.updatedAt).toLocaleDateString())}</span>
                </p>
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="d-flex text-end justify-content-between align-items-start mt-2">
                <p className="modal-title">
                  Estimated credit
<span className="text-muted" style={{ marginLeft: "0.3rem" }}>{estimatedCredit && estimatedCredit}</span>
                </p>
              </Col>
            </Row>

            <Row>
              <Col xs="2" className="d-flex mt-2" style={{ width: "100%", paddingLeft: 0 }}>
                <ApproveButton
                  model={setSecondVisitModal}
                  updateData={updateData}
                  setSuccessModal={setSuccessModal}
                  setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                  setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                  current={current}
                />
              </Col>
            </Row>
          </Container>

          <Container className="mt-3" dir="rtl">
            <Form>
              {/* dynamic  */}
              <DynamicAF
                setUserEdits={setUserEdits}
                userEdits={userEdits}
                AFDynamicData={AFDynamicData}
                AFstaticData={AFStaticData}
                setAFDynamicData={setAFDynamicData}
                setAFStaticData={setAFStaticData}
                AFRetailerData={AFRetailerData}
                setAFRetailerData={setAFRetailerData}
                setAFCancelCommentInfo={setAFCancelCommentInfo}
                setAgentAFStaticData={setAgentAFStaticData}
                agentAFStaticData={agentAFStaticData}
                setColEditsData={setColEditsData}
                setEstimatedCredit={setEstimatedCredit}
                id={current.id}
                editCase={false}
              />

              {/* reinstate comment */}
              {currentAFCancelComment && currentAFCancelComment.length !== 0
                ? (
                  <Container className="mt-3">
                    <BiMessageDetail
                        className="AF-reinstate-comment-icon cursor-pointer"
                        onClick={() => {
                            setSecondVisitModal(false);
                            setShowAFComment(true);
                            setAFCancelComment(currentAFCancelComment);
                          }}
                      />
                    <span className="AF-reinstate-comment-text">
                        reinstate comment
                                    </span>
                  </Container>
                )
                : null}

              {/* actions btn */}
              <Row>
                <Col xs="6" className="d-flex justify-content-start align-items-center mt-5 ">
                  <APActionButtons
                    permissionName="retailerActivationProcess"
                    actionName="cancel retailer activation process"
                    btnClassName="cancel-visit"
                    btnType="submit"
                    btnName="Cancel"
                    isLoaderFlagTrue={false}
                    btnActions={(e) => {
                        e.preventDefault();
                        setSecondVisitModal(false);
                        setCancelConfirmModal(true);
                      }}
                  />

                  <APActionButtons
                    permissionName=""
                    actionName=""
                    noPermission
                    btnClassName="action-visit"
                    btnType="button"
                    btnName="Reschdule"
                    isLoaderFlagTrue={false}
                    btnActions={(e) => {
                        e.preventDefault();
                        setSecondVisitRescheduleFlag(true);
                        setFirstVisitRescheduleFlag(false);
                        setRegisterRescheduleFlag(false);
                        setContractRescheduleFlag(false);
                        setReschduleModal(true);
                        setRescheduleCurrent(AFDynamicData);
                        setSecondVisitModal(false);
                      }}
                  />

                </Col>
                <Col xs="6" className="d-flex justify-content-end align-items-center mt-5 ">
                  <APActionButtons
                    permissionName=""
                    actionName=""
                    noPermission
                    btnClassName="save-edits"
                    btnType="submit"
                    btnName="Save Edits"
                    isLoaderFlagTrue={saveEditsBtn}
                    btnActions={saveEdits}
                  />

                </Col>
              </Row>
            </Form>
          </Container>
          {/* logs */}
          {/* <Container>
                    <p className="logs-title mt-5">All Logs</p>
                    <div className='Ap-logs' style={{paddingLeft:"1.5em"}}>
                        {logs.map((i)=>
                        <span>
                            <div className='d-flex'>
                                <p className='logs-data'>mohmaed ali</p>
                                <p className='logs-data'>22/10/2022 </p>
                                <p className='logs-data'>3:00pm</p>
                            </div>
                            <div className='info'>
                                created form
                            </div>
                        </span>
                        )}
                    </div>
                </Container> */}

        </Modal.Body>
      </Modal>
    </div>

  );
};

export default SecondVisit;

import React, { useState, useEffect } from 'react'

import { Modal, Container } from "react-bootstrap"
import { getFromAgel } from '../../utils/api'

const RelationComment = (props) => {

    const {
        setRelationCommentModal,
        currentCommentTxt,
        currentCommentMaker
    } = props



    return (
        <div>
            <Modal show={true} onHide={() => setRelationCommentModal(false)} centered size="lg" className="dasboardModal">
                <Modal.Header closeButton className="cancel-msg-modal-header">
                    Relation Comment
                </Modal.Header>
                <Modal.Body>
                        <div className="mb-4">
                            <div className="cancel-msg-modal-title">
                                <p> Commented by
                                    <span className="cancelled-by"> {currentCommentMaker&&currentCommentMaker} </span>
                                </p>
                            </div>
                            <div className="reason-box">
                                <span>{currentCommentTxt&&currentCommentTxt}</span>
                            </div>
                        </div>
                   
                </Modal.Body>
                <Modal.Footer>
                    <Container className="text-end">
                        <button className="role-close-btn" onClick={() => setRelationCommentModal(false)}>Close</button>
                    </Container>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default RelationComment

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { IoArrowBackCircle } from 'react-icons/io5'
import './AuthorizationRequiredScreen.css'


export default function AuthorizationRequiredScreen() {
    let navigate = useNavigate()
    return (
        <div className="text-center">
            <div>
                <img src="/images/autho-required.png" alt="not found img" className="img-error-screen" />
            </div>
            <div className="error-screen-text-msg">
                Authorization required.
            </div>
            {/* <Row className="d-flex justify-content-center align-items-center">
                <Col lg="2" md="3" xs="4">
                <button className='back-home-btn' onClick={() => { navigate(-1) }}><IoArrowBackCircle size={"18"} /> Go Back</button>
                </Col>
            </Row> */}
        </div>
    )
}
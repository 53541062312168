import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai'

const RoleCards = (props) => {
    // props
    let {roles ,setCurrentEditRoleId, setShowEdit} = props
    return (
        <div className='tables-layout'>
            <Container fluid>
            <Table responsive>
                <thead className='text-center'>
                    <tr className='role-table-header'>
                        <th>ID</th>
                        <th>Role Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                {roles && roles.map((role) =>
                        <tr key={role.id} className='role-table-text'>
                        <td className='text-center'>{role.id}</td>
                        <td className='text-center'>{role.name}</td>
                        <td className='text-center'>
                        <button className='role-edit-btn'
                        style={{width:"6.5rem"}}
                            onClick={() => {
                                setShowEdit(true);
                                setCurrentEditRoleId(role.id)
                                console.log('pressed')
                            }}
                        >
                            Edit <AiOutlineEdit/>
                        </button>
                        </td>
                        </tr>                
                )}
                </tbody>
            </Table>
            </Container>
        </div>
    )
}

export default RoleCards

import React from "react";
import './LoadingScreen.css'


export default function LoadingScreen() {
    return (
        <div className='d-flex justify-content-center align-items-center mt-5'>
            <p className='loadingText'>
                Loading...
            </p>
        </div>
    )
}



import React, { useState } from 'react'

import { Modal, Form, Container, Row, Col } from "react-bootstrap"
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


const Adjust = (props) => {

    const {
        setAdjustModal,
        pos,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        updateData
    } = props

    //Done btn state checker
    const [adjustBtn, setAdjustBtn] = useState(false)

    const [available, setAvailable] = useState("");
    const [booked, setBooked] = useState("");
    const [damaged, setDamaged] = useState("");
    const [deliverd, setDeliverd] = useState("");
    const [totalCheckFlag, setTotalCheckFlag] = useState(false);

    let totalUserEntry = parseInt(available) + parseInt(booked) + parseInt(damaged) + parseInt(deliverd)
    const stockTotalNumber = pos && pos.total


    //submit adjust
    const Submit = async (e) => {

        e.preventDefault();

        if (stockTotalNumber >= totalUserEntry) {
            setTotalCheckFlag(false)
            setAdjustBtn(true);
            var data = {
                'available': parseInt(available),
                'booked': parseInt(booked),
                'damaged': parseInt(damaged),
                'delivered': parseInt(deliverd),
            }
            try {
                /* adjust pos */
                await putToAgel(`${process.env.React_APP_ADJUST_POS}`, data)
                setSuccessModal(true)
            }
            catch (err) {
                setErrorMsgInFailedModal(err?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
            updateData()
            setAdjustModal(false)
        }
        else {
            setTotalCheckFlag(true)
            setAdjustBtn(false);
        }
    }


    return (
        <div>
            <Modal show={true} onHide={() => setAdjustModal(false)} centered size="lg" style={{ marginTop: "5em" }} className="posActionModal">
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <span className="dashboard-font">
                            <strong>
                                Adjust
                            </strong>
                        </span>
                        {/* form */}
                        <Form onSubmit={Submit}>
                            <Row className='mt-4'>
                                <Col xs="3">
                                    <Form.Group>
                                        <Form.Label className="form-text">Available</Form.Label>
                                        <Form.Control type="text" className="amount-input" value={available} onChange={(e) => setAvailable(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                                <Col xs="3">
                                    <Form.Group>
                                        <Form.Label className="form-text">Booked</Form.Label>
                                        <Form.Control type="text" className="amount-input" value={booked} onChange={(e) => setBooked(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                                <Col xs="3">
                                    <Form.Group>
                                        <Form.Label className="form-text">Damaged</Form.Label>
                                        <Form.Control type="text" className="amount-input" value={damaged} onChange={(e) => setDamaged(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                                <Col xs="3">
                                    <Form.Group>
                                        <Form.Label className="form-text">Deliverd</Form.Label>
                                        <Form.Control type="text" className="amount-input" value={deliverd} onChange={(e) => setDeliverd(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {totalCheckFlag === true &&
                                <p className='password-confirm-msg mt-4'>Total values should be less than or equal the stock number</p>
                            }

                            <Modal.Footer className='d-flex justify-content-end align-items-center mt-4' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={adjustBtn ? true : false}>
                                    {adjustBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>
                                <button className="roleCloseBtn" onClick={() => setAdjustModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Adjust

import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import Switch from "react-switch";
import { Row, Col, Form, Container, Table } from 'react-bootstrap'
import { CgProfile } from 'react-icons/cg'
import { AiFillPlusCircle } from 'react-icons/ai'

//components
import AddNoteModal from './AddNoteModal';
import AuthorizationRequiredScreen from '../../screens/AuthorizationRequiredScreen/AuthorizationRequiredScreen';
import Failed_Modal from '../common/FailedModal';
import SuccessModal from '../common/SuccessModal';

//api
import { getFromAgel } from '../../utils/api'

import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';



export default function Invoices(props) {

    //permissions
    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewWsProfileInvoiceScreenPermission = userValidPermissionAndAction(validPermissionsAndActions, "wholesalerPayment", "viewWholesalerPaymentByPaymentId")

    // params
    const {
        id, wsID
    } = useParams()

    let navigate = useNavigate()

    // console.log("params=", id, wsID)

    const [invoicesData, setInvoicesData] = useState([])
    const [checked, setChecked] = useState("")
    const [firstInvoiceImgValidity, setFirstInvoiceImgValidity] = useState(false);
    const [secondInvoiceImgValidity, setSecondInvoiceImgValidity] = useState(false);
    const [thirdInvoiceImgValidity, setThirdInvoiceImgValidity] = useState(false);

    const [addNote, setAddNote] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [loadMoreVisiblity, setLoadMoreVisiblity] = useState("block")
    const [notes, setNotes] = useState([])

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")

    //paid/not paid switch button 
    const toggleSwitch = async (checked) => {
        setChecked(checked)
        var data = {
            "checked": checked
        }
        try {
            // await putToAgel(`/dashboard/..${id}/`, data)
            // console.log("trydata=", data)
            // console.log({ id })
        }
        catch (error) {
            // console.log("errdata=", data)
            // console.log({ error })
        }
    }


    const fetchData = async () => {
        try {
            /* get ws invoices data */
            const invoicesInfoDataResponse = await getFromAgel(`dashboard/wholesaler/${wsID}/wholesalerpayments/${id}`)
            setInvoicesData(invoicesInfoDataResponse.data);
            setNotes(invoicesInfoDataResponse.data.notes);
        }
        catch (error) {
            // console.log({ error })
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    let singleNote = notes ? notes[0] : null;
    // console.log("notes=", singleNote)

    if (viewWsProfileInvoiceScreenPermission) {
        return (
            <div className='layout-invoices'>

                {/* switch paid/not paid */}
                <Row className='d-flex justify-content-end align-items-center'>
                    <Col xs="4" className="text-center">
                        <span className="switch-text">Paid/Notpaid</span>
                        <label>
                            <Switch onChange={toggleSwitch} checked={invoicesData && invoicesData.payments && invoicesData.payments.status && invoicesData.payments.status} checkedIcon={false} uncheckedIcon={false} height={23} width={48} disabled={true} /> {/* disabled={true} 34an sabry m4 3ayzha editable now 8er keda de hattms7 */}
                        </label>
                    </Col>
                </Row>

                {/* info inputs */}
                <Container fluid className="mt-5">
                    <Form>

                        {/* row1 */}
                        <Row>
                            <Col xs="4">
                                <Form.Group>
                                    <Form.Label className="text-label">
                                        Wholesaler Name
                                        <CgProfile className="invoices-icon" onClick={() => navigate(`/wholesalers/list/${invoicesData && invoicesData.payments && invoicesData.payments.Transaction && invoicesData.payments.Transaction.Wholesaler && invoicesData.payments.Transaction.Wholesaler.id && invoicesData.payments.Transaction.Wholesaler.id}/details`)} />
                                    </Form.Label>
                                    <Form.Control type="text" className="invoices-input mt-2" value={invoicesData && invoicesData.payments && invoicesData.payments.Transaction && invoicesData.payments.Transaction.Wholesaler && invoicesData.payments.Transaction.Wholesaler.name && invoicesData.payments.Transaction.Wholesaler.name} onChange={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group>
                                    <Form.Label className="text-label">
                                        Retailer Name
                                        <CgProfile className="invoices-icon" onClick={() => navigate(`/retailers/list/${invoicesData && invoicesData.payments && invoicesData.payments.Transaction && invoicesData.payments.Transaction.Retailer && invoicesData.payments.Transaction.Retailer.id && invoicesData.payments.Transaction.Retailer.id}/details`)} />
                                    </Form.Label>
                                    <Form.Control type="text" className="invoices-input mt-2" value={invoicesData && invoicesData.payments && invoicesData.payments.Transaction && invoicesData.payments.Transaction.Retailer && invoicesData.payments.Transaction.Retailer.name && invoicesData.payments.Transaction.Retailer.name} onChange={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group>
                                    <Form.Label className="text-label">Date</Form.Label>
                                    <Form.Control type="text" className="invoices-input mt-2" value={new Date(invoicesData && invoicesData.payments && invoicesData.payments.Transaction && invoicesData.payments.Transaction.createdAt && invoicesData.payments.Transaction.createdAt).toLocaleDateString()} onChange={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* row2 */}
                        <Row className="mt-4">
                            <Col xs="4">
                                <Form.Group>
                                    <Form.Label className="text-label">Store</Form.Label>
                                    <Form.Control type="text" className="invoices-input mt-2" value={invoicesData && invoicesData.storeName && invoicesData.storeName} onChange={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group>
                                    <Form.Label className="text-label">Paid/Notpaid</Form.Label>
                                    <Form.Control type="text" className="invoices-input mt-2" value={invoicesData && invoicesData.payments && invoicesData.payments.status && invoicesData.payments.status} onChange={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group>
                                    <Form.Label className="text-label">Status</Form.Label>
                                    <Form.Control type="text" className="invoices-input mt-2" value={invoicesData && invoicesData.payments && invoicesData.payments.Transaction && invoicesData.payments.Transaction.status && invoicesData.payments.Transaction.status} onChange={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* row3 */}
                        <Row className="mt-4">
                            <Col xs="4">
                                <Form.Group>
                                    <Form.Label className="text-label">Total</Form.Label>
                                    <Form.Control type="text" className="invoices-input mt-2" value={invoicesData && invoicesData.payments && invoicesData.payments.Transaction && invoicesData.payments.Transaction.price && invoicesData.payments.Transaction.price} onChange={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group>
                                    <Form.Label className="text-label">Discount</Form.Label>
                                    <Form.Control type="text" className="invoices-input mt-2" value={invoicesData && invoicesData.payments && invoicesData.payments.Transaction && invoicesData.payments.Transaction.discount && invoicesData.payments.Transaction.discount} onChange={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group>
                                    <Form.Label className="text-label">Amount</Form.Label>
                                    <Form.Control type="text" className="invoices-input mt-2" value={invoicesData && invoicesData.amount && invoicesData.amount} onChange={""} readOnly disabled />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* row4 */}
                        {/* <Row className="mt-4">
                        <Col xs="4">
                            <Form.Group>
                                <Form.Label className="text-label">
                                    Loan
                                    <GiReceiveMoney className="invoices-icon" />   hyro7 3l loan page b3den onClick={()=>navigate(`/retailers/${id}/loan`)} wm4 hykon fe input field
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row> */}

                    </Form>
                </Container>

                <hr />
                <Container fluid className="mt-5">
                    <Row>
                        {/* invoice img1 validity */}
                        {!firstInvoiceImgValidity && invoicesData?.payments?.Transaction?.invoiceImages?.imageOne ?
                            <Col xs="4">
                                <a href={invoicesData?.payments?.Transaction?.invoiceImages?.imageOne} target="_blank">
                                    <img onError={() => { setFirstInvoiceImgValidity(true) }} src={invoicesData?.payments?.Transaction?.invoiceImages?.imageOne} alt="invoice img1" className="img-fluid invoice-img" />
                                </a>
                            </Col>
                            : null}

                        {/* invoice img2 validity */}
                        {!secondInvoiceImgValidity && invoicesData?.payments?.Transaction?.invoiceImages?.imageTwo ?
                            <Col xs="4">
                                <a href={invoicesData?.payments?.Transaction?.invoiceImages?.imageTwo} target="_blank">
                                    <img onError={() => { setSecondInvoiceImgValidity(true) }} src={invoicesData?.payments?.Transaction?.invoiceImages?.imageTwo} alt="invoice img2" className="img-fluid invoice-img" />
                                </a>
                            </Col>
                            : null}

                        {/* invoice img3 validity */}
                        {!thirdInvoiceImgValidity && invoicesData?.payments?.Transaction?.invoiceImages?.imageThree ?
                            <Col xs="4">
                                <a href={invoicesData?.payments?.Transaction?.invoiceImages?.imageThree} target="_blank">
                                    <img onError={() => { setThirdInvoiceImgValidity(true) }} src={invoicesData?.payments?.Transaction?.invoiceImages?.imageThree} alt="invoice img3" className="img-fluid invoice-img" />
                                </a>
                            </Col>
                            : null}
                    </Row>
                </Container>

                {/* table invoice */}
                <h5 className='account-profile-titles mt-5'>Invoice</h5>
                <Container fluid className="mt-5">
                    <Table responsive>
                        <thead className='text-center'>
                            <tr className='role-table-header'>
                                <th className="text-center" >Item</th>
                                <th className="text-center" >Amount</th>
                                <th className="text-center" >Quantity</th>
                                <th className="text-center" >Total</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {invoicesData && invoicesData.payments && invoicesData.payments.Transaction && invoicesData.payments.Transaction.itemJS && invoicesData.payments.Transaction.itemJS.map((i) =>
                                <tr key={i.id}>
                                    <td className="text-center">{i && i.itemName && i.itemName}</td>
                                    <td className="text-center">{i && i.price && i.price}</td>
                                    <td className="text-center">{i && i.quantity && i.quantity}</td>
                                    <td className="text-center">{i && i.ItemtotPrice && i.ItemtotPrice}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Container>

                {/* notes */}
                <Container fluid className="mt-5  mb-5">
                    <div>
                        <Row>

                            {singleNote && (
                                <Col className="d-flex justify-content-start align-items-center">
                                    <strong className='add-note-title'>Notes</strong>
                                </Col>

                            )}


                            <Col className="d-flex justify-content-end align-items-center" style={{ marginRight: "9%" }}>
                                <button className="add-note-btn" onClick={() => {
                                    setAddNote(true)
                                }}>
                                    <AiFillPlusCircle className='add-note-icon' />
                                    add note
                                </button>
                            </Col>

                        </Row>

                        <Row>
                            <Col>
                                {!loadMore ?
                                    <div>
                                        {singleNote &&
                                            <div className="note-container" style={{ width: "70%" }}> {/* hy7ot el singleNote 34an hya el array of [0] y3ne 34an ygeb awl note bs */}
                                                <div className="d-flex">
                                                    <p className="note-title-wrapper">
                                                        By : {singleNote && singleNote.By}
                                                    </p>
                                                    <p className="note-title-wrapper">
                                                        Date : {new Date(singleNote && singleNote.createdAt).toLocaleDateString()}
                                                    </p>
                                                    <p className="note-title-wrapper">
                                                        Time :
                                                        {new Date(singleNote && singleNote.createdAt).getHours()}:{new Date(singleNote && singleNote.createdAt).getMinutes()}:{new Date(singleNote && singleNote.createdAt).getSeconds()}
                                                    </p>
                                                </div>
                                                <div className='text-center mt-4 mb-2'>
                                                    <p className="note-main-text">
                                                        {singleNote && singleNote.note}
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        {notes && notes.map((note) =>

                                            <div className="note-container" style={{ width: "70%" }}>
                                                <div className="d-flex">
                                                    <p className="note-title-wrapper">
                                                        By : {note.By}
                                                    </p>
                                                    <p className="note-title-wrapper">
                                                        Date : {new Date(note.createdAt).toLocaleDateString()}
                                                    </p>
                                                    <p className="note-title-wrapper">
                                                        Time :
                                                        {new Date(note.createdAt).getHours()}:{new Date(note.createdAt).getMinutes()}:{new Date(note.createdAt).getSeconds()}
                                                    </p>
                                                </div>
                                                <div className='text-center mt-4 mb-2'>
                                                    <p className="note-main-text">
                                                        {note.note}
                                                    </p>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                }

                            </Col>
                        </Row>

                        {
                            singleNote &&
                            (
                                <Row>
                                    <Col style={{ cursor: "pointer", display: loadMoreVisiblity, marginLeft: "30%" }} className="mt-2" onClick={() => { setLoadMore(true); setLoadMoreVisiblity("none") }}>
                                        <span className="load_more_text text-start">load more</span>
                                        <img src="/images/loadMoreArrows.png" style={{ width: "1.5vw", marginTop: "-0.1vw" }} className="img-fluid" />
                                    </Col>
                                </Row>

                            )
                        }

                        {
                            addNote ?
                                <AddNoteModal
                                    setAddNote={setAddNote}
                                    paymentId={id}
                                    setSuccessModal={setSuccessModal}
                                    setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                    setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                    updateData={fetchData}
                                    id={wsID}
                                />
                                : null
                        }
                    </div>

                </Container>


                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={fetchData}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }

            </div>
        )
    }

    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
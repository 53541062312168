import React from 'react';
import { Table, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
//icons
import { BiMessageDetail } from 'react-icons/bi'

const RetentionTable = (props) => {
    //props
    let {
        rentention,
        setCancelConfirmModal,
        setReinstateModal,
        setMessageCancelReasons,
        setCurrent,
    } = props


    return (
        <div className='tables-layout mt-4'>

            <Container fluid>
                <Table responsive>
                    <thead className='text-center'>
                        <tr className='role-table-header'>
                            <th className="text-center" >Id</th>
                            <th className="text-center" >customer name</th>
                            <th className="text-center" >phase</th>
                            <th className="text-center" >date</th>
                            <th className="text-center" >city</th>
                            <th className="text-center" > </th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {rentention.map((i) =>
                            <tr key={i.id} className='role-table-text'>
                                <td className="text-center">{i?.id}</td>
                                <td className="text-center">{i?.Retailer?.name}</td>
                                <td className="text-center">{i?.status}</td>
                                <td className='text-center'>{new Date(i?.updatedAt).toLocaleDateString()}</td>
                                <td className="text-center">{i?.Retailer?.City?.EnName}</td>
                                <td className="text-center d-flex" style={{ verticalAlign: "middle" }} >

                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip>Check cancelletion reason comment</Tooltip>}
                                    >
                                        <span>
                                            <BiMessageDetail className='message-btn'
                                                onClick={() => {
                                                    setMessageCancelReasons(true);
                                                    setCurrent(i);
                                                }} />
                                        </span>
                                    </OverlayTrigger>

                                    <button className='reinstate-btn' onClick={() => {
                                        setReinstateModal(true);
                                        setCurrent(i);
                                    }}
                                    >
                                        Reinstate
                                    </button>

                                    <button className='cancel-retention-btn' onClick={() => {
                                        setCancelConfirmModal(true);
                                        setCurrent(i);
                                    }}
                                    >
                                        Cancel
                                    </button>

                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default RetentionTable

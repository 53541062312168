
import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Select from 'react-select';
import './WsActivationProcess.css'
//permission
import { userValidPermissionAndAction } from "../../utils/permissionsAndActions";
import { userPermissionsAndActionsContext } from "../../App";
//api
import { getFromAgel } from "../../utils/api";
// components
import WSActivationTable from "../../components/WsActivationProcess/WsActivationTable";
import Register from "../../components/WsApModals/Register";
import FirstVisit from "../../components/WsApModals/FirstVisit";
import ReschduleModal from "../../components/WsApModals/ReschduleModal";
import FirstTeam from "../../components/WsApModals/FirstTeam";
import RevertModal from "../../components/WsApModals/RevertModal";
import SecondTeam from "../../components/WsApModals/SecondTeam";
import Final from "../../components/WsApModals/Final";
import CancelConfirmScreen from "../../components/WsApModals/CancelConfirmScreen";
import AFComment from "../../components/ActivationProcessCommonModals/AFComment";
import AuthorizationRequiredScreen from "../AuthorizationRequiredScreen/AuthorizationRequiredScreen";
import Failed_Modal from '../../components/common/FailedModal'
import SuccessModal from "../../components/common/SuccessModal";



export default function WsActivationProcess() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewWsApPermission = userValidPermissionAndAction(validPermissionsAndActions, "wholesalerActivationProcess", "viewWSActivationProcess")
    let viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity")
    let viewIndustriesPermission = userValidPermissionAndAction(validPermissionsAndActions, "industry", "viewIndustry")

    //filters api
    const [cities, setCities] = useState([])
    const [industries, setIndustries] = useState([])

    //filters
    const [filterByNameOrPhoneNumber, setFilterByNameOrPhoneNumber] = useState("") // search input state
    const [selectedCity, setSelectedCity] = useState("")
    const [selectedIndustry, setSelectedIndustry] = useState("")

    ///Modals
    const [registerModal, setRegisterModal] = useState(false)
    const [current, setCurrent] = useState({})
    const [rescheduleCurrent, setRescheduleCurrent] = useState({})
    const [firstVisitModal, setFirstVisitModal] = useState(false)
    const [reschduleModal, setReschduleModal] = useState(false)
    const [registerRescheduleFlag, setRegisterRescheduleFlag] = useState(false)
    const [firstVisitRescheduleFlag, setFirstVisitRescheduleFlag] = useState(false)
    const [contractRescheduleFlag, setContractRescheduleFlag] = useState(false)
    const [secondVisitRescheduleFlag, setSecondVisitRescheduleFlag] = useState(false)
    const [firstTeamModal, setFirstTeamModal] = useState(false)
    const [revertModal, setRevertModal] = useState(false)
    const [secondTeamModal, setSecondTeamModal] = useState(false)
    const [finalModal, setFinalModal] = useState(false)
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false)
    const [hold, setHold] = useState(false)
    const [showAFComment, setShowAFComment] = useState(false)
    const [AFCancelComment, setAFCancelComment] = useState("")

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")

    //tables pagination states
    //pending
    const [pendingCounter, setPendingCounter] = useState(0)
    const [pendingList, setPendingList] = useState([]); // storing list
    const [pendingWasLastList, setPendingWasLastList] = useState(false); // setting a flag to know the last list

    //first visit
    const [firstVisitCounter, setFirstVisitCounter] = useState(0)
    const [firstVisitList, setFirstVisitList] = useState([]); // storing list
    const [firstVisitWasLastList, setFirstVisitWasLastList] = useState(false); // setting a flag to know the last list

    //first team
    const [firstTeamCounter, setFirstTeamCounter] = useState(0)
    const [firstTeamList, setFirstTeamList] = useState([]); // storing list
    const [firstTeamWasLastList, setFirstTeamWasLastList] = useState(false); // setting a flag to know the last list

    //second team
    const [secondTeamCounter, setSecondTeamCounter] = useState(0)
    const [secondTeamList, setSecondTeamList] = useState([]); // storing list
    const [secondTeamWasLastList, setSecondTeamWasLastList] = useState(false); // setting a flag to know the last list

    //final
    const [finalCounter, setFinalCounter] = useState(0)
    const [finalList, setFinalList] = useState([]); // storing list
    const [finalWasLastList, setFinalWasLastList] = useState(false); // setting a flag to know the last list

    //check on all filters thats not empty for loading checker
    const [pendingLoadingSkeleton, setPendingLoadingSkeleton] = useState(false)
    const [firstVisitLoadingSkeleton, setFirstVisitLoadingSkeleton] = useState(false)
    const [firstTeamLoadingSkeleton, setFirstTeamLoadingSkeleton] = useState(false)
    const [secondTeamLoadingSkeleton, setSecondTeamLoadingSkeleton] = useState(false)
    const [finalLoadingSkeleton, setFinalLoadingSkeleton] = useState(false)




    //tables get ws activation process data use effect functions

    //pending
    const fetchPendingApData = async (pageNumber) => {
        setPendingLoadingSkeleton(true)
        try {
            const pendingResponse = await getFromAgel(`dashboard/wholesalers/activationProcess/v2/`, {
                params: {
                    status: "pending",
                    page: pageNumber, //ana bb3to f kol mara bb3t request b3ml increment hnak f el fn nfsha wbnsba l awl mara bytnada bb3t f el useEffect
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setPendingLoadingSkeleton(false)
            setPendingList(prev => [...prev, ...pendingResponse.data.pending.data]); //prev fadtle el array el adem 
            setPendingWasLastList(pendingResponse.data.pending.data.length !== 0 ? false : true)
        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }

    useEffect(() => {
        fetchPendingApData(0);
    }, [])


    //first visit
    const fetchfirstVisitApData = async (pageNumber) => {
        setFirstVisitLoadingSkeleton(true)
        try {
            const firstVisitResponse = await getFromAgel(`dashboard/wholesalers/activationProcess/v2/`, {
                params: {
                    status: "firstVisit",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setFirstVisitLoadingSkeleton(false)
            setFirstVisitList((prev) => [...prev, ...firstVisitResponse.data.firstVisit.data]);
            setFirstVisitWasLastList(firstVisitResponse.data.firstVisit.data.length !== 0 ? false : true)
        }
        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }
    useEffect(() => {
        fetchfirstVisitApData(0);
    }, [])


    //first team
    const fetchFirstTeamApData = async (pageNumber) => {
        setFirstTeamLoadingSkeleton(true)
        try {
            const firstTeamResponse = await getFromAgel(`dashboard/wholesalers/activationProcess/v2/`, {
                params: {
                    status: "firstTeam",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setFirstTeamLoadingSkeleton(false)
            setFirstTeamList((prev) => [...prev, ...firstTeamResponse.data.firstTeam.data]);
            firstTeamResponse.data.firstTeam.data.length !== 0 ? setFirstTeamWasLastList(false) : setFirstTeamWasLastList(true)

        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }
    useEffect(() => {
        fetchFirstTeamApData(0)
    }, [])


    //second team
    const fetchSecondTeamApData = async (pageNumber) => {
        setSecondTeamLoadingSkeleton(true)
        try {
            const secondTeamResponse = await getFromAgel(`dashboard/wholesalers/activationProcess/v2/`, {
                params: {
                    status: "secondTeam",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setSecondTeamLoadingSkeleton(false)
            setSecondTeamList((prev) => [...prev, ...secondTeamResponse.data.secondTeam.data]);
            setSecondTeamWasLastList(secondTeamResponse.data.secondTeam.data.length !== 0 ? false : true)
        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }
    useEffect(() => {
        fetchSecondTeamApData(0);
    }, [])


    //final
    const fetchFinalApData = async (pageNumber) => {
        setFinalLoadingSkeleton(true)
        try {
            const finalResponse = await getFromAgel(`dashboard/wholesalers/activationProcess/v2/`, {
                params: {
                    status: "final",
                    page: pageNumber,
                    ...(selectedCity ? { CityId: selectedCity } : {}),
                    ...(selectedIndustry ? { IndustryId: selectedIndustry } : {}),
                    ...(filterByNameOrPhoneNumber ? { filterByPhoneOrName: filterByNameOrPhoneNumber } : {}),
                }
            })
            setFinalLoadingSkeleton(false)
            setFinalList((prev) => [...prev, ...finalResponse.data.final.data]);
            setFinalWasLastList(finalResponse.data.final.data.length !== 0 ? false : true)
        }

        catch (err) {
            // console.log('error=', err)
            // console.log({ selectedCity, selectedIndustry })
        }
    }

    useEffect(() => {
        fetchFinalApData(0);
    }, [])



    const fetchCitiesAndIndtries = async () => {
        try {
            //get cities //view cities permission
            if (viewCitiesPermission) {
                const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
                setCities(cityResponse.data.cities)
            }

            //industries //view industries permission
            if (viewIndustriesPermission) {
                const industryResponse = await getFromAgel(`${process.env.React_APP_GET_INDUSTRIES}`);
                setIndustries(industryResponse.data.industries)
            }
        }
        catch (err) {
            // console.log('error=', err)
        }
    }

    //component did mout ///// [] y3ne hy render only first time lma el component yd5ol el dom wyt3mlo render awl mara
    useEffect(() => {
        fetchCitiesAndIndtries()
    }, [])



    //set counters to zero to start new filter search
    const setCountersToZero = () => {
        setPendingCounter(0)
        setFirstVisitCounter(0)
        setFirstTeamCounter(0)
        setSecondTeamCounter(0)
        setFinalCounter(0)

    }


    //fetch all ws ap tables request at one time
    const fetchAllApDataTables = () => {
        fetchPendingApData(0)
        fetchfirstVisitApData(0)
        fetchFirstTeamApData(0)
        fetchSecondTeamApData(0)
        fetchFinalApData(0)
    }

    const resetApListsAndFetchAllLists = () => {

        //set empty lists for reseting new data after filteration
        setPendingList([])
        setFirstVisitList([])
        setFirstTeamList([])
        setSecondTeamList([])
        setFinalList([])

        //make request to get new data after filterarion
        fetchAllApDataTables()

    }


    if (viewWsApPermission) {
        return (
            <div>
                <Row className="layout-top">

                    <Col xs="3 text-start">
                        {/* input */}
                        <div className='input-wrapper'>
                            <input type="text" placeholder="Name/phone" value={filterByNameOrPhoneNumber} onChange={(e) => { setFilterByNameOrPhoneNumber(e.target.value); setCountersToZero(); }} />
                        </div>
                    </Col>
                    <Col xs="4 text-start">
                        {/* search */}
                        <button className='role-search-btn' onClick={() => { resetApListsAndFetchAllLists() }}>Search</button>
                    </Col>
                </Row>

                <Row className="layout-row-2">

                    <Col xs={2} style={{ zIndex: "999" }}>

                        <Select
                            className="search-select mt-2"
                            defaultValue={{ "label": "City", "value": 0 }}
                            onChange={(e) => { setSelectedCity(e.value); setCountersToZero(); }}
                            options={cities && cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                        />
                    </Col>
                    <Col xs={2} style={{ zIndex: "999" }}>
                        <Select
                            className="search-select mt-2"
                            defaultValue={{ "label": "Industry", "value": 0 }}
                            onChange={(e) => { setSelectedIndustry(e.value); setCountersToZero(); }}
                            options={industries && industries.map((industry) => ({ "value": industry.id, "label": industry.name }))}
                        />
                    </Col>

                </Row>



                {/* WS activation process table  */}
                <WSActivationTable
                    pendingWasLastList={pendingWasLastList}
                    firstVisitWasLastList={firstVisitWasLastList}
                    firstTeamWasLastList={firstTeamWasLastList}
                    secondTeamWasLastList={secondTeamWasLastList}
                    finalWasLastList={finalWasLastList}
                    setRegisterModal={setRegisterModal}
                    setCurrent={setCurrent}
                    setFirstVisitModal={setFirstVisitModal}
                    setFirstTeamModal={setFirstTeamModal}
                    setSecondTeamModal={setSecondTeamModal}
                    setFinalModal={setFinalModal}
                    pendingLoadingSkeleton={pendingLoadingSkeleton}
                    firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                    firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                    secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                    finalLoadingSkeleton={finalLoadingSkeleton}
                    pendingList={pendingList}
                    fetchPendingApData={fetchPendingApData}
                    firstVisitList={firstVisitList}
                    fetchfirstVisitApData={fetchfirstVisitApData}
                    firstTeamList={firstTeamList}
                    fetchFirstTeamApData={fetchFirstTeamApData}
                    secondTeamList={secondTeamList}
                    fetchSecondTeamApData={fetchSecondTeamApData}
                    finalList={finalList}
                    fetchFinalApData={fetchFinalApData}
                    pendingCounter={pendingCounter}
                    firstVisitCounter={firstVisitCounter}
                    firstTeamCounter={firstTeamCounter}
                    secondTeamCounter={secondTeamCounter}
                    finalCounter={finalCounter}
                    setPendingCounter={setPendingCounter}
                    setFirstVisitCounter={setFirstVisitCounter}
                    setFirstTeamCounter={setFirstTeamCounter}
                    setSecondTeamCounter={setSecondTeamCounter}
                    setFinalCounter={setFinalCounter}
                    selectedCity={selectedCity}
                    selectedIndustry={selectedIndustry}
                    filterByNameOrPhoneNumber={filterByNameOrPhoneNumber}
                />

                {/* Register model */}
                {
                    registerModal ?
                        <Register
                            setRegisterModal={setRegisterModal}
                            setRegisterRescheduleFlag={setRegisterRescheduleFlag}
                            setFirstVisitRescheduleFlag={setFirstVisitRescheduleFlag}
                            setContractRescheduleFlag={setContractRescheduleFlag}
                            setSecondVisitRescheduleFlag={setSecondVisitRescheduleFlag}
                            current={current}
                            setCurrent={setCurrent}
                            setReschduleModal={setReschduleModal}
                            setRescheduleCurrent={setRescheduleCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                            updateData={resetApListsAndFetchAllLists}
                        />
                        : null
                }

                {/* FirstVisit model */}
                {
                    firstVisitModal ?
                        <FirstVisit
                            setFirstVisitModal={setFirstVisitModal}
                            current={current}
                            setCurrent={setCurrent}
                            setReschduleModal={setReschduleModal}
                            setRescheduleCurrent={setRescheduleCurrent}
                            setRegisterRescheduleFlag={setRegisterRescheduleFlag}
                            setFirstVisitRescheduleFlag={setFirstVisitRescheduleFlag}
                            setContractRescheduleFlag={setContractRescheduleFlag}
                            setSecondVisitRescheduleFlag={setSecondVisitRescheduleFlag}
                            setRevertModal={setRevertModal}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }

                {/* firstTeam model */}
                {
                    firstTeamModal ?
                        <FirstTeam
                            setFirstTeamModal={setFirstTeamModal}
                            current={current}
                            setCurrent={setCurrent}
                            setRevertModal={setRevertModal}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }


                {/* ReschduleModal model */}
                {
                    reschduleModal ?
                        <ReschduleModal
                            current={current}
                            rescheduleCurrent={rescheduleCurrent}
                            registerRescheduleFlag={registerRescheduleFlag}
                            firstVisitRescheduleFlag={firstVisitRescheduleFlag}
                            secondVisitRescheduleFlag={secondVisitRescheduleFlag}
                            contractRescheduleFlag={contractRescheduleFlag}
                            setReschduleModal={setReschduleModal}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}

                        />
                        : null
                }

                {/* revert Modal model */}
                {
                    revertModal ?
                        <RevertModal
                            setRevertModal={setRevertModal}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            current={current}
                        />
                        : null
                }

                {/* second team model */}
                {
                    secondTeamModal ?
                        <SecondTeam
                            setSecondTeamModal={setSecondTeamModal}
                            current={current}
                            setCurrent={setCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }

                {/* final model */}
                {
                    finalModal ?
                        <Final
                            setFinalModal={setFinalModal}
                            setHold={setHold}
                            current={current}
                            setCurrent={setCurrent}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                            updateData={resetApListsAndFetchAllLists}
                            setCancelConfirmModal={setCancelConfirmModal}
                            setShowAFComment={setShowAFComment}
                            setAFCancelComment={setAFCancelComment}
                        />
                        : null
                }


                {/* cancel */}
                {cancelConfirmModal ?
                    <CancelConfirmScreen
                        setCancelConfirmModal={setCancelConfirmModal}
                        setSuccessModal={setSuccessModal}
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        updateData={resetApListsAndFetchAllLists}
                        current={current}
                        hold={hold}
                    />
                    : null}


                {/* cancelation comment in AF */}
                {showAFComment ?
                    <AFComment
                        setShowAFComment={setShowAFComment}
                        AFCancelComment={AFCancelComment}
                    />
                    : null
                }

                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={resetApListsAndFetchAllLists}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }

            </div>
        )
    }

    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
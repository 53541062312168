import React from 'react'

import { Modal } from "react-bootstrap"
import{ HiOutlineXCircle } from 'react-icons/hi'
const Failed_Modal = (props) => {

    const{
        setFailedModalWithErrorMsg,
        errorMsg
    }=props

    // console.log({props})
    return (
        <div>
            <Modal show={true} onHide={() =>{setFailedModalWithErrorMsg(false)}} centered className="server-msg-modal" size="sm" style={{marginTop:"5em"}}>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="text-center">
                    <HiOutlineXCircle size={40} color={"red"}/>
                    <p className="agent-msg mt-3">{errorMsg?errorMsg:"Error, Please try again!"}</p> {/* Network error */}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Failed_Modal

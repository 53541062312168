import React, { useState } from 'react'
import { Modal, Container, Form, Button } from 'react-bootstrap'
import { putToAgel } from '../../utils/api'
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn'




const EditModal = (props) => {
    //  props
    const {
        setEditAreas,
        current,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props


    //edit btn vars
    const [editSelectCity, setEditSelectCity] = useState('')
    const [editArabicName, setEditArabicName] = useState('')
    const [editEnglishName, setEditEnglishName] = useState('')
    const [editActive, setEditActive] = useState("")

    //state checker on buttons 
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)

    //post edits
    const submitEdit = async (e) => {

        e.preventDefault();
        setSaveEditsBtn(true);

        var data = {
            'CityId': editSelectCity === "" ? current.CountryId : editSelectCity,
            'ArName': editArabicName === "" ? current.ArName : editArabicName,
            'EnName': editEnglishName === "" ? current.EnName : editEnglishName,
            'isActive': editActive === "" ? current.isActive : editActive,
        }
        try {
            await putToAgel(`${process.env.React_APP_EDIT_AREA}${current.id}`, data)
            setSuccessModal(true)
        }
        catch (err) {
            setErrorMsgInFailedModal(err?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        updateData();
        setEditAreas(false)
    }



    return (
        <div>
            <Modal show={true} onHide={() => setEditAreas(false)} centered>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Container className="mt-3">
                        {/* form */}
                        <Form onSubmit={submitEdit}>
                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Arabic Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Arabic Name" defaultValue={current.ArName} onChange={(e) => setEditArabicName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="form-text">English Name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter English Name" defaultValue={current.EnName} onChange={(e) => setEditEnglishName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group>
                                <Form.Check type="checkbox" className="status form-text mt-3" label="isActive" defaultChecked={current.isActive} onChange={(e) => setEditActive(e.target.checked)} />
                            </Form.Group>

                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%", paddingRight: "0" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={saveEditsBtn ? true : false}>
                                    {saveEditsBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setEditAreas(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditModal

import React, { useState, useEffect } from "react";
import { IoExpand } from 'react-icons/io5'
import { Outlet } from "react-router";
import Navbar from "../components/common/Navbar/Navbar";
import Sidebar from "../components/common/Sidebar/Sidebar";

export default function Layout() {
    const [pages, setPages] = useState([])
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)


    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth)
            setWindowHeight(window.innerHeight)
        }
        )
        console.log({windowWidth,windowHeight})
    }, [])

    if (windowWidth > 1100 && windowHeight > 572) {

        return (
            <div>
                <Navbar pages={pages} setPages={setPages} />
                <div>
                    <div>
                        <Sidebar pages={pages} setPages={setPages} />
                    </div>

                    <div>
                        <Outlet />
                    </div>

                </div>

            </div>
        )
    }
    else {
        return (
            <div className="expand-screen-layout rubberBand">
                <p>Expand Your Screen</p>
                <span><IoExpand size={17} /></span>
            </div>
        )
    }
}
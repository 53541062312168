import React, { useEffect, useState, useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import './Industries.css'

import { getFromAgel } from '../../utils/api'
// components
import IndustryTable from '../../components/Industries/IndustryTable';
import EditIndustryModal from '../../components/Industries/EditIndustryModal';
import CreateIndustryModal from '../../components/Industries/CreateIndustryModal';
import AddLabelToIndustry from '../../components/Industries/AddLabelToIndustry'
import UpdateIndustryImg from '../../components/Industries/UpdateIndustryImage';
import Failed_Modal from '../../components/common/FailedModal';
import SuccessModal from '../../components/common/SuccessModal';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen';

//react icons
import { MdAddCircle } from 'react-icons/md'

import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';
import { userPermissionsAndActionsContext } from '../../App';




export default function Industries() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewIndustriesPermission = userValidPermissionAndAction(validPermissionsAndActions, "industry", "viewIndustry")

    //industry api
    const [industries, setIndustries] = useState([])
    const [industriesLabelLength, setIndustriesLabelLength] = useState([])


    //modal
    const [editIndustry, setEditIndustry] = useState(false)
    const [currentIndustry, setCurrentIndustry] = useState({});
    const [showCreate, setShowCreate] = useState(false);
    const [addLabelModal, setAddLabelModal] = useState(false)
    const [updateIndutryImgModal, setUpdateIndutryImgModal] = useState(false)
    const [current_selected_industry_image, set_current_selected_industry_image] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")
    const [successModal, setSuccessModal] = useState(false)




    const sortedIndustries = industries && industries.sort((a, b) => (a.id) - (b.id));




    async function fetchData() {
        try {
            const industryResponse = await getFromAgel(`${process.env.React_APP_GET_INDUSTRIES}`);
            setIndustries(industryResponse.data.industries)
            setIndustriesLabelLength(industryResponse.data.lengths)
        }
        catch (err) {
            // console.log("error", err)
        }
    }

    //fetch industry
    useEffect(() => {
        fetchData()
    }, [])


    //search
    const [industrySearch, setIndustrySearch] = useState("") // search input state
    const [searchResult, setSearchResult] = useState("") //search btn

    //search btn fn
    const search = () => {
        setSearchResult(industrySearch)
    }
    //update role result
    let industrySearchResult = searchResult !== "" ? sortedIndustries.filter((i) => i.name.toLowerCase().includes(searchResult.toLowerCase())) : sortedIndustries

    //filter search

    if (viewIndustriesPermission) {
        return (
            <div>
                <Row className="layout-top">
                    <Col xs="3" className='text-start'>
                        {/* input */}
                        <div className='input-wrapper'>
                            <input type="text" placeholder="Industry Name" value={industrySearch} onChange={(e) => setIndustrySearch(e.target.value)} />
                        </div>
                    </Col>
                    <Col xs="4" className='text-start'>
                        {/* search */}
                        <button className='role-search-btn' onClick={() => search()}>Search</button>
                    </Col>
                    <Col xs="5" className='text-end'>
                        {/* create country btn */}
                        <div>
                            <button className='add-role-btn' onClick={() => { setShowCreate(true) }}>
                                <span className='d-flex align-items-center justify-content-center'>
                                    <MdAddCircle size={20} className="add-role-btn-icon" />
                                    <p>Add Industry</p>
                                </span>
                            </button>
                            {
                                showCreate ?
                                    <CreateIndustryModal
                                        setShowCreate={setShowCreate}
                                        updateData={fetchData}
                                        setSuccessModal={setSuccessModal}
                                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                    />
                                    : null
                            }
                        </div>
                    </Col>
                </Row>

                {/* table and search bar */}
                <IndustryTable
                    industries={industrySearchResult}
                    industriesLabelLength={industriesLabelLength}
                    setEditIndustry={setEditIndustry}
                    setAddLabelModal={setAddLabelModal}
                    setCurrentIndustry={setCurrentIndustry}
                    setUpdateIndutryImgModal={setUpdateIndutryImgModal}
                    setImgSrc={set_current_selected_industry_image}
                />

                {/* edite modal  */}
                {
                    editIndustry ?
                        <EditIndustryModal
                            updateData={fetchData}
                            setEditIndustry={setEditIndustry}
                            currentIndustry={currentIndustry}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null
                }

                {/* add new label to specific industry */}
                {addLabelModal ?
                    <AddLabelToIndustry
                        setAddLabelModal={setAddLabelModal}
                        currentIndustry={currentIndustry}
                        updateData={fetchData}
                    />
                    : null}

                {/* update industry image */}
                {updateIndutryImgModal ?
                    <UpdateIndustryImg
                        setUpdateIndutryImgModal={setUpdateIndutryImgModal}
                        currentIndustry={currentIndustry}
                        imgSrc={current_selected_industry_image}
                        setImgSrc={set_current_selected_industry_image}
                        updateData={fetchData}
                        setSuccessModal={setSuccessModal}
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                    />
                    : null}

                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={fetchData}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }

            </div>
        )
    }
    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
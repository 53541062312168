import React from 'react';
import { Table, Container, Badge } from 'react-bootstrap';
//icons
import { AiOutlineEdit } from 'react-icons/ai'
import { MdAddCircle } from 'react-icons/md'
import { IoStorefrontSharp } from 'react-icons/io5'

const IndustryTable = (props) => {
    //props
    let {
        industries,
        industriesLabelLength,
        setEditIndustry,
        setAddLabelModal,
        setCurrentIndustry,
        setUpdateIndutryImgModal,
        setImgSrc,
    } = props



    return (
        <div className='tables-layout'>

            <Container fluid>
                <Table responsive>
                    <thead className='text-center'>
                        <tr className='role-table-header'>
                            <th className="text-center" >ID</th>
                            <th className="text-center" >Industry Name</th>
                            <th className="text-center" >Labels</th>
                            <th className="text-center" >Status</th>
                            <th className="text-center" ></th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {industries.map((i, index) =>
                            <tr key={i.id} className='role-table-text'>
                                <td className="text-center">{i.id}</td>
                                <td className="text-center">{i.name}</td>
                                <td className="text-center">
                                    <Badge bg="secondary" pill>{industriesLabelLength[index]}</Badge>
                                </td>
                                <td className='text-center' style={i.isActive ? { color: "#00BD85" } : null}>{i.isActive ? ("Active") : ("Inactive")}</td>
                                <td className="text-center d-flex justify-content-center align-items-center" >

                                    {/* industry logo image modal */}
                                    <button className='double-action-btns-table'
                                        onClick={() => {
                                            setUpdateIndutryImgModal(true);
                                            setCurrentIndustry(i);
                                            setImgSrc(i.industryLogo)
                                        }
                                        }
                                        style={{ backgroundColor: "#6f1818" }}
                                    >
                                        Logo <IoStorefrontSharp />
                                    </button>

                                    {/* industry labels */}
                                    <button className='double-action-btns-table'
                                        onClick={() => {
                                            setAddLabelModal(true);
                                            setCurrentIndustry(i)
                                        }
                                        }
                                        style={{ backgroundColor: "#704e7c" }}
                                    >
                                        Labels <MdAddCircle />
                                    </button>

                                    {/* edit on each industry */}
                                    <button className='double-action-btns-table'
                                        onClick={() => {
                                            setEditIndustry(true);
                                            setCurrentIndustry(i)
                                        }
                                        } style={{ backgroundColor: "#064b48" }}>
                                        Edit <AiOutlineEdit />
                                    </button>

                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default IndustryTable

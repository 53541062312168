import React, { Fragment, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./screens/Login/Login";
import LayOut from "./screens/Layout";
import PrivateRoute from "./PrivateRoute";
// css
import "./App.css";
// import screens
import Roles from "./screens/Roles/Roles";
import Cities from "./screens/Cities/Cities";
import Countries from "./screens/Countries/Countries";
import Staff from "./screens/Staff/Staff";
import Agents from "./screens/Agents/Agents";
import Collectors from "./screens/Collectors/Collectors";
import CollectorProfile from "./screens/CollectorProfile/CollectorProfile";
import Industries from "./screens/Industries/Industries";
import Wholesaler from "./screens/wholesaler/wholesaler";
import Retailer from "./screens/Retailer/Retailer";
// import PaymentPlan from './screens/PaymentPlan/PaymentPlan';
import WsProfile from "./screens/WsProfile/WsProfile";
import RetailerProfile from "./screens/RetailerProfile/RetailerProfile";
import RetailerActivationProcess from "./screens/RetailerActivationProcess/RetailerActivationProcess";
import WsActivationProcess from "./screens/WsActivationProcess/WsActivationProcess";
import Home from "./screens/Home/home";
import WsRetention from "./screens/WsRetention/Retention";
import RetailerRetention from "./screens/RetailerRetention.js/Retention";
import PosStock from "./screens/PosStock/PosStock";
import Areas from "./screens/Areas/Areas";
import NotFound from "./screens/NotFound/NotFound";
import ServerErrorScreen from "./screens/ServerErrorScreen/ServerErrorScreen";
import AuthorizationRequiredScreen from "./screens/AuthorizationRequiredScreen/AuthorizationRequiredScreen";
import Pos from "./components/Wholesalers/Pos";
import Invoices from "./components/Wholesalers/Invoices";
import RetailerInvoices from "./components/Retailers/Invoices";
import PaymentReport from "./screens/PaymentReport/PaymentReport";
import Compliance from "./screens/Compliance/Compliance";
import RetailerAcquisition from "./screens/Acquisition/RetailerAcquisition";
import WholesalerAcquisition from "./screens/Acquisition/WholesalerAcquisition";
import GenerateOTP from "./screens/OTP/OTP";

// for removing all console.log in the dashboard app
// console.log = () => { };

export const userPermissionsAndActionsContext = React.createContext();

function getLocalStoragePermissions() {
  const PermissionsInlocalStorage = localStorage.getItem(
    "PermissionsInlocalStorage",
  );
  if (PermissionsInlocalStorage) {
    return JSON.parse(PermissionsInlocalStorage);
  }

  return {};
}

function App() {
  // eslint-disable-next-line max-len
  const [userPermissionsAndActions, setUserPermissionsAndActions] = useState(
    getLocalStoragePermissions,
  );

  return (
    <>
      <userPermissionsAndActionsContext.Provider
        value={userPermissionsAndActions}
      >
        {" "}
        {/* ay 7aga gwa hat access el userPermissionsAndActionsContext da */}
        <Routes>
          <Route path="*" element={<PrivateRoute />}>
            {" "}
            {/* private Route */}{" "}
            {/* ay route hayd5ol gwa el protected route da m3na ene a7ot "*" */}
            {/* not found */}
            <Route exact path="*" element={<NotFound />} />{" "}
            <Route path="/*" element={<LayOut />}>
              {" "}
              {/* ay 7aga b3d el '/' had5ol 3l layout */}
              <Route path="" element={<Home />} />
              {/* employees path */}
              {/* roles */}
              <Route exact path="employee/roles" element={<Roles />} />
              {/* staff */}
              <Route exact path="employee/staff" element={<Staff />} />
              {/* agents */}
              <Route exact path="employee/agents" element={<Agents />} />
              {/* collector screen */}
              <Route
                exact
                path="employee/collectors"
                element={<Collectors />}
              />
              <Route
                exact
                path="employee/collectors/:id/cashIn/cashOut"
                element={<CollectorProfile />}
              />

              {/* zones */}
              {/* countries */}
              <Route exact path="zones/countries" element={<Countries />} />
              {/* cities */}
              <Route exact path="zones/cities" element={<Cities />} />
              {/* areas */}
              <Route exact path="zones/areas" element={<Areas />} />
              {/* products */}
              {/* industries */}
              <Route
                exact
                path="products/industries"
                element={<Industries />}
              />
              {/* ws account path */}
              <Route exact path="wholesalers/list" element={<Wholesaler />} />
              <Route
                exact
                path="wholesalers/WsActivationProcess"
                element={<WsActivationProcess />}
              />
              <Route
                exact
                path="wholesalers/list/:id/details"
                element={<WsProfile />}
              />
              <Route
                exact
                path="wholesalers/list/stores/:id"
                element={<Pos />}
              />
              <Route
                exact
                path="wholesalers/list/:id/:wsID/details/invoices"
                element={<Invoices />}
              />
              {/* retailer account pathes */}
              <Route exact path="retailers/list" element={<Retailer />} />
              <Route
                exact
                path="retailers/RetailerActivationProcess"
                element={<RetailerActivationProcess />}
              />
              <Route
                exact
                path="retailers/list/:id/details"
                element={<RetailerProfile />}
              />
              <Route
                exact
                path="retailers/list/:paymentId/:retailerId/details/invoices"
                element={<RetailerInvoices />}
              />
              {/* Reports */}
              {/* payment report */}
              <Route
                exact
                path="report/paymentReport"
                element={<PaymentReport />}
              />
              {/* component hy4t8l b3den */}
              {/* <Route exact path='paymentPlan' element={<PaymentPlan />} /> */}
              {/* retention ws/retailer */}
              <Route
                exact
                path="retention/wholesalerRetentionQueue"
                element={<WsRetention />}
              />
              <Route
                exact
                path="retention/retailerRetentionQueue"
                element={<RetailerRetention />}
              />
              {/* pos stock */}
              <Route exact path="stock/posStock" element={<PosStock />} />
              {/* generate otp */}
              <Route exact path="otp/generateOtp" element={<GenerateOTP />} />
              {/* compliance */}
              <Route
                exact
                path="compliance/relation"
                element={<Compliance />}
              />
              {/* Acquisition */}
              <Route
                exact
                path="acquisition/wholesaler"
                element={<WholesalerAcquisition />}
              />
              <Route
                exact
                path="acquisition/retailer"
                element={<RetailerAcquisition />}
              />
            </Route>
          </Route>

          <Route
            path="/login"
            element={
              <Login
                setUserPermissionsAndActions={setUserPermissionsAndActions}
              />
            }
          />

          {/* image view */}
          {/* <Route exact path="image/view:url" element={<PosStock />} /> */}

          {/* server error */}
          <Route exact path="/500" element={<ServerErrorScreen />} />

          {/* Authorization Required */}
          <Route
            exact
            path="/authorizationRequired"
            element={<AuthorizationRequiredScreen />}
          />
        </Routes>
      </userPermissionsAndActionsContext.Provider>
    </>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import {
  Table, Container, Pagination, Row, Col,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getFromAgel } from '../../utils/api';
import TableSkeletonUI from '../common/loadingSkeletonUi/tableLoadingSkeletonUi';
import NotFoundText from '../common/notFoundText/notFoundText';
import LoadingScreen from '../common/LoadingScreen/LoadingScreen';

const RetailerTable = (props) => {
  // navigation
  const navigate = useNavigate();

  // props
  const {
    retailer,
    pagination_count_of_retailers,
    current_pagination_item,
    set_current_pagination_item,
    fetchData,
    industries,
    allFiltersChecker,
    viewAllRetailersListPermission,
    viewOnlyFilteredRetailersPermission,
    errorMsgResponseOfViewOnlyFilteredRetailerPermission,
    requestLoader,
  } = props;

  // const [pageCount, setPageCount] = useState(-1)
  const [items, setItems] = useState([0, 1, 2, 3]);
  const [nextBtnPaginationItemsFlag, setNextBtnPaginationItemsFlag] = useState(false);


  const paginationItemsCount = Math.ceil(parseInt(pagination_count_of_retailers) / 20);

  const incrementPaginationItem = () => {
    if (items[3] >= (paginationItemsCount - 4)) {
      setNextBtnPaginationItemsFlag(true);
      const tempItems = [paginationItemsCount - 4, paginationItemsCount - 3, paginationItemsCount - 2, paginationItemsCount - 1];
      setItems([...tempItems]);
    } else {
      setNextBtnPaginationItemsFlag(false);
      const tempItems = [current_pagination_item + 1, current_pagination_item + 2, current_pagination_item + 3, current_pagination_item + 4];
      setItems([...tempItems]);
    }
    fetchNewRetailerPage(current_pagination_item + 1);
  };
  const fetchLastPaginationPage = () => {
    const tempItems = [paginationItemsCount - 4, paginationItemsCount - 3, paginationItemsCount - 2, paginationItemsCount - 1];
    setItems([...tempItems]);
    fetchNewRetailerPage(paginationItemsCount - 1);
    setNextBtnPaginationItemsFlag(true); // so that > disappear on click on >>
  };

  const decrementPaginationItem = () => {
    setItems((prev) => prev.map((i) => i - 1));
    fetchNewRetailerPage(current_pagination_item - 1);
    setNextBtnPaginationItemsFlag(false);
  };

  const fetchFirstPaginationPage = () => {
    const tempItems = [0, 1, 2, 3];
    setItems([...tempItems]);
    fetchNewRetailerPage(0);
    setNextBtnPaginationItemsFlag(false);
  };

  // updating pagination item to update el current page
  const fetchNewRetailerPage = (currentItem) => {
    set_current_pagination_item(currentItem);
  };

  return (
    <div className="tables-layout">

      {viewOnlyFilteredRetailersPermission && errorMsgResponseOfViewOnlyFilteredRetailerPermission !== ""
        ? (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <p className="catchErrorMsg">
              {errorMsgResponseOfViewOnlyFilteredRetailerPermission}
            </p>
          </div>
        )
        : null}

      {requestLoader && retailer.length === 0
        ? <LoadingScreen />
        : retailer.length === 0 && allFiltersChecker === true && !requestLoader
          ? <NotFoundText />
          : retailer.length !== 0
            ? (
              <Container fluid>
                <Table responsive>
                  <thead className="text-center">
                    <tr className="role-table-header">
                      <th className="text-center">ID</th>
                      <th className="text-center">Owner Name</th>
                      <th className="text-center">Store Name</th>
                      <th className="text-center">Mobile Number</th>
                      <th className="text-center">City</th>
                      <th className="text-center">Industry</th>
                      <th className="text-center">Status</th>
                    </tr>
                  </thead>
                  {retailer.length === 0 && allFiltersChecker === false
                    ? <TableSkeletonUI rows={7} cols={7} />
                    : (
                      <tbody className="text-center">
                        {retailer.map((i) => (
                          <tr key={i.id} className="role-table-text clickable-row" onClick={() => navigate(`/retailers/list/${i.id}/details`)}>
                            <td className="text-center">{i.id}</td>
                            <td className="text-center">{i.name}</td>
                            <td className="text-center">{i.mega_store_name && i.mega_store_name}</td>
                            <td className="text-center">{i.phoneNumber}</td>
                            <td className="text-center">{i.City && i.City.EnName}</td>
                            <td className="text-center">
                              {
                                industries && industries.map((industry) => (industry.id === i.IndustryId ? (industry.name) : null))
                              }
                            </td>
                            <td className="text-center" style={i.status === "active" ? { color: "#00BD85" } : i.status === "pending" ? { color: "#ffe511" } : i.status === "idle" ? { color: "#ffe511" } : null}>{i?.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                </Table>
              </Container>
            )
            : null}

      {/* pagination won't appear till the retailer intial table appear and the user has viewAllRetailersListPermission permisssion */}
      {(retailer && retailer.length !== 0) && viewAllRetailersListPermission
        ? (
          <Row>
            <Col xs="12" className="d-flex justify-content-center align-items-center mt-4">
              <Pagination className="retailer-table-pagination" style={{ width: "50%", flexFlow: "wrap" }}>
                {/* first page */}
                {current_pagination_item !== 0
                  && <Pagination.First onClick={() => { fetchFirstPaginationPage(); }} />}

                {/* prev page */}
                {current_pagination_item > 0 && items[0] !== 0 ? (
                  <Pagination.Prev onClick={() => { decrementPaginationItem(); }} />
                ) : null}

                {/* all pages */}
                {
                  items.map((i) => (
                    <Pagination.Item active={current_pagination_item === i} key={i} onClick={() => { fetchNewRetailerPage(i); }}>
                      {i + 1}
                    </Pagination.Item>
                  ))
                }

                {/* next page */}
                {!nextBtnPaginationItemsFlag
                  && <Pagination.Next onClick={() => { incrementPaginationItem(); }} />}

                {/* last page */}
                {current_pagination_item + 1 < paginationItemsCount
                  && (<Pagination.Last onClick={() => { fetchLastPaginationPage(); }} />)}

              </Pagination>

            </Col>
          </Row>
        )
        : null}

    </div>
  );
};

export default RetailerTable;

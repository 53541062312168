import React, { useState } from 'react'
import { Modal, Container } from 'react-bootstrap'
import { BsPersonPlusFill, BsPersonXFill } from 'react-icons/bs'
import { putToAgel } from '../../utils/api'
import { default_log } from '../../utils/static'

//components
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn'



export default function UpdateProfileImg(props) {

    const {
        setProfileImgModal,
        imgSrc,
        setImgSrc,
        id,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
    } = props

    const [corruptedImg, setCorruptedImg] = useState(false)

    // btn state checker
    const [saveImgBtn, setSaveImgBtn] = useState(false)

    const uploadProfileImg = async (e) => {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);

        fileReader.onload = async (event) => {
            setSaveImgBtn(true) //open loader indicator till request complete

            var data = {
                "wholesaler": {
                    "logo_image": event.target.result
                }
            }
            try {
                await putToAgel(`${process.env.React_APP_WS_SAVE_EDITS_ACCOUNT}${id}/`, data)
                setImgSrc(event.target.result)
                setSuccessModal(true)
            }
            catch (e) {
                setErrorMsgInFailedModal(e?.response?.data?.message)
                setFailedModalWithErrorMsg(true)
            }
            setSaveImgBtn(false)
            setProfileImgModal(false)
        }
    }



    const deleteImage = async () => {
        setSaveImgBtn(true) //open loader indicator till request complete
        var data = {
            "wholesaler": {
                "logo_image": default_log
            }
        }
        try {
            /* save edits in account */
            await putToAgel(`${process.env.React_APP_WS_SAVE_EDITS_ACCOUNT}${id}/`, data)
            setImgSrc(default_log)
            setSuccessModal(true)
        }
        catch (e) {
            setErrorMsgInFailedModal(e?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        setSaveImgBtn(false)
        setProfileImgModal(false)
    }


    return (
        <div>
            <Modal show={true} onHide={() => setProfileImgModal(false)} className="dashboard-modal mt-5" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>

                    {/* image */}
                    {!corruptedImg ?
                        (<Container className="d-flex justify-content-center align-items-center">
                            <a href={imgSrc} target="_blank">
                                <img src={imgSrc} onError={() => setCorruptedImg(true)} className="img-fluid" style={{ width: "9vw", height: "9vw" }} />
                            </a>
                        </Container>
                        ) : null
                    }

                    {/* loading during changing images */}
                    {saveImgBtn &&
                        <Container className='d-flex justify-content-center align-items-center mt-2'>
                            <LoadingBtnSpinner />
                        </Container>
                    }

                    {/* options button icons */}
                    <Container className="d-flex justify-content-center align-items-center mt-4">
                        <div className='text-center'>
                            <label>
                                <BsPersonPlusFill className="profile-img-setting-icon" style={{ color: '#00BD85', borderColor: "#00BD85" }} />
                                <input type="file" style={{ display: "none" }} onChange={uploadProfileImg} />
                            </label>
                            <p className='text-label'>Add Photo</p>
                        </div>
                        <div className='text-center'>
                            <BsPersonXFill className="profile-img-setting-icon"
                                style={{ color: 'red', borderColor: "red" }}
                                onClick={deleteImage}
                            />
                            <p className='text-label'>Delete Photo</p>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

        </div>
    )
}
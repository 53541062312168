import React, { useState } from 'react'
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { FiFileText } from 'react-icons/fi'

//components
import ActivationForm from './ActivationForm';


export default function Stores(props) {

    let navigate = useNavigate()

    const {
        wholesalerStores,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        setSuccessModal,
    } = props

    const [showAF, setShowAF] = useState(false);

    const [storeIdAf, setStoreIdAf] = useState(0)

    const [APIdFromEachStore, setAPIdFromEachStore] = useState("")

    return (
        <div className="mt-5">
            <Table>
                <thead className="thead-profile">
                    <tr>
                        <th className="text-center">Store Name</th>
                        <th className="text-center">City</th>
                        <th className="text-center">
                            Activation form
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {
                        wholesalerStores && wholesalerStores.map(i =>
                        (
                            <tr key={i && i.store && i.store.id} className='role-table-text clickable-row text-center'>
                                <td onClick={() => navigate(`/wholesalers/list/stores/${i.store && i.store.id}`)}>{i && i.store && i.store.name}</td>
                                <td onClick={() => navigate(`/wholesalers/list/stores/${i.store && i.store.id}`)}>{i && i.store && i.store.City && i.store.City.EnName}</td>
                                <td>
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip>Check store activation form </Tooltip>}
                                    >
                                        <span>
                                            <FiFileText
                                                className='message-btn'
                                                onClick={() => {
                                                    setShowAF(true)
                                                    setStoreIdAf(i.store && i.store.id)
                                                    setAPIdFromEachStore(i.wActivationProcess && i.wActivationProcess.id)
                                                }} />
                                        </span>
                                    </OverlayTrigger>

                                </td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </Table>
            {/* open activation form in account on click*/}
            {showAF ?
                <ActivationForm
                    setShowAF={setShowAF}
                    id={storeIdAf}
                    APIdFromEachStore={APIdFromEachStore}
                    setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                    setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                    setSuccessModal={setSuccessModal}
                /> : null}
        </div>

    )
}
import React, { useState, useEffect } from 'react'

import { Modal, Form, Container, Row, Col } from "react-bootstrap"

import DynamicAF from '../RetailerApModals/DynamicAFAfterAgentVisit ';

const Reinstate = (props) => {
    //props

    let {
        setReinstateModal,
        setCancelConfirmModal,
        setReinstateConfirmModal,
        current,
    } = props;
    console.log("props=", props)

    const [AFDynamicData, setAFDynamicData] = useState([])
    const [AFStaticData, setAFStaticData] = useState([])
    const [AFRetailerData, setAFRetailerData] = useState([]);
    const [agentAFStaticData, setAgentAFStaticData] = useState([])
    const [estimatedCredit, setEstimatedCredit] = useState("")
    const [logs, setLogs] = useState([1]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                //logs
                // const logsResponse=await getFromAgel(`/${current.id}`)
                // console.log("id=",current.id)
                // console.log("logsResponse=",logsResponse)
                // setLogs(logsResponse)
            }
            catch (e) {
                console.log("error=", e)
                console.log("id=", current.id)
            }
        }
        fetchData()
    }, [])


    return (
        <div>
            <Modal show={true} onHide={() => setReinstateModal(false)} centered size="lg" className="AP-modal" style={{ marginTop: "5em" }}>
                {/* top */}
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body style={{ marginBottom: "5em" }}>
                    <Container style={{ marginTop: "-1.5rem" }}>
                        <Row>
                            <Col xs={12} className="modal-header-title">
                                <p>{AFStaticData && AFStaticData.name}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} className='d-flex  justify-content-between align-items-start'>
                                <p className="modal-title">Agent name <span className='text-muted' style={{ marginLeft: "0.3rem" }}>{agentAFStaticData && agentAFStaticData.name && agentAFStaticData.name}</span></p>
                                <p className="modal-title">request date <span className='text-muted' style={{ marginLeft: "0.3rem" }}>{(new Date(AFDynamicData && AFDynamicData.createdAt).toLocaleDateString())}</span></p>
                                <p className="modal-title">visit date <span className='text-muted' style={{ marginLeft: "0.3rem" }}>{(new Date(AFDynamicData && AFDynamicData.updatedAt).toLocaleDateString())}</span></p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} className='d-flex text-end justify-content-between align-items-start mt-2'>
                                <p className="modal-title">Estimated credit<span className='text-muted' style={{ marginLeft: "0.3rem" }}>{estimatedCredit && estimatedCredit}</span></p>
                            </Col>
                        </Row>

                    </Container>

                    <Container className="mt-3" dir="rtl">
                        <Form>

                            {/* dynamic  */}
                            <DynamicAF
                                AFDynamicData={AFDynamicData}
                                AFstaticData={AFStaticData}
                                setAFDynamicData={setAFDynamicData}
                                setAFStaticData={setAFStaticData}
                                AFRetailerData={AFRetailerData}
                                setAFRetailerData={setAFRetailerData}
                                setAgentAFStaticData={setAgentAFStaticData}
                                agentAFStaticData={agentAFStaticData}
                                setEstimatedCredit={setEstimatedCredit}
                                id={current.id}
                                editCase={true}
                                editableLatAndLong={true}
                            />

                            {/* action btns */}
                            <Row>
                                <Col xs="8" className='d-flex justify-content-start align-items-center mt-5 ' >
                                    <button className="cancel-retention-modal-btn" type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setReinstateModal(false);
                                            setCancelConfirmModal(true);
                                        }}
                                    >Cancel</button>
                                    <button className="reinstate-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setReinstateModal(false);
                                            setReinstateConfirmModal(true);
                                        }}
                                    >
                                        Reinstate
                                    </button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>

                    {/* logs */}
                    {/* <Container>
                    <p className="logs-title mt-5">All Logs</p>
                    <div className='Ap-logs' style={{paddingLeft:"1.5em"}}>
                        {logs.map((i)=>
                        <span>
                            <div className='d-flex'>
                                <p className='logs-data'>mohmaed ali</p>
                                <p className='logs-data'>22/10/2022 </p>
                                <p className='logs-data'>3:00pm</p>
                            </div>
                            <div className='info'>
                                created form
                            </div>
                        </span>
                        )}
                    </div>
                </Container> */}

                </Modal.Body>
            </Modal>
        </div>

    )
}

export default Reinstate

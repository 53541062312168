import React, { useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import NotFoundText from '../common/notFoundText/notFoundText';
import ActivationProcessTablesChunks from './ActivationProcessTablesChunks';

const WSActivationTable = (props) => {


    //props
    const {
        setRegisterModal,
        setCurrent,
        setFirstVisitModal,
        setFirstTeamModal,
        setSecondTeamModal,
        setFinalModal,
        pendingLoadingSkeleton,
        firstVisitLoadingSkeleton,
        firstTeamLoadingSkeleton,
        secondTeamLoadingSkeleton,
        finalLoadingSkeleton,
        pendingList,
        fetchPendingApData,
        firstVisitList,
        fetchfirstVisitApData,
        firstTeamList,
        fetchFirstTeamApData,
        secondTeamList,
        fetchSecondTeamApData,
        finalList,
        fetchFinalApData,
        selectedCity,
        selectedIndustry,
        filterByNameOrPhoneNumber,
        pendingCounter,
        firstVisitCounter,
        firstTeamCounter,
        secondTeamCounter,
        finalCounter,
        setPendingCounter,
        setFirstVisitCounter,
        setFirstTeamCounter,
        setSecondTeamCounter,
        setFinalCounter,
        pendingWasLastList,
        firstVisitWasLastList,
        firstTeamWasLastList,
        secondTeamWasLastList,
        finalWasLastList,
    } = props

    //ref tracking tables scroll
    const pendingRef = useRef();
    const firstVisitRef = useRef();
    const firstTeamRef = useRef();
    const secondTeamRef = useRef();
    const finalRef = useRef();


    //handle scroll event in each table
    const onScrollTableVisits = (phaseRef, fetchPhaseVisits, setPhaseCounter, phaseCounter, phaseWasLastList) => {
        if (phaseRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = phaseRef.current;
            if (scrollTop + clientHeight < scrollHeight) {
                if (!phaseWasLastList) {
                    setPhaseCounter(phaseCounter + 1)
                    fetchPhaseVisits(phaseCounter + 1)
                }
            }
            console.log({ scrollTop, clientHeight, scrollHeight, phaseWasLastList })
        }
    }


    return (
        <div>
            {pendingList.length === 0 && firstVisitList.length === 0 && firstTeamList.length === 0 && secondTeamList.length === 0 && finalList.length === 0 && (selectedCity !== "" || selectedIndustry !== "" || filterByNameOrPhoneNumber !== "") && !pendingLoadingSkeleton && !firstVisitLoadingSkeleton && !firstTeamLoadingSkeleton && !secondTeamLoadingSkeleton && !finalLoadingSkeleton ?
                <div className='tables-layout'>
                    <NotFoundText />
                </div>
                :
                <div className='Ap-table-layout d-flex'>

                    {/* pending */}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(pendingRef, fetchPendingApData, setPendingCounter, pendingCounter, pendingWasLastList) }}
                        ref={pendingRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={pendingList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setRegisterModal}
                            tableTitle={"Team Lead"}
                            tableStatus={"pending"}
                            btnName={"Registered"}
                            btnClass={"pending-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>

                    {/* firstVisit */}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(firstVisitRef, fetchfirstVisitApData, setFirstVisitCounter, firstVisitCounter, firstVisitWasLastList) }}
                        ref={firstVisitRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={firstVisitList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setFirstVisitModal}
                            tableTitle={"1st Visit"}
                            tableStatus={"firstVisit"}
                            btnName={"Informer"}
                            btnClass={"firstVisit-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>

                    {/* firstTeam */}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(firstTeamRef, fetchFirstTeamApData, setFirstTeamCounter, firstTeamCounter, firstTeamWasLastList) }}
                        ref={firstTeamRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={firstTeamList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setFirstTeamModal}
                            tableTitle={"Team 1"}
                            tableStatus={"firstTeam"}
                            btnName={"Informer review"}
                            btnClass={"firstTeam-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>

                    {/* secondTeam */}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(secondTeamRef, fetchSecondTeamApData, setSecondTeamCounter, secondTeamCounter, secondTeamWasLastList) }}
                        ref={secondTeamRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={secondTeamList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setSecondTeamModal}
                            tableTitle={"Team 2"}
                            tableStatus={"secondTeam"}
                            btnName={"Validation"}
                            btnClass={"secondTeam-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>

                    {/*final*/}
                    <Container fluid className='scroll-table'
                        onScroll={() => { onScrollTableVisits(finalRef, fetchFinalApData, setFinalCounter, finalCounter, finalWasLastList) }}
                        ref={finalRef}
                    >
                        <ActivationProcessTablesChunks
                            visitsList={finalList}
                            setCurrent={setCurrent}
                            pendingLoadingSkeleton={pendingLoadingSkeleton}
                            firstVisitLoadingSkeleton={firstVisitLoadingSkeleton}
                            firstTeamLoadingSkeleton={firstTeamLoadingSkeleton}
                            secondTeamLoadingSkeleton={secondTeamLoadingSkeleton}
                            finalLoadingSkeleton={finalLoadingSkeleton}
                            setModal={setFinalModal}
                            tableTitle={"Manager"}
                            tableStatus={"final"}
                            btnName={"Activation"}
                            btnClass={"final-btn"}
                            pendingCounter={pendingCounter}
                            firstVisitCounter={firstVisitCounter}
                            firstTeamCounter={firstTeamCounter}
                            secondTeamCounter={secondTeamCounter}
                            finalCounter={finalCounter}
                        />
                    </Container>
                </div>
            }
        </div>
    )
}

export default WSActivationTable
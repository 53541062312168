import React from 'react'
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'



export default function PaymentsDetails(props) {
    const payments = props.retailerPayment
    const retailerName = props.retailer.name
    const retailerId = props.retailerId

    let navigate = useNavigate()


    const seeInvoices = (paymentId) => {
        navigate(`/retailers/list/${paymentId}/${retailerId}/details/invoices`)
    }
    
    //sort payment dscending
    const sortPayments = payments.sort((a, b) => (b.payment.id) - (a.payment.id))

    return (
        <div className="mt-5">
            <Table className='text-center'>
                <thead className="thead-profile">
                    <tr>
                        <th>Id</th>
                        <th>Date</th>
                        <th>Retailer Name</th>
                        <th >Wholesaler Name</th>
                        <th >W/Store</th>
                        <th>Paid</th>
                        <th>Price</th>
                        <th>Payment plan</th>
                        <th>Number of installment</th>



                    </tr>
                </thead>
                <tbody >
                    {sortPayments?.map((i) =>
                    (
                        <tr key={i?.payment?.id} className='role-table-text clickable-row' onClick={() => { seeInvoices(i?.payment?.id) }}>
                            <td>
                                {i?.payment?.id}
                            </td>
                            <td>{
                                new Date(i && i.payment && i.payment.createdAt).toLocaleDateString({},{timeZone: "UTC"})
                            }</td>
                            <td>{retailerName}</td>
                            <td>{i && i.payment && i.payment.Transaction && i.payment.Transaction.Wholesaler && i.payment.Transaction.Wholesaler.name && i.payment.Transaction.Wholesaler.name}</td>
                            <td >{i && i.payment && i.payment.Transaction && i.payment.Transaction.Wstore && i.payment.Transaction.Wstore.name}</td>
                            <td className={i && i.payment && i.payment.status ? "profile-active" : "profile-pending"} >{i.payment.status ? "Paid" : "Unpaid"}</td> {/* onClick={() => { seeInvoices(5) }} lma n3ml invoices no7t de */}
                            <td className="profile-active">{i && i.payment && i.payment.price}</td>
                            <td >{i && i.installment && i.installment.PaymentPlan && i.installment.PaymentPlan.name && i.installment.PaymentPlan.name}</td>
                            <td >{i && i.installment && i.installment.PaymentPlan && i.installment.PaymentPlan.numberOf_periods}</td>
                        </tr>
                    )
                    )
                    }

                </tbody>
            </Table>
        </div>
    )
}

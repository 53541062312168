import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';

import './Collector.css';
import { getFromAgel } from '../../utils/api';

// components
import SuccessModal from '../../components/common/SuccessModal';
import Failed_Modal from '../../components/common/FailedModal';
import CollectorsTable from '../../components/Collectors/CollectorsTable';
import CreateCollector from '../../components/Collectors/CreateCollector';
import EditCollector from '../../components/Collectors/EditCollector';
import AssignCollectorsToRetailers from '../../components/Collectors/AssignCollectorsToRetailers';
import ViewAssignedRetailers from '../../components/Collectors/ViewAssignedRetailers';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen';
import ResetCollectorPassword from '../../components/Collectors/ResetCollectorPassword ';

//permission
import { userPermissionsAndActionsContext } from '../../App';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';


export default function Collectors() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext);
    let viewCollectorPermission = userValidPermissionAndAction(validPermissionsAndActions, "collector", "viewCollector");
    let viewCitiesPermission = userValidPermissionAndAction(validPermissionsAndActions, "city", "viewCity");

    const [collectors, setCollectors] = useState([]);
    const [pagination_count_of_collector, set_pagination_count_of_collector] = useState("");
    const [current_pagination_item, set_current_pagination_item] = useState(0);
    const [collectorReuestLoader, setCollectorReuestLoader] = useState(false);
    const [cities, setCities] = useState([]);
    const [currentCollector, setCurrentCollector] = useState('');

    //modals
    const [createCollectorModal, setCreateCollectorModal] = useState(false);
    const [editCollectorModal, setEditCollectorModal] = useState(false);
    const [assignCollectorsToRetailersModal, setAssignCollectorsToRetailersModal] = useState(false);
    const [viewAssignedRetailersModal, setViewAssignedRetailersModal] = useState(false);
    const [resetCollectorPasswordModal, setResetCollectorPasswordModal] = useState(false);



    // search
    const [collectorInputSearch, setCollectorInputSearch] = useState("") // search input state

    // server modal response success/failed
    const [successModal, setSuccessModal] = useState(false);
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false);
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("");

    const fetchCollectorData = async () => {
        setCollectorReuestLoader(true)
        try {
            // get collectors data
            const collectorDataResponse = await getFromAgel(`dashboard/collectors`, {
                params: {
                    page: current_pagination_item,
                    ...(collectorInputSearch ? { "filterByPhoneOrName": collectorInputSearch } : {}),
                }
            })
            setCollectors(collectorDataResponse.data.data);
            set_pagination_count_of_collector(collectorDataResponse.data.count);
        }
        catch (err) {
            // console.log('error=', err)
        }
        setCollectorReuestLoader(false)
    }

    useEffect(() => {
        fetchCollectorData();
    }, [current_pagination_item])

    const fetchCities = async () => {
        try {
            //get cities //view cities permission
            if (viewCitiesPermission) {
                const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
                setCities(cityResponse.data.cities)
            }

        }
        catch (err) {
            // console.log('no cities permission')
        }
    }

    useEffect(() => {
        fetchCities()
    }, [])

    // const sortedCollector = collectors?.sort((a, b) => (a.id) - (b.id));

    //search btn fn
    const search = () => {
        fetchCollectorData()
    }

    if (viewCollectorPermission) {
        return (
            <div>
                <Row className="layout-top">
                    <Col xs="3 text-start">
                        {/* input */}
                        <div className='input-wrapper'>
                            <input type="text" placeholder="name/phone" value={collectorInputSearch} onChange={(e) => { setCollectorInputSearch(e.target.value); set_current_pagination_item(0); }} />
                        </div>
                    </Col>
                    <Col xs="4 text-start">
                        {/* search */}
                        <button className='role-search-btn' onClick={() => search()}>Search</button>
                    </Col>
                    <Col xs="5 text-end">
                        {/* create role btn */}
                        <div>
                            <button className='add-role-btn' onClick={() => { setCreateCollectorModal(true) }}>
                                <span className='d-flex align-items-center justify-content-center'>
                                    <MdAddCircle size={20} className="add-role-btn-icon" />
                                    <p>Add Collector</p>
                                </span>
                            </button>
                            {
                                createCollectorModal ?
                                    <CreateCollector
                                        cities={cities}
                                        setCreateCollectorModal={setCreateCollectorModal}
                                        updateData={fetchCollectorData}
                                        setSuccessModal={setSuccessModal}
                                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                    />
                                    : null
                            }
                        </div>
                    </Col>
                </Row>

                {/* collectors table */}
                <CollectorsTable
                    collectors={collectors}
                    fetchCollectorData={fetchCollectorData}
                    pagination_count_of_collector={pagination_count_of_collector}
                    current_pagination_item={current_pagination_item}
                    set_current_pagination_item={set_current_pagination_item}
                    setCurrentCollector={setCurrentCollector}
                    collectorReuestLoader={collectorReuestLoader}
                    setEditCollectorModal={setEditCollectorModal}
                    setAssignCollectorsToRetailersModal={setAssignCollectorsToRetailersModal}
                    setResetCollectorPasswordModal={setResetCollectorPasswordModal}
                    setViewAssignedRetailersModal={setViewAssignedRetailersModal}
                />


                {/* EDit model */}
                {
                    editCollectorModal ?
                        <EditCollector
                            setEditCollectorModal={setEditCollectorModal}
                            currentCollector={currentCollector}
                            cities={cities}
                            updateData={fetchCollectorData}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null
                }

                {/* assign collectors to retailers model */}
                {
                    assignCollectorsToRetailersModal ?
                        <AssignCollectorsToRetailers
                            setAssignCollectorsToRetailersModal={setAssignCollectorsToRetailersModal}
                            collectors={collectors}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null
                }

                {/* view assigned retailers model */}
                {
                    viewAssignedRetailersModal ?
                        <ViewAssignedRetailers
                            setViewAssignedRetailersModal={setViewAssignedRetailersModal}
                            currentCollector={currentCollector}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null
                }

                {/* Reset collector Password model */}
                {
                    resetCollectorPasswordModal ?
                        <ResetCollectorPassword
                            setResetCollectorPasswordModal={setResetCollectorPasswordModal}
                            currentCollector={currentCollector}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null
                }

                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={fetchCollectorData}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }


            </div>
        )
    }
    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }

}

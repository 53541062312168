import React, { useEffect, useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { getFromAgel, postToAgel } from '../../../utils/api';


export default function CreateLead(props) {

    let navigate = useNavigate()

    const {
        setCreateLeadModal,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal,
        userType
    } = props


    //data array of eact select box
    const [cities, setCities] = useState([])
    const [industries, setIndustries] = useState([])
    const [sources, setSources] = useState([])
    const [accountManagers, setAccountManagers] = useState([])
    const [customerAccounts, setCustomerAccounts] = useState([])

    // inputs states
    const [name, setName] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")
    const [alternativeNumber, setAlternativeNumber] = useState("")
    const [storeName, setStoreName] = useState("")
    const [address, setAddress] = useState("")

    // selection states
    const [filterOnCity, setFilterOnCity] = useState('');
    const [filterOnIndustry, setFilterOnIndustry] = useState('');
    const [filterOnSources, setFilterOnSources] = useState('');
    const [filterOnAccountManagers, setFilterOnAccountManagers] = useState('');
    const [filterOnCustomerAccounts, setFilterOnCustomerAccounts] = useState('');

    //selection validation states
    const [citiesSelectionValidation, setCitiesSelectionValidation] = useState(false)
    const [industriesSelectionValidation, setIndustriesSelectionValidation] = useState(false)
    const [sourcesSelectionValidation, setSourcesSelectionValidation] = useState(false)
    const [accountManagersSelectionValidation, setAccountManagersSelectionValidation] = useState(false)
    // const [customerAccountsSelectionValidation, setCustomerAccountsSelectionValidation] = useState(false)

    const fetchfiltersSelectorsData = async () => {
        try {
            //get cities
            const cityResponse = await getFromAgel(`${process.env.React_APP_GET_CITIES}`);
            console.log("cityResponse=", cityResponse.data.cities)
            setCities(cityResponse.data.cities)

            //get industries
            const industryResponse = await getFromAgel(`${process.env.React_APP_GET_INDUSTRIES}`);
            console.log("industryResponse=", industryResponse.data.industries)
            setIndustries(industryResponse.data.industries)

            //get sources
            const sourcesResponse = await getFromAgel(`dashboard/sources/`);
            console.log("sourcesResponse=", sourcesResponse.data)
            // setSources(sourcesResponse.data)

            //get accountManagers
            const accountManagersResponse = await getFromAgel(`${""}`);
            console.log("accountManagersResponse=", accountManagersResponse.data)
            // setAccountManagers(accountManagersResponse.data)

            //get customerAccounts
            const customerAccountsResponse = await getFromAgel(`${""}`, { params: { userType: userType } }); //lazm ab3t hena params hya ws wla retailer
            console.log("customerAccountsResponse=", customerAccountsResponse.data) //ha5od names w id w phones 
            // setCustomerAccounts(customerAccountsResponse.data)            
        }
        catch (e) {
            //
        }
    }
    useEffect(() => {
        fetchfiltersSelectorsData()
    }, [])


    const createNewLead = async (e) => {
        e.preventDefault();

        var data = {
            "name": name,
            "phoneNumber": mobileNumber,
            "secondNumber": alternativeNumber,
            "CityId": filterOnCity,
            "IndustryId": filterOnIndustry,
            "SourceId": filterOnSources,
            "storeAdress": address,
            "storeName": storeName,
            "accountManagerId": filterOnAccountManagers,
            "userType": userType,
        }
        if (filterOnCity !== "" && filterOnIndustry !== "" && filterOnSources !== "" && filterOnAccountManagers !== "" && filterOnCustomerAccounts !== "") {

            try {
                await postToAgel(`dashboard/CRM/lead/create`, data)
                setSuccessModal(true)
            }
            catch (error) {
                // console.log({error})
                // error.response.data.message[0] ? setErrorMsgInFailedModal(error.response.data.message[0]) : setErrorMsgInFailedModal(error.response.data.message)
                setFailedModalWithErrorMsg(true)
            }
            setCreateLeadModal(false);
            updateData();
        }
        else if (filterOnCity === "") {
            setCitiesSelectionValidation(true)
        }
        else if (filterOnIndustry === "") {
            setIndustriesSelectionValidation(true)
        }
        else if (filterOnSources === "") {
            setSourcesSelectionValidation(true)
        }
        else if (filterOnAccountManagers === "") {
            setAccountManagersSelectionValidation(true)
        }
        // else if (filterOnCustomerAccounts === "") {
        //     setCustomerAccountsSelectionValidation(true)
        // }
    }



    //customer account filteration and navigation
    
    const searchForAccountsUsingPhoneNumber = (e) => {
        let temp = e.key
        if (temp !== "Backspace") {
            setFilterOnCustomerAccounts((prev) => prev + temp)
        }
        else if (temp === "Backspace") {
            setFilterOnCustomerAccounts((prev) => prev.slice(0, -1))
        }
    }
    // console.log({ filterOnCustomerAccounts })

    
    //34an lma yege y search feha b phone number
    let customerAccountAfterFilteration = filterOnCustomerAccounts !== "" ? customerAccounts.filter((i) => i && i.phonenumber.inclues(filterOnCustomerAccounts)) : customerAccounts

    const navigateToCustomerAccountProfile = (id) => {
        if (userType === "wholesaler") {
            navigate(`/wholesalers/list/${id}/details`)
        }
        else if (userType === "retailer") {
            navigate(`/retailers/list/${id}/details`)
        }
    }

    return (
        <div>
            <Modal show={true} onHide={() => setCreateLeadModal(false)} centered size="lg" className="dasboardModal">
                <Modal.Header closeButton className="cancel-msg-modal-header">
                    create new lead
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={createNewLead}>
                        {/* personal info */}
                        <div>
                            <span className='profile-label-text'>
                                personal info
                            </span>

                            <div className='acquisition-modals-containers mt-2' style={{ backgroundColor: "#fcf0e1" }}>
                                <Form.Group className='d-flex align-items-center'>
                                    <Form.Label className="acquisition-create-lead-labels">Name: </Form.Label>
                                    <Form.Control type="text" className="acquisition-create-lead-inputs" placeholder="type..." value={name} onChange={(e) => setName(e.target.value)} required />
                                </Form.Group>

                                <Form.Group className='d-flex align-items-center'>
                                    <Form.Label className="acquisition-create-lead-labels">mobile number: </Form.Label>
                                    <Form.Control type="text" className="acquisition-create-lead-inputs" placeholder="type..." value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} required />
                                </Form.Group>


                                <Form.Group className='d-flex align-items-center'>
                                    <Form.Label className="acquisition-create-lead-labels">alternative: </Form.Label>
                                    <Form.Control type="text" className="acquisition-create-lead-inputs" placeholder="type..." value={alternativeNumber} onChange={(e) => setAlternativeNumber(e.target.value)} />
                                </Form.Group>

                                <Form.Group className='d-flex align-items-center mt-1'>
                                    <Form.Label className="acquisition-create-lead-labels">city: </Form.Label>
                                    <Select
                                        className="acquisition-create-lead-select"
                                        defaultValue={{ "label": "select...", "value": 0 }}
                                        onChange={(e) => { setFilterOnCity(e.value) }}
                                        options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                                    />
                                </Form.Group>
                                {/* cities validation msg */}
                                {citiesSelectionValidation && (<p className="select-error-msg text-end ml-2 mt-1">select a city!</p>)}

                            </div>
                        </div>

                        {/* Business info */}
                        <div className='mt-3'>
                            <span className='profile-label-text'>
                                Business info
                            </span>

                            <div className='acquisition-modals-containers mt-2' style={{ backgroundColor: "#d9f5e5" }}>
                                <Form.Group className='d-flex align-items-center'>
                                    <Form.Label className="acquisition-create-lead-labels">store name: </Form.Label>
                                    <Form.Control type="text" className="acquisition-create-lead-inputs" placeholder="type..." value={storeName} onChange={(e) => setStoreName(e.target.value)} required />
                                </Form.Group>

                                <Form.Group className='d-flex align-items-center mt-1 mb-1'>
                                    <Form.Label className="acquisition-create-lead-labels">industry: </Form.Label>
                                    <Select
                                        className="acquisition-create-lead-select"
                                        defaultValue={{ "label": "select...", "value": 0 }}
                                        onChange={(e) => { setFilterOnIndustry(e.value) }}
                                        options={industries.map((industry) => ({ "value": industry.id, "label": industry.name }))}
                                    />
                                </Form.Group>
                                {/* industry validation msg */}
                                {industriesSelectionValidation && (<p className="select-error-msg text-end ml-2 mt-1">select an industry!</p>)}


                                <Form.Group className='d-flex align-items-center'>
                                    <Form.Label className="acquisition-create-lead-labels">address: </Form.Label>
                                    <Form.Control type="text" className="acquisition-create-lead-inputs" placeholder="type..." value={address} onChange={(e) => setAddress(e.target.value)} required />
                                </Form.Group>
                            </div>
                        </div>


                        {/* other */}
                        <div className='mt-3'>
                            <span className='profile-label-text'>
                                other...
                            </span>

                            <div className='acquisition-modals-containers mt-2' style={{ backgroundColor: "rgb(209, 209, 209)" }}>
                                <Form.Group className='d-flex align-items-center'>
                                    <Form.Label className="acquisition-create-lead-labels">source: </Form.Label>
                                    <Select
                                        className="acquisition-create-lead-select"
                                        defaultValue={{ "label": "select...", "value": 0 }}
                                        onChange={(e) => { setFilterOnSources(e.value) }}
                                        options={sources.map((source) => ({ "value": source.id, "label": source.name }))}
                                    />
                                </Form.Group>
                                {/* source validation msg */}
                                {sourcesSelectionValidation && (<p className="select-error-msg text-end ml-2 mt-1">select a source!</p>)}


                                <Form.Group className='d-flex align-items-center'>
                                    <Form.Label className="acquisition-create-lead-labels">account manager: </Form.Label>
                                    <Select
                                        className="acquisition-create-lead-select"
                                        defaultValue={{ "label": "select...", "value": 0 }}
                                        onChange={(e) => { setFilterOnAccountManagers(e.value) }}
                                        options={accountManagers.map((accountManager) => ({ "value": accountManagers.id, "label": accountManagers.name }))}
                                    />
                                </Form.Group>
                                {/* account Manager validation msg */}
                                {accountManagersSelectionValidation && (<p className="select-error-msg text-end ml-2 mt-1">select an account Manager!</p>)}


                                <Form.Group className='d-flex align-items-center'>
                                    <Form.Label className="acquisition-create-lead-labels"> customer account: </Form.Label>
                                    <Select
                                        className="acquisition-create-lead-select"
                                        defaultValue={{ "label": "select...", "value": 0 }}
                                        onChange={(e) => { navigateToCustomerAccountProfile(e.value) }} //hena awl may3ml onchange hay navigate ll account
                                        onKeyDown={(e) => searchForAccountsUsingPhoneNumber(e)}
                                        options={customerAccountAfterFilteration && customerAccountAfterFilteration.map((customerAccount) => ({ "value": customerAccount.id, "label": customerAccount.name }))}
                                    />
                                </Form.Group>
                                {/* customer Account validation msg */}
                                {/* {customerAccountsSelectionValidation && (<p className="select-error-msg text-end ml-2 mt-1">select a customer Account!</p>)} */}

                            </div>
                        </div>

                        <Modal.Footer>
                            <button type="submit" className="role-done-btn">Done</button>
                            <button className="role-close-btn" onClick={() => setCreateLeadModal(false)}>Close</button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal>
        </div>
    )
}

import React, { useState } from 'react'
import Select from 'react-select';
import { Form, Modal, Row, Col, Container } from "react-bootstrap"
import { putToAgel } from '../../utils/api';
import LoadingBtnSpinner from '../common/LoadingBtn/LoadingBtn';


const EditStaffModal = (props) => {
    //props
    let {
        setEditAgentModal,
        currentAgentModal,
        cities,
        updateData,
        setSuccessModal,
        setFailedModalWithErrorMsg,
        setErrorMsgInFailedModal
    } = props;

    //Edit input Btn's values
    const [editName, setEditName] = useState(currentAgentModal?.name);
    const [editPhone, setEditPhone] = useState(currentAgentModal?.phoneNumber);
    const [editCity, setEditCity] = useState(currentAgentModal?.City?.id);
    const [editActive, setEditActive] = useState("");

    //state checker on buttons 
    const [saveEditsBtn, setSaveEditsBtn] = useState(false)


    const submitEdit = async (e) => {

        e.preventDefault();
        setSaveEditsBtn(true);

        var data = {
            'name': editName,
            'phoneNumber': editPhone,
            'CityId': editCity,
            'isActive': editActive === "" ? currentAgentModal.isActive : `${editActive}`,
            // 'password':editPassword
        }
        try {
            await putToAgel(`${process.env.React_APP_EDIT_AGENT}${currentAgentModal.id}/`, data)
            setSuccessModal(true)
        }
        catch (e) {
            setErrorMsgInFailedModal(e?.response?.data?.message)
            setFailedModalWithErrorMsg(true)
        }
        updateData();
        setEditAgentModal(false)
    }
    // const currentCity=cities.filter((c)=>c.id===currentAgentModal.CityId)
    // const currentDefaultCity=currentCity[0]&&currentCity[0].EnName

    return (
        <div>

            <Modal show={true} onHide={() => setEditAgentModal(false)} className="dashboard-modal mt-5" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Container className="mt-3">
                        {/* keda hy3ml form */}
                        <Form onSubmit={submitEdit}>
                            <Form.Group className="mb-3" style={{ marginTop: "-3em" }}>
                                <Form.Label className="form-text">Full name</Form.Label>
                                <Form.Control type="text" className="role-input mt-2" placeholder="Enter Full Name" defaultValue={editName} onChange={(e) => setEditName(e.target.value)} required />
                            </Form.Group>
                            <Row>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">Phone number</Form.Label>
                                        <Form.Control type="text" className="role-input mt-2" placeholder="Enter Phone number" defaultValue={editPhone} onChange={(e) => setEditPhone(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                                <Col xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="form-text">City</Form.Label>
                                        <Select
                                            className="search-select mt-2"
                                            // <Select className="form-select customize-select mt-2"
                                            defaultValue={{ "label": currentAgentModal.City && currentAgentModal.City.EnName, "value": editCity }}
                                            onChange={(e) => { setEditCity(e.value) }}
                                            options={cities.map((city) => ({ "value": city.id, "label": city.EnName }))}
                                        />
                                    </Form.Group>

                                </Col>
                            </Row>

                            <Row>
                                <Form.Group className="mb-3">
                                    <Form.Check type="checkbox" className="status form-text mt-3" label="isActive" defaultChecked={currentAgentModal.isActive} onChange={(e) => setEditActive(e.target.checked)} />
                                </Form.Group>
                            </Row>


                            <Modal.Footer className='d-flex justify-content-end align-items-center' style={{ width: "100%" }}>
                                <button className="role-add-btn-with-loader-spinner" type="submit" disabled={saveEditsBtn ? true : false}>
                                    {saveEditsBtn ?
                                        (<LoadingBtnSpinner />)
                                        : (<span>Done</span>)}
                                </button>

                                <button className="roleCloseBtn" onClick={() => setEditAgentModal(false)}>Close</button>
                            </Modal.Footer>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default EditStaffModal

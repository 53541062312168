
import React, { useContext, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { MdAddCircle } from 'react-icons/md'

import './Roles.css'

import { getFromAgel } from '../../utils/api';
import { userPermissionsAndActionsContext } from '../../App';

// components
import EditRoleModal from '../../components/Roles/EditRoleModal';
import CreateRoleModal from '../../components/Roles/CreateRoleModal';
import RoleCards from '../../components/Roles/RoleCards';
import SuccessModal from '../../components/common/SuccessModal';
import Failed_Modal from '../../components/common/FailedModal';
import AuthorizationRequiredScreen from '../AuthorizationRequiredScreen/AuthorizationRequiredScreen';
import { userValidPermissionAndAction } from '../../utils/permissionsAndActions';



export default function Roles() {

    const validPermissionsAndActions = useContext(userPermissionsAndActionsContext)
    let viewRolePermission = userValidPermissionAndAction(validPermissionsAndActions,"", "")

    //modal
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [currentEditRoleId, setCurrentEditRoleId] = useState({});


    const [roles, setRoles] = useState();
    const [allPermissions, setAllPermissions] = useState([])
    const [currentSelectedPermission, setCurrentSelectedPermission] = useState()
    const [actionsOfSpecificPermission, setActionsOfSpecificPermission] = useState([])


    //search
    const [roleSearch, setRoleSearch] = useState("") // search input state
    const [searchResult, setSearchResult] = useState("") //search btn

    //server modal response success/failed
    const [successModal, setSuccessModal] = useState(false)
    const [failedModalWithErrorMsg, setFailedModalWithErrorMsg] = useState(false)
    const [errorMsgInFailedModal, setErrorMsgInFailedModal] = useState("")



    const fetchData = async () => {
        try {
            // get roles
            const rolesResponse = await getFromAgel(`${process.env.React_APP_GET_ROLES}`)
            setRoles(rolesResponse.data.roles)

            // get all permissions
            const getAllPermissionsResponse = await getFromAgel('dashboard/permission')
            setAllPermissions(getAllPermissionsResponse.data.permissions)

            // const permissionsResponse = await getFromAgel(`${process.env.React_APP_GET_Permissions}`)
            // setPermission(permissionsResponse.data.permissions) //elmfrod hena a5od el response de el obj el gwaha a update beh el viewRoles{}
        }
        catch (err) {
            // console.log('error=', err)
        }
    }

    useEffect(() => {

        fetchData()
    }, [])


    // get actions
    async function fetchActionsBasedOnSelectedPermissions() {
        if (currentSelectedPermission) {
            const actionsResponse = await getFromAgel(`dashboard/action/${currentSelectedPermission}`)
            setActionsOfSpecificPermission(actionsResponse.data.permissionActions)
        }
    }
    useEffect(() => {
        fetchActionsBasedOnSelectedPermissions()
    }, [currentSelectedPermission])


    const sortedRoles = roles && roles.sort((a, b) => (a.id) - (b.id));

    //search btn fn
    const search = () => {
        setSearchResult(roleSearch)
    }


    //update role result
    let roleSearchResult = searchResult !== "" ? roles.filter((role) => role.name.toLowerCase().includes(searchResult.toLowerCase())) : roles

    if (viewRolePermission) {

        return (
            <div>
                <Row className="layout-top">
                    <Col xs="3 text-start">
                        {/* input */}
                        <div className='input-wrapper'>
                            <input type="text" placeholder="Role Name" value={roleSearch} onChange={(e) => setRoleSearch(e.target.value)} />
                        </div>
                    </Col>
                    <Col xs="4 text-start">
                        {/* search */}
                        <button className='role-search-btn' onClick={() => search()}>Search</button>
                    </Col>
                    <Col xs="5 text-end">
                        {/* create role btn */}
                        <div>
                            <button className='add-role-btn' onClick={() => { setShowCreate(true) }}>
                                <span className='d-flex align-items-center justify-content-center'>
                                    <MdAddCircle size={20} className="add-role-btn-icon" />
                                    <p>Add Role</p>
                                </span>
                            </button>
                            {
                                showCreate ?
                                    <CreateRoleModal
                                        updateData={fetchData}
                                        setShowCreate={setShowCreate}
                                        allPermissions={allPermissions}
                                        currentSelectedPermission={currentSelectedPermission}
                                        setCurrentSelectedPermission={setCurrentSelectedPermission}
                                        actionsOfSpecificPermission={actionsOfSpecificPermission}
                                        setSuccessModal={setSuccessModal}
                                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                                        setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                                    />
                                    : null
                            }
                        </div>
                    </Col>
                </Row>



                {/* here we render all of roles table */}
                <RoleCards
                    roles={roleSearchResult}
                    setShowEdit={setShowEdit}
                    setCurrentEditRoleId={setCurrentEditRoleId}
                />


                {/* EDit modal */}
                {
                    showEdit ?
                        <EditRoleModal

                            updateData={fetchData}
                            currentEditRoleId={currentEditRoleId}
                            setShowEdit={setShowEdit}
                            allPermissions={allPermissions}
                            currentSelectedPermission={currentSelectedPermission}
                            setCurrentSelectedPermission={setCurrentSelectedPermission}
                            actionsOfSpecificPermission={actionsOfSpecificPermission}
                            setSuccessModal={setSuccessModal}
                            setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                            setErrorMsgInFailedModal={setErrorMsgInFailedModal}
                        />
                        : null
                }

                {/* success server response model */}
                {
                    successModal ?
                        <SuccessModal
                            setSuccessModal={setSuccessModal}
                            updateData={fetchData}
                        />
                        : null
                }

                {/* failed server response model with error msg */}

                {failedModalWithErrorMsg ?
                    (<Failed_Modal
                        setFailedModalWithErrorMsg={setFailedModalWithErrorMsg}
                        errorMsg={errorMsgInFailedModal}
                    />)
                    : null
                }


            </div>
        )
    }

    else {
        return (
            <AuthorizationRequiredScreen />
        )
    }
}
